import { Component, computed, inject, type OnInit } from "@angular/core";
import { ButtonComponent } from "src/app/shared/empowered/base/button/button.component";
import { MenuComponent } from "src/app/shared/empowered/base/menus/menu/menu.component";
import type { MenuItem } from "src/app/shared/empowered/base/menus/menus.models";
import { BaseCellRendererComponent } from "src/app/shared/empowered/base/table/components/cells-renderers/base-cell-renderer.component.ts/base-cell-renderer.component";
import type { WrSubmissionDataViewerModel } from "src/app/tasks/components/shared/services/w-r-submissions-view-model/models/data-viewer-model";
import { WorkRequestMenuItemsService } from "src/app/tasks/components/shared/services/work-request-menu-items/work-request-menu-items.service";
import { WorkRequestSubmissionStatus } from "src/app/tasks/services/work-request-submission-view-model-factory/mappers/work-request-submission-view-model-mapper.service";

@Component({
   selector: "work-request-actions-cell-renderer",
   templateUrl: "./work-request-actions-cell-renderer.component.html",
   styleUrls: ["./work-request-actions-cell-renderer.component.scss"],
   imports: [ButtonComponent, MenuComponent],
   providers: [WorkRequestMenuItemsService],
})
export class WorkRequestActionsCellRendererComponent
   extends BaseCellRendererComponent<WrSubmissionDataViewerModel>
   implements OnInit
{
   private readonly menuItemsService = inject(WorkRequestMenuItemsService);

   public menuItems!: Array<MenuItem>;

   private readonly isPendingWorkRequest = computed(() => {
      return this.cell.status.toLowerCase() === WorkRequestSubmissionStatus.PENDING;
   });

   protected readonly showCellContent = computed(() => {
      return this.menuItems.length > 0 && this.isPendingWorkRequest();
   });

   public ngOnInit() {
      this.menuItems = this.menuItemsService.getItems(this.cell);
   }
}
