import { inject, Injectable } from "@angular/core";
import { ActivatedRoute } from "@angular/router";
import {
   isMobile,
   LimUiModalRef,
   LoadingBarService,
   manageDemo,
   ModalService,
} from "@limblecmms/lim-ui";
import $ from "jquery";
import moment from "moment/moment";
import {
   BehaviorSubject,
   from,
   lastValueFrom,
   type Observable,
   of,
   share,
   startWith,
   switchMap,
} from "rxjs";
import { map, take } from "rxjs/operators";
import { PickAssets } from "src/app/assets/components/pickAssetsModal/pickAssets.modal.component";
import { PopAsset } from "src/app/assets/components/popAssetModal/popAsset.modal.component";
import { ManageAsset } from "src/app/assets/services/manageAsset";
import type { Asset } from "src/app/assets/types/asset.types";
import { PickAssetDataLogType } from "src/app/assets/types/pick-assets.types";
import { TranslateService } from "src/app/languages";
import { InstructionStorageSyncService } from "src/app/lite/local-db/resources/collection/task/instruction/instruction.storage.sync.service";
import { ManageLocation } from "src/app/locations/services/manageLocation";
import { ManageParts } from "src/app/parts/services/manageParts";
import { PurchaseOrderItemType } from "src/app/purchasing/pos/purchase-order-item-type";
import { RequestPurchase } from "src/app/purchasing/pos/requestPurchaseModal/requestPurchase.modal.component";
import {
   ManagePO,
   type PurchaseOrderItemToAddSkeleton,
} from "src/app/purchasing/services/managePO";
import { CustomizePriorities } from "src/app/settings/components/customizePrioritiesModal/customizePriorities.modal.component";
import { CustomizeStatuses } from "src/app/settings/components/customizeStatusesModal/customizeStatuses.modal.component";
import { Confirm } from "src/app/shared/components/global/confrimModal/confirm.modal.component";
import { EmailTemplate } from "src/app/shared/components/global/emailTemplateModal/emailTemplate.modal.component";
import { PickDate } from "src/app/shared/components/global/pickDateModal/pickDate.modal.component";
import { AlertService } from "src/app/shared/services/alert.service";
import { FeatureFlagService } from "src/app/shared/services/feature-flags/feature-flag.service";
import type { IsFeatureEnabledMap } from "src/app/shared/services/feature-flags/feature.types";
import { ManageFeatureFlags } from "src/app/shared/services/feature-flags/manageFeatureFlags";
import type { RequestOptions } from "src/app/shared/services/flannel-api-service";
import { ParamsService } from "src/app/shared/services/params.service";
import type { DataLogEventDefinition } from "src/app/shared/types/dataLog.types";
import { assert } from "src/app/shared/utils/assert.utils";
import { DateUtilsService } from "src/app/shared/utils/date-utils.service";
import { Lookup } from "src/app/shared/utils/lookup";
import { CompleteExternalTask } from "src/app/tasks/components/completeExternalTaskModal/completeExternalTask.modal.component";
import { DeferTask } from "src/app/tasks/components/deferTaskModal/deferTask.modal.component";
import { ImportCompletedTasks } from "src/app/tasks/components/importCompletedTasksModal/importCompletedTasks.modal.component";
import { PickTaskType } from "src/app/tasks/components/pickTaskTypeModal/pickTaskType.modal.component";
import { PopTask } from "src/app/tasks/components/popTaskModal/popTask.modal.component";
import { RecreateWorkRequest } from "src/app/tasks/components/recreateWorkRequestModal/recreateWorkRequest.modal.component";
import type { TaskDataViewerViewModel } from "src/app/tasks/components/shared/components/tasks-data-viewer/task-data-viewer.model";
import {
   InstructionSetService,
   type InstructionSetUpdate,
} from "src/app/tasks/components/shared/services/instruction-sets/instruction-set.service";
import type { TaskEntityFilters } from "src/app/tasks/components/shared/services/tasks-api/task-api.models";
import { TasksApiService } from "src/app/tasks/components/shared/services/tasks-api/tasks-api.service";
import type {
   ChangeDueDateParams,
   DeferTaskParams,
   DeleteTaskParams,
   DuplicateTaskParams,
   EmailReminderParams,
} from "src/app/tasks/components/shared/services/tasks-facade/task-facade.models";
import { TaskInstructionTypeID } from "src/app/tasks/schemata/tasks/instructions/task-instruction.enum";
import { BulkTasks } from "src/app/tasks/services/bulkTasks";
import { FakePercentageTimerService } from "src/app/tasks/services/fake-percentage-timer/fake-percentage-timer.service";
import { ManagePriority } from "src/app/tasks/services/managePriority";
import { ManageStatus } from "src/app/tasks/services/manageStatus";
import { ManageTask } from "src/app/tasks/services/manageTask";
import { ManageTaskItem } from "src/app/tasks/services/manageTaskItem";
import { SetupWorkOrderService } from "src/app/tasks/services/setup-work-order.service";
import { StartWOService } from "src/app/tasks/services/start-wo.service";
import { TaskExporterService } from "src/app/tasks/services/task-exporter-service/task-exporter.service";
import type { Task } from "src/app/tasks/types/task.types";
import { PickUserOrProfileLegacy } from "src/app/users/components/pickUserOrProfileModalLegacy/pickUserOrProfile.modal.component";
import { CredService } from "src/app/users/services/creds/cred.service";
import { ManageLogin } from "src/app/users/services/manageLogin";
import { ManageProfile } from "src/app/users/services/manageProfile";
import { ManageUser } from "src/app/users/services/manageUser";

export type CustomTagListObject = {
   clickTag: (tag: any) => void;
   selectOne: boolean;
   advancedSettings: boolean;
   sources: (string | null)[];
};

export type TaskModalCredentials = {
   credToEditOpenTaskInstructions: boolean;
   deleteOpenTaskCred: boolean;
   deleteCompletedTaskCred: boolean;
   editCompletedTaskCred: boolean;
   startPOCred: boolean;
   tagTaskCred: boolean;
   deleteCommentCred: boolean;
   requestPurchaseCred: boolean;
   recreateWorkRequestCred: boolean;
   addCommentCred: boolean;
   shareTasksCred: boolean;
   viewLaborCostsCred: boolean;
   viewInvoicesCred: boolean;
   superUser: boolean;
   assetCheckInOut: boolean;
   hasLogTimeForOthersCredentials: boolean;
   hasEditCommentCredential: boolean;
   addPartsToOpenTasksCred: boolean;
   allowEditTemplateInstructions: boolean;
};

export type TaskDataMode = "instance" | "template";

/**
 * Wrapper for front-end business logic.
 */
@Injectable({ providedIn: "root" })
export class TasksFacadeService {
   private readonly credService = inject(CredService);
   private readonly alertService = inject(AlertService);
   private readonly manageAsset = inject(ManageAsset);
   private readonly manageFeatureFlags = inject(ManageFeatureFlags);
   private readonly featureFlagService = inject(FeatureFlagService);
   private readonly manageLogin = inject(ManageLogin);
   private readonly manageProfile = inject(ManageProfile);
   private readonly manageTask = inject(ManageTask);
   private readonly manageTaskItem = inject(ManageTaskItem);
   private readonly manageUser = inject(ManageUser);
   private readonly modalService = inject(ModalService);
   private readonly paramsService = inject(ParamsService);
   private readonly manageLocation = inject(ManageLocation);
   private readonly tasksAPIService = inject(TasksApiService);
   private readonly bulkTasks = inject(BulkTasks);
   private readonly startWOService = inject(StartWOService);
   private readonly dateUtils = inject(DateUtilsService);
   private readonly loadingBarService = inject(LoadingBarService);
   private readonly fakePercentageTimerService = inject(FakePercentageTimerService);
   private readonly taskExporterService = inject(TaskExporterService);
   private readonly setupWorkOrderService = inject(SetupWorkOrderService);
   private readonly instructionStorageSyncService = inject(InstructionStorageSyncService);
   private readonly manageParts = inject(ManageParts);
   private readonly managePO = inject(ManagePO);
   private readonly route = inject(ActivatedRoute);
   private readonly manageStatus = inject(ManageStatus);
   private readonly managePriority = inject(ManagePriority);
   private readonly instructionSet = inject(InstructionSetService);
   private readonly t = inject(TranslateService);
   private readonly modalRef = inject(LimUiModalRef, { optional: true });

   public isMobile: boolean = isMobile();

   public dropdownClicked = false;
   protected addPartPopoverCheck: boolean = false;

   private readonly percentageOfTasksLoadedSubject: BehaviorSubject<number> =
      new BehaviorSubject<number>(0);

   private readonly taskNeedRefreshSubject = new BehaviorSubject<number>(0);

   /**
    * Notifies when something in a task has been updated and needs to be refreshed
    */
   public taskNeedRefresh$ = this.taskNeedRefreshSubject
      .asObservable()
      .pipe(startWith(0));

   public percentageOfTasksLoaded$ = this.percentageOfTasksLoadedSubject.asObservable();

   private isAllowedToPrintOrShare: boolean | undefined = undefined;
   private isAllowedToExport: boolean | undefined = undefined;
   private isAllowedToExportCompletedTasks: boolean | undefined = undefined;

   #dataLogSection: string | undefined;
   #isInModal: boolean = false;
   #hasPermissionToDeferTask: boolean | undefined;
   #hasPermissionToChangeDueDate: Record<number, boolean> = {};

   private readonly features$ = this.manageFeatureFlags.features$.pipe(share());
   /**
    * This determines if it is possible to duplicate tasks based on the limit of work Order
    */
   public canDuplicateTasks$: Observable<boolean> = this.features$.pipe(
      switchMap(() => {
         return from(
            this.manageFeatureFlags.hasReachedWOInstructionLimitFor(moment()),
         ).pipe(map((hasReachedLimit) => !hasReachedLimit));
      }),
   );
   public woInstructionLimit$: Observable<number> = this.features$.pipe(
      startWith(0),
      switchMap(() => of(this.manageFeatureFlags.getWOInstructionLimit())),
   );
   public currentLocationID$ = this.manageLocation.selectedLocationID$;

   public changeTaskType(
      isCompleted: boolean,
      checklistID: number,
      locationID: number,
   ): void {
      if (isCompleted) {
         this.alertService.addAlert(this.t.instant("cantChange"), "warning", 6000);
         return;
      }

      if (!this.manageLogin.credCheckEditChecklist({ checklistID, locationID })) {
         this.alertService.addAlert(this.t.instant("credFailGeneric"), "warning", 6000);
         return;
      }

      const instance = this.modalService.open(PickTaskType);

      this.paramsService.params = {
         modalInstance: instance,
         resolve: {
            message: this.t.instant("pickTaskTypeMessage"),
            title: this.t.instant("pickTaskTypeTitle"),
         },
      };

      instance.result.then((result) => {
         if (!result) {
            return;
         }

         this.manageTask
            .updateTaskType(
               checklistID,
               result.checklistTemplateOld,
               result.checklistBatchID,
            )
            .then((answer) => {
               if (answer.data.success === true) {
                  const targetTask = this.manageTask.getTaskLocalLookup(checklistID);

                  if (targetTask) {
                     targetTask.checklistLastEdited = Math.floor(Date.now() / 1000);
                  }
                  this.setTaskNeedsRefresh();
                  this.alertService.addAlert(
                     this.t.instant("successMsg"),
                     "success",
                     2000,
                  );

                  //this is a work around for a list not updating properly.
                  setTimeout(() => {
                     if ($("#popUpCompletedTasksCloseMe")[0]) {
                        $("#popUpCompletedTasksCloseMe")[0].click();

                        //also closes out the task because its on the dashboard.
                        $(`#popTaskCloseMe${checklistID}`)[0].click();
                     }
                  }, 100);
               } else {
                  this.alertService.addAlert(this.t.instant("errorMsg"), "danger", 6000);
               }
            });
      });
   }

   public async startWorkOrderFromLocation(
      locationID: number,
      date?: Date | false,
   ): Promise<void> {
      await this.setupWorkOrderService.startWorkOrder(locationID, date);
      this.setTaskNeedsRefresh();
   }

   public isAllowedToDelete(task: TaskDataViewerViewModel): boolean {
      return this.credService.isAuthorized(
         task.locationID,
         this.credService.Permissions.DeleteOpenTasks,
      );
   }

   public async canExportCompletedTasks(locationID: number): Promise<boolean> {
      if (this.isAllowedToExportCompletedTasks !== undefined) {
         return this.isAllowedToExportCompletedTasks;
      }

      // Since the property is not set, let's wait for the login process to finish...
      await lastValueFrom(this.manageUser.currentUserChanges$.pipe(take(1)));

      // Now that the login process is finished, let's check the permissions...
      this.isAllowedToExportCompletedTasks = this.credService.isAuthorized(
         locationID,
         this.credService.Permissions.ExportCompletedTasks,
      );

      return this.isAllowedToExportCompletedTasks;
   }

   public async canExport(locationID: number): Promise<boolean> {
      if (this.isAllowedToExport !== undefined) {
         return this.isAllowedToExport;
      }

      // Since the property is not set, let's wait for the login process to finish...
      await lastValueFrom(this.manageUser.currentUserChanges$.pipe(take(1)));

      // Now that the login process is finished, let's check the permissions...
      this.isAllowedToExport = this.credService.isAuthorized(
         locationID,
         this.credService.Permissions.ExportTasks,
      );

      return this.isAllowedToExport;
   }

   public async canPrintOrShare(locationID: number): Promise<boolean> {
      if (this.isAllowedToPrintOrShare !== undefined) {
         return this.isAllowedToPrintOrShare;
      }

      // Since the property is not set, let's wait for the login process to finish...
      await lastValueFrom(this.manageUser.currentUserChanges$.pipe(take(1)));

      // Now that the login process is finished, let's check the permissions...
      this.isAllowedToPrintOrShare = this.credService.isAuthorized(
         locationID,
         this.credService.Permissions.PrintOrShareTasks,
      );

      return this.isAllowedToPrintOrShare;
   }

   public async canDownloadCompletedTasks(locationID: number): Promise<boolean> {
      return (
         (await this.canExport(locationID)) &&
         (await this.canExportCompletedTasks(locationID))
      );
   }

   public async duplicate(task: DuplicateTaskParams): Promise<void> {
      const canDuplicateTasks =
         !(await this.manageFeatureFlags.hasReachedWOInstructionLimitFor(moment()));
      if (!canDuplicateTasks) {
         // Only make the extra network request if the user has reached the limit
         const itemsData = await this.manageTaskItem.getTaskItems(task.checklistID);

         if (itemsData.data.items && itemsData.data.items.length > 1) {
            this.addPartPopoverCheck = true;

            return;
         }
      }

      if (
         !this.credService.isAuthorized(
            task.locationID,
            this.credService.Permissions.StartNewTasks,
         )
      ) {
         this.alertService.addAlert(this.t.instant("cred43Fail"), "danger", 10000);
         return;
      }

      await this.manageTask.duplicateOpenTask(task.checklistID, task.locationID).then(
         () => {
            this.setTaskNeedsRefresh();
            this.alertService.addAlert(
               this.t.instant("successMsgAndNotification"),
               "success",
               5000,
            );
         },
         () => {
            this.alertService.addAlert(this.t.instant("errorMsg"), "danger", 10000);
         },
      );
   }

   public async viewTask(checklistID: number): Promise<void> {
      if (this.dropdownClicked) {
         this.dropdownClicked = false;
         return;
      }

      let dataLogOptions: DataLogEventDefinition = {};
      if (this.#dataLogSection) {
         dataLogOptions = {
            taskInitiateLabel: this.#dataLogSection
               ? `${this.#dataLogSection}-initiateAPart`
               : undefined,
            vendorInitiateLabel: this.#dataLogSection
               ? `${this.#dataLogSection}-initiateAVendor`
               : undefined,
         };
      }

      const instance = this.modalService.open(PopTask);
      this.paramsService.params = {
         modalInstance: instance,
         resolve: {
            closeModalFunc: () => {
               this.setTaskNeedsRefresh();
            },
            dataLogOptions,
            data: {
               checklistID,
            },
         },
      };
      await instance.result;
      this.setTaskNeedsRefresh();
   }

   public deleteTask(task: DeleteTaskParams): void {
      this.addPartPopoverCheck = false;
      if (
         !this.credService.isAuthorized(
            task.locationID,
            this.credService.Permissions.DeleteOpenTasks,
         )
      ) {
         this.alertService.addAlert(this.t.instant("cred24Fail"), "danger", 10000);
         return;
      }

      const instance = this.modalService.open(Confirm);

      this.paramsService.params = {
         modalInstance: instance,
         resolve: {
            message: this.t.instant("deleteTaskMessage"),
            title: `${this.t.instant("Delete")} ${task.checklistName}`,
         },
      };

      instance.result.then((result) => {
         if (result === 1) {
            if (!task) return;
            this.manageTask.deleteChecklistInstance(task.checklistID).then((answer) => {
               if (answer.data.success === true) {
                  this.alertService.addAlert(
                     `<i class='fa-regular fa-square-check fa-fw'></i> ${this.t.instant("successMsgDeletion")}`,
                     "success",
                     5000,
                  );
                  this.setTaskNeedsRefresh();
               } else {
                  this.alertService.addAlert(
                     this.t.instant("errorMsg"),
                     "warning",
                     10000,
                  );
               }
            });
         }
      });
   }

   public canChangeAssignment(task: TaskDataViewerViewModel): boolean {
      if (task.isCompleted) {
         return false;
      }

      return this.credService.isAuthorized(
         task.locationID,
         this.credService.Permissions.ChangeAssignmentsOfOpenTasks,
      );
   }

   public changeAssignment(task: TaskDataViewerViewModel): void {
      if (!this.canChangeAssignment(task)) {
         this.alertService.addAlert(this.t.instant("cred26Fail"), "danger", 10000);
         return;
      }

      const extraUsersOptions = {
         arr: [
            {
               userFirstName: this.t.instant("Unassigned"),
               userID: 0,
               profileID: 0,
            },
         ],
         key: {},
      };
      extraUsersOptions.arr.forEach((user) => {
         extraUsersOptions.key[user.userID] = user;
      });

      const instance = this.modalService.open(PickUserOrProfileLegacy);

      this.paramsService.params = {
         modalInstance: instance,
         resolve: {
            data: {
               showAuditOptions: false,
               title: this.t.instant("changeAssignmentTitle"),
               message: this.t.instant("changeAssignmentMessage"),
               locationID: task.locationID,
               extraUsers: extraUsersOptions.arr,
               defaultUser: task.userID,
               defaultProfile: task.profileID,
            },
         },
      };

      instance.result.then((result) => {
         if (result !== 0) {
            this.manageTask
               .changeOwner(
                  result.userID,
                  result.profileID,
                  result.multiUsers,
                  task.checklistID,
                  this.manageUser,
                  this.manageProfile,
               )
               .then((answer: any) => {
                  if (answer.data.success === true) {
                     if (!task) return;
                     task.userID = answer.data.userID;
                     task.profileID = answer.data.profileID;
                     this.setTaskNeedsRefresh();

                     this.alertService.addAlert(
                        `<i class='fa-regular fa-square-check fa-fw'></i>${this.t.instant("successMsgAssignmentAndNotification")}`,
                        "success",
                        2000,
                     );

                     // this.runChangeDetection();
                  } else {
                     this.alertService.addAlert(
                        this.t.instant("errorMsg"),
                        "warning",
                        10000,
                     );
                  }
               });
         }
      });
   }

   public emailReminder(task: EmailReminderParams): void {
      const instance = this.modalService.open(EmailTemplate);
      const assetNameStr = this.manageAsset.getAssetNameIncludeParents(task.assetID);
      const currentUser = this.manageUser.getCurrentUser();
      this.paramsService.params = {
         modalInstance: instance,
         resolve: {
            message: this.t.instant("emailReminderMessage"),
            title: this.t.instant("emailReminderTitle"),
            data: {
               emailTo: false,
               emailSubject: this.t.instant("emailReminderSubject"),
               emailMessage: `"${task.checklistName} - #${task.checklistID} - ${assetNameStr} at ${task.locationName}" ${this.t.instant("emailReminderBody")} - ${currentUser.userInfo.customerSignature}`,
               pickEmployees: true,
               id: task.checklistID,
               onSubmit: "send",
            },
         },
         backdrop: "static",
         keyboard: false,
      };
   }

   public deferTask(task: DeferTaskParams): void {
      if (
         !this.credService.isAuthorized(
            task.locationID,
            this.credService.Permissions.DeferAnOpenTask,
         )
      ) {
         this.alertService.addAlert(this.t.instant("cred102Fail"), "danger", 10000);
         return;
      }

      const instance = this.modalService.open(DeferTask);

      this.paramsService.params = {
         modalInstance: instance,
         resolve: {
            message: this.t.instant("deferMessage"),
            title: `${this.t.instant("Defer")} - <b>${task.checklistName}</b>?`,
            id: task.checklistID,
         },
      };

      instance.result.then((result) => {
         if (!result) {
            return;
         }
         this.manageTask
            .deferTask(task, result.reason, result.pickedDate)
            .then((answer) => {
               if (!task) return;
               if (answer.data.success === true) {
                  // TODO: This needs to be moved to the new Mapper
                  task.checklistDueDateDisplay = this.manageTask.getTaskDueDateDisplay(
                     task as unknown as Task,
                  );

                  this.setTaskNeedsRefresh();
                  this.alertService.addAlert(
                     `<i class='fa-regular fa-square-check fa-fw'></i> ${this.t.instant("successMsgDeferred")}`,
                     "success",
                     5000,
                  );
               } else {
                  this.alertService.addAlert(
                     this.t.instant("errorMsg"),
                     "warning",
                     10000,
                  );
               }
            });
      });
   }

   public changeDueDate(task: ChangeDueDateParams): void {
      if (
         !this.credService.isAuthorized(
            task.locationID,
            this.credService.Permissions.ChangeDueDatesOfOpenTasks,
         )
      ) {
         this.alertService.addAlert(this.t.instant("cred46Fail"), "danger", 10000);
         return;
      }

      const instance = this.modalService.open(PickDate);
      const dueDateTimestamp = this.dateUtils.dateToUnixTimestamp(
         task.dueDate ?? new Date(),
      );
      const startDateTimestamp = task.startDate
         ? this.dateUtils.dateToUnixTimestamp(task.startDate)
         : undefined;

      this.paramsService.params = {
         modalInstance: instance,
         resolve: {
            message: this.t.instant("changeDueDateMessage"),
            title: this.t.instant("changeDueDateTitle"),
            buttonText: this.t.instant("Change"),
            currentDate: dueDateTimestamp,
            data: {
               viewTimeOfDay: true,
               setNoTimeOfDayOffset: true,
               id: task.checklistID,
               parentOfDate: "dueDate",

               startDate: {
                  viewTimeOfDay: true,
                  currentDate: startDateTimestamp,
                  setting: task.checklistStartDateSetting,
                  setNoTimeOfDayOffset: true,
               },
            },
         },
      };

      instance.result.then((result) => {
         if (!result.date) {
            return;
         }

         const date = new Date(result.date);
         const newTime = date.getTime() / 1000;
         const oldTime = (task.dueDate?.getTime() ?? 0) / 1000;
         if (result.timeOfDay === false) {
            task.checklistDueDateSetting = 0;
         } else {
            task.checklistDueDateSetting = 1;
         }
         let startDate, newStartDate, startDateSetting;
         if (result.startDate.date === false) {
            newStartDate = 0;
            startDateSetting = false;
         } else {
            startDate = new Date(result.startDate.date);
            newStartDate = startDate.getTime() / 1000;
            if (result.startDate.timeOfDay === false) {
               startDateSetting = false;
            } else {
               startDateSetting = true;
            }
         }
         const oldStartDate = (task.dueDate?.getTime() ?? 0) / 1000;
         this.manageTask
            .updateDueDate(
               oldTime,
               newTime,
               task.checklistID,
               result.timeOfDay,
               oldStartDate,
               newStartDate,
               startDateSetting,
            )
            .then((answer) => {
               if (answer.data.success === true) {
                  this.setTaskNeedsRefresh();
                  this.alertService.addAlert(
                     `<i class='fa-regular fa-square-check fa-fw'></i> ${this.t.instant("successMsgDueDateChange")}`,
                     "success",
                     5000,
                  );
               } else {
                  this.alertService.addAlert(this.t.instant("errorMsg"), "danger", 10000);
               }
            });
      });
   }

   public setTaskNeedsRefresh(): void {
      this.taskNeedRefreshSubject.next(this.taskNeedRefreshSubject.value + 1);
   }

   public setIsInModal(isInModal: boolean): void {
      this.#isInModal = isInModal;
   }

   public getIsInModal(): boolean {
      return this.#isInModal;
   }

   public setDataLogSection(dataLogSection: string): void {
      this.#dataLogSection = dataLogSection;
   }

   public getPermissionToDeferTask(): boolean {
      // Get the value only once
      if (this.#hasPermissionToDeferTask === undefined) {
         const customerID = this.manageUser.getCurrentUser().userInfo.customerID;
         this.#hasPermissionToDeferTask = this.manageTask.deferTaskPermission(customerID);
      }

      return this.#hasPermissionToDeferTask;
   }

   public getPermissionToChangeDueDate(locationId: number): boolean {
      if (this.#hasPermissionToChangeDueDate[locationId] === undefined) {
         this.#hasPermissionToChangeDueDate[locationId] = this.credService.isAuthorized(
            locationId,
            this.credService.Permissions.ChangeDueDatesOfOpenTasks,
         );
      }
      return this.#hasPermissionToChangeDueDate[locationId];
   }

   public async canImportWorkOrder() {
      const isFeatureEnabledMap: IsFeatureEnabledMap = await lastValueFrom(
         this.manageFeatureFlags.features$.pipe(take(1)),
      );
      return isFeatureEnabledMap.featureWOImport;
   }

   public viewAsset(assetID: number): void {
      const instance = this.modalService.open(PopAsset);

      this.paramsService.params = {
         modalInstance: instance,
         resolve: {
            assetID: assetID,
            // closeModalFunc: () => {
            //    this.taskNeedRefreshSubject.next(this.taskNeedRefreshSubject.value + 1);(true);
            // }, TODO: stephen this should be replaced by a service that can see when any modal is closed and update
            //an observable that can be subscribed to by any other modal that would be underneath
            data: {
               restrict: false,
            },
         },
      };
   }

   public startWorkOrder(date?: Date | false): void {
      this.startWOService.startWorkOrder(date);
      this.setTaskNeedsRefresh();
   }

   public canCreateWOs(): boolean {
      return this.startWOService.canCreateWOs();
   }

   public async downloadExcel(
      args: Partial<RequestOptions<TaskEntityFilters>>,
      isTaskAndSchedules?: boolean,
   ): Promise<void> {
      await this.taskExporterService.exportEverything(args, isTaskAndSchedules);
   }

   public async downloadExcelLimited(
      args: Partial<RequestOptions<TaskEntityFilters>>,
      columns: { [key: string]: boolean | undefined },
      isTaskAndSchedules?: boolean,
   ): Promise<void> {
      await this.taskExporterService.exportVisibleData(args, columns, isTaskAndSchedules);
   }

   public async bulkPrint(
      args: Partial<RequestOptions<TaskEntityFilters>>,
   ): Promise<void> {
      this.loadingBarService.show({
         header: this.t.instant("ThisMayTakeAMoment"),
         msg: this.t.instant("PreparingTasksForBulkPrint"),
         showProgressBar: true,
      });

      const taskLookup = new Lookup<
         "checklistID",
         { checklistID: number; checklistDueDate: number }
      >("checklistID");
      // Don't use fetchAllTasks here - it fetches a lot of extra columns, and it loads all the
      // tasks into memory. We don't need that here, we only care about the ID and the due date.
      const total = await this.tasksAPIService.getTotal(args);

      this.fakePercentageTimerService.setLoadingTimer(total);

      for (const task of await this.tasksAPIService.getAllItems(args, total)) {
         if (!task.checklistDueDate) {
            continue;
         }
         const { checklistID, checklistDueDate } = task;
         taskLookup.set(task.checklistID, { checklistID, checklistDueDate });
      }

      this.percentageOfTasksLoadedSubject.next(0);
      this.loadingBarService.remove();
      this.fakePercentageTimerService.clearLoadingTimer();
      await this.bulkTasks.bulkPrint(taskLookup);
   }

   public async importCompletedTasks(locationID: number): Promise<void> {
      const canImportWorkOrder = await this.canImportWorkOrder();
      if (!canImportWorkOrder) {
         return;
      }

      if (
         this.alertService.noCredAtLocationAlert(
            locationID,
            this.credService.Permissions.ImportCompletedTasks,
         )
      ) {
         return;
      }

      if (manageDemo.demo) {
         this.alertService.addAlert(
            this.t.instant("ImportingCompletedTasksIsDisabledWhenLiveTestDriving"),
            "danger",
            6000,
         );
         return;
      }

      const location = this.manageLocation.getLocation(locationID);
      const instance = this.modalService.open(ImportCompletedTasks);
      this.paramsService.params = {
         modalInstance: instance,
         resolve: {
            message: "",
            title: `${this.t.instant("ImportAListOfCompletedTasksInto")} ${location?.locationName ?? ""}`,
            locationID: locationID,
         },
      };
   }

   public async exportUpcomingWork(locationID: number, serarch: string) {
      await this.taskExporterService.exportOpenAndUpcomingTasks(locationID, serarch);
   }

   public broadcastTaskOpen(taskData: { checklistID: number }) {
      this.manageTask.broadcastTaskOpen(
         taskData.checklistID,
         this.manageUser.getCurrentUser().gUserID,
      );
   }

   public broadcastTaskClosed(taskData: { checklistID: number }) {
      this.manageTask.broadcastTaskClosed(
         taskData.checklistID,
         this.manageUser.getCurrentUser().gUserID,
      );
   }

   public checkUpdateUserLastVisited(
      task: TaskDataViewerViewModel,
      bypassCheck: boolean,
   ) {
      let updateTimestamp = false;
      if (bypassCheck) {
         // you can bypass the checks for situations where you are leaving a comment, and want to make sure the the last visisted
         // timestamp gets updated. Otherwise when you leave the task it will look like you have unread comments.
         updateTimestamp = true;
      } else if (task.checklistCompletedDate === 0) {
         // we want to record a last visited timestamp for open tasks
         updateTimestamp = true;
      } else if (task.comments && task.comments.length > 0) {
         // We don't want to take into account auto generated notes, just comments when checking for new comments on a completed task.
         const userComments = task.comments.filter(
            (comment) => comment.noteAutomaticGen === 0,
         );

         if (
            userComments?.length > 0 &&
            Number(userComments[userComments.length - 1]?.noteTimestamp) >
               Number(task.checklistCompletedDate)
         ) {
            // we want to record a last visited timestamp if a completed task has new comments on it since it was closed.
            updateTimestamp = true;
         }
      }

      if (updateTimestamp) {
         this.manageUser.updateUserLastVisited(task.checklistID, 1);
      }
   }

   public async reset(task: TaskDataViewerViewModel): Promise<void> {
      if (!this.manageLogin.credCheckEditChecklist(task)) {
         this.alertService.addAlert(this.t.instant("credFailGeneric"), "warning", 6000);
         return;
      }

      const instance = this.modalService.open(Confirm);

      this.paramsService.params = {
         modalInstance: instance,
         resolve: {
            message: this.t.instant("ResetTaskMsg"),
            title: this.t.instant("ResetTask"),
         },
      };

      const confirmResetResult = await instance.result;
      if (confirmResetResult === 1) {
         this.loadingBarService.show({ header: this.t.instant("WakingUpHamsters") });
         const resetTaskResult = await this.manageTask.resetTask(task.checklistID);

         this.loadingBarService.remove();
         if (resetTaskResult?.data.success === true) {
            this.taskNeedRefreshSubject.next(this.taskNeedRefreshSubject.value + 1);
            this.instructionStorageSyncService.syncInstructionsReset(task.checklistID);
         } else if (resetTaskResult?.data.reason === "alreadyCompleted") {
            this.alertService.addAlert(
               this.t.instant("AlreadyCompletedMsg"),
               "warning",
               6000,
            );
         } else {
            this.alertService.addAlert(this.t.instant("errorMsg"), "danger", 6000);
         }
      }
   }

   // Info methods
   public doWeCheck(item: any, currentStack: number = 1) {
      // Define a max stack to prevent infinite recursion.
      const MAX_STACK = 500;

      if (currentStack > MAX_STACK) {
         return false;
      }

      // Avoid infinite recursion for case where A is parent of B and B is parent of A.
      if (item.parent?.parent?.itemID === item.itemID) {
         return false;
      }
      const parentResponse = item.parent === undefined ? null : item.parent.itemResponse;
      const parentTypeID = item.parent === undefined ? null : item.parent.itemTypeID;

      if (
         parentResponse === item.itemParentResponse ||
         item.itemParentID === 0 ||
         (parentTypeID > 0 &&
            parentTypeID !== TaskInstructionTypeID.OptionList &&
            parentTypeID !== TaskInstructionTypeID.DropdownList)
      ) {
         let returnVal = true;

         if (item.parent) {
            returnVal = this.doWeCheck(item.parent, currentStack + 1);
         }

         return returnVal;
      }
      return false;
   }

   public calcCompletion(items: any[]): {
      totalItems: number;
      totalCompleteItems: number;
      complete: boolean;
   } {
      let totalItems = 0;
      let totalCompleteItems = 0;
      let complete = true;

      if (items) {
         for (const item of items) {
            if (
               (item.itemTypeID === TaskInstructionTypeID.VerifyLocation ||
                  item.itemTypeID === TaskInstructionTypeID.RequestApproval) &&
               !this.featureFlagService
                  .featureSetDefaulted()
                  .has("customApprovalInstruction")
            ) {
               item.done = true;
               continue;
            }

            if (
               item.itemTypeID === TaskInstructionTypeID.Note ||
               item.itemTypeID === TaskInstructionTypeID.InstructionSet
            ) {
               item.done = true;
               continue;
            }

            if (item.itemTypeID === TaskInstructionTypeID.FileOrPictureAttachment) {
               if (
                  this.featureFlagService
                     .featureSetDefaulted()
                     .has("requirePhotoInstruction")
               ) {
                  //feature flag to require photo instruction
               } else {
                  continue;
               }
            }

            if (
               item.itemTypeID === TaskInstructionTypeID.AssignAuditPM &&
               item.checklistToSpawn === null
            ) {
               continue;
            }

            if (
               item.itemTypeID === TaskInstructionTypeID.AssignPM &&
               item.checklistToSpawn === null
            ) {
               continue;
            }

            if (this.doWeCheck(item)) {
               totalItems += 1;

               if (item.done) {
                  totalCompleteItems += 1;
               } else {
                  complete = false;
               }
            }
         }
      }

      return {
         totalItems: totalItems,
         totalCompleteItems: totalCompleteItems,
         complete: complete,
      };
   }

   //this functions primary purpose is to sort and process any parts return or extrabatches returned.
   //this is because certain functions change part qtys and we need an easy way to update them.
   processReturnPartsExtraBatchesAndRemoveRelations = (
      answer,
      task: TaskDataViewerViewModel,
   ) => {
      const parts = task.parts;
      if (parts === undefined) {
         console.error(
            "Parts are undefined in processReturnPartsExtraBatchesAndRemoveRelations",
         );
         return;
      }
      const partIDsForUpdatePartOverstocked = answer.data.partIDsForUpdatePartOverstocked;
      //have to update extrabatches that were changed
      if (answer.data.returnExtraBatches) {
         for (const extraBatch of answer.data.returnExtraBatches) {
            const ebLookup = this.manageParts.getExtraBatches();
            const lookupBatch = ebLookup.get(extraBatch.extraBatchID);
            if (lookupBatch) {
               lookupBatch.partQty = Number(extraBatch.partQty);
               lookupBatch.partQtyUsed = Number(extraBatch.partQtyUsed);
               lookupBatch.partPrice = Number(extraBatch.partPrice);
            } else {
               this.manageParts.addExtraBatchToLookup(extraBatch);
            }
         }
      }

      //have to update parts that were changes
      if (answer.data.returnParts) {
         for (const part of answer.data.returnParts) {
            const partsLookup = this.manageParts.getParts();
            if (partsLookup.get(part.partID)) {
               const partToUpdate = partsLookup.get(part.partID);
               assert(partToUpdate);
               partToUpdate.partQty = Number(part.partQty);
               partToUpdate.partOverstocked = part.partOverstocked;
               this.manageParts.calculatePartData(partToUpdate);
               if (partIDsForUpdatePartOverstocked?.includes(part.partID)) {
                  partToUpdate.partOverstocked = 0;
               }
            }
         }
      }

      //have to clean up relations that were removed as well.
      if (answer.data.returnRemoveRelations) {
         for (const relationID of answer.data.returnRemoveRelations) {
            const relations = this.manageTask.getPartRelations();
            for (const relation of relations) {
               if (relationID === relation.relationID) {
                  relations.delete(relationID);
               }
            }
         }
      }
   };

   public completeExternalUser(task: TaskDataViewerViewModel) {
      if (!this.manageLogin.credCheckEditChecklist(task)) {
         this.alertService.addAlert(this.t.instant("credFailGeneric"), "warning", 6000);
         return;
      }

      if (this.manageUser.getCurrentUser().workOrderUser === 1) {
         //we don't need to check for calc completion as a vendor may only need to do a portion of the task
         //complete task externalUser here.
         //this wont be a real complete, it will just comment the task and say it's complete.
         //pop up modal here to confirm that the task will be complete and warn the user they will not get nofifications anymore.
         const instance = this.modalService.open(CompleteExternalTask);
         this.paramsService.params = {
            modalInstance: instance,
            resolve: {
               message: this.t.instant("FinishTaskExternalUserMsg"),
               title: this.t.instant("FinishTaskExternalUser"),
               task: task,
               parts: task.parts,
               link: this.route.snapshot.paramMap.get("link"),
            },
         };
      }
   }

   public getStatusListIndex() {
      return this.manageStatus.getStatusListIndex();
   }

   public getMiddleStatuses() {
      const statusList = this.getStatusListIndex();
      return statusList.filter((status) => {
         return status.statusID !== 0 && status.statusID !== 2;
      });
   }

   public async getAssetForTask(
      task: TaskDataViewerViewModel,
   ): Promise<Asset | undefined> {
      let singleLocation;
      //if they only have one location simply show only that location in pickAssets.ts
      if (this.manageLocation.getLocations().length === 1) {
         singleLocation = this.manageLocation.getLocations()[0].locationID;
      } else {
         singleLocation = 0;
      }

      const modalRef = this.modalService.open(PickAssets);
      const instance = modalRef.componentInstance;
      instance.message = this.t.instant("changeTasksAssetTooltip");
      instance.title = this.t.instant("changeTasksAsset");
      instance.singleLocation = singleLocation;
      instance.selectOne = true;
      instance.restrictToCred = false;
      instance.iDontKnowOption = true;
      instance.dataLogSelectLabel = PickAssetDataLogType.TASK;

      let asset = await modalRef.result;
      if (!asset) {
         return undefined;
      }

      if (asset === "unsure") {
         asset = {
            assetID: 0,
            assetName: "",
            locationID: task?.locationID,
         };
      }
      return asset;
   }

   /**
    * Opens the customize statuses modal
    */
   public editStatuses(): void {
      const instance = this.modalService.open(CustomizeStatuses);
      this.paramsService.params = {
         modalInstance: instance,
      };

      instance.result.then(() => {
         // Notify the user that they need to refresh to see updated statuses
         this.alertService.addAlert(
            this.t.instant("StatusesUpdatedRefreshNeeded"),
            "info",
            5000,
         );
      });
   }

   /**
    * Updates the task status ID
    */
   public updateTaskStatusId(
      taskId: number,
      locationId: number,
      newStatusId: number,
      oldStatusId: number,
   ): void {
      // If the status hasn't changed, do nothing
      if (Number(oldStatusId) === Number(newStatusId)) {
         return;
      }

      // Check authorization
      if (
         !this.credService.isAuthorized(
            locationId,
            this.credService.Permissions.ChangeAnOpenTasksPriorityLevel,
         )
      ) {
         this.alertService.addAlert(this.t.instant("cred101Fail"), "danger", 10000);
         return;
      }

      // If they try to change it to completed via the dropdown, display a warning
      if (newStatusId === 2) {
         this.alertService.addAlert(
            this.t.instant("ChangeStatusToCompletedWarning"),
            "warning",
            10000,
         );
         return;
      }

      const noteHiddenFromExternal = Number(
         this.manageUser.getCurrentUser().userInfo.noteHiddenFromExternalFlag,
      );

      // Update the task status
      this.manageTask
         .updateTaskStatusID(taskId, newStatusId, oldStatusId, noteHiddenFromExternal)
         .then((answer: any) => {
            if (answer.data.success === true) {
               const statusInfo = this.manageTask.getStatusInfo(newStatusId);

               this.alertService.addAlert(
                  `<i class='fa-regular fa-square-check fa-fw'></i> ${this.t.instant("StatusSuccessfullyChangedTo")} <b>${statusInfo?.statusName}`,
                  "success",
                  5000,
               );
            } else {
               this.alertService.addAlert(this.t.instant("errorMsg"), "danger", 10000);
            }
         });
   }

   public async changeAsset(
      task: TaskDataViewerViewModel,
      asset: Asset,
   ): Promise<"success" | "failed" | "cancelled"> {
      const answer = await this.manageTask.updateTasksAsset(task.checklistID, asset, []);

      if (answer.data.success === true) {
         return "success";
      }
      return "failed";
   }

   public async deleteCompletedTask(task: TaskDataViewerViewModel): Promise<void> {
      if (!task.taskCredentials.deleteCompletedTaskCred) {
         this.alertService.addAlert(this.t.instant("credFailGeneric"), "warning", 6000);
         return;
      }

      const instance = this.modalService.open(Confirm);

      this.paramsService.params = {
         modalInstance: instance,
         resolve: {
            message: this.t.instant("DeleteTaskMsg"),
            title: this.t.instant("DeleteTask"),
         },
      };

      const result = await instance.result;
      if (result === 1) {
         const answer = await this.manageTask.deleteCompletedTask(task.checklistID);
         if (answer.data.success === true) {
            this.alertService.addAlert(
               this.t.instant("successMsgTaskDeleted"),
               "success",
               4000,
            );
            this.taskNeedRefreshSubject.next(this.taskNeedRefreshSubject.value + 1);

            //we need to close the task they just deleted
            if (this.modalRef === null) {
               this.manageLogin.findCorrectDefaultState();
            } else {
               this.modalRef.close();
               this.manageTask.incTasksWatchVar();
            }
         } else {
            this.alertService.addAlert(this.t.instant("errorMsg"), "danger", 6000);
         }
      }
   }

   public async deleteOpenTask(task: TaskDataViewerViewModel): Promise<void> {
      if (!task.taskCredentials.deleteOpenTaskCred) {
         this.alertService.addAlert(this.t.instant("credFailGeneric"), "warning", 6000);
         return;
      }

      const instance = this.modalService.open(Confirm);

      this.paramsService.params = {
         modalInstance: instance,
         resolve: {
            message: this.t.instant("DeleteTaskMsg"),
            title: this.t.instant("DeleteTask"),
         },
      };

      const result = await instance.result;
      if (result === 1) {
         const answer = await this.manageTask.deleteChecklistInstance(task.checklistID);

         if (answer.data.success === true) {
            // this.task.checklistDepreciated = 1;

            this.alertService.addAlert(
               this.t.instant("successMsgTaskDeleted"),
               "success",
               4000,
            );

            this.taskNeedRefreshSubject.next(this.taskNeedRefreshSubject.value + 1);

            //we need to close the task they just deleted
            if (this.modalRef === null) {
               this.manageLogin.findCorrectDefaultState();
            } else {
               this.modalRef.close();
               this.manageTask.incTasksWatchVar();
            }
         } else {
            this.alertService.addAlert(this.t.instant("errorMsg"), "danger", 6000);
         }
      }
   }

   public async recreateWorkRequest(task: TaskDataViewerViewModel): Promise<void> {
      if (!task.taskCredentials.recreateWorkRequestCred) {
         this.alertService.addAlert(this.t.instant("credFailGeneric"), "warning", 6000);
         return;
      }

      const instance = this.modalService.open(RecreateWorkRequest);

      this.paramsService.params = {
         modalInstance: instance,
         resolve: {
            message: this.t.instant("RecreateWorkRequestMsg"),
            title: this.t.instant("RecreateWorkRequest"),
            checklistID: task.checklistID,
         },
      };

      const result = await instance.result;
      const defaultPriority = this.managePriority.getDefaultPriority();
      if (result) {
         const answer = await this.manageTask.recreateWorkRequest(
            task.checklistID,
            result.checklistID,
            result.profileID,
            result.userID,
            defaultPriority.priorityID,
            result.tags,
         );

         const data = answer.data;
         if (data?.success) {
            this.taskNeedRefreshSubject.next(this.taskNeedRefreshSubject.value + 1);
         }
      }
   }

   public async requestPurchase(
      task: TaskDataViewerViewModel,
   ): Promise<"success" | "failed" | "cancelled"> {
      const instance = this.modalService.open(RequestPurchase);
      this.paramsService.params = {
         modalInstance: instance,
         resolve: {
            data: {
               checklistID: task.checklistID,
               locationID: task.locationID,
            },
         },
      };

      const result = await instance.result;

      if (!result) {
         return "cancelled";
      }
      this.loadingBarService.show({ header: this.t.instant("WakingUpHamsters") });

      const poItemsToAdd: Array<PurchaseOrderItemToAddSkeleton> = [];
      let vendorID;

      //Service
      //IMPORTANT... service has to run first so that we can set the vendorID because we very well might set it back to 0 if they also are requesting Parts or Other
      if (result.service.length > 0) {
         const itemSkeleton: PurchaseOrderItemToAddSkeleton = {
            itemType: PurchaseOrderItemType.Service,
            partID: 0,
            assetID: 0,
            checklistID: task.checklistID,
            description: result.service,
            qty: result.serviceQty,
            rate: result.servicePrice,
            glID: 0,
         };
         poItemsToAdd.push(itemSkeleton);

         vendorID = result.vendorID || 0;
      }

      //Parts
      if (result.parts.length > 0) {
         for (const part of result.parts) {
            const itemSkeleton: PurchaseOrderItemToAddSkeleton = {
               itemType: PurchaseOrderItemType.Part,
               partID: part.partID,
               assetID: 0,
               checklistID: 0,
               description: "",
               qty: part.tempNumber,
               rate: 0,
               glID: 0,
            };
            itemSkeleton.description = this.manageParts.generateDescriptionForPart(
               itemSkeleton,
               part,
            );
            poItemsToAdd.push(itemSkeleton);
         }
         vendorID = 0;
      }

      //Other
      if (result.otherArr.length > 0) {
         for (const item of result.otherArr) {
            const itemSkeleton: PurchaseOrderItemToAddSkeleton = {
               itemType: PurchaseOrderItemType.Other,
               partID: 0,
               assetID: 0,
               checklistID: task.checklistID,
               description: item.other,
               qty: item.otherQty,
               rate: item.otherPrice,
               glID: 0,
            };

            poItemsToAdd.push(itemSkeleton);
            vendorID = result.vendor2ID || 0;
         }
      }

      const answer = await this.managePO.addPurchaseOrder(
         task.locationID,
         poItemsToAdd,
         vendorID,
         task.checklistID,
         this.manageUser.getCurrentUser().userInfo.userID,
         result.reason,
         "requestPurchase",
      );

      if (answer?.data.success !== true) {
         return "failed";
      }
      // this is to get all the POItems information for the PO and add it to our POItems object in managePO
      if (answer.data.po) {
         await this.managePO.getPurchaseOrderDetails(answer.data.po.poID);
      }

      // If a file was attached, then set it as a comment on the new PO
      if (result.filesUploaded.length > 0) {
         const poID: number = answer.data.po.poID;
         const comment = this.t.instant("AttachedFile");
         let numfilesMoved = 0;

         // move the files from their temp location to the PO
         result.filesUploaded.forEach((file) => {
            this.managePO.moveCommentTempFile(poID, file.fileName).then((filesAnswer) => {
               if (filesAnswer.data.success === true) {
                  numfilesMoved++;
               } else {
                  return "failed";
               }

               // Make sure all files are moved before creating the comment
               if (numfilesMoved === result.filesUploaded.length) {
                  // add the file as a comment to the PO
                  this.managePO
                     .addPurchaseOrderComment(comment, poID, 0, false)
                     .then((commentAnswer) => {
                        if (!commentAnswer) {
                           return "failed";
                        }
                        return "success";
                     });
               }
               return "success";
            });
         });
      }
      this.loadingBarService.remove();
      return "success";
   }

   public async syncRelatedInstructionSets(
      instructionSetBatchID: number,
      toUpdateArray: Array<InstructionSetUpdate>,
      progressObservable: BehaviorSubject<number>,
   ) {
      return this.instructionSet.syncRelatedInstructionSets(
         instructionSetBatchID,
         toUpdateArray,
         progressObservable,
      );
   }

   public editPriorities(): void {
      const instance = this.modalService.open(CustomizePriorities);
      this.paramsService.params = {
         modalInstance: instance,
      };
   }

   public updateTaskPriorityID(
      taskId: number,
      locationId: number,
      newPriorityId: number,
      currentPriorityId: number | null,
   ): void {
      if (
         !this.credService.isAuthorized(
            locationId,
            this.credService.Permissions.ChangeAnOpenTasksPriorityLevel,
         )
      ) {
         this.alertService.addAlert(this.t.instant("cred101Fail"), "danger", 10000);
         return;
      }

      if (Number(newPriorityId) === Number(currentPriorityId)) {
         return;
      }

      if (currentPriorityId === null) return;

      this.manageTask
         .updateTaskPriorityID(taskId, newPriorityId, currentPriorityId)
         .then((answer: any) => {
            if (answer.data.success === true) {
               const priorityInfo = this.manageTask.getPriorityInfo(newPriorityId);

               this.alertService.addAlert(
                  `<i class='fa-regular fa-square-check fa-fw'></i> ${this.t.instant("PrioritySuccessfullyChangedTo")} <b>${priorityInfo.priorityName}`,
                  "success",
                  5000,
               );
            } else {
               this.alertService.addAlert(this.t.instant("errorMsg"), "danger", 10000);
            }
         });
   }
}
