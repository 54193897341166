import { CommonModule } from "@angular/common";
import { Component, inject, input, signal } from "@angular/core";
import {
   CardComponent,
   OutlinedButtonComponent,
   SparklesPulseSpinComponent,
   TypewriterDirective,
   fadeIn,
   LoadingAnimationComponent,
} from "@limblecmms/lim-ui";
import { TranslationService } from "src/app/languages/translation/translation.service";
import { AnomalyStateStore } from "src/app/tasks/stores/anomaly-detection/anomaly-state.store";

@Component({
   selector: "anomalous-value-detected-card",
   standalone: true,
   imports: [
      CommonModule,
      CardComponent,
      OutlinedButtonComponent,
      SparklesPulseSpinComponent,
      TypewriterDirective,
      LoadingAnimationComponent,
   ],
   templateUrl: "./anomalous-value-detected-card.component.html",
   styleUrls: ["./anomalous-value-detected-card.component.scss"],
   animations: [fadeIn],
})
export class AnomalousValueDetectedCardComponent {
   public newValue = input<number>();
   public checklistItemId = input.required<number>();
   public anomalyID = input.required<number | null>();

   protected readonly lang = inject(TranslationService).i18n;
   protected readonly anomalyStateStore = inject(AnomalyStateStore);
   protected isTypingComplete = signal(false);

   protected async confirmAnomaly() {
      this.anomalyStateStore.setIsUpdatingAnomalyState(true);
      const anomalyID = this.anomalyID();
      if (anomalyID) {
         await this.anomalyStateStore.confirmAnomaly(anomalyID, this.checklistItemId());
      }
      this.anomalyStateStore.setIsUpdatingAnomalyState(false);
   }

   protected onTypingComplete() {
      this.isTypingComplete.set(true);
   }
}
