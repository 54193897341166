import { formatNumber } from "@angular/common";
import { computed, EventEmitter, inject, Injectable } from "@angular/core";
import { isMobile, ModalService } from "@limblecmms/lim-ui";
import type { AxiosResponse } from "axios/dist/axios";
import type { Moment } from "moment";
import moment from "moment";
import {
   combineLatest,
   EMPTY,
   firstValueFrom,
   from,
   lastValueFrom,
   type Observable,
   Subject,
   tap,
} from "rxjs";
import { expand, filter, map, reduce } from "rxjs/operators";
import { ManageAsset } from "src/app/assets/services//manageAsset";
import type { Asset } from "src/app/assets/types/asset.types";
import { ManageFiles } from "src/app/files/services/manageFiles";
import { ManageLang } from "src/app/languages/services/manageLang";
import { TranslationService } from "src/app/languages/translation/translation.service";
import { AssetFieldValueStorageSyncService } from "src/app/lite/local-db/resources/collection/asset/field/value/asset-field-value.storage.sync.service";
import { CommentStorageSyncService } from "src/app/lite/local-db/resources/collection/task/comment/comment.storage.sync.service";
import { ExtraTimeStorageSyncService } from "src/app/lite/local-db/resources/collection/task/extra-time/extra-time.storage.sync.service";
import { InstructionStorageSyncService } from "src/app/lite/local-db/resources/collection/task/instruction/instruction.storage.sync.service";
import { TaskStorageSyncService } from "src/app/lite/local-db/resources/collection/task/task.storage.sync.service";
import { ManageLocation } from "src/app/locations/services/manageLocation";
import type { ManageParts } from "src/app/parts/services/manageParts";
import { ManageInvoice } from "src/app/purchasing/services/manageInvoice";
import type { Recurrence } from "src/app/schedules/recurrence.types";
import type { Schedule } from "src/app/schedules/schedule.types";
import { AlertService } from "src/app/shared/services/alert.service";
import { BetterDate } from "src/app/shared/services/betterDate";
import { EnvRoutesService } from "src/app/shared/services/envRoutes.service";
import { InsightsService } from "src/app/shared/services/insights/insights.service";
import { Flags } from "src/app/shared/services/launch-flags/launch-flags.models";
import { LaunchFlagsService } from "src/app/shared/services/launch-flags/launch-flags.service";
import { LegacyLaunchFlagsService } from "src/app/shared/services/launch-flags/legacy-launch-flags.service";
import { ManageFilters } from "src/app/shared/services/manageFilters";
import { ManageObservables } from "src/app/shared/services/manageObservables";
import { ManageUtil } from "src/app/shared/services/manageUtil";
import { LocalData, logLocalDataUsage } from "src/app/shared/services/migration-logger";
import { ParamsService } from "src/app/shared/services/params.service";
import { logApiPerformance } from "src/app/shared/services/performance-logger";
import { WebsocketService } from "src/app/shared/services/websockets/websocket.service";
import type { LimbleWebsocketMessage } from "src/app/shared/types/websocket.types";
import { cleanWordPaste } from "src/app/shared/utils/app.util";
import { assert } from "src/app/shared/utils/assert.utils";
import { LimbleMap } from "src/app/shared/utils/limbleMap";
import { Lookup } from "src/app/shared/utils/lookup";
import { PopTask } from "src/app/tasks/components/popTaskModal/popTask.modal.component";
import type { TaskDataViewerViewModel } from "src/app/tasks/components/shared/components/tasks-data-viewer/task-data-viewer.model";
import { TaskExtraTimeApiService } from "src/app/tasks/components/shared/services/extra-time-api/task-extra-time-api.service";
import type { ScheduleEntity } from "src/app/tasks/components/shared/services/schedules-api/schedules-api.models";
import type { TaskCommentsEntityFilters } from "src/app/tasks/components/shared/services/task-comments-api/task-comments-api.models";
import { TaskCommentsApiService } from "src/app/tasks/components/shared/services/task-comments-api/task-comments-api.service";
import type { TaskTemplateEntity } from "src/app/tasks/components/shared/services/task-templates-api/task-templates-api.models";
import type {
   TaskComment,
   TaskEntity,
   TaskExtraTime,
} from "src/app/tasks/components/shared/services/tasks-api/task-api.models";
import { TasksApiService } from "src/app/tasks/components/shared/services/tasks-api/tasks-api.service";
import type { TasksSchedulesCombinedEntity } from "src/app/tasks/components/shared/services/tasks-schedules-combined-api/tasks-schedules-combined.models";
import { TaskInstructionTypeID } from "src/app/tasks/schemata/tasks/instructions/task-instruction.enum";
import { ColorSetsService } from "src/app/tasks/services/color-sets.service";
import { ManagePriority } from "src/app/tasks/services/managePriority";
import { ManageStatus } from "src/app/tasks/services/manageStatus";
import { ManageTaskItem } from "src/app/tasks/services/manageTaskItem";
import { TaskPhpApiService } from "src/app/tasks/services/task-php-api/task-php-api.service";
import { TaskRefreshService } from "src/app/tasks/services/task-refresh.service";
import { TaskType, TaskTypeService } from "src/app/tasks/services/task-type.service";
import type {
   Comment,
   CommentCalculatedInfo,
} from "src/app/tasks/types/comment/comment.types";
import type { Deferment } from "src/app/tasks/types/deferment/deferment.types";
import type { ExtraAsset } from "src/app/tasks/types/extra-asset/extra-asset.types";
import type { ExtraTime } from "src/app/tasks/types/extra-time/extra-time.types";
import type { TaskInstructionType } from "src/app/tasks/types/instruction/instruction.types";
import type { TaskPartRelation } from "src/app/tasks/types/part/task-part.types";
import type { TaskProfile } from "src/app/tasks/types/profile/profile.types";
import type { TaskUser } from "src/app/tasks/types/task-user/task-user.types";
import type {
   Task,
   TaskAssignmentInfo,
   TaskLookup,
   TaskRelationData,
} from "src/app/tasks/types/task.types";
import type { VendorTask } from "src/app/tasks/types/vendor-task/vendor-task.types";
import { CredService } from "src/app/users/services//creds/cred.service";
import type { ManageProfile } from "src/app/users/services//manageProfile";
import { ManageUser } from "src/app/users/services//manageUser";
import { ManageVendor } from "src/app/vendors/services/manageVendor";

@Injectable({ providedIn: "root" })
export class ManageTask {
   private tasks: TaskLookup = new Lookup("checklistID");
   private unfilteredTasks: TaskLookup = new Lookup("checklistID");
   private completedTasks: TaskLookup = new Lookup("checklistID");
   private vendorTasks: Lookup<"shareID", VendorTask> = new Lookup("shareID");
   private readonly vendorTasksByChecklistID: LimbleMap<
      number,
      Lookup<"shareID", VendorTask>
   > = new LimbleMap();
   private extraTime: Lookup<"extraTimeID", ExtraTime> = new Lookup("extraTimeID");
   private readonly extraTimeByChecklistID: LimbleMap<
      number,
      Lookup<"extraTimeID", ExtraTime>
   > = new LimbleMap();
   private allUsers: Lookup<"userID", TaskUser> = new Lookup("userID");
   private allProfiles: Lookup<"profileID", TaskProfile> = new Lookup("profileID");
   private deferments: Lookup<"deferID", Deferment> = new Lookup("deferID");
   private comments: Lookup<"noteID", Comment> = new Lookup("noteID");
   private recurrences: Lookup<"reoccurID", Recurrence> = new Lookup("reoccurID");
   private partRelations: Lookup<"relationID", TaskPartRelation> = new Lookup(
      "relationID",
   );
   private readonly partRelationsByPartID: LimbleMap<
      number,
      Lookup<"relationID", TaskPartRelation>
   > = new LimbleMap();
   private extraAssets: Lookup<"relationID", ExtraAsset> = new Lookup("relationID");
   private readonly extraAssetsByChecklistID: LimbleMap<
      number,
      Lookup<"assetID", ExtraAsset>
   > = new LimbleMap();
   private itemTypes: Lookup<"itemTypeID", TaskInstructionType> = new Lookup(
      "itemTypeID",
   );

   private completedTasksWatchVar;
   private tasksWatchVar;
   private readonly vendorTileWatchVar;
   private taskRelations: { [id: string | number]: TaskRelationData } = {};
   private websocketUrl: string | null = null;
   public readonly taskMessages$: Observable<LimbleWebsocketMessage>;
   public readonly taskDataChanged$: Subject<string> = new Subject();
   public onNoteIDsChanged = new EventEmitter();

   private readonly manageAsset = inject(ManageAsset);
   private readonly manageInvoice = inject(ManageInvoice);
   private readonly manageLocation = inject(ManageLocation);
   private readonly manageLang = inject(ManageLang);
   private readonly manageUtil = inject(ManageUtil);
   private readonly manageFiles = inject(ManageFiles);
   private readonly alertService = inject(AlertService);
   private readonly manageObservables = inject(ManageObservables);
   private readonly manageFilters = inject(ManageFilters);
   private readonly websocketService = inject(WebsocketService);
   private readonly manageTaskItem = inject(ManageTaskItem);
   private readonly managePriority = inject(ManagePriority);
   private readonly manageStatus = inject(ManageStatus);
   private readonly manageUser = inject(ManageUser);
   private readonly modalService = inject(ModalService);
   private readonly paramsService = inject(ParamsService);
   private readonly betterDate = inject(BetterDate);
   private readonly manageVendor = inject(ManageVendor);
   private readonly credService = inject(CredService);
   private readonly taskTypeService = inject(TaskTypeService);
   private readonly instructionStorageSyncService = inject(InstructionStorageSyncService);
   private readonly taskStorageSyncService = inject(TaskStorageSyncService);
   private readonly taskExtraTimeStorageSyncService = inject(ExtraTimeStorageSyncService);
   private readonly launchFlagsService = inject(LegacyLaunchFlagsService);
   private readonly envRoutesService = inject(EnvRoutesService);
   private readonly commentStorageSyncService = inject(CommentStorageSyncService);
   private readonly taskCommentsApiService = inject(TaskCommentsApiService);
   private readonly tasksApiService = inject(TasksApiService);
   private readonly assetFieldValueStorageSyncService = inject(
      AssetFieldValueStorageSyncService,
   );
   private readonly colorSetsService = inject(ColorSetsService);
   private readonly taskRefreshService = inject(TaskRefreshService);
   private readonly insightsService = inject(InsightsService);
   private readonly i18n = inject(TranslationService).i18n;
   private readonly launchFlagDarklyService = inject(LaunchFlagsService);
   private readonly taskExtraTimeApiService = inject(TaskExtraTimeApiService);
   private readonly taskPhpApiService = inject(TaskPhpApiService);

   private readonly shouldUseRefactoredProblemPortal = computed(() =>
      this.launchFlagDarklyService.getFlag("refactored-problem-portal", false),
   );

   private readonly shouldLogLocalDataUsage = computed(() =>
      this.launchFlagDarklyService.getFlag("log-localdata-usage", false)(),
   );

   protected readonly lang = computed(() => this.manageLang.lang() ?? {});
   public constructor() {
      this.completedTasksWatchVar = 0;
      this.tasksWatchVar = 0;
      this.vendorTileWatchVar = 0;
      this.createObservables();
      this.taskMessages$ = this.websocketService.messages$.pipe(
         filter((msg) => msg.tags.includes("task")),
      );

      this.envRoutesService.getRoutes().then((routes) => {
         this.websocketUrl = routes.websocketUrl;
      });
   }

   public clearData() {
      this.tasks.clear();
      this.completedTasks.clear();
      this.unfilteredTasks.clear();
      this.vendorTasks.clear();
      this.vendorTasksByChecklistID.clear();
      this.extraTime.clear();
      this.extraTimeByChecklistID.clear();
      this.allUsers.clear();
      this.allProfiles.clear();
      this.deferments.clear();
      this.comments.clear();
      this.recurrences.clear();
      this.partRelations.clear();
      this.partRelationsByPartID.clear();
      this.extraAssets.clear();
      this.extraAssetsByChecklistID.clear();
      this.itemTypes.clear();
   }

   /**
    * @deprecated fetch tasks through the backend using the tasks-api service rather than using the local lookup.
    */
   public getTasks(caller?: string): TaskLookup {
      logLocalDataUsage(LocalData.Tasks, this.shouldLogLocalDataUsage(), caller);
      return this.tasks;
   }

   /**
    * @deprecated fetch tasks through the backend using the tasks-api service rather than using the local lookup.
    */
   public async getTask(
      checklistID: number,
      forceRefresh = false,
   ): Promise<Task | undefined> {
      if (!checklistID) return undefined;

      if (!forceRefresh) {
         const localTask = this.getTaskLocalLookup(checklistID);
         if (localTask) return localTask;
      }

      return this.refreshLocalTask(checklistID);
   }

   /**
    * @deprecated fetch tasks through the backend using the tasks-api service rather than using the local lookup.
    */
   public async refreshLocalTask(checklistID: number): Promise<Task | undefined> {
      const singleTaskResponse =
         await this.tasksApiService.getLegacySingleTask(checklistID);
      if (singleTaskResponse !== undefined) {
         const originalTaskObj = this.tasks.get(checklistID) ?? {};
         // Have to maintain reference, so we overwrite properties instead of replacing.
         const task = Object.assign(originalTaskObj, singleTaskResponse);
         if (task.checklistCompletedDate !== null && task.checklistCompletedDate > 0) {
            this.completedTasks.set(checklistID, task);
            this.incCompletedTasksWatchVar();
            return this.completedTasks.get(checklistID);
         }
         // Ensure task is removed from local storage if reopened
         if (this.completedTasks.get(checklistID)) {
            this.completedTasks.delete(checklistID);
            this.incCompletedTasksWatchVar();
         }
         this.tasks.set(checklistID, task);
         this.incTasksWatchVar();
         return this.tasks.get(checklistID);
      }
      return undefined;
   }

   // Return a lookup of vendor tasks for a given checklist ID
   public getVendorTasksForChecklistID(
      checklistID: number,
   ): Lookup<"shareID", VendorTask> | undefined {
      return this.vendorTasksByChecklistID.get(checklistID);
   }

   public getVendorTasks(): Lookup<"shareID", VendorTask> {
      return this.vendorTasks;
   }

   public getExtraTime(extraTimeID: number, caller?: string): ExtraTime | undefined {
      logLocalDataUsage(LocalData.ExtraTime, this.shouldLogLocalDataUsage(), caller);
      return this.extraTime.get(extraTimeID);
   }

   public getAllUsers(): Lookup<"userID", TaskUser> {
      return this.allUsers;
   }

   public getTaskUser(userID: number): TaskUser | undefined {
      return this.allUsers.get(userID);
   }

   public getAllProfiles(): Lookup<"profileID", TaskProfile> {
      return this.allProfiles;
   }

   public getTaskProfile(profileID: number): TaskProfile | undefined {
      return this.allProfiles.get(profileID);
   }

   public getDeferments(): Lookup<"deferID", Deferment> {
      return this.deferments;
   }

   public getDeferment(deferID: number): Deferment | undefined {
      return this.deferments.get(deferID);
   }

   public getComments(): Lookup<"noteID", Comment> {
      return this.comments;
   }

   public getComment(noteID: number): Comment | undefined {
      return this.comments.get(noteID);
   }

   public getRecurrences(): Lookup<"reoccurID", Recurrence> {
      return this.recurrences;
   }

   public getRecurrence(reoccurID: number): Recurrence | undefined {
      return this.recurrences.get(reoccurID);
   }

   public getPartRelations(): Lookup<"relationID", TaskPartRelation> {
      return this.partRelations;
   }

   public getPartRelation(relationID: number): TaskPartRelation | undefined {
      return this.partRelations.get(relationID);
   }

   public getAllPartRelationsByPartID(): LimbleMap<
      number,
      Lookup<"relationID", TaskPartRelation>
   > {
      return this.partRelationsByPartID;
   }

   public getExtraAssets(): Lookup<"relationID", ExtraAsset> {
      return this.extraAssets;
   }

   public getExtraAssetsByChecklistID(): LimbleMap<
      number,
      Lookup<"assetID", ExtraAsset>
   > {
      return this.extraAssetsByChecklistID;
   }

   public getExtraAsset(relationID: number): ExtraAsset | undefined {
      return this.extraAssets.get(relationID);
   }

   public getItemTypes(): Lookup<"itemTypeID", TaskInstructionType> {
      return this.itemTypes;
   }

   public getItemType(itemTypeID: number): TaskInstructionType | undefined {
      return this.itemTypes.get(itemTypeID);
   }

   /**
    * @deprecated fetch tasks through the backend rather than using the local lookup.
    */
   public getCompletedTasks(caller?: string): TaskLookup {
      logLocalDataUsage(LocalData.CompletedTasks, this.shouldLogLocalDataUsage(), caller);
      return this.completedTasks;
   }

   /**
    * @deprecated fetch tasks through the backend rather than using the local lookup.
    */
   public getCompletedTask(checklistID: number, caller?: string): Task | undefined {
      logLocalDataUsage(LocalData.CompletedTask, this.shouldLogLocalDataUsage(), caller);
      return this.completedTasks.get(checklistID);
   }

   createObservables = () => {
      this.manageObservables.createObservable("tasksWatchVar", this.tasksWatchVar);
      this.manageObservables.createObservable(
         "completedTasksWatchVar",
         this.completedTasksWatchVar,
      );
      this.manageObservables.createObservable(
         "vendorTileWatchVar",
         this.vendorTileWatchVar,
      );
      this.manageObservables.createObservable("taskRelationsLoaded", false);
      this.manageObservables.createObservable("task", this.tasks);
   };

   incCompletedTasksWatchVar = () => {
      //update the watch variable so that digests will pick up on it
      this.completedTasksWatchVar++;
      this.manageObservables.updateObservable(
         "completedTasksWatchVar",
         this.completedTasksWatchVar,
      );
   };

   incTasksWatchVar = () => {
      this.tasksWatchVar++;
      this.manageObservables.updateObservable("tasksWatchVar", this.tasksWatchVar);
   };

   public resetTasksWatchVar() {
      this.tasksWatchVar = 0;
      this.manageObservables.updateObservable("tasksWatchVar", this.tasksWatchVar);
   }

   public async createCycleCountInstance(
      task,
      profileID: number,
      userID: number,
      multiUsers: number[],
      timestamp: number,
      recurrence,
   ): Promise<AxiosResponse | undefined> {
      const answer = await this.taskPhpApiService.createCycleCountInstance({
         assetID: task.assetID,
         locationID: task.locationID,
         checklistID: recurrence.checklistID,
         reoccurID: recurrence.reoccurID,
         customerID: recurrence.customerID,
         profileID: profileID,
         timestamp: timestamp,
         userID: userID,
         multiUsers: multiUsers,
      });

      if (answer.data.success !== true) {
         if (!answer.data?.error) {
            this.alertService.addAlert(this.lang().errorMsg, "danger", 6000);
         }
         if (answer.data?.error === "empty result set") {
            this.alertService.addAlert(
               this.lang().NoPartsForCycleCountMsg,
               "warning",
               10000,
            );
         }
         return undefined;
      }
      for (const newTask of answer.data.tasks) {
         this.addTaskToLookup(newTask);
         newTask.checklistPriorityDisplay = Number(newTask.checklistPriority) + 1;
         if (newTask.checklistPriorityDisplay == 7) {
            newTask.checklistPriorityDisplay = 0;
         }
         this.incTasksWatchVar();
      }

      return answer;
   }

   public getTaskRequestorInfo(task: Task | TaskEntity): string {
      let requestorInformation = "";

      requestorInformation = "";
      if (task.checklistEmailCN) {
         requestorInformation += `${task.checklistEmailCN}, `;
      }
      if (task.requestName) {
         requestorInformation += `${task.requestName}, `;
      }
      if (task.requestPhone) {
         requestorInformation += `${task.requestPhone}, `;
      }

      if (requestorInformation.length > 0) {
         requestorInformation = requestorInformation.slice(0, -2);
      }
      return requestorInformation;
   }

   public getExtraTimeCalculatedData(extraTime: ExtraTime) {
      const user = extraTime.userID ? this.allUsers.get(extraTime.userID) : undefined;
      let userFirstName,
         userLastName = "";
      if (user) {
         userFirstName = user.userFirstName ?? "";
         userLastName = user.userLastName ?? "";
      } else {
         userFirstName = this.lang().Deleted;
         userLastName = this.lang().User;
      }
      return {
         promptTimeHours: Number(Math.floor((extraTime.promptTime ?? 0) / 3600)),
         promptTimeMinutes: Number(Math.floor(((extraTime.promptTime ?? 0) / 60) % 60)),
         billableHours: Number(Math.floor((extraTime.billableTime ?? 0) / 3600)),
         billableMinutes: Number(Math.floor(((extraTime.billableTime ?? 0) / 60) % 60)),
         userFirstName,
         userLastName,
      };
   }

   public getTaskExtraTimeCalculatedData(extraTime: TaskExtraTime): {
      promptTimeHours: number;
      promptTimeMinutes: number;
      billableHours: number;
      billableMinutes: number;
      userFirstName: string;
      userLastName: string;
   } {
      const user = extraTime.user;
      let userFirstName,
         userLastName = "";
      if (!user) {
         userFirstName = "";
         userLastName = "";
      } else if ("deleted" in user) {
         userFirstName = this.lang().Deleted;
         userLastName = this.lang().User;
      } else {
         userFirstName = user.firstName ?? "";
         userLastName = user.lastName ?? "";
      }

      return {
         promptTimeHours: Number(Math.floor((extraTime.promptTime ?? 0) / 3600)),
         promptTimeMinutes: Number(Math.floor(((extraTime.promptTime ?? 0) / 60) % 60)),
         billableHours: Number(Math.floor((extraTime.billableTime ?? 0) / 3600)),
         billableMinutes: Number(Math.floor(((extraTime.billableTime ?? 0) / 60) % 60)),
         userFirstName,
         userLastName,
      };
   }

   public getChecklistPromptTimeTotal(
      task: Task,
      options?: { extraTimeDateRange?: { start?: number; end?: number } },
   ): number {
      let checklistPromptTimeTotal = task.checklistPromptTime ?? 0;
      if (task.extraTimeIDs) {
         const extraTimes: Array<ExtraTime | undefined> = task.extraTimeIDs
            .map((extraTimeID) => this.getExtraTime(extraTimeID))
            .filter((extraTime) => {
               if (extraTime && options?.extraTimeDateRange) {
                  const loggedAtTimestamp = (extraTime.loggedAt ?? 0) * 1000;
                  return (
                     (options?.extraTimeDateRange.start === undefined ||
                        loggedAtTimestamp >= options?.extraTimeDateRange.start) &&
                     (options?.extraTimeDateRange.end === undefined ||
                        loggedAtTimestamp <= options?.extraTimeDateRange.end)
                  );
               }
               return true;
            });

         for (const time of extraTimes) {
            if (time === undefined) continue;
            checklistPromptTimeTotal += time.promptTime ?? 0;
         }
      }
      return checklistPromptTimeTotal;
   }

   public getCalculatedTaskInfo(task: Task | TaskEntity | TaskTemplateEntity): {
      checklistEstTimeMinutes: number;
      checklistPriorityDisplay: number;
      checklistTotalInvoiceCost: number;
      checklistTotalPartsCost: number;
      checklistPromptTimeTotal: number;
      checklistPromptTimeTotalHours: number;
      checklistPromptTimeTotalMinutes: number;
      billableTimeTotal: number;
      billableTimeTotalHours: number;
      billableTimeTotalMinutes: number;
      checklistTotalLaborCost: number;
      checklistTotalOperatingCost: number;
      checklistTemplateOldString: string;
   } {
      const lang = this.lang();
      const checklistEstTimeMinutes = Number(task.checklistEstTime) / 60;
      let checklistPriorityDisplay = Number(task.checklistPriority) + 1;
      if (checklistPriorityDisplay === 7) {
         //PLAID TODO: why are we doing this, and what is the significance?  We should get some documentation here
         checklistPriorityDisplay = 0;
      }
      let checklistTotalInvoiceCost = 0;
      if ("invoiceIDs" in task && task.invoiceIDs !== undefined) {
         checklistTotalInvoiceCost = task.invoiceIDs.reduce((accumulator, invoiceID) => {
            const invoice = this.manageInvoice.getInvoicesIndex()[invoiceID];
            if (!invoice || invoice.invoiceCost === null) return accumulator;
            return accumulator + invoice.invoiceCost;
         }, 0);
      } else if ("invoiceCost" in task) {
         //needed for JIT workflows
         checklistTotalInvoiceCost = task.invoiceCost;
      }
      let checklistTotalPartsCost = 0;
      if ("partRelationIDs" in task && task.partRelationIDs !== undefined) {
         checklistTotalPartsCost = task.partRelationIDs.reduce(
            (accumulator, relationID) => {
               const partRelation = this.getPartRelation(relationID);
               if (
                  typeof partRelation?.usedNumber !== "number" ||
                  typeof partRelation?.usedPrice !== "number"
               )
                  return accumulator;

               return accumulator + partRelation.usedNumber * partRelation.usedPrice;
            },
            0,
         );
      } else if ("partsCost" in task) {
         checklistTotalPartsCost = task.partsCost;
      }
      let extraTime: Array<ExtraTime | TaskExtraTime | undefined> = [];
      if ("extraTimeIDs" in task && task.extraTimeIDs !== undefined) {
         extraTime = task.extraTimeIDs.map((extraTimeID) =>
            this.getExtraTime(extraTimeID),
         );
      }

      if ("extraTime" in task && task.extraTime !== undefined) {
         extraTime = task.extraTime;
      }

      let checklistPromptTimeTotal = task.checklistPromptTime ?? 0;
      let billableTimeTotal = task.billableTime ?? 0;

      let checklistTotalLaborCost;
      const completedByUser = task.checklistUserCompleted
         ? this.allUsers.get(task.checklistUserCompleted)
         : undefined;

      if (completedByUser && task.checklistUserWage !== null) {
         checklistTotalLaborCost =
            (Number(task.checklistPromptTime) / 60 / 60) * (task.checklistUserWage ?? 0);
      } else {
         checklistTotalLaborCost = 0; //couldn't find the user any more so labor costs are 0
      }
      for (const time of extraTime) {
         if (time === undefined) continue;
         checklistPromptTimeTotal += time.promptTime ?? 0;
         billableTimeTotal += time.billableTime ?? 0;
         const userID = "user" in time ? time.user.userID : time.userID;
         const associatedUser = userID === null ? undefined : this.allUsers.get(userID);
         let userWage: number | null = 0;
         if ("user" in time) {
            userWage = "userWage" in time.user ? time.user.userWage : null;
         } else {
            userWage = time.userWage;
         }

         if (associatedUser && time.promptTime !== null && userWage !== null) {
            checklistTotalLaborCost += (time.promptTime / 60 / 60) * userWage;
         }
      }

      const checklistPromptTimeTotalHours = Math.floor(checklistPromptTimeTotal / 3600);

      const checklistPromptTimeTotalMinutes = Math.floor(
         (checklistPromptTimeTotal / 60) % 60,
      );

      const billableTimeTotalHours = Math.floor(billableTimeTotal / 3600);
      const billableTimeTotalMinutes = Math.floor((billableTimeTotal / 60) % 60);

      //IMPORTANT...
      //if they do not have the permission to view the labor costs of a task we will automatically put this at 0;
      if (this.credService.isAuthorized(task.locationID, 142) == false) {
         checklistTotalLaborCost = 0;
      }

      const checklistTotalOperatingCost =
         checklistTotalInvoiceCost + checklistTotalLaborCost + checklistTotalPartsCost;

      let checklistTemplateOldString;
      if (task.checklistTemplateOld == 1) {
         checklistTemplateOldString = lang.PM;
      } else if (
         task.checklistTemplateOld == 2 &&
         (task.checklistBatchID == 300112 || task.checklistBatchID == 10000)
      ) {
         checklistTemplateOldString = lang.WorkRequest;
      } else if (task.checklistTemplateOld == 2) {
         checklistTemplateOldString = lang.UnplannedWO;
      } else if (task.checklistTemplateOld == 4) {
         checklistTemplateOldString = lang.PlannedWO;
      } else if (task.checklistTemplateOld == 5) {
         checklistTemplateOldString = lang.CycleCount;
      }

      return {
         checklistEstTimeMinutes,
         checklistPriorityDisplay,
         checklistTotalInvoiceCost,
         checklistTotalPartsCost,
         checklistPromptTimeTotal,
         checklistPromptTimeTotalHours,
         checklistPromptTimeTotalMinutes,
         billableTimeTotal,
         billableTimeTotalHours,
         billableTimeTotalMinutes,
         checklistTotalLaborCost,
         checklistTotalOperatingCost,
         checklistTemplateOldString,
      };
   }

   public getCompletedDaysPastDue(task: {
      checklistCompletedDate?: number | null;
      checklistDueDate?: number | null;
   }): { completedDaysPastDueDate: number; completedDaysPastDueDateTooltip: string } {
      const completedDaysPastDueDate = Math.floor(
         ((task.checklistCompletedDate ?? 0) - (task.checklistDueDate ?? 0)) /
            60 /
            60 /
            24,
      );

      //set the tooltip so it says completed after or before correctly
      let completedDaysPastDueDateTooltip = "";
      const lang = this.lang();
      if (completedDaysPastDueDate >= 0) {
         completedDaysPastDueDateTooltip = `${lang.Completed} ${completedDaysPastDueDate} ${lang.DaysAfterDueDate}`;
      } else {
         completedDaysPastDueDateTooltip = `${lang.Completed} ${
            completedDaysPastDueDate * -1
         } ${lang.DaysBeforeDueDate}`;
      }

      return { completedDaysPastDueDate, completedDaysPastDueDateTooltip };
   }

   public getCompletedTaskCalculatedInfo(task: Task | TaskEntity | TaskTemplateEntity):
      | {
           completedFirstName: string;
           completedLastName: string;
           completedByStr: string;
           completedDaysPastDueDate: number;
           completedDaysPastDueDateTooltip: string;
        }
      | undefined {
      if (!task?.checklistUserCompleted || !task?.checklistCompletedDate)
         return undefined;

      let completedByStr;

      const userCompleted = this.allUsers.get(task.checklistUserCompleted);
      if (!userCompleted) {
         completedByStr = `${this.lang().Deleted} ${this.lang().User}`;
      }

      const completedFirstName = userCompleted?.userFirstName ?? "";
      const completedLastName = userCompleted?.userLastName ?? "";
      completedByStr = `${completedFirstName} ${completedLastName}`;

      const usedUsers = {};
      usedUsers[task.checklistUserCompleted] = true;
      let extraTimes;
      if ("extraTimeIDs" in task && task.extraTimeIDs) {
         extraTimes = task.extraTimeIDs.map((extraTimeID) =>
            this.extraTime.get(extraTimeID),
         );
      } else if ("extraTime" in task) {
         extraTimes = task.extraTime ?? [];
      } else {
         extraTimes = [];
      }
      if (extraTimes) {
         for (const extraTime of extraTimes) {
            if (!extraTime?.userID) continue;
            if (usedUsers[extraTime.userID] == true) {
               continue; //user is already added to completedByStr
            }
            const user = this.allUsers.get(extraTime.userID);

            //now for each extra time we need to add on costs.
            if (user) {
               completedByStr += `, ${user.userFirstName} ${user.userLastName}`;
               usedUsers[extraTime.userID] = true;
            } else {
               //couldn't find the user any more so don't add any value onto the labor cost
               completedByStr += `, ${this.lang().DeletedUser}`;
            }
         }
      }

      const { completedDaysPastDueDate, completedDaysPastDueDateTooltip } =
         this.getCompletedDaysPastDue(task);

      return {
         completedFirstName,
         completedLastName,
         completedByStr,
         completedDaysPastDueDate,
         completedDaysPastDueDateTooltip,
      };
   }

   public getTaskAssignmentInfo(
      task:
         | Task
         | TaskEntity
         | TaskTemplateEntity
         | TasksSchedulesCombinedEntity
         | ScheduleEntity,
   ): TaskAssignmentInfo {
      let userEmail: string = "",
         userFirstName: string = "",
         userLastName: string = "",
         displayName: string = "",
         profileDescription: string = "",
         assignment: string = "";

      if (task.userID && task.userID > 0) {
         const user = this.manageUser.getUser(task.userID);
         if (user) {
            userEmail = user.userEmail;
            userFirstName = user.userFirstName;
            userLastName = user.userLastName;
            displayName = `${userFirstName} ${userLastName}`;
         } else {
            userFirstName = this.lang().Deleted;
            userLastName = this.lang().User;
            displayName = this.lang().DeletedUser;
         }
         assignment = userLastName;
      } else if (task.profileID) {
         const taskProfile = this.getTaskProfile(task.profileID);
         if (taskProfile) {
            profileDescription = taskProfile.profileDescription ?? "";
            displayName = profileDescription;
         }
         assignment = profileDescription;
      } else {
         displayName = this.lang().Unassigned;
         assignment = this.lang().Unassigned;
      }
      return {
         userEmail,
         userFirstName,
         userLastName,
         displayName,
         profileDescription,
         assignment,
      };
   }

   public getPriorityInfo(priorityID: number | null): {
      priorityLevel: number;
      priorityName: string;
   } {
      const priorityListIndex = this.managePriority.getPriorityListIndex();
      if (priorityID === null || priorityListIndex[priorityID] === undefined) {
         return {
            priorityLevel: 0,
            priorityName: this.lang().NoPriority,
         };
      }
      return {
         priorityLevel: Number(priorityListIndex[priorityID].priorityLevel),
         priorityName: `${priorityListIndex[priorityID].priorityLevel} ${priorityListIndex[priorityID].name}`,
      };
   }

   public getStatusInfo(statusID: number | null):
      | {
           statusName: string;
           statusAbbr: string;
        }
      | undefined {
      const statusListIndex = this.manageStatus.getStatusListIndex();
      if (statusID === null || !statusListIndex[statusID]) {
         return undefined;
      }
      return {
         statusName: statusListIndex[statusID].name,
         statusAbbr: this.getAbbreviation(statusListIndex[statusID].name),
      };
   }

   private getAbbreviation(text: string): string {
      if (typeof text !== "string" || !text) {
         return "";
      }
      return text
         .split(" ")
         .map((word) => word.slice(0, 1))
         .join("")
         .toUpperCase()
         .slice(0, 4);
   }

   public getDaysForTask(task: Task | TaskEntity | TaskTemplateEntity):
      | {
           days: number;
           daysMsg: string;
           daysStatus: string;
           exactDays: number;
        }
      | undefined {
      if (task.checklistDueDate === null) return undefined;
      const seconds: any = new Date(new Date().toDateString());
      //PLAID worth checking here that the exactDays value represents what it is supposed to.
      //As far as I can tell, it should be the inverse of days.  Not sure what the name
      //'exactDays' is supposed to signify
      const dueDate: any = new Date(
         new Date((task.checklistDueDate ?? 0) * 1000).toDateString(),
      );
      let days = (dueDate - seconds) / (1000 * 60 * 60 * 24);

      if (days > 0) {
         days = Math.floor(days);
         if (days == 1) {
            return {
               days: days * -1,
               daysMsg: this.lang().dayLeftToDueDate,
               daysStatus: this.lang().beforeDueDate,
               exactDays: days * -1,
            };
         }
         return {
            days: days * -1,
            daysMsg: this.lang().daysLeftToDueDate,
            daysStatus: this.lang().beforeDueDate,
            exactDays: days * -1,
         };
      } else if (days === 0) {
         return {
            days,
            daysMsg: this.lang().dueToday,
            daysStatus: this.lang().dueToday,
            exactDays: days,
         };
      }
      days = Math.ceil(days);
      if (days == -1) {
         return {
            days: days * -1,
            daysMsg: this.lang().dayPastDue,
            daysStatus: this.lang().Overdue,
            exactDays: days * -1,
         };
      }
      return {
         days: days * -1,
         daysMsg: this.lang().daysPastDue,
         daysStatus: this.lang().Overdue,
         exactDays: days * -1,
      };
   }

   public getTaskDueDateDisplay(task: Task | TaskEntity | TaskTemplateEntity): string {
      if (
         typeof task.checklistDueDate !== "number" ||
         typeof task.checklistStartDate !== "number"
      )
         return "";
      let checklistDueDateDisplay;

      if (task.checklistDueDateSetting == 1) {
         //if the setting flag shows that we should fully display...
         checklistDueDateDisplay = this.betterDate.formatBetterDate(
            task.checklistDueDate * 1000,
            "dateTimeWithSeconds",
         );
      } else {
         checklistDueDateDisplay = this.betterDate.formatBetterDate(
            task.checklistDueDate * 1000,
            "date",
         );
      }

      //if there is a start date then we need to prepend so it shows that it is between two dates
      if (
         task.checklistStartDate > 0 &&
         task.checklistStartDate < task.checklistDueDate
      ) {
         if (task.checklistStartDateSetting == 1) {
            //if the setting flag shows that we should fully display...
            checklistDueDateDisplay = `${this.betterDate.formatBetterDate(
               task.checklistStartDate * 1000,
               "dateTimeWithSeconds",
            )} - ${checklistDueDateDisplay}`;
         } else {
            checklistDueDateDisplay = `${this.betterDate.formatBetterDate(
               task.checklistStartDate * 1000,
               "date",
            )} - ${checklistDueDateDisplay}`;
         }
      }

      return checklistDueDateDisplay;
   }

   public getTaskCommentsInfo(task: Task): {
      showCommentsHint: boolean;
      unreadComments: number;
   } {
      // Augmented this to also track not just if the task has comments, but also how many unrad tasks.
      // Since we were already looping though the comments anyway, I figured this would be the most
      // efficient place to add this code.
      const lastVisited =
         this.manageUser.getUserLastVisitedIndexByChecklistID()[task.checklistID];
      let lastVisitedTimestamp = 0;
      if (lastVisited?.lastVisitedTimestamp) {
         lastVisitedTimestamp = lastVisited.lastVisitedTimestamp;
      } else if (
         task.checklistCompletedDate !== null &&
         task.checklistCompletedDate > 0
      ) {
         lastVisitedTimestamp = task.checklistCompletedDate;
      }
      let unreadComments = 0;
      let showCommentsHint = false;
      if (!task) {
         return {
            showCommentsHint: false,
            unreadComments: 0,
         };
      }
      if (task.noteIDs) {
         for (const noteID of task.noteIDs) {
            const note = this.getComment(noteID);
            if (note === undefined) {
               return {
                  showCommentsHint: false,
                  unreadComments: 0,
               };
            }
            if (note.noteAutomaticGen == 0) {
               showCommentsHint = true;
               if (
                  note.noteTimestamp !== null &&
                  note.noteTimestamp > lastVisitedTimestamp
               ) {
                  unreadComments++;
               }
            }
         }
      }
      return {
         showCommentsHint,
         unreadComments,
      };
   }

   public getPartRelationsByPartID(
      partID: number,
   ): Lookup<"relationID", TaskPartRelation> {
      return this.partRelationsByPartID.get(partID) ?? new Lookup("relationID");
   }

   /**
    * @deprecated fetch tasks through the backend using the tasks-api service rather than using the local lookup.
    */
   public addTaskToLookup(task): void {
      const newTask: Task = {
         ...task,
         invoiceIDs: [],
         defermentIDs: [],
         extraAssetIDs: [],
         reoccurIDs: [],
         extraTimeIDs: [],
         noteIDs: [],
      };
      if (!newTask.partRelationIDs) newTask.partRelationIDs = [];
      this.tasks.set(newTask.checklistID, newTask);
   }

   public addVendorTaskToLookup(vendorTask): void {
      const newVendorTask: VendorTask = { ...vendorTask };
      this.vendorTasks.set(newVendorTask.shareID, newVendorTask);
   }

   addHiddenProfileToFlatDataIfNeeded = (profileID, name, locationID) => {
      if (profileID > 0 && this.getTaskProfile(profileID) === undefined) {
         //we made a hidden profile on the fly so we need to now update it.
         const profile: TaskProfile = {
            profileID: profileID,
            profileDescription: name,
            profileDeleted: 0,
            locationID: locationID,
            profileHidden: 1,
            profileParent: null,
            customerID: null,
            userID: null,
         };

         this.allProfiles.set(profileID, profile);
      }
   };

   public getCustomTagsObj(task: { checklistInstructions?: string | null }): {
      customTags: Array<any>;
      customTagsStr: string;
   } {
      const customInfo: { customTags: Array<any>; customTagsStr: string } = {
         customTags: [],
         customTagsStr: "",
      };

      let str = task.checklistInstructions ?? "";

      str = this.stripEmails(str);

      const result = str.match(/@(.*?);/g);
      for (const key2 in result) {
         customInfo.customTagsStr = `${customInfo.customTagsStr + result[key2]} `;
         customInfo.customTags.push(result[key2]);
      }

      if (customInfo.customTagsStr.length > 0) {
         customInfo.customTagsStr = customInfo.customTagsStr.slice(0, -1);
      }
      return customInfo;
   }

   private stripEmails(email: string): string {
      return email.replace(/([^.@\s]+)(\.[^.@\s]+)*@([^.@\s]+\.)+([^.@\s]+)/g, "");
   }

   public getTaskNotesCalculatedInfoLegacy(
      note: Comment | TaskComment,
   ): CommentCalculatedInfo {
      let relatedChecklistName;

      if (note.relatedChecklistID && note.relatedChecklistID > 0) {
         const task = this.getTaskLocalLookup(note.relatedChecklistID);
         relatedChecklistName = task?.checklistName;
      }

      //build out the files so they can be displayed properly
      const files: Array<{
         fileName: string;
         fileExt: string;
         getURL: string;
         isImage: boolean;
      }> = [];
      if (note.noteFiles) {
         const splitFiles = note.noteFiles.split("|") || [];
         for (const file of splitFiles) {
            if (file.length > 1) {
               const obj: any = {};
               const regex = /(?:\.([^.]+))?$/;

               obj.fileName = file;
               obj.fileExt = regex.exec(obj.fileName)?.[1]?.toLowerCase();
               const customerID = this.manageUser.getCurrentUser().userInfo.customerID;
               const checklistID =
                  "checklistID" in note ? note.checklistID : note.relatedChecklistID;
               obj.getURL = `viewFile.php?f=upload-${customerID}/comments/${note.userID}/${checklistID}/${obj.fileName}`;
               obj.isImage = false;

               if (this.manageFiles.checkImageExt(obj.fileName)) {
                  obj.isImage = true;
               }

               files.push(obj);
            }
         }
      }

      //build out the display for who actually submitted this note

      const lang = this.lang();
      const user = note.userID ? this.allUsers.get(note.userID) : undefined;

      let displayName;
      if (
         //checks to see if this note was created by the Limble System
         note.userID == 1 ||
         (user && user.userInternal == 1 && user.userWorkOrderUser == 0)
      ) {
         displayName = lang.System;
      } else if (
         //check to see if it was made by a user
         note.noteEmailAddress == null &&
         note.userID != 1 &&
         user &&
         user.userInternal == 0
      ) {
         displayName = `${user.userFirstName} ${user.userLastName}`;
      } else if (
         //must be an external note then
         note.noteEmailAddress != null &&
         note.noteEmailAddress != ""
      ) {
         displayName = `${lang.ExternalUser} - ${note.noteEmailAddress}`;
      }
      return {
         relatedChecklistName,
         files,
         displayName,
      };
   }

   /**
    * @deprecated fetch tasks through the backend using the tasks-api service rather than using the local lookup.
    */
   public getTaskLocalLookup(checklistID: number): Task | undefined {
      //this is primary used for doing local lookups
      //this funciton was made because I didn't want to run the chance that we would be calling a ton of posts by doing getTask

      const openTask = this.tasks.get(checklistID);
      if (openTask !== undefined) {
         return openTask;
      }
      const completedTask = this.getCompletedTask(checklistID, "ManageTask");
      if (completedTask !== undefined) {
         return completedTask;
      }
      return undefined;
   }

   /**
    * @deprecated fetch tasks through the backend using the tasks-api service rather than using the local lookup.
    */
   public getOpenTaskLocalLookup(checklistID: number): Task | undefined {
      const openTask = this.tasks.get(checklistID);

      if (openTask !== undefined) {
         return openTask;
      }

      return undefined;
   }

   /**
    * @deprecated fetch tasks through the backend using the tasks-api service rather than using the local lookup.
    */
   public getUnfilteredTask(checklistID: number): Task | undefined {
      return this.unfilteredTasks.get(checklistID);
   }

   public async addNote(
      note: string,
      checklistID: number,
      noteHidden: number,
      autoGen: number,
      sendNotifications: boolean,
   ): Promise<AxiosResponse> {
      const answer = await this.taskPhpApiService.addNote({
         note: note,
         checklistID: checklistID,
         noteHidden: noteHidden,
         timestamp: null,
         autoGen: autoGen,
         sendNotifications: sendNotifications,
      });

      if (answer.data.success == true) {
         answer.data.note.noteHidden = noteHidden;
         this.comments.set(answer.data.note.noteID, answer.data.note);

         const commentID = Number(answer.data.note.noteID);
         const task = await this.refreshLocalTask(checklistID);
         if (task && !task.noteIDs?.includes(commentID)) {
            task.noteIDs.push(commentID);
         }
         Promise.all([
            this.taskStorageSyncService.syncTaskByID(checklistID),
            this.commentStorageSyncService.syncComment(commentID),
         ]);

         this.onNoteIDsChanged.emit(answer.data.note);
      }

      return answer;
   }

   public async editNote(checklistID: number, noteID: number, noteMessage: string) {
      const post = this.taskPhpApiService.editNote({
         noteID: noteID,
         noteMessage: noteMessage,
         checklistID: checklistID,
      });

      post.then((answer) => {
         if (!answer.data.success) {
            return;
         }

         const oldNote = this.comments.get(answer.data.note.noteID);
         answer.data.note.noteHidden = oldNote?.noteHidden;
         this.comments.set(answer.data.note.noteID, answer.data.note);

         this.taskStorageSyncService.syncTaskByID(checklistID);
         this.commentStorageSyncService.syncComment(noteID);

         this.onNoteIDsChanged.emit(answer.data.note);
      });

      return post;
   }

   /**
    * This is a copy of the getData function, with the caveat that requests are handled
    * separately from processing the data in cases where the requests are excessively time-consuming.
    *
    * Currently, this function splits the request and processing phases for:
    * - Uncompleted tasks
    */
   public async getDataNew(uncompletedTasks: Array<any>): Promise<void> {
      this.processUncompletedTasks(uncompletedTasks);

      await Promise.all([
         this.fetchPartRelations(),
         this.fetchRecurrences(),
         this.fetchDeferments(),
         this.fetchExtraTime(),
         this.fetchExtraAssets(),
         this.fetchProfiles(),
         this.fetchExternalShares(),
         this.fetchItemTypes(),
         this.fetchUsers(),
         this.getAllTaskRelations(),
      ]);
      this.buildSecondaryLookups();
      this.incTasksWatchVar();
   }

   public async getData(): Promise<void> {
      await Promise.all([
         this.fetchUncompletedTasks(),
         this.fetchPartRelations(),
         this.fetchRecurrences(),
         this.fetchDeferments(),
         this.fetchExtraTime(),
         this.fetchExtraAssets(),
         this.fetchProfiles(),
         this.fetchExternalShares(),
         this.fetchItemTypes(),
         this.fetchUsers(),
         this.getAllTaskRelations(),
      ]);
      this.buildSecondaryLookups();
      this.incTasksWatchVar();
   }

   private buildSecondaryLookups(): void {
      for (const partRelation of this.partRelations) {
         this.addPartRelationByChecklistIDToLocalData(partRelation);
      }
      for (const extraAsset of this.extraAssets) {
         const existingLookupByChecklistID = this.extraAssetsByChecklistID.get(
            extraAsset.checklistID,
         );
         if (existingLookupByChecklistID) {
            existingLookupByChecklistID?.set(extraAsset.assetID, extraAsset);
            continue;
         }
         const newAssetIDLookup: Lookup<"assetID", ExtraAsset> = new Lookup("assetID");
         newAssetIDLookup.set(extraAsset.assetID, extraAsset);

         this.extraAssetsByChecklistID.set(extraAsset.checklistID, newAssetIDLookup);
      }
      for (const extraTime of this.extraTime) {
         this.addExtraTimeToExtraTimeByChecklistID(extraTime);
      }
      for (const vendorTask of this.vendorTasks) {
         const existingLookupByChecklistID = this.vendorTasksByChecklistID.get(
            vendorTask.checklistID,
         );
         if (existingLookupByChecklistID) {
            existingLookupByChecklistID?.set(vendorTask.shareID, vendorTask);
            continue;
         }
         const newShareIDLookup: Lookup<"shareID", VendorTask> = new Lookup("shareID");
         newShareIDLookup.set(vendorTask.shareID, vendorTask);

         this.vendorTasksByChecklistID.set(vendorTask.checklistID, newShareIDLookup);
      }
   }

   public addPartRelationByChecklistIDToLocalData(partRelation: TaskPartRelation) {
      const existingValueByPartID = this.partRelationsByPartID.get(partRelation.partID);
      if (existingValueByPartID) {
         existingValueByPartID?.set(partRelation.relationID, partRelation);
         return;
      }
      const newRelationIDLookup: Lookup<"relationID", TaskPartRelation> = new Lookup(
         "relationID",
      );
      newRelationIDLookup.set(partRelation.relationID, partRelation);

      this.partRelationsByPartID.set(partRelation.partID, newRelationIDLookup);
   }

   public removePartRelationByChecklistIDToLocalData(partRelation: TaskPartRelation) {
      const existingValueByPartID = this.partRelationsByPartID.get(partRelation.partID);

      if (existingValueByPartID) {
         existingValueByPartID?.delete(partRelation.relationID);
      }
   }

   private addExtraTimeToExtraTimeByChecklistID(extraTime: ExtraTime): void {
      const existingLookupByChecklistID = this.extraTimeByChecklistID.get(
         extraTime.checklistID,
      );
      if (existingLookupByChecklistID) {
         existingLookupByChecklistID?.set(extraTime.extraTimeID, extraTime);
         return;
      }
      const newExtraTimeIDLookup: Lookup<"extraTimeID", ExtraTime> = new Lookup(
         "extraTimeID",
      );
      newExtraTimeIDLookup.set(extraTime.extraTimeID, extraTime);

      this.extraTimeByChecklistID.set(extraTime.checklistID, newExtraTimeIDLookup);
   }

   public requestUncompletedTasks(includeTemplates?: boolean): Observable<Array<any>> {
      let page = 1;
      const limit = 25000; //this limit targets 50MB without going over for perforance reasons

      //this pattern gets paginated results sequentially by expanding a recursive call
      const completedTasks$ = from(
         this.runUncompletedTasksQuery(includeTemplates, page, limit),
      ).pipe(
         expand((response): Promise<AxiosResponse> | Observable<never> => {
            page += 1;
            return response.data.length < limit
               ? EMPTY
               : this.runUncompletedTasksQuery(includeTemplates, page, limit);
         }),
         map((val) => val.data),
         reduce((acc, cur) => {
            // eslint-disable-next-line typescript/prefer-for-of -- because the for loop is faster for this performance sensitive action
            for (let i = 0; i < cur.length; i++) {
               acc.push(cur[i]);
            }
            return acc;
         }),
      );
      return completedTasks$;
   }

   private async runUncompletedTasksQuery(
      includeTemplatesOverride: boolean | undefined,
      page: number,
      limit: number,
   ): Promise<AxiosResponse<Array<any>>> {
      const startTime = Math.floor(Date.now());
      let includeTemplates = !isMobile();
      if (includeTemplatesOverride !== undefined) {
         includeTemplates = includeTemplatesOverride;
      }
      return this.tasksApiService
         .uncompletedTasks(includeTemplates, page, limit)
         .finally(() => {
            logApiPerformance(
               "uncompletedTasks",
               startTime,
               this.manageUser.getCurrentUser(),
            );
         });
   }

   public async processUncompletedTasks(tasks) {
      for (const task of tasks) {
         this.processDisplayedTextOnTask(task);
         if (task.geoLocation) task.geoLocation = JSON.parse(task.geoLocation);
      }
      this.unfilteredTasks = new Lookup("checklistID", tasks);

      const currentUser = await this.manageUser.getCurrentUserFirstValue();

      this.tasks = this.manageFilters.filterTasksByCredAtLocation(
         this.unfilteredTasks,
         currentUser,
      );
   }

   public async fetchUncompletedTasks(): Promise<void> {
      const startTime = Math.floor(Date.now());
      const isJitTemplatesDesktopEnabled = await this.launchFlagsService.isEnabled(
         Flags.JIT_TEMPLATES_DESKTOP,
      );
      const includeTemplates = !isJitTemplatesDesktopEnabled;

      const response = await firstValueFrom(
         this.requestUncompletedTasks(includeTemplates),
      );

      for (const task of response) {
         this.processDisplayedTextOnTask(task);
         if (task.geoLocation) task.geoLocation = JSON.parse(task.geoLocation);
      }
      this.unfilteredTasks = new Lookup("checklistID", response);
      this.tasks = this.manageFilters.filterTasksByCredAtLocation(
         this.unfilteredTasks,
         this.manageUser.getCurrentUser(),
      );
      logApiPerformance(
         "uncompletedTasks",
         startTime,
         this.manageUser.getCurrentUser(),
         response,
      );
   }

   public async fetchNotes(filters?: TaskCommentsEntityFilters): Promise<void> {
      const startTime = Math.floor(Date.now());
      if (filters) {
         const taskComments = await lastValueFrom(
            this.taskCommentsApiService.getPaginatedComments({ filters }),
         );

         // if filters are provided, don't create new lookup as not to discard lookups from other JIT task notes usage
         this.comments ??= new Lookup("noteID", []);
         taskComments.forEach((taskComment) =>
            this.comments.set(taskComment.noteID, taskComment),
         );
      } else {
         const taskComments = await lastValueFrom(
            this.taskCommentsApiService.getPaginatedComments(),
         );
         this.comments = new Lookup("noteID", taskComments ?? []);
      }
      logApiPerformance("notes", startTime, this.manageUser.getCurrentUser());
   }

   public async fetchPartRelations(): Promise<void> {
      const startTime = Math.floor(Date.now());
      const response = await this.tasksApiService.getTaskParts();

      logApiPerformance(
         "tasksParts",
         startTime,
         this.manageUser.getCurrentUser(),
         response,
      );
      this.partRelations = new Lookup("relationID", response.data);
   }

   public async fetchRecurrences(): Promise<void> {
      const startTime = Math.floor(Date.now());

      const response = await this.tasksApiService.getTaskRecurrences();

      logApiPerformance(
         "taskRecurrences",
         startTime,
         this.manageUser.getCurrentUser(),
         response,
      );
      this.recurrences = new Lookup("reoccurID", response.data);
   }

   public async fetchDeferments(): Promise<void> {
      const startTime = Math.floor(Date.now());

      const response = await this.tasksApiService.getTaskDeferments();

      logApiPerformance(
         "taskDeferments",
         startTime,
         this.manageUser.getCurrentUser(),
         response,
      );
      this.deferments = new Lookup("deferID", response.data);
   }

   public async fetchExtraTime(): Promise<void> {
      const startTime = Math.floor(Date.now());

      const shouldApplyDataLimit = await this.launchFlagDarklyService.getFlagPromise(
         Flags.EXTRA_TIME_DATA_LIMIT,
         false,
      );

      if (shouldApplyDataLimit) {
         const response = await lastValueFrom(
            this.taskExtraTimeApiService.getPaginatedExtraTime({
               filters: {
                  dataLimiting: true,
               },
            }),
         );
         this.extraTime = new Lookup("extraTimeID", response);
         logApiPerformance(
            "tasksExtraTime",
            startTime,
            this.manageUser.getCurrentUser(),
            response,
         );
      } else {
         const response = await this.tasksApiService.getTaskExtraTime();
         this.extraTime = new Lookup("extraTimeID", response.data);
         logApiPerformance(
            "tasksExtraTime",
            startTime,
            this.manageUser.getCurrentUser(),
            response,
         );
      }
   }

   public async fetchExtraAssets(): Promise<void> {
      const startTime = Math.floor(Date.now());

      const response = await this.tasksApiService.getTaskExtraAssets();

      logApiPerformance(
         "tasksExtraAssets",
         startTime,
         this.manageUser.getCurrentUser(),
         response,
      );
      this.extraAssets = new Lookup("relationID", response.data);
   }

   public async fetchProfiles(): Promise<void> {
      const startTime = Math.floor(Date.now());

      const response = await this.tasksApiService.getTaskProfiles();

      logApiPerformance(
         "profiles",
         startTime,
         this.manageUser.getCurrentUser(),
         response,
      );
      this.allProfiles = new Lookup("profileID", response.data);
   }

   public async fetchExternalShares(): Promise<void> {
      const startTime = Math.floor(Date.now());

      const response = await this.tasksApiService.getTaskExternalShares();

      logApiPerformance(
         "vendorTasks",
         startTime,
         this.manageUser.getCurrentUser(),
         response,
      );

      this.vendorTasks = new Lookup("shareID", response.data);
   }

   public async fetchItemTypes(): Promise<void> {
      const startTime = Math.floor(Date.now());

      const response = await this.tasksApiService.getTaskItemTypes();

      logApiPerformance(
         "taskItemTypes",
         startTime,
         this.manageUser.getCurrentUser(),
         response,
      );
      this.itemTypes = new Lookup("itemTypeID", response.data);
   }

   public async fetchUsers(): Promise<void> {
      const startTime = Math.floor(Date.now());

      const response = await this.tasksApiService.getTaskUsers();

      logApiPerformance("users", startTime, this.manageUser.getCurrentUser(), response);
      this.allUsers = new Lookup("userID", response.data);
   }

   private async getAllTaskRelations() {
      const startTime = Math.floor(Date.now());

      const response = await this.taskPhpApiService.getAllTaskRelations();

      const relations = response.data.relations || [];

      for (const thisRelation of relations) {
         this.taskRelations[thisRelation.assignedChecklistID] = thisRelation;
      }

      logApiPerformance("tasksParts", startTime, this.manageUser.getCurrentUser());
      this.manageObservables.updateObservable("taskRelationsLoaded", true);
   }

   public async generateUniqueExternalLink(
      checklistID: number,
      email: string,
      emailMessage: string,
      subject: string,
      locationID: number,
      vendorID: number,
   ): Promise<AxiosResponse> {
      const post = this.taskPhpApiService.generateUniqueExternalLink({
         checklistID: checklistID,
         email: email,
         emailMessage: emailMessage,
         subject: subject,
         locationID: locationID,
         vendorID: vendorID,
      });

      post.then((answer) => {
         if (answer.data.success) {
            answer.data.shares = answer.data.shares || [];
            for (const share of answer.data.shares) {
               this.addVendorTaskToLookup(share);
            }

            if (answer.data.notes) {
               for (const note of answer.data.notes) {
                  const task = this.getTaskLocalLookup(note.checklistID);

                  if (task) {
                     note.noteMessage = note.noteMessage.replace(/&gt;/g, ">");
                     note.noteMessage = note.noteMessage.replace(/&lt;/g, "<");
                     this.addNoteToLocalData(note, task.checklistID);
                     this.onNoteIDsChanged.emit(note);
                     task.checklistLastEdited = Math.floor(Date.now() / 1000);
                  }
               }

               this.incTasksWatchVar();
            }
         }
      });

      return post;
   }

   //PT_GoodToGo
   getUniqueExternalLink = async (link, email, customerID) => {
      const post = this.taskPhpApiService.getUniqueExternalLink({
         link: link,
         email: email,
         customerID: customerID,
      });

      return post;
   };

   getCompletedTasksWatchVar = () => {
      return this.completedTasksWatchVar;
   };

   getTasksWatchVar = () => {
      return this.tasksWatchVar;
   };

   getVendorTileWatchVar = () => {
      return this.vendorTileWatchVar;
   };

   public filterByDashboardReportingLimit(
      tasks,
      dashboardReportingLimit: number | undefined,
   ): TaskLookup {
      if (dashboardReportingLimit && tasks) {
         return tasks.filter((task) => {
            return (
               task.checklistCreatedDate >=
               moment().subtract(dashboardReportingLimit, "days").unix()
            );
         });
      }

      return tasks;
   }

   /**
    * @deprecated fetch tasks through the backend using the tasks-api service rather than using the local lookup.
    */
   public getPMs(): TaskLookup {
      const allPMs =
         this.tasks.filter((task) => {
            const type = this.taskTypeService.getType(task);
            return type === TaskType.PlannedMaintenanceTemplate;
         }) ?? [];

      return this.manageFilters.filterOutTasksWithDeletedAssets(
         allPMs,
         this.manageAsset.getAssets(),
      );
   }

   public getPMsByLocation(locationID: number): TaskLookup {
      const tasks = this.getPMs();
      return this.manageFilters.getTaskTemplatesAtLocation(tasks, locationID);
   }

   public checkRelation(taskID): { success: boolean; relation?: TaskRelationData } {
      if (this.taskRelations[taskID] === undefined) {
         return { success: false };
      }
      return { success: true, relation: this.taskRelations[taskID] };
   }

   addRelation = (
      itemID,
      originalChecklistID,
      assignedChecklistID,
      originalChecklistName,
   ) => {
      this.taskRelations[assignedChecklistID] = {
         itemID: itemID,
         originalChecklistID: originalChecklistID,
         assignedChecklistID: assignedChecklistID,
         checklistName: originalChecklistName,
      };
   };

   public requestCompletedTasks() {
      this.taskRefreshService.refreshCompletedTasks(
         this.processCompletedTasks.bind(this),
      );
   }

   public requestCompletedTasksLegacy(): Observable<{ tasks: Array<any> }> {
      const startTime = Math.floor(Date.now());
      // NOTE: this can be improved, if first we get the total number of completed tasks
      // and create smaller parallel requests, since the refresh service will be taking
      // as much time as the longest request to complete.
      const combinedData$ = combineLatest(
         [this.getPaginatedCompletedTasksLegacy()],
         (tasks) => {
            return { tasks };
         },
      );

      return combinedData$.pipe(
         tap((response) => {
            logApiPerformance(
               "completedTasks",
               startTime,
               this.manageUser.getCurrentUser(),
               response?.tasks,
            );
         }),
      );
   }

   public processCompletedTasks(tasks) {
      for (const task of tasks) {
         task.checklistStartDate = task.checklistStartDate ??= 0;
         task.checklistStartDateSetting = task.checklistStartDateSetting ??= 0;
         task.checklistGreen = task.checklistGreen ??= 0;
         task.checklistYellow = task.checklistYellow ??= -1;
         task.checklistRed = task.checklistRed ??= -2;
         task.checklistPriorBatchID = task.checklistPriorBatchID ??= 0;
         task.checklistDowntime = task.checklistDowntime ??= 0;
         task.checklistEstTime = task.checklistEstTime ??= 0;
         task.checklistPriority = task.checklistPriority ??= 0;
         task.billableRate = task.billableRate ??= null;

         this.processDisplayedTextOnTask(task);
         if (task.geoLocation) {
            try {
               const geo = JSON.parse(task.geoLocation);
               task.geoLocation = geo;
            } catch (error) {
               console.error(`Invalid JSON format for geoLocation:`, task.geoLocation);
            }
         }
      }

      this.completedTasks = new Lookup("checklistID", tasks);
      this.incCompletedTasksWatchVar();
   }

   /**
    * NOTE: This should be removed once the flag for IMPROVE_FIRST_LOAD is removed
    */
   public processCompletedTasksLegacy(tasks) {
      for (const task of tasks) {
         this.processDisplayedTextOnTask(task);
         if (task.geoLocation) {
            try {
               const geo = JSON.parse(task.geoLocation);
               task.geoLocation = geo;
            } catch (error) {
               console.error(`Invalid JSON format for geoLocation:`, task.geoLocation);
            }
         }
      }

      this.completedTasks = new Lookup("checklistID", tasks);
      this.incCompletedTasksWatchVar();
   }

   public async fetchCompletedTasks() {
      const combinedData$ = combineLatest(
         [this.getPaginatedCompletedTasksLegacy()],
         (tasks) => {
            return { tasks };
         },
      );
      const response = await firstValueFrom(combinedData$);

      for (const task of response.tasks) {
         this.processDisplayedTextOnTask(task);
         if (task.geoLocation) task.geoLocation = JSON.parse(task.geoLocation);
      }

      this.completedTasks = new Lookup("checklistID", response.tasks);
      this.incCompletedTasksWatchVar();
   }

   private processDisplayedTextOnTask(task: Task): void {
      //This is processing we decided to keep, since the alternative would be to either
      //create functions to get the checklistName for display and use that in each and
      //every place checklistName is displayed OR to go through the database and remove
      //duplicate spaces from all checklistName values and then add a check for duplicate
      //spaces on update of a task name.  Both of those options seemed unreasonable or
      //outside the scope of this project
      task.checklistName =
         task.checklistName && this.manageUtil.removeDuplicateSpaces(task.checklistName);
      task.checklistInstructions =
         task.checklistInstructions &&
         this.manageUtil.removeDuplicateSpaces(task.checklistInstructions);
   }

   private getPaginatedCompletedTasksLegacy(): Observable<Array<any>> {
      let page = 1;
      const limit = 25000;

      const completedTasks$ = from(this.runCompletedTasksQuery(page, limit)).pipe(
         expand((response): Promise<AxiosResponse> | Observable<never> => {
            page += 1;
            return response.data.length < limit
               ? EMPTY
               : this.runCompletedTasksQuery(page, limit);
         }),
         map((val) => val.data),
         reduce((acc, cur) => {
            // eslint-disable-next-line typescript/prefer-for-of -- because the for loop is faster for this performance sensitive action
            for (let i = 0; i < cur.length; i++) {
               acc.push(cur[i]);
            }
            return acc;
         }),
      );
      return completedTasks$;
   }

   private async runCompletedTasksQuery(
      page: number,
      limit: number,
   ): Promise<AxiosResponse<Array<any>>> {
      const response = await this.tasksApiService.getCompleteTasks(limit, page);
      for (const value of response.data) {
         value.checklistStartDate ??= 0;
         value.checklistStartDateSetting ??= 0;
         value.checklistGreen ??= 0;
         value.checklistYellow ??= -1;
         value.checklistRed ??= -2;
         value.checklistPriorBatchID ??= 0;
         value.checklistDowntime ??= 0;
         value.checklistEstTime ??= 0;
         value.checklistPriority ??= 0;
         value.billableRate ??= null;
      }

      return response;
   }

   public async getTaskCountWithInstructions(month: Moment): Promise<number> {
      return this.tasksApiService.getTaskCountWithInstructions(month);
   }

   /**
    * @deprecated fetch tasks through the backend using the tasks-api service rather than using the local lookup.
    */
   public getAllTasks(): Lookup<"checklistID", Task> {
      return new Lookup("checklistID", [...this.completedTasks, ...this.tasks]);
   }

   public async getFutureTasks(taskID: number, locationID: number) {
      const post = await this.taskPhpApiService.getFutureTasks({
         taskID: taskID,
         locationID: locationID,
      });

      for (const schedule of post.data.schedules) {
         const asset = this.manageAsset.getAsset(schedule.assetID);
         if (asset) {
            schedule.assetName = asset.assetName;
         }
      }

      return post.data.schedules;
   }

   /****************************************
    *@function updateSingleSchedule
    *@purpose gets two arrays.  The first is of all the users and the other is of all the profiles.
    *@name updateSingleSchedule
    *@param
    *@return
    ****************************************/
   updateSingleSchedule = async (
      userID: number,
      profileID: number,
      multiUsers: Array<number>,
      scheduleID: number,
   ) => {
      const multiUsersStringified = JSON.stringify(multiUsers);

      const post = this.taskPhpApiService.updateSingleSchedule({
         userID: userID,
         profileID: profileID,
         multiUsers: multiUsersStringified,
         scheduleID: scheduleID,
      });

      return post;
   };

   /****************************************
    *@function deleteSingleSchedule
    *@purpose removes a single schedule
    *@name deleteSingleSchedule
    *@param
    *@return
    ****************************************/
   deleteSingleSchedule = async (scheduleID: number, userID: number) => {
      const post = this.taskPhpApiService.deleteSingleSchedule({
         scheduleID: scheduleID,
         userID: userID,
      });

      return post;
   };

   startInstanceTask = async (
      checklistID: number,
      locationID: number,
      userID: number | null,
      profileID: number | null,
      multiUsers: Array<number> | null,
      timestamp,
      assetID: number,
      timeOfDay,
      manageUser: ManageUser,
      manageProfile: ManageProfile,
   ) => {
      const multiUsersStringified = JSON.stringify(multiUsers);

      const post = this.taskPhpApiService.startInstanceTask({
         checklistID: checklistID,
         locationID: locationID,
         userID: userID,
         profileID: profileID,
         multiUsers: multiUsersStringified,
         timestamp: timestamp,
         assetID: assetID,
         timeOfDay: timeOfDay,
      });

      post.then((answer) => {
         if (answer.data.success == true) {
            const profileDescription = answer.data.profileDescription;

            const task = answer.data.row;
            if (task.profileID > 0) {
               //since we are assigning hidden profiles potentially we need to add them to the right flat data sets if needed... yes I know wtf do we have multiple data sets :(
               this.manageFilters.updateHiddenProfiles(
                  {
                     profileID: task.profileID,
                     name: profileDescription,
                     locationID: locationID,
                     multiUsers: multiUsers ?? [],
                  },
                  this,
                  manageUser,
                  manageProfile,
               );
            }

            if (task.newPartRelations) {
               if (!task.partRelationIDs) task.partRelationIDs = [];
               task.partRelationIDs.push(
                  ...task.newPartRelations.map((partRelation) => partRelation.partID),
               );
            }

            this.addTaskToLookup(task);
            this.incTasksWatchVar();
         }
      });

      return post;
   };

   public async duplicateOpenTask(
      checklistID: number,
      locationID: number,
   ): Promise<AxiosResponse> {
      const post = await this.taskPhpApiService.duplicateOpenTask({
         checklistID: checklistID,
         locationID: locationID,
      });

      if (post.data.success !== true) {
         return post;
      }
      this.addTaskToLookup(post.data.row);
      this.incTasksWatchVar();

      return post;
   }

   public async deleteTaskTemplate(
      checklistID: number,
      locationID: number,
   ): Promise<AxiosResponse> {
      const post = this.taskPhpApiService.deleteTaskTemplate({
         checklistID: checklistID,
         locationID: locationID,
      });
      post.then((answer) => {
         if (answer.data.success == true) {
            this.removeTaskFromLookup(checklistID);
            this.incTasksWatchVar();
         }
      });

      return post;
   }

   public async deleteWOTemplate(checklistID: number, locationID: number) {
      const post = this.taskPhpApiService.deleteWOTemplate({
         checklistID: checklistID,
         locationID: locationID,
      });

      post.then((answer) => {
         if (answer.data.success == true) {
            this.removeTaskFromLookup(checklistID);
            this.incTasksWatchVar();
         }
      });

      return post;
   }

   public removeTaskFromLookup(checklistID) {
      this.tasks.delete(checklistID);
   }

   /****************************************
    *@function updateTemplateScheduleColor
    *@purpose
    *@name updateTemplateScheduleColor
    *@param
    *@return
    ****************************************/
   updateTemplateScheduleColor = async (checklistID, days, colorID) => {
      const post = this.taskPhpApiService.updateTemplateScheduleColor({
         checklistID: checklistID,
         colorID: colorID,
         days: days,
      });

      return post;
   };

   /****************************************
    *@function changeSchedule
    *@purpose
    *@name changeSchedule
    *@param
    *@return
    ****************************************/
   public async changeSchedule(
      userID: number,
      profileID: number,
      multiUsers: Array<number>,
      task: Task | TaskTemplateEntity,
      manageUser: ManageUser,
      manageProfile: ManageProfile,
      dynamicAssignment: number = 0,
   ): Promise<any> {
      const multiUsersStringified = JSON.stringify(multiUsers);

      const post = this.taskPhpApiService.changeSchedule({
         taskID: task.checklistID,
         userID: userID,
         profileID: profileID,
         multiUsers: multiUsersStringified,
         dynamicAssignment: dynamicAssignment,
      });

      post.then((answer) => {
         if (answer.data.success == true) {
            if (answer.data.profileID > 0 && task.locationID) {
               //since we are assigning hidden profiles potentially we need to add them to the right flat data sets if needed... yes I know wtf do we have multiple data sets :(
               this.manageFilters.updateHiddenProfiles(
                  {
                     profileID: answer.data.profileID,
                     name: answer.data.profileDescription,
                     locationID: task.locationID,
                     multiUsers,
                  },
                  this,
                  manageUser,
                  manageProfile,
               );
            }

            task.profileID = answer.data.profileID;
            task.userID = answer.data.userID;
         }
      });

      return post;
   }

   public async changeOwner(
      userID,
      profileID,
      multiUsers,
      checklistID: number,
      manageUser: ManageUser,
      manageProfile: ManageProfile,
   ): Promise<any> {
      const task = await this.refreshLocalTask(checklistID);
      if (task === undefined) return Promise.resolve();
      if (multiUsers.length > 0) {
         //always run for multiUsers
      } else if (userID > 0) {
         if (this.tasks.get(task.checklistID)?.userID == userID) {
            //we aren't changing anything ;p
            return Promise.resolve();
         }
      } else if (profileID > 0) {
         if (this.tasks.get(task.checklistID)?.profileID == profileID) {
            //we aren't changing anything ;p
            return Promise.resolve();
         }
      } else if (userID == 0 && profileID == 0) {
         if (
            this.tasks.get(task.checklistID)?.userID == 0 &&
            this.tasks.get(task.checklistID)?.profileID == 0
         ) {
            //already unassigned
            return Promise.resolve();
         }
      }

      const post = this.taskPhpApiService.changeOwner({
         checklistID: checklistID,
         userID: userID,
         profileID: profileID,
         multiUsers: multiUsers,
      });

      post.then((answer) => {
         if (answer.data.success == true) {
            task.profileID = answer.data.profileID;
            task.userID = answer.data.userID;
            this.taskStorageSyncService.syncTaskByID(checklistID);

            if (answer.data.profileID > 0) {
               //since we are assigning hidden profiles potentially we need to add them to the right flat data sets if needed... yes I know wtf do we have multiple data sets :(
               this.manageFilters.updateHiddenProfiles(
                  {
                     profileID: answer.data.profileID,
                     name: answer.data.fName,
                     locationID: task.locationID,
                     multiUsers,
                  },
                  this,
                  manageUser,
                  manageProfile,
               );
            }

            this.incTasksWatchVar();
         }
      });

      return post;
   }

   /****************************************
    *@function changeOwner2
    *@purpose
    *@name changeOwner2
    *@param
    *@return
    ****************************************/
   changeOwner2 = async (
      userID: number,
      profileID: number,
      taskID: number,
   ): Promise<any> => {
      const task = this.getTaskLocalLookup(taskID);
      if (task === undefined) {
         return Promise.resolve();
      }
      if (userID > 0) {
         if (task.userID == userID) {
            //we aren't changing anything ;p
            return Promise.resolve();
         }
      } else if (profileID > 0) {
         if (task.profileID == profileID) {
            //we aren't changing anything ;p
            return Promise.resolve();
         }
      } else if (task.userID == 0 && task.profileID == 0) {
         //already unassigned
         return Promise.resolve();
      }

      const post = this.taskPhpApiService.changeOwnerV2({
         checklistID: taskID,
         userID: userID,
         profileID: profileID,
      });

      post.then((answer) => {
         if (answer.data.success == true) {
            if (task === undefined) {
               this.incTasksWatchVar();
               return;
            }

            task.profileID = profileID;
            task.userID = userID;

            this.incTasksWatchVar();
         }
      });

      return post;
   };

   public async changeOwnerInChk(
      userID: number,
      profileID: number,
      multiUsers,
      checklistID: number,
      item,
      manageUser: ManageUser,
      manageProfile: ManageProfile,
   ): Promise<unknown> {
      const task = this.tasks.get(checklistID);
      if (task === undefined) {
         return Promise.resolve();
      }
      if (multiUsers.length > 0) {
         //always run for multiUsers
      } else if (userID > 0) {
         if (task.userID == userID) {
            //we aren't changing anything ;p
            return Promise.resolve();
         }
      } else if (profileID > 0) {
         if (task.profileID == profileID) {
            //we aren't changing anything ;p
            return Promise.resolve();
         }
      } else if (task.userID == 0 && task.profileID == 0) {
         //already unassigned
         return Promise.resolve();
      }

      const multiUsersStringified = JSON.stringify(multiUsers);

      const post = this.taskPhpApiService.changeOwnerInTask({
         checklistID: task.checklistID,
         userID: userID,
         profileID: profileID,
         multiUsers: multiUsersStringified,
         itemID: item.itemID,
      });

      post.then((answer) => {
         if (answer.data.success == true) {
            task.profileID = answer.data.profileID;
            task.userID = answer.data.userID;

            if (answer.data.profileID > 0) {
               //since we are assigning hidden profiles potentially we need to add them to the right flat data sets if needed... yes I know wtf do we have multiple data sets :(
               this.manageFilters.updateHiddenProfiles(
                  {
                     profileID: answer.data.profileID,
                     name: answer.data.profileDescription,
                     locationID: task.locationID,
                     multiUsers,
                  },
                  this,
                  manageUser,
                  manageProfile,
               );
            }
         }
      });

      return post;
   }

   public async triggerApproval(
      itemID: number,
      userID: number,
      profileID: number,
      multiUsers,
      checklistID: number,
      manageUser: ManageUser,
      manageProfile: ManageProfile,
   ): Promise<AxiosResponse> {
      const multiUsersStringified = JSON.stringify(multiUsers);

      const post = this.taskPhpApiService.triggerApproval({
         itemID: itemID,
         userID: userID,
         profileID: profileID,
         multiUsers: multiUsersStringified,
      });

      post.then((answer) => {
         if (answer.data.success == true) {
            const task = this.getTaskLocalLookup(checklistID);
            if (task !== undefined) {
               task.profileID = profileID;
               task.userID = userID;

               if (profileID > 0) {
                  //since we are assigning hidden profiles potentially we need to add them to the right flat data sets if needed... yes I know wtf do we have multiple data sets :(
                  this.manageFilters.updateHiddenProfiles(
                     {
                        profileID: profileID,
                        name: answer.data.profileDescription,
                        locationID: task.locationID,
                        multiUsers,
                     },
                     this,
                     manageUser,
                     manageProfile,
                  );
               }
            }

            this.incCompletedTasksWatchVar();
            this.incTasksWatchVar();
         }
      });

      return post;
   }

   public async approveApproval(item): Promise<AxiosResponse> {
      const post = this.taskPhpApiService.approveApproval({
         itemID: item.itemID,
         approvalSignatureInfo: item.approvalSignatureInfo,
      });

      post.then((answer) => {
         if (answer.data.success == true) {
            const checklistID = item.checklistID;
            let profileID = item.onApproveProfileID;
            let userID = item.onApproveUserID;

            if (profileID == -1 && userID == -1) {
               profileID = item.lastProfileID;
               userID = item.lastUserID;
            }

            const task = this.getTaskLocalLookup(checklistID);
            if (task !== undefined && (userID > 0 || profileID > 0)) {
               task.profileID = profileID;
               task.userID = userID;

               this.incCompletedTasksWatchVar();
               this.incTasksWatchVar();
            }
         }
      });

      return post;
   }

   disapproveApproval = async (
      item,
      userID: number,
      profileID: number,
      multiUsers,
      reason,
      manageUser: ManageUser,
      manageProfile: ManageProfile,
   ): Promise<AxiosResponse> => {
      const multiUsersStringified = JSON.stringify(multiUsers);

      const post = this.taskPhpApiService.disapproveApproval({
         itemID: item.itemID,
         userID: userID,
         profileID: profileID,
         multiUsers: multiUsersStringified,
         reason: reason,
      });

      post.then((answer) => {
         if (answer.data.success == true) {
            const task = this.getTaskLocalLookup(item.checklistID);
            if (task !== undefined) {
               task.profileID = answer.data.profileID;
               task.userID = answer.data.userID;

               if (answer.data.profileID > 0) {
                  //since we are assigning hidden profiles potentially we need to add them to the right flat data sets if needed... yes I know wtf do we have multiple data sets :(
                  this.manageFilters.updateHiddenProfiles(
                     {
                        profileID: answer.data.profileID,
                        name: answer.data.fName,
                        locationID: task.locationID,
                        multiUsers,
                     },
                     this,
                     manageUser,
                     manageProfile,
                  );
               }
            }

            this.incCompletedTasksWatchVar();
            this.incTasksWatchVar();
         }
      });

      return post;
   };

   public async changeCompletedUser(
      userID: number,
      checklistID: number,
   ): Promise<AxiosResponse> {
      const post = await this.taskPhpApiService.changeCompletedUser({
         checklistID: checklistID,
         userID: userID,
      });
      const task = this.getTaskLocalLookup(checklistID);

      if (post.data.success !== true || task === undefined) {
         return post;
      }

      if (task) {
         task.checklistUserCompleted = userID;
         task.checklistUserWage = post.data.userWage;
      }

      if (post.data.note) {
         this.addNoteToLocalData(post.data.note, checklistID);
      }

      this.incCompletedTasksWatchVar();

      return post;
   }

   public async changeOwnerCompletedTask(
      userID: number,
      profileID: number,
      multiUsers,
      checklistID: number,
      manageUser: ManageUser,
      manageProfile: ManageProfile,
   ): Promise<AxiosResponse> {
      const multiUsersStringified = JSON.stringify(multiUsers);

      const post = await this.taskPhpApiService.changeOwnerCompletedTask({
         checklistID: checklistID,
         userID: userID,
         multiUsers: multiUsersStringified,
         profileID: profileID,
      });

      const task = this.getTaskLocalLookup(checklistID);
      if (post.data.success !== true || task === undefined) {
         return post;
      }
      if (post.data.profileID > 0) {
         //since we are assigning hidden profiles potentially we need to add them to the right flat data sets if needed... yes I know wtf do we have multiple data sets :(
         this.manageFilters.updateHiddenProfiles(
            {
               profileID: post.data.profileID,
               name: post.data.profileDescription,
               locationID: task.locationID,
               multiUsers,
            },
            this,
            manageUser,
            manageProfile,
         );
      }

      task.profileID = post.data.profileID;
      task.userID = post.data.userID;

      return post;
   }

   /****************************************
    *@function notify
    *@purpose
    *@name notify
    *@param
    *@return
    ****************************************/
   public async notify(to, subject, message, users, checklistID) {
      const usersStringified = JSON.stringify(users);

      const post = this.taskPhpApiService.notify({
         to: to,
         subject: subject,
         message: message,
         users: usersStringified,
         checklistID: checklistID,
      });

      return post;
   }

   /****************************************
    *@function assignTask
    *@purpose This function is used to start a PM from within a Task
    *@name assignTask
    *@param
    *@return
    ****************************************/
   public async assignTask(
      item,
      data,
      assetID: number,
      manageUser: ManageUser,
      manageProfile: ManageProfile,
   ): Promise<AxiosResponse> {
      const multiUsers = JSON.stringify(data.multiUsers);

      const task = this.getTaskLocalLookup(item.checklistID);

      const post = this.taskPhpApiService.spawnChecklist({
         checklistID: item.checklistToSpawn,
         locationID: task?.locationID ?? null,
         userID: data.userID,
         profileID: data.profileID,
         multiUsers: multiUsers,
         itemID: item.itemID,
         date: data.timestamp,
         timeOfDay: data.timeOfDay,
         assetID: assetID,
      });

      post.then((answer) => {
         if (task === undefined) {
            console.error("Unable to access task in assignTask");
            return;
         }
         const profileID = answer.data.checklist.profileID;
         const profileDescription = answer.data.profileDescription;

         if (profileID > 0) {
            //since we are assigning hidden profiles potentially we need to add them to the right flat data sets if needed... yes I know wtf do we have multiple data sets :(
            this.manageFilters.updateHiddenProfiles(
               {
                  profileID: profileID,
                  name: profileDescription,
                  locationID: task.locationID,
                  multiUsers: data.multiUsers,
               },
               this,
               manageUser,
               manageProfile,
            );
         }

         this.addTaskToLookup(answer.data.checklist);

         this.taskRelations[answer.data.checklist.checklistID] = {
            itemID: item.itemID,
            originalChecklistID: item.checklistID,
            assignedChecklistID: answer.data.checklist.checklistID,
            checklistName: answer.data.checklist.checklistName,
         };

         //increment watch variable so watches will fire.  Workaround to deep watchs or watch collections.
         //call this carefully or you will cause digest loops.  It should only be called when key things are added or removed to the task.arr array, such as new tasks, deleted tasks, flagging tasks as deleted etc.
         this.incTasksWatchVar();
      });

      return post;
   }

   public async resetTask(
      checklistID: number,
      lastEdited?: number,
   ): Promise<AxiosResponse> {
      const post = await this.taskPhpApiService.resetTask({
         checklistID: checklistID,
         lastEdited: lastEdited,
      });

      //resets the tasks created by assign tasks

      //filter items to only items for this checklist AND only for start WO or assign PM types AND have been started
      const checklistIDsForDeletion: Array<string> = this.manageTaskItem
         .getLocalTaskItems()
         .filter((item) => {
            return (
               item.checklistID == checklistID &&
               (item.itemTypeID == TaskInstructionTypeID.AssignPM ||
                  item.itemTypeID == TaskInstructionTypeID.StartWO) &&
               item.itemResponse > 0
            );
         })
         .map((item) => item.itemResponse);

      for (const checklistIDToDelete of checklistIDsForDeletion) {
         const numericalChecklistID = Number(checklistIDToDelete);
         this.tasks.delete(numericalChecklistID);
      }

      //now go through those items and remove the started tasks from memory
      return post;
   }

   public async logTimeOnTask(
      checklistID: number,
      totalSeconds: number,
      userID: number,
      loggedAt,
      extraTimeNotes,
      categoryID: number,
      shouldWeAddNote: boolean,
      noteHidden,
   ): Promise<AxiosResponse> {
      const post = await this.taskPhpApiService.logTimeOnTask({
         checklistID: checklistID,
         totalSeconds: totalSeconds,
         userID: userID,
         loggedAt: loggedAt,
         extraTimeNotes: extraTimeNotes,
         categoryID: categoryID,
         addNote: shouldWeAddNote,
         noteHidden: noteHidden,
      });

      const task = this.getTaskLocalLookup(checklistID);
      if (post.data.success !== true || task === undefined) {
         return post;
      }

      const extraTime = post.data.extraTime;
      this.extraTime.set(extraTime.extraTimeID, extraTime);

      this.addExtraTimeToExtraTimeByChecklistID(extraTime);

      task.extraTimeIDs.push(extraTime.extraTimeID);

      if (post.data.note) {
         this.comments.set(post.data.note.noteID, post.data.note);
         task.noteIDs.push(post.data.note.noteID);
      }

      const extraTimeID = Number(extraTime.extraTimeID);
      this.taskExtraTimeStorageSyncService.syncExtraTimeByID(extraTimeID);

      return post;
   }

   public async finishChecklist(
      checklistID: number,
      promptTime: number,
      downtime: number,
      parts: Array<[number, number | null, number | null, number]>,
      notesToTheRequestor,
      multiWork,
      categoryID: number,
      locationID?: number,
   ): Promise<AxiosResponse | undefined> {
      const post = this.taskPhpApiService.finishChecklist({
         checklistID: checklistID,
         promptTime: promptTime,
         downtime: downtime,
         parts: JSON.stringify(parts),
         notesToTheRequestor: notesToTheRequestor,
         multiWork: multiWork,
         categoryID: categoryID,
         locationID: locationID ?? this.tasks.get(checklistID)?.locationID,
      });

      post.then((answer) => {
         if (answer.data.success == true) {
            const returnedTask = answer.data.checklist;
            const task = this.getTaskLocalLookup(checklistID);
            if (task === undefined) {
               console.error("local Task undefined in finishChecklist");
               return;
            }

            task.checklistCompletedDate = returnedTask.checklistCompletedDate;
            task.checklistStatusID = returnedTask.checklistStatusID;
            task.checklistUserCompleted = returnedTask.checklistUserCompleted;
            task.checklistPromptTime = promptTime;
            task.checklistDowntime = downtime;
            task.checklistInstructions = returnedTask.checklistInstructions;
            task.finalColorStatus = returnedTask.finalColorStatus;
            task.statusID = 2;

            task.billableTime = returnedTask.billableTime;
            task.billableRate = returnedTask.billableRate;
            task.categoryID = returnedTask.categoryID;

            //This is set if the user completed a task and went the multiple time route.  What we do is take the first occurance in the multiple time and then make that the primary logged time (that is stored in chk table).  Because of that we need to remove an extra time because it was moved...
            const extraTime = this.getExtraTime(answer.data.extraTimeIDToRemove);
            if (extraTime?.userID && answer.data.extraTimeIDToRemove != false) {
               //first update the task's primary time attribute
               task.checklistPromptTime = extraTime.promptTime;
               task.checklistUserCompleted = extraTime.userID;

               if (extraTime.notes) {
                  task.extraTimeNotes = extraTime.notes;
               }

               //next clean up the extraTime
               this.extraTime.delete(extraTime.extraTimeID);

               // Remove the extraTimeID from the task's extraTimeID's array.
               // I added this so we don't get an error if we reopen the task, close it,
               // and open it again.
               // This will be irrelevant after plaid JIT.
               if (task) {
                  const index = task.extraTimeIDs.indexOf(extraTime.extraTimeID);
                  if (index > -1) {
                     task.extraTimeIDs.splice(index, 1);
                  }
               }

               const entryFromIndexByChecklistID =
                  this.extraTimeByChecklistID.get(checklistID);
               if (entryFromIndexByChecklistID) {
                  entryFromIndexByChecklistID.delete(extraTime.extraTimeID);
               }
            }

            //we may have updated invoices or part relations so let's set those correct
            for (const invoice of returnedTask.newInvoices) {
               const indexedInvoices = this.manageInvoice.getInvoicesIndex();
               if (indexedInvoices[invoice.invoiceID]) {
                  continue;
               }
               this.manageInvoice.addInvoiceToLocalData(invoice);
               if (!task.invoiceIDs.includes(invoice.invoiceID)) {
                  task.invoiceIDs.push(invoice.invoiceID);
               }
            }

            for (const partRelation of returnedTask.newPartRelations) {
               this.partRelations.set(partRelation.relationID, partRelation);
               task.partRelationIDs.push(partRelation.relationID);
            }

            //ok now that task is updated with returnTask's values we can add it to the this.completedTasks
            this.completedTasks.set(checklistID, task);

            //if a task needs to be created because part qty threshold or any other reason this code such as
            //recurrance types 7 and 8.
            if (answer.data.newTasks && answer.data.newTasks !== "skip") {
               for (const newTask of answer.data.newTasks) {
                  this.addTaskToLookup(newTask);
               }
               //filter the tasks to only include the correct ones.  This step is just a precaution
               this.tasks = this.manageFilters.filterTasksByCredAtLocation(
                  this.tasks,
                  this.manageUser.getCurrentUser(),
               );
            }

            //we also need to go through all of the vendorTask shares and mark that share as vendorCompleted it because the task is now fully completed.
            for (const vendorTask of this.vendorTasks) {
               if (vendorTask.checklistID === checklistID) {
                  if (vendorTask.vendorID && vendorTask.shareDisabled == 0) {
                     vendorTask.vendorCompleted = 1;
                  }
               }
            }

            //next remove the task from the open task's memory object
            this.tasks.delete(checklistID);

            //remove the task from Lite local db
            this.taskStorageSyncService.syncTaskDeletion(checklistID);

            //update the asset information if there is asset information to update
            //loop through all the items that should be processed

            for (const item of answer.data.itemsToProcess) {
               if (item.fieldIDForLink > 0) {
                  if (Number(item.assetIDForLink) === 0) {
                     item.assetIDForLink = task.assetID;
                  }

                  const value = this.manageAsset.getFieldValueFromFieldIDAndAssetID(
                     item.fieldIDForLink,
                     item.assetIDForLink,
                  );

                  if (value !== undefined) {
                     value.valueContent = item.itemResponse;

                     this.assetFieldValueStorageSyncService.syncAssetFieldValue(
                        value.valueID,
                     );
                  }
               }
            }

            this.incTasksWatchVar();
            this.incCompletedTasksWatchVar();
         }
      });
      return post;
   }

   public async deleteChecklistInstance(checklistID: number): Promise<AxiosResponse> {
      const post = await this.taskPhpApiService.deleteChecklistInstance({
         checklistID: checklistID,
      });
      if (post.data.success !== true) {
         return post;
      }

      this.tasks.delete(checklistID);
      this.taskStorageSyncService.syncTaskDeletion(checklistID);

      for (const extraTime of this.extraTime) {
         if (extraTime.checklistID === checklistID) {
            this.extraTime.deleteValue(extraTime);
         }
      }

      for (const vendorTask of this.vendorTasks) {
         if (vendorTask.checklistID == checklistID) {
            this.vendorTasks.deleteValue(vendorTask);
         }
      }

      this.incTasksWatchVar();
      this.incCompletedTasksWatchVar();
      return post;
   }
   //PT - Good to go
   /****************************************
    *@function deleteOpenTasksInBulk
    *@purpose This function is used to delete a bunch of open tasks at once
    *@name deleteOpenTasksInBulk
    *@param
    *@return
    ****************************************/
   deleteOpenTasksInBulk = async (
      tasksToDelete: Array<number>,
   ): Promise<AxiosResponse> => {
      const tasksToDeleteStringified = JSON.stringify(tasksToDelete);

      const post = this.taskPhpApiService.deleteOpenTasksInBulk({
         tasks: tasksToDeleteStringified,
      });

      return post;
   };

   /****************************************
    *@function deletePMTemplatesInBulk
    *@purpose This function is used to delete a bunch of PM Templates
    *@name deletePMTemplatesInBulk
    *@param
    *@return
    ****************************************/
   deletePMTemplatesInBulk = async (tasksToDelete: Array<number>, locationID: number) => {
      const tasksToDeleteStringified = JSON.stringify(tasksToDelete);

      const post = this.taskPhpApiService.deletePMTemplatesInBulk({
         tasks: tasksToDeleteStringified,
         locationID: locationID,
      });

      return post;
   };

   /****************************************
    *@function reassignOpenTasksInBulk
    *@purpose This function is used to reassign open Tasks in bulk
    *@name reassignOpenTasksInBulk
    *@param
    *@return
    ****************************************/
   reassignOpenTasksInBulk = async (
      tasksToReassign: Array<number>,
      userID: number,
      profileID: number,
      multiUsers: Array<number>,
      locationID: number,
   ): Promise<AxiosResponse> => {
      const tasksToReassignStringifed = JSON.stringify(tasksToReassign);
      const multiUsersStringified = JSON.stringify(multiUsers);

      const post = this.taskPhpApiService.reassignOpenTasksInBulk({
         tasks: tasksToReassignStringifed,
         userID: userID,
         profileID: profileID,
         multiUsers: multiUsersStringified,
         locationID: locationID,
      });

      return post;
   };
   //PT - Good to go
   /****************************************
    *@function bulkCompleteOpenTasks
    *@purpose This function is used to complete open tasks in bulk
    *@name bulkCompleteOpenTasks
    *@param
    *@return
    ****************************************/
   bulkCompleteOpenTasks = async (
      tasksToComplete: Array<number>,
      totalSeconds,
      userID: number,
      loggedAt,
      categoryID: number,
      locationID: number,
      notes,
   ) => {
      const tasksToCompleteStringified = JSON.stringify(tasksToComplete);

      const post = this.taskPhpApiService.bulkCompleteOpenTasks({
         tasks: tasksToCompleteStringified,
         totalSeconds: totalSeconds,
         userID: userID,
         loggedAt: loggedAt,
         categoryID: categoryID,
         locationID: locationID,
         notes: notes,
      });

      return post;
   };
   //PT - Good to go
   /****************************************
    *@function bulkChangeDueDateOpenTasks
    *@purpose This function is used to change open tasks's due date in bulk
    *@name bulkChangeDueDateOpenTasks
    *@param
    *@return
    ****************************************/
   bulkChangeDueDateOpenTasks = async (
      tasksToChange: Array<number>,
      newTime,
      timeOfDay,
      newStartDate,
      startDateSetting,
   ): Promise<AxiosResponse> => {
      const timestamp = Math.floor(Date.now() / 1000);
      const tasksToChangeStringified = JSON.stringify(tasksToChange);

      const post = this.taskPhpApiService.bulkChangeDueDateOpenTasks({
         tasks: tasksToChangeStringified,
         newTime: newTime,
         timeOfDay: timeOfDay,
         timestamp: timestamp,
         newStartDate: newStartDate,
         startDateSetting: startDateSetting,
      });

      return post;
   };
   //PT - Good to go
   bulkChangePriorityOpenTasks = async (tasksToChange, priority) => {
      const post = this.taskPhpApiService.bulkChangePriorityOpenTasks({
         tasks: tasksToChange,
         priorityID: priority.priorityID,
      });

      return post;
   };
   //PT - Good to go
   /****************************************
    *@function bulkDeferTasks
    *@purpose This function is used to defer in bulk tasks
    *@name bulkDeferTasks
    *@param
    *@return
    ****************************************/
   bulkDeferTasks = async (tasksToDefer: Array<number>, reason: string, pickedDate) => {
      const tasksToDeferStringified = JSON.stringify(tasksToDefer);

      const post = this.taskPhpApiService.bulkDeferTasks({
         tasks: tasksToDeferStringified,
         reason: reason,
         pickedDate: pickedDate,
      });

      return post;
   };
   //PT - Good to go
   /****************************************
    *@function reassignPMTemplatesInBulk
    *@purpose This function is used to reassign PM templates in bulk
    *@name reassignPMTemplatesInBulk
    *@param
    *@return
    ****************************************/
   reassignPMTemplatesInBulk = async (
      tasksToReassign: Array<number>,
      userID,
      profileID,
      multiUsers,
      locationID,
      dynamicAssignment: boolean,
   ) => {
      const tasksToReassignStringifed = JSON.stringify(tasksToReassign);
      const multiUsersStringified = JSON.stringify(multiUsers);

      const post = this.taskPhpApiService.reassignPMTemplatesInBulk({
         tasks: tasksToReassignStringifed,
         userID: userID,
         profileID: profileID,
         multiUsers: multiUsersStringified,
         locationID: locationID,
         dynamicAssignment: dynamicAssignment,
      });
      return post;
   };
   // PT - Good to go
   /****************************************
    *@function changePMTemplatesAssetAssignmentInBulk
    *@purpose This function is used to change PM template Asset assignments in bulk
    *@name changePMTemplatesAssetAssignmentInBulk
    *@param
    *@return
    ****************************************/
   changePMTemplatesAssetAssignmentInBulk = async (
      tasksToChange: Array<number>,
      assetID: number,
   ) => {
      const tasksToChangeStringified = JSON.stringify(tasksToChange);

      const post = this.taskPhpApiService.changePMTemplatesAssetAssignmentInBulk({
         tasks: tasksToChangeStringified,
         assetID: assetID,
      });

      return post;
   };

   /****************************************
    *@function changePMTemplateRelationsInBulk
    *@purpose This function is used to change PM template relations in bulk.  This is for updating related PMs
    *@name changePMTemplateRelationsInBulk
    *@param
    *@return
    ****************************************/
   changePMTemplateRelationsInBulk = async (
      tasksToChange: Array<number>,
      checklistBatchID,
      checklistID,
   ) => {
      const tasksToChangeStringified = JSON.stringify(tasksToChange);

      const post = this.taskPhpApiService.changePMTemplateRelationsInBulk({
         tasks: tasksToChangeStringified,
         checklistBatchID: checklistBatchID,
         checklistID: checklistID,
      });

      return post;
   };

   /****************************************
    *@function breakRelationPMTemplate
    *@purpose This function is used to break a relationship between PMs.  This type of relationship governs which PM groups will update each other if they are updated in bulk.
    *@name breakRelationPMTemplate
    *@param
    *@return
    ****************************************/
   breakRelationPMTemplate = async (checklistID) => {
      const post = this.taskPhpApiService.breakRelationPMTemplate({
         checklistID: checklistID,
      });

      return post;
   };

   /****************************************
    *@function addUserToChecklistNotifications
    *@purpose This function is used to add a User to a Task's notification chain
    *@name addUserToChecklistNotifications
    *@param
    *@return
    ****************************************/
   public async addUserToChecklistNotifications(
      userID: number,
      checklistID: number,
      reason = "manually added",
   ): Promise<AxiosResponse> {
      const post = this.taskPhpApiService.addUserToChecklistNotifications({
         checklistID: checklistID,
         userID: userID,
         reason: reason,
      });

      return post;
   }

   /****************************************
    *@function addEmailStrToChecklistNotifications
    *@purpose This function is used to add a an email string to a Task's notification chain
    *@name addEmailStrToChecklistNotifications
    *@param
    *@return
    ****************************************/
   public async addEmailStrToChecklistNotifications(
      emailStr: string,
      checklistID: number,
   ): Promise<AxiosResponse> {
      const post = this.taskPhpApiService.addEmailStrToChecklistNotifications({
         checklistID: checklistID,
         emailStr: emailStr,
      });

      return post;
   }

   /****************************************
    *@function removeUserFromChecklistNotifications
    *@purpose This function is used to remove a user from a Task's notification chain
    *@name removeUserFromChecklistNotifications
    *@param
    *@return
    ****************************************/
   public async removeUserFromChecklistNotifications(
      userID: number,
      checklistID: number,
   ): Promise<AxiosResponse> {
      const post = this.taskPhpApiService.removeUserFromChecklistNotifications({
         checklistID: checklistID,
         userID: userID,
      });

      return post;
   }

   /****************************************
    *@function removeManuallyAddedCommentNotificationEmailStr
    *@purpose This function is used to remove an email string from a Task's notification chain
    *@name removeManuallyAddedCommentNotificationEmailStr
    *@param
    *@return
    ****************************************/
   public async removeManuallyAddedCommentNotificationEmailStr(
      emailStr: string,
      checklistID: number,
   ): Promise<AxiosResponse> {
      const post = this.taskPhpApiService.removeManuallyAddedCommentNotificationEmailStr({
         emailStr: emailStr,
         checklistID: checklistID,
      });

      return post;
   }

   /****************************************
    *@function insItem
    *@purpose This function is used to remove an email string from a Task's notification chain
    *@name insItem
    *@param
    *@return
    ****************************************/
   insItem = async (chk, itemTypeID, itemText) => {
      const itemTextCleaned = cleanWordPaste(itemText);

      const post = await this.taskPhpApiService.insItem({
         chk: chk.checklistID,
         itemTypeID: itemTypeID,
         itemText: itemTextCleaned,
      });

      if (post.data.success) {
         const instructionID = Number(post.data.myItem.itemID);
         this.instructionStorageSyncService.syncInstruction(instructionID);
      }

      return post;
   };

   public async updateTaskDescription(
      checklistID: number,
      checklistInstructions: string,
   ): Promise<AxiosResponse> {
      const newChecklistInstructions = cleanWordPaste(checklistInstructions);

      const post = await this.taskPhpApiService.updateTaskDescription({
         checklistID: checklistID,
         checklistInstructions: newChecklistInstructions,
      });

      if (post.data.success) {
         this.taskStorageSyncService.syncTaskByID(checklistID);
      }

      return post;
   }

   public async addOrRemoveCustomTag(
      checklistID: number,
      checklistInstructions: string,
      state: string,
      customTag: string,
   ): Promise<AxiosResponse> {
      const post = this.taskPhpApiService.addOrRemoveCustomTag({
         checklistID: checklistID,
         checklistInstructions: checklistInstructions,
         state: state,
         customTag: customTag,
      });

      post.then((answer) => {
         if (answer.data.success == true) {
            const task = this.getTaskLocalLookup(checklistID);
            if (task) {
               task.checklistPriority = answer.data.task.checklistPriority;
               task.checklistInstructions = this.manageUtil.removeDuplicateSpaces(
                  answer.data.task.checklistInstructions,
               );

               //increment watch variable so watches will fire.  Workaround to deep watchs or watch collections.
               //call this carefully or you will cause digest loops.  It should only be called when key things are added or removed to the task.arr array, such as new tasks, deleted tasks, flagging tasks as deleted etc.
               this.incTasksWatchVar();
               this.incCompletedTasksWatchVar();
               this.taskStorageSyncService.syncTaskByID(checklistID);
            }
         }
      });

      return post;
   }

   public async updateTaskName(
      checklistID: number,
      checklistName: string,
   ): Promise<AxiosResponse> {
      //According to previous comments, instances of quotation marks and semicolons
      //'cause problems' and must be removed before putting this data into the database
      const regex1 = new RegExp('"', "g");
      const regex2 = new RegExp(";", "g");
      const cleanChecklistName = checklistName.replace(regex1, "").replace(regex2, "");

      const post = await this.taskPhpApiService.updateTaskName({
         checklistID: checklistID,
         checklistName: cleanChecklistName,
      });
      const task = await this.refreshLocalTask(checklistID);
      if (post.data.success && task) {
         this.taskStorageSyncService.syncTaskByID(checklistID);
      }
      return post;
   }

   /****************************************
    *@function createWorkOrder
    *@purpose This function is used to update a Task's name
    *@name createWorkOrder
    *@param
    *@return post
    ****************************************/
   createWorkOrder = async (assetID, locationID, templateID, sourceItemID) => {
      const post = await this.taskPhpApiService.createWorkOrder({
         assetID: assetID,
         locationID: locationID,
         templateID: templateID,
         sourceItemID: sourceItemID,
      });

      this.addTaskToLookup(post.data.task);

      if (post.data.success) {
         this.taskStorageSyncService.syncTaskByID(post.data.task.checklistID);
      }

      return post;
   };

   public async goLiveWorkOrder(
      WOdata,
      profileID: number,
      userID: number,
      multiUsers: Array<number>,
      dueDate,
      itemID,
      timeOfDay,
      startDate,
      startDateTimeOfDay,
      manageUser: ManageUser,
      manageProfile: ManageProfile,
   ): Promise<AxiosResponse<any, any>> {
      const multiUsersStringifed = JSON.stringify(multiUsers);

      let post;
      if (WOdata.state === "simple") {
         WOdata.name = cleanWordPaste(WOdata.name);
         WOdata.description = cleanWordPaste(WOdata.description);
         post = await this.taskPhpApiService.goLiveWorkOrderSimple({
            checklistID: WOdata.task.checklistID,
            name: WOdata.name,
            description: WOdata.description,
            profileID: profileID,
            userID: userID,
            multiUsers: multiUsersStringifed,
            options: WOdata.options,
            dueDate: dueDate,
            type: WOdata.WOtype,
            itemID: itemID,
            timeOfDay: timeOfDay,
            startDate: startDate,
            startDateTimeOfDay: startDateTimeOfDay,
         });
         await this.updateTaskDescription(
            WOdata.task.checklistID,
            WOdata.task.checklistInstructions,
         );
      } else {
         post = await this.taskPhpApiService.goLiveWorkOrderAdvanced({
            checklistID: WOdata.task.checklistID,
            profileID: profileID,
            userID: userID,
            multiUsers: multiUsersStringifed,
            dueDate: dueDate,
            type: WOdata.WOtype,
            itemID: itemID,
            timeOfDay: timeOfDay,
            startDate: startDate,
            startDateTimeOfDay: startDateTimeOfDay,
         });
      }

      if (!post.data.success) return post;

      if (post.data.task.profileID > 0) {
         this.manageFilters.updateHiddenProfiles(
            {
               profileID: post.data.task.profileID,
               name: post.data.profileDescription,
               locationID: post.data.task.locationID,
               multiUsers,
            },
            this,
            manageUser,
            manageProfile,
         );
      }

      // before this point, the task had a checklistTemplate value of 2 or 4 and could not be fetched with the `getTask`
      // function that added it to the initial load data.  Now that the task is live, its checklistTemplate value is 0
      // and it can be fetched and added to the local lookup data using the pre-JIT method
      await this.getTask(WOdata.task.checklistID);

      for (const task of this.tasks) {
         if (task.checklistID == WOdata.task.checklistID) {
            //it is IMPORTANT to keep this for loop.  If we did task = post.data.task then it will break the link lookup.  It is better to simply loop through the task returned and update that way we arent' replacing the full task obj and only actually updating it.
            for (const key2 in post.data.task) {
               task[key2] = post.data.task[key2];
            }

            task.checklistDueDateCalendarOrder =
               post.data.task.checklistDueDateCalendarOrder;
         }
      }
      this.incTasksWatchVar();

      const taskID = Number(post.data.task.checklistID);
      this.taskStorageSyncService.syncTaskByID(taskID);

      //add instructions for new task
      await this.instructionStorageSyncService.syncInstructionsByTask(taskID);

      if (itemID) {
         //update source instruction
         await this.instructionStorageSyncService.syncInstruction(itemID);
      }

      return post;
   }

   public async addPartToTask(
      checklistID: number,
      partID: number,
      overrideCompleted: boolean,
   ): Promise<AxiosResponse> {
      const post = this.taskPhpApiService.addPartToTask({
         checklistID: checklistID,
         partID: partID,
         overrideCompleted: overrideCompleted,
      });

      post.then((answer) => {
         if (answer.data.success == true) {
            const part = answer.data.part;

            //the PHP returns a part in a shape we don't expect, so here we must construct a
            //TaskPart in the shape we expect
            this.partRelations.set(answer.data.part.relationID, {
               relationID: part.relationID,
               checklistID,
               partID,
               suggestedNumber: part.suggestedNumber,
               usedNumber: part.usedNumber,
               usedPrice: part.usedPrice,
               checklistPartLastEdited: part.checklistPartLastEdited,
               poItemID: part.poItemID,
            });

            const task = this.getTaskLocalLookup(checklistID);
            if (task) {
               task.partRelationIDs.push(part.relationID);
            }

            const partRelation = this.getPartRelation(part.relationID);
            if (partRelation !== undefined) {
               this.addPartRelationByChecklistIDToLocalData(partRelation);
            }

            this.incTasksWatchVar();
         }
      });

      return post;
   }

   public async removePartFromTask(
      relationID: number,
      checklistID: number,
      manageParts: ManageParts,
   ): Promise<AxiosResponse> {
      const post = this.taskPhpApiService.removePartFromTask({
         checklistID: checklistID,
         relationID: relationID,
      });

      post.then((answer) => {
         if (answer.data.success == true) {
            const partRelation = this.getPartRelation(answer.data.relationID);
            if (partRelation !== undefined) {
               this.removePartRelationByChecklistIDToLocalData(partRelation);

               const part = manageParts.getPart(partRelation.partID);
               if (part) manageParts.calculatePartData(part); //this resets the calculatedPartInfo
            }

            this.partRelations.delete(answer.data.relationID);
            const task = this.getTaskLocalLookup(checklistID);
            const relationIDToDeleteIndex = task?.partRelationIDs.indexOf(relationID);
            if (relationIDToDeleteIndex !== undefined && relationIDToDeleteIndex > -1) {
               task?.partRelationIDs.splice(relationIDToDeleteIndex, 1);
            }
         }
         this.incTasksWatchVar();
      });

      return post;
   }

   public async updatePartSuggestedNumber(part): Promise<AxiosResponse> {
      const post = this.taskPhpApiService.updatePartSuggestedNumber({
         relationID: part.relationID,
         suggestedNumber: part.suggestedNumber,
      });

      post.then((answer) => {
         part.checklistPartLastEdited = answer.data.checklistPartLastEdited;
         const partRelation = this.getPartRelation(part.relationID);
         if (partRelation) {
            partRelation.checklistPartLastEdited = answer.data.checklistPartLastEdited;
         }
         this.incTasksWatchVar();
      });

      return post;
   }

   public async updatePartUsedNumber(part): Promise<AxiosResponse> {
      const post = await this.taskPhpApiService.updatePartUsedNumber({
         relationID: part.relationID,
         usedNumber: part.usedNumber,
      });

      const partRelation = this.getPartRelation(part.relationID);

      if (!post.data.success || partRelation === undefined) {
         return post;
      }

      partRelation.usedNumber = part.usedNumber;

      return post;
   }

   /****************************************
    *@function getWhoWillReceiveTaskNotifications
    *@purpose
    *@name getWhoWillReceiveTaskNotifications
    *@param
    *@return post
    ****************************************/
   getWhoWillReceiveTaskNotifications = async (checklistID) => {
      const post = this.taskPhpApiService.getWhoWillReceiveTaskNotifications({
         checklistID: checklistID,
      });
      return post;
   };

   public async updateChecklistEstTime(
      checklistID: number,
      estimatedTimeInMinutes: number,
   ): Promise<AxiosResponse> {
      const post = await this.taskPhpApiService.updateChecklistEstTime({
         checklistID: checklistID,
         estimatedTimeInMinutes: estimatedTimeInMinutes,
      });

      const task = this.getTaskLocalLookup(checklistID);
      if (post.data.success !== true || task === undefined) {
         return post;
      }
      //convert time back to seconds
      task.checklistEstTime = estimatedTimeInMinutes * 60;
      this.incTasksWatchVar();

      return post;
   }

   public async updateTaskCompletionNotes(
      checklistID: number,
      checklistComments: string,
   ): Promise<AxiosResponse> {
      const newChecklistComments = cleanWordPaste(checklistComments);

      const post = await this.taskPhpApiService.updateTaskCompletionNotes({
         checklistID: checklistID,
         comments: newChecklistComments,
      });

      if (post.data.success) {
         this.taskStorageSyncService.syncTaskByID(checklistID);
      }

      return post;
   }

   /****************************************
    *@function deferTask
    *@purpose
    *@name deferTask
    *@param
    *@return post
    ****************************************/
   deferTask = async (task, reason, pickedDate) => {
      const post = this.taskPhpApiService.deferTask({
         checklistID: task.checklistID,
         reason: reason,
         pickedDate: pickedDate,
      });

      post.then((answer) => {
         if (answer.data.success == true) {
            task.checklistColor = answer.data.task.checklistColor;
            task.checklistDueDate = answer.data.task.checklistDueDate;
            task.checklistStartDate = answer.data.task.checklistStartDate;
            task.numOfDueDateChange = Number(task.numOfDueDateChange) + 1;

            if (!task.deferments) {
               task.deferments = [];
            }
            task.deferments.push(answer.data.deferment);
         }
      });

      return post;
   };

   public async newTaskTemplateFromCopy(
      taskToCopyID: number,
      assetIDTarget: number,
      locationIDTarget: number,
      newName: string,
      isDuplicating = false,
   ) {
      const post = this.taskPhpApiService.newTaskTemplateFromCopy({
         taskToCopyID: taskToCopyID,
         assetIDTarget: assetIDTarget,
         locationIDTarget: locationIDTarget,
         newName: newName,
         isDuplicating: isDuplicating,
      });

      post.then((answer) => {
         if (answer.data.success == true) {
            const fields = this.manageAsset.getFields();

            for (const field of answer.data.fieldsToProcess) {
               //we couldn't find this field so that means it was created in PHP and we need to add it
               if (!fields.has(field.fieldID)) {
                  fields.set(field.fieldID, field);
               }
            }

            const values = this.manageAsset.getFieldValues();

            for (const value of answer.data.valuesToProcess) {
               //we couldn't find this value so that means it was create in PHP and we need to add it
               if (!values.has(value.valueID)) {
                  values.set(value.valueID, value);
               }
            }

            for (const task of answer.data.tasks) {
               this.addTaskToLookup(task);
            }

            //next we need to add the returned recurrances and sort the data properly
            //now that the partRelations are built we need to setup recurrances
            for (const recurrence of answer.data.recurrances) {
               //this logic here shows if a recurrance is active or not

               if (recurrence.checklistID !== null) {
                  const task = this.tasks.get(recurrence.checklistID);
                  if (task && typeof task.reoccurIDs === "undefined") {
                     task.reoccurIDs = [];
                  }

                  //add the recurrances to the right task dataset
                  if (task) {
                     task.reoccurIDs?.push(recurrence.reoccurID);
                  }
               }

               this.recurrences.set(recurrence.reoccurID, recurrence);
            }

            this.incTasksWatchVar();
         }
      });

      return post;
   }

   public async newTemplate(
      assetID: number,
      newName: string,
      locationID: number,
      type: string,
   ): Promise<AxiosResponse> {
      const post = this.taskPhpApiService.newTemplate({
         assetID: assetID,
         newName: newName,
         locationID: locationID,
         type: type,
      });

      post.then((answer) => {
         if (answer.data.success == true) {
            this.addTaskToLookup(answer.data.task);
            this.incTasksWatchVar();
         }
      });

      return post;
   }

   private sliceIntoChunks<T>(arr: Array<T>, chunkSize: number): Array<Array<T>> {
      const res: Array<Array<T>> = [];
      for (let chunkIndex = 0; chunkIndex < arr.length; chunkIndex += chunkSize) {
         const chunk = arr.slice(chunkIndex, chunkIndex + chunkSize);
         res.push(chunk);
      }
      return res;
   }

   public async downloadExcel(
      tasksToDownload: TaskLookup | Array<Task> | Array<TaskEntity>,
   ): Promise<Array<any>> {
      const today = this.betterDate.createTodayTimestamp();
      const lang = this.lang();

      const fileName = `${lang.Tasks}-${today}.xlsx`;

      const tempArr: any = [];
      const completedDaysPastDueDateMap = new Map<string, number>();
      for (const task of tasksToDownload) {
         tempArr.push(task.checklistID);
         const completedDaysPastDueDate =
            this.getCompletedTaskCalculatedInfo(task)?.completedDaysPastDueDate ?? 0;
         completedDaysPastDueDateMap.set(
            `${task.checklistName} - #${task.checklistID}`,
            completedDaysPastDueDate,
         );
      }

      if (tempArr.length > 3000) {
         return this.batchDownloadExcel(tempArr, fileName);
      }
      const answer = await this.taskPhpApiService.downloadExcel({
         tasks: tempArr,
      });
      if (answer.data.success !== true) {
         this.alertService.addAlert(lang.ThereWasAnErrorDownloadingTasks, "danger", 6000);
         return [];
      }
      if (!answer.data.tasks) {
         this.alertService.addAlert(lang.ThereIsNothingToDownload, "danger", 6000);
         return [];
      }
      for (const task of answer.data.tasks) {
         task[lang.CompletedDaysPastDueDate] = completedDaysPastDueDateMap.get(
            task["Task Name"],
         );
      }
      this.manageUtil.objToExcel(answer.data.tasks, "Tasks", fileName);
      return answer.data.tasks;
   }

   private async batchDownloadExcel(
      tasksToDownload: Array<any>,
      fileName: string,
   ): Promise<Array<any>> {
      const chunkedArrays = this.sliceIntoChunks(tasksToDownload, 1000);

      const promises = chunkedArrays.map(async (chunkArr) => {
         return this.taskPhpApiService.downloadExcel({
            tasks: chunkArr,
         });
      });
      const totalTasks = await Promise.all(promises).then((answers) => {
         return answers.map((answer) => answer.data.tasks).flat();
      });

      const chunkedTotalTasks = this.sliceIntoChunks(totalTasks, 3000);
      this.manageUtil.objsToExcel(chunkedTotalTasks, "Tasks", fileName);

      return totalTasks;
   }

   /**
    * @deprecated
    * A temporary mapper function that should not be needed once the backend handles downloads.
    */
   public mapTaskScheduleCombinedEntityToExcelFormat(
      entity: TasksSchedulesCombinedEntity,
   ) {
      const obj: any = {};
      //task name, due date, assignedto, taskID, assetName, scheduleID

      // task name
      obj[this.i18n().t("TaskName")] =
         `${this.manageUtil.stripTags(entity.checklistName)} - #${entity.checklistID}`;

      // due date
      obj[this.i18n().t("DueDate")] = this.betterDate.formatBetterDate(
         (entity.checklistDueDate ?? 0) * 1000,
         "dateTimeWithSeconds",
      );

      // assignment
      const displayName = this.getTaskAssignmentInfo(entity).displayName;
      obj[this.i18n().t("AssignedTo")] = displayName;

      // taskID
      obj[this.i18n().t("TaskID")] = entity.checklistID;

      // asset name
      const assetName = this.manageAsset.getAssetNameIncludeParents(entity.assetID) ?? "";
      obj[this.i18n().t("AssetName")] = assetName;

      // scheduleID
      obj[this.i18n().t("ScheduleID")] = entity.scheduleID;

      // checklistEstTime
      obj[this.i18n().t("EstimatedTime")] = `${formatNumber(
         (entity.checklistEstTime ?? 0) / 60 / 60,
         this.manageLang.getLocaleID(),
         "1.2-2",
      )} ${this.i18n().t("hrs")}`;

      return obj;
   }

   public downloadTasksLimited(
      tasksToDownload:
         | Array<
              Task &
                 Partial<{
                    startTemplateURL: string;
                    flipScheduleURL: string;
                    extraTimeDownloadTemp: Array<any>;
                 }>
           >
         | Array<TaskEntity>,
      columns,
   ): boolean {
      const lang = this.lang();

      const tempTasks: any = [];

      for (const task of tasksToDownload) {
         const obj: any = {};

         //add the Task name
         if (task.checklistName) {
            obj[lang.TaskName] = `${this.manageUtil.stripTags(task.checklistName)} - #${
               task.checklistID
            }`;
         }
         if (task.assetID) {
            const assetName =
               this.manageAsset.getAssetNameIncludeParents(task.assetID) ?? "";
            //add Asset name
            obj[lang.AssetName] = assetName;
         }

         for (const key in columns) {
            //we are looping through an obj, we must keep this type of loop
            const column = columns[key];
            //loop through the columns selected and add that to the Obj.
            if (key === "locationName" && column == true) {
               if (this.manageLocation.getLocationsIndex()[task.locationID]) {
                  obj[lang.LocationName] =
                     this.manageLocation.getLocationsIndex()[
                        task.locationID
                     ].locationName;
               }
            } else if (key === "checklistTemplateOld" && column == true) {
               if (task.checklistTemplateOld == 1) {
                  obj[lang.Type] = lang.PM;
               } else if (
                  task.checklistTemplateOld == 2 &&
                  (task.checklistBatchID == 300112 || task.checklistBatchID == 10000)
               ) {
                  obj[lang.Type] = lang.WorkRequest;
               } else if (task.checklistTemplateOld == 2) {
                  obj[lang.Type] = lang.UnplannedWO;
               } else if (task.checklistTemplateOld == 4) {
                  obj[lang.Type] = lang.PlannedWO;
               } else if (task.checklistTemplateOld == 5) {
                  obj[lang.Type] = lang.CycleCount;
               }
            } else if (key === "checklistEstTime" && column == true) {
               obj[lang.EstimatedTime] = `${formatNumber(
                  (task.checklistEstTime ?? 0) / 60 / 60,
                  this.manageLang.getLocaleID(),
                  "1.2-2",
               )} ${lang.hrs}`;
            } else if (key === "checklistLastEdited" && column == true) {
               obj[lang.LastEdited] = this.betterDate.formatBetterDate(
                  (task.checklistLastEdited ?? 0) * 1000,
                  "dateTimeWithSeconds",
               );
            } else if (key === "checklistPriority" && column == true) {
               const priorityName = this.getPriorityInfo(task.priorityID).priorityName;
               obj[lang.PriorityLevelHeader] = priorityName;
            } else if (key === "assignedTo" && column == true) {
               const displayName = this.getTaskAssignmentInfo(task).displayName;
               obj[lang.AssignedTo] = displayName;
            } else if (key === "listOfComments" && column == true) {
               let str2 = "";
               let notes: Array<{
                  userID?: number | null;
                  noteMessage?: string | null;
                  noteTimestamp?: number | null;
               }>;
               if ("noteIDs" in task) {
                  notes = task.noteIDs
                     .map((noteID) => this.getComment(noteID))
                     .filter((note): note is Comment => note !== undefined);
               } else if ("comments" in task) {
                  notes = task.comments;
               } else {
                  notes = [];
               }
               for (const note of notes) {
                  if (!note.userID) {
                     continue;
                  }
                  const name = `${this.getTaskUser(note.userID)?.userFirstName} ${
                     this.getTaskUser(note.userID)?.userLastName
                  }`;
                  str2 += `${
                     note.noteMessage
                  } - ${name} - ${this.betterDate.formatBetterDate(
                     (note.noteTimestamp ?? 0) * 1000,
                     "dateTimeWithSeconds",
                  )} | `;
               }
               str2 = str2.slice(0, str2.length - 3);
               obj[lang.Comments] = str2;
            } else if (key === "checklistCompletedDate" && column == true) {
               if (task.checklistCompletedDate && task.checklistCompletedDate > 0) {
                  obj[lang.CompletedDate] = this.betterDate.formatBetterDate(
                     task.checklistCompletedDate * 1000,
                     "dateTimeWithSeconds",
                  );
               } else {
                  obj[lang.CompletedDate] = "";
               }
            } else if (key === "checklistCreatedDate" && column == true) {
               if (task.checklistCreatedDate && task.checklistCreatedDate > 0) {
                  obj[lang.CreatedDate] = this.betterDate.formatBetterDate(
                     task.checklistCreatedDate * 1000,
                     "dateTimeWithSeconds",
                  );
               } else {
                  obj[lang.CreatedDate] = "";
               }
            } else if (key === "checklistDowntime" && column == true) {
               obj[lang.Downtime] = `${formatNumber(
                  (task.checklistDowntime ?? 0) / 60 / 60,
                  this.manageLang.getLocaleID(),
                  "1.2-2",
               )} ${lang.hrs}`;
            } else if (key === "checklistPromptTime" && column == true) {
               const checklistPromptTimeTotal =
                  this.getCalculatedTaskInfo(task).checklistPromptTimeTotal;
               obj[lang.TimeSpent] = `${formatNumber(
                  checklistPromptTimeTotal / 60 / 60,
                  this.manageLang.getLocaleID(),
                  "1.2-2",
               )} ${lang.hrs}`;
               if (task.extraTimeNotes && task.extraTimeNotes.length > 2) {
                  obj[lang.TimeSpent] += ` - ${task.extraTimeNotes}`;
               }
            } else if (key === "checklistPromptTimeBreakdown" && column == true) {
               let str = `${formatNumber(
                  (task.checklistPromptTime ?? 0) / 60 / 60,
                  this.manageLang.getLocaleID(),
                  "1.2-2",
               )} ${lang.hrs}`;

               if (task.extraTimeNotes && task.extraTimeNotes.length > 2) {
                  str += ` - ${task.extraTimeNotes}`;
               }

               const completedFirstName =
                  this.getCompletedTaskCalculatedInfo(task)?.completedFirstName ?? "";
               const completedLastName =
                  this.getCompletedTaskCalculatedInfo(task)?.completedLastName ?? "";
               if (completedFirstName) {
                  str += ` - ${completedFirstName} ${completedLastName}`;
               }

               if (task.checklistCompletedDate && task.checklistCompletedDate > 0) {
                  str += ` - ${this.betterDate.formatBetterDate(
                     task.checklistCompletedDate * 1000,
                     "dateTimeWithSeconds",
                  )}`;
               }

               let extraTime:
                  | Array<{
                       promptTime: number | null;
                       notes: string | null;
                       loggedAt: number | null;
                       userFirstName: string;
                       userLastName: string;
                    }>
                  | Array<TaskExtraTime> = [];
               if ("extraTimeDownloadTemp" in task) {
                  extraTime = task.extraTimeDownloadTemp;
               } else if ("extraTime" in task) {
                  extraTime = task.extraTime;
               }
               if (extraTime) {
                  for (const extraTimeItem of extraTime) {
                     str += `, ${formatNumber(
                        (extraTimeItem.promptTime ?? 0) / 60 / 60,
                        this.manageLang.getLocaleID(),
                        "1.2-2",
                     )} ${lang.hrs}`;

                     if (extraTimeItem.notes && extraTimeItem.notes.length > 2) {
                        str += ` - ${extraTimeItem.notes}`;
                     }

                     if ("user" in extraTimeItem) {
                        if ("deleted" in extraTimeItem.user) {
                           str += ` - ${lang.DeletedUser}`;
                        } else {
                           str += ` - ${extraTimeItem.user.firstName ?? ""} ${
                              extraTimeItem.user.lastName ?? ""
                           }`;
                        }
                     } else {
                        str += ` - ${extraTimeItem.userFirstName} ${extraTimeItem.userLastName}`;
                     }

                     str += ` - ${this.betterDate.formatBetterDate(
                        (extraTimeItem.loggedAt ?? 0) * 1000,
                        "dateTimeWithSeconds",
                     )}`;
                  }
               }
               obj[lang.TimeSpent] = str;
            } else if (key === "checklistTotalInvoiceCost" && column == true) {
               const checklistTotalInvoiceCost =
                  this.getCalculatedTaskInfo(task).checklistTotalInvoiceCost;
               obj[lang.TotalInvoiceCost] = parseFloat(
                  checklistTotalInvoiceCost.toFixed(2),
               );
            } else if (key === "checklistTotalInvoiceCostBreakdown" && column == true) {
               let str = "";
               const invoices =
                  "invoiceIDs" in task
                     ? task.invoiceIDs.map(
                          (id) => this.manageInvoice.getInvoicesIndex()[id],
                       )
                     : (task.invoices ?? []);
               for (const invoice of invoices) {
                  if (invoice.invoiceFileName) {
                     str += `${invoice.invoiceFileName} ${
                        lang.forATotalOf
                     } ${formatNumber(
                        invoice.invoiceCost,
                        this.manageLang.getLocaleID(),
                        "1.2-2",
                     )}`;
                  } else {
                     str += `${lang.InvoiceForATotalOf} ${formatNumber(
                        invoice.invoiceCost,
                        this.manageLang.getLocaleID(),
                        "1.2-2",
                     )}`;
                  }
                  str += ` - #${invoice.invoiceID}`;
                  if (
                     invoice.invoiceDescription &&
                     invoice.invoiceDescription.length > 0
                  ) {
                     str += ` - ${invoice.invoiceDescription}`;
                  }
                  if (invoice.poItemID > 0) {
                     str += ` - ${lang.PO} - ${invoice.poNumber}`;
                  }
               }
               obj[lang.InvoiceCostBreakdown] = str;
            } else if (key === "checklistTotalLaborCost" && column == true) {
               const checklistTotalLaborCost =
                  this.getCalculatedTaskInfo(task).checklistTotalLaborCost;
               obj[lang.TotalLaborCost] = parseFloat(checklistTotalLaborCost.toFixed(2));
            } else if (key === "checklistTotalPartsCost" && column == true) {
               const checklistTotalPartsCost =
                  this.getCalculatedTaskInfo(task).checklistTotalPartsCost;
               obj[lang.TotalPartsCost] = parseFloat(checklistTotalPartsCost.toFixed(2));
            } else if (key === "checklistTotalOperatingCost" && column == true) {
               const checklistTotalOperatingCost =
                  this.getCalculatedTaskInfo(task).checklistTotalOperatingCost;
               obj[lang.TotalOperatingCost] = parseFloat(
                  checklistTotalOperatingCost.toFixed(2),
               );
            } else if (key === "checklistInstructions" && column == true) {
               obj[lang.Description] = task.checklistInstructions;
            } else if (key === "requestDescription" && column == true) {
               obj[lang.RequestDescription] = task.requestDescription;
            } else if (key === "requestorInformation" && column == true) {
               obj[lang.RequestorInfo] = this.getTaskRequestorInfo(task);
            } else if (key === "customTags" && column == true) {
               obj[lang.CustomTags] = this.getCustomTagsObj(task).customTagsStr;
            } else if (key === "checklistID" && column == true) {
               obj[lang.TaskID] = task.checklistID;
            } else if (key === "checklistUserCompleted" && column == true) {
               const taskCompleted = Boolean(task.checklistCompletedDate);
               obj[lang.CompletedBy] = taskCompleted
                  ? this.getCompletedTaskCalculatedInfo(task)?.completedByStr
                  : "";
               if (taskCompleted && task.profileID && task.profileID > 0) {
                  obj[lang.CompletedBy] += ` - ${
                     this.getTaskAssignmentInfo(task).profileDescription
                  }`;
               }
            } else if (key === "checklistDueDate" && column == true) {
               obj[lang.DueDate] = this.betterDate.formatBetterDate(
                  (task.checklistDueDate ?? 0) * 1000,
                  "dateTimeWithSeconds",
               );
            } else if (
               (key === "checklistStatus" || key == "statusID") &&
               column == true
            ) {
               obj[lang.Status] = this.getStatusInfo(task.statusID)?.statusName;
            } else if (key === "completionNotes" && column == true) {
               if (
                  task.checklistComments === undefined ||
                  task.checklistComments === null
               ) {
                  obj[lang.CompletionNotesHeader] = "";
               } else if (task.checklistComments.length > 0) {
                  obj[lang.CompletionNotesHeader] = task.checklistComments;
               }
            } else if (key === "completedDaysPastDueDate" && column == true) {
               const completedDaysPastDueDate =
                  this.getCompletedTaskCalculatedInfo(task)?.completedDaysPastDueDate;
               if (completedDaysPastDueDate === undefined) {
                  obj[lang.CompletedDaysPastDueDateHeader] = "";
               } else {
                  obj[lang.CompletedDaysPastDueDateHeader] = completedDaysPastDueDate;
               }
            } else if (key === "startTemplateUrl" && column) {
               obj[lang.URLToStartTemplate] = this.startTemplateURL(task);
            } else if (key === "flipScheduleURL" && column) {
               obj[lang.URLToFlipSchedules] = this.flipScheduleURL(task);
            } else if (key === "sharedExternally" && column === true) {
               let sharedWith = "";
               const sharedTasks = this.vendorTasksByChecklistID.get(task.checklistID);
               if (sharedTasks?.size) {
                  for (const sharedTask of sharedTasks) {
                     if (sharedTask.vendorID === 0 || sharedTask.vendorID === null) {
                        sharedWith += `${sharedTask.shareTargetEmail}, `;
                     } else {
                        const vendor = this.manageVendor.getVendor(sharedTask.vendorID);
                        assert(vendor);
                        sharedWith += `${vendor.vendorName}, `;
                     }
                  }
               }
               if (sharedWith !== "") {
                  sharedWith = sharedWith.slice(0, -2);
               }

               obj[lang.SharedExternally] = sharedWith;
            }
         }
         tempTasks.push(obj);
      }

      const today = this.betterDate.createTodayTimestamp();

      const fileName = `${lang.Tasks}-${today}.xlsx`;

      this.manageUtil.objToExcel(tempTasks, "Tasks", fileName);

      return true;
   }

   downloadExcelSchedules = async (
      schedules,
      tasksToDownload: Iterable<{ checklistID: number }>,
   ) => {
      const today = this.betterDate.createTodayTimestamp();

      const fileName = `Tasks And Schedules-${today}.xlsx`;

      let tempArr: any = [];
      for (const task of tasksToDownload) {
         tempArr.push(task.checklistID);
      }

      tempArr = JSON.stringify(tempArr);

      let tempSch: any = [];
      for (const schedule of schedules) {
         if (schedule.scheduleID) {
            tempSch.push(schedule.scheduleID);
         }
      }

      tempSch = JSON.stringify(tempSch);

      const post = this.taskPhpApiService.downloadExcelSchedules({
         tasks: tempArr,
         schedules: tempSch,
      });

      post.then((answer) => {
         if (answer.data.success == true) {
            if (answer.data.tasks) {
               this.manageUtil.objToExcel(answer.data.tasks, "Schedules", fileName);
            } else {
               this.alertService.addAlert(
                  this.lang().ThereIsNothingToDownload,
                  "warning",
                  6000,
               );
            }
         }
      });

      return post;
   };

   /****************************************
    *@function checkSubmitProblemRecaptcha
    *@purpose
    *@name checkSubmitProblemRecaptcha
    *@param
    *@return post
    ****************************************/
   public async checkSubmitProblemRecaptcha(recaptcha) {
      const post = this.taskPhpApiService.checkSubmitProblemRecaptcha({
         recaptcha: recaptcha,
      });

      return post;
   }

   /****************************************
    *@function checkForDuplicateWorkRequests
    *@purpose
    *@name checkForDuplicateWorkRequests
    *@param
    *@return post
    ****************************************/
   public async checkForDuplicateWorkRequests(customerID, locationID, assetID) {
      const post = this.taskPhpApiService.checkForDuplicateWorkRequests({
         customerID: customerID,
         locationID: locationID,
         assetID: assetID,
      });

      return post;
   }

   /****************************************
    *@function submitProblemReport
    *@purpose
    *@name submitProblemReport
    *@param
    *@return post
    ****************************************/
   public async submitProblemReport(
      locationID,
      assetID,
      partsAdded,
      customerCode,
      problem,
      name,
      phone,
      email,
      file,
      unixtime,
      problemName,
      customTag,
      priority,
      customField1,
      customField2,
      customField3,
      customField1Title,
      customField2Title,
      customField3Title,
      customDropdownAnswer1,
      customDropdownAnswer2,
      customDropdownAnswer3,
      customDropdownAnswer1Order,
      customDropdownAnswer2Order,
      customDropdownAnswer3Order,
      customDropdownTitle1,
      customDropdownTitle2,
      customDropdownTitle3,
      customDropdownOptions1,
      customDropdownOptions2,
      customDropdownOptions3,
      global,
      dueDateUnit,
      geoLocation,
   ) {
      /**
       * We set the priority to null so the backend knows we didn't decide on a priority.
       * The backend will then decide whether to use the WR template's priority or to use the global
       * default priority.
       * */
      const priorityID = priority?.priorityID ?? null;

      const shouldUseRefactoredProblemPortal =
         await this.launchFlagDarklyService.getFlagPromise(
            "refactored-problem-portal",
            false,
         );
      let action: "submitProblemReport" | "v2-submitProblemReport" =
         "submitProblemReport";
      if (shouldUseRefactoredProblemPortal) {
         action = "v2-submitProblemReport";
      }

      const params = {
         locationID: locationID,
         assetID: assetID,
         parts: partsAdded,
         customerCode: customerCode,
         problem: problem,
         name: name,
         phone: phone,
         email: email,
         file: file,
         unixtime: unixtime,
         problemName: problemName,
         customTag: customTag,
         priorityID: priorityID,
         customField1: customField1,
         customField2: customField2,
         customField3: customField3,
         customField1Title: customField1Title,
         customField2Title: customField2Title,
         customField3Title: customField3Title,
         customDropdownAnswer1: customDropdownAnswer1,
         customDropdownAnswer2: customDropdownAnswer2,
         customDropdownAnswer3: customDropdownAnswer3,
         customDropdownAnswer1Order: customDropdownAnswer1Order,
         customDropdownAnswer2Order: customDropdownAnswer2Order,
         customDropdownAnswer3Order: customDropdownAnswer3Order,
         customDropdownTitle1: customDropdownTitle1,
         customDropdownTitle2: customDropdownTitle2,
         customDropdownTitle3: customDropdownTitle3,
         customDropdownOptions1: customDropdownOptions1,
         customDropdownOptions2: customDropdownOptions2,
         customDropdownOptions3: customDropdownOptions3,
         global: global,
         dueDateUnit: dueDateUnit,
         geoLocation: geoLocation,
      };

      const post = this.taskPhpApiService.submitProblemReport(action, params);

      post.then((answer) => {
         if (answer.data.success) {
            const taskID = Number(answer.data.checklistID);
            this.taskStorageSyncService.syncTaskByID(taskID);
         }
      });

      return post;
   }

   /****************************************
    *@function prepProblemReportHTML
    *@purpose
    *@name prepProblemReportHTML
    *@param
    *@return post
    ****************************************/
   public async prepProblemReportHTML(locationID, assetID, customerCode) {
      const post = this.taskPhpApiService.prepProblemReportHTML({
         locationID: locationID,
         assetID: assetID,
         customerCode: customerCode,
      });

      return post;
   }

   /****************************************
    *@function prepCheckWorkRequestsPage
    *@purpose
    *@name prepCheckWorkRequestsPage
    *@param
    *@return post
    ****************************************/
   public async prepCheckWorkRequestsPage(customerCode, locationID, emailViewing, hash) {
      const post = this.taskPhpApiService.prepCheckWorkRequestsPage({
         customerCode: customerCode,
         locationID: locationID,
         emailViewing: emailViewing,
         hash: hash,
      });

      return post;
   }

   /****************************************
    *@function checkWorkRequestsPageValidateEmail
    *@purpose
    *@name checkWorkRequestsPageValidateEmail
    *@param
    *@return post
    ****************************************/
   public async checkWorkRequestsPageValidateEmail(
      customerCode,
      email,
      hash,
      locationID,
   ) {
      const post = this.taskPhpApiService.checkWorkRequestsPageValidateEmail({
         customerCode: customerCode,
         email: email,
         hash: hash,
         locationID: locationID,
      });

      return post;
   }

   /****************************************
    *@function getCheckWorkRequestsPageData
    *@purpose
    *@name getCheckWorkRequestsPageData
    *@param
    *@return post
    ****************************************/
   public async getCheckWorkRequestsPageData(customerCode, email, hash, locationID) {
      const post = this.taskPhpApiService.getCheckWorkRequestsPageData({
         customerCode: customerCode,
         email: email,
         hash: hash,
         locationID: locationID,
      });

      return post;
   }

   /****************************************
    *@function prepWorkRequestTemplate
    *@purpose Called when the work request portal they are configuring doesn't yet have a template.
    *@name prepWorkRequestTemplate
    *@param
    *@return post
    ****************************************/
   public async prepWorkRequestTemplate(locationID, target) {
      const post = this.taskPhpApiService.prepWorkRequestTemplate({
         locationID: locationID,
         target: target,
      });

      post.then((answer) => {
         if (answer.data.success == true) {
            this.addTaskToLookup(answer.data.task);

            this.incTasksWatchVar();
         }
      });

      return post;
   }

   public async updateTaskPriorityID(
      checklistID: number,
      priorityID: number,
      oldPriorityID: number | null,
   ): Promise<AxiosResponse & { note?: unknown }> {
      const lang = this.lang();

      const post = this.taskPhpApiService.updateTaskPriorityID({
         checklistID: checklistID,
         priorityID: priorityID,
      });

      // When creating a new task and setting the priority, oldPriority is null
      // so make sure it exists before trying to call addNote()
      if (oldPriorityID !== null) {
         const answer = await post;
         if (answer.data.success) {
            const priorityListIndex = this.managePriority.getPriorityListIndex();
            const newPriorityLevel = priorityListIndex[priorityID].priorityLevel;
            const newPriorityName = priorityListIndex[priorityID].name;
            let oldPriorityLevel;
            let oldPriorityName;
            if (oldPriorityID === 0) {
               oldPriorityLevel = 0;
               oldPriorityName = lang.NoPriority;
            } else {
               oldPriorityLevel = priorityListIndex[oldPriorityID].priorityLevel;
               oldPriorityName = priorityListIndex[oldPriorityID].name;
            }

            const note = `${lang.ThisTasksPriorityWasChanged} ${lang.from} ${oldPriorityLevel} - ${oldPriorityName} ${lang.to} ${newPriorityLevel} - ${newPriorityName}`;

            const resp = await this.addNote(note, checklistID, 1, 1, false);
            let promise: Promise<AxiosResponse & { note?: unknown }> = new Promise(
               (resolve) => {
                  const combinedData = { ...answer };
                  resolve(combinedData);
               },
            );

            if (resp.data.success) {
               promise = new Promise((resolve) => {
                  const combinedData = { ...answer, note: resp.data.note };
                  resolve(combinedData);
               });
               const task = await this.refreshLocalTask(checklistID);
               if (task) {
                  task.priorityID = priorityID;
               }
               this.taskStorageSyncService.syncTaskByID(checklistID);
               this.incTasksWatchVar();
            }
            return promise;
         }
      }
      return post;
   }

   public async updateTaskStatusID(
      checklistID: number,
      statusID: number,
      oldStatusID: number | null,
      noteHiddenFromExternal: number,
   ): Promise<unknown> {
      const post = this.taskPhpApiService.updateTaskStatusID({
         checklistID: checklistID,
         statusID: statusID,
      });

      // When creating a new task and setting the status, oldStatus is null
      // so make sure it exists before trying to call addNote()
      if (oldStatusID !== null) {
         const answer = await post;
         if (answer.data.success) {
            this.taskStorageSyncService.syncTaskByID(checklistID);

            const addNoteResponse = await this.sendTaskStatusUpdatedNote(
               checklistID,
               oldStatusID,
               statusID,
               noteHiddenFromExternal,
            );

            let promise = new Promise((resolve) => {
               const combinedData = { ...answer };
               resolve(combinedData);
            });

            if (addNoteResponse.data.success) {
               promise = new Promise((resolve) => {
                  const combinedData = { ...answer, note: addNoteResponse.data.note };
                  resolve(combinedData);
               });

               this.incTasksWatchVar();
               return promise;
            }

            return promise;
         }
      }
      return post;
   }

   public async changeCompletedTime(checklistID: number, newTime: number) {
      const post = await this.taskPhpApiService.changeCompletedTime({
         checklistID: checklistID,
         newTime: newTime,
      });

      const task = this.getTaskLocalLookup(checklistID);
      if (post.data.success !== true || !task) {
         return post;
      }
      task.checklistPromptTime = newTime;

      if (post.data.note) {
         this.getComments().set(post.data.note.noteID, post.data.note);
         task.noteIDs.push(post.data.note.noteID);
      }
      this.incCompletedTasksWatchVar();

      return post;
   }

   public async changeCompletedTimeExtraTime(
      extraTimeID: number,
      newTime: number,
      checklistID: number,
   ) {
      const post = await this.taskPhpApiService.changeCompletedTimeExtraTime({
         extraTimeID: extraTimeID,
         newTime: newTime,
      });

      const extraTime = this.getExtraTime(extraTimeID);
      if (post.data.success !== true || extraTime === undefined) {
         return post;
      }

      extraTime.promptTime = Number(newTime);

      const task = this.getTaskLocalLookup(checklistID);
      if (post.data.note) {
         this.getComments().set(post.data.note.noteID, post.data.note);
         if (task) {
            task.noteIDs.push(post.data.note.noteID);
         }
      }

      return post;
   }

   public async changeCompletedUserExtraTime(
      userID: number,
      extraTimeID: number,
      checklistID: number,
   ): Promise<AxiosResponse> {
      const post = await this.taskPhpApiService.changeCompletedUserExtraTime({
         userID: userID,
         extraTimeID: extraTimeID,
      });

      const extraTime = this.getExtraTime(extraTimeID);

      if (post.data.success !== true || extraTime === undefined) {
         return post;
      }

      extraTime.userID = userID;
      extraTime.userWage = post.data.userWage;

      const task = this.getTaskLocalLookup(checklistID);
      if (post.data.note && task !== undefined) {
         this.getComments().set(post.data.note.noteID, post.data.note);
         task.noteIDs.push(post.data.note.noteID);
      }

      return post;
   }

   public async deleteExtraTime(
      extraTimeID: number,
      checklistID: number,
   ): Promise<AxiosResponse> {
      const post = await this.taskPhpApiService.deleteExtraTime({
         extraTimeID: extraTimeID,
      });

      const task = this.getTaskLocalLookup(checklistID);
      if (post.data.success !== true || task === undefined) {
         return post;
      }

      this.extraTime.delete(extraTimeID);

      const referencesByChecklistID = this.extraTimeByChecklistID.get(checklistID);

      referencesByChecklistID?.delete(extraTimeID);

      const extraTimeIndex = task.extraTimeIDs.indexOf(extraTimeID);
      if (extraTimeIndex !== -1) {
         task.extraTimeIDs.splice(extraTimeIndex, 1);
      }

      if (post.data.note) {
         this.addNoteToLocalData(post.data.note, checklistID);
      }

      this.taskExtraTimeStorageSyncService.syncExtraTimeDeletion(extraTimeID);

      return post;
   }

   public async deleteTimeLoggedFromCompletedTask(
      checklistID: number,
   ): Promise<AxiosResponse> {
      const post = await this.taskPhpApiService.deleteTimeLoggedFromCompletedTask({
         checklistID: checklistID,
      });
      const task = this.getTaskLocalLookup(checklistID);
      if (
         post.data.success !== true ||
         !post.data.extraTimeIDToRemove ||
         task === undefined
      ) {
         return post;
      }

      const extraTimeIDToRemove = post.data.extraTimeIDToRemove;

      this.extraTime.delete(extraTimeIDToRemove);

      const referencesByChecklistID = this.extraTimeByChecklistID.get(checklistID);

      referencesByChecklistID?.delete(extraTimeIDToRemove);

      const extraTimeIndex = task.extraTimeIDs.indexOf(extraTimeIDToRemove);
      if (extraTimeIndex !== -1) {
         task.extraTimeIDs.splice(extraTimeIndex, 1);
      }

      if (post.data.note) {
         this.addNoteToLocalData(post.data.note, checklistID);
      }

      this.taskExtraTimeStorageSyncService.syncExtraTimeDeletion(extraTimeIDToRemove);

      return post;
   }

   public async changeTaskBillableTime(
      checklistID: number,
      newTime: number,
   ): Promise<AxiosResponse> {
      const post = await this.taskPhpApiService.changeTaskBillableTime({
         checklistID: checklistID,
         newTime: newTime,
      });
      const task = this.getTaskLocalLookup(checklistID);
      if (post.data.success !== true || !task) {
         return post;
      }
      task.billableTime = newTime;

      this.incCompletedTasksWatchVar();

      if (post.data.note) {
         this.addNoteToLocalData(post.data.note, checklistID);
      }

      return post;
   }

   public async changeTaskBillableCategory(
      checklistID: number,
      categoryID: number,
   ): Promise<AxiosResponse | undefined> {
      const task = this.getTaskLocalLookup(checklistID);
      if (task === undefined || task.categoryID === categoryID) {
         return undefined;
      }
      const post = await this.taskPhpApiService.changeTaskBillableCategory({
         checklistID: checklistID,
         categoryID: categoryID,
      });

      if (post.data.success !== true) {
         return post;
      }
      task.categoryID = categoryID;
      task.checklistUserWage = post.data.userWage;
      task.billableRate = post.data.billableRate;

      if (post.data.note) {
         this.getComments().set(post.data.note.noteID, post.data.note);
         task.noteIDs.push(post.data.note.noteID);
      }

      return post;
   }

   public async changeTaskBillableRate(
      checklistID: number,
      rate: number,
   ): Promise<AxiosResponse | undefined> {
      const task = this.getTaskLocalLookup(checklistID);
      if (task === undefined || task.billableRate == rate || task === undefined) {
         return undefined;
      }

      const post = await this.taskPhpApiService.changeTaskBillableRate({
         checklistID: checklistID,
         rate: rate,
      });

      if (post.data.success !== true) {
         return post;
      }
      task.billableRate = Number(
         formatNumber(rate, this.manageLang.getLocaleID(), "1.2-2"),
      );

      if (post.data.note) {
         this.getComments().set(post.data.note.noteID, post.data.note);
         task.noteIDs.push(post.data.note.noteID);
      }

      return post;
   }

   public async changeTaskExtraTimeNotes(
      checklistID: number,
      text: string,
   ): Promise<AxiosResponse | undefined> {
      const task = this.getTaskLocalLookup(checklistID);
      if (task === undefined || task.extraTimeNotes == text) {
         return undefined;
      }

      const post = await this.taskPhpApiService.changeTaskExtraTimeNotes({
         checklistID: checklistID,
         text: text,
      });

      if (post.data.success !== true) {
         return post;
      }

      task.extraTimeNotes = text;

      if (post.data.note) {
         this.addNoteToLocalData(post.data.note, checklistID);
      }

      return post;
   }

   public async changeExtraTimeBillableTime(extraTimeID: number, newTime: number) {
      const post = await this.taskPhpApiService.changeExtraTimeBillableTime({
         extraTimeID: extraTimeID,
         newTime: newTime,
      });

      const extraTime = this.getExtraTime(extraTimeID);

      if (post.data.success !== true || extraTime === undefined) {
         return post;
      }

      extraTime.billableTime = newTime;

      const task = this.getTaskLocalLookup(extraTime.checklistID);
      if (post.data.note && task) {
         this.comments.set(post.data.note.noteID, post.data.note);
         task.noteIDs.push(post.data.note.noteIDs);
      }

      return post;
   }

   public async changeExtraTimeBillableCategory(
      extraTimeID: number,
      categoryID: number,
   ): Promise<AxiosResponse | undefined> {
      const extraTime = this.getExtraTime(extraTimeID);
      if (extraTime === undefined || extraTime.categoryID === categoryID) {
         return undefined;
      }

      const post = await this.taskPhpApiService.changeExtraTimeBillableCategory({
         extraTimeID: extraTimeID,
         categoryID: categoryID,
      });

      if (post.data.success !== true) {
         return post;
      }

      extraTime.categoryID = categoryID;
      extraTime.userWage = post.data.userWage;
      extraTime.billableRate = post.data.billableRate;

      const task = this.getTaskLocalLookup(extraTime.checklistID);
      if (post.data.note && task !== undefined) {
         this.getComments().set(post.data.note.noteID, post.data.note);
         task.noteIDs.push(post.data.note.noteID);
      }

      return post;
   }

   public async changeExtraTimeBillableRate(
      extraTimeID: number,
      billableRate: number,
   ): Promise<AxiosResponse | undefined> {
      const extraTime = this.getExtraTime(extraTimeID);
      if (extraTime === undefined || extraTime.billableRate == billableRate) {
         //they are the same so don't update it ;p
         return undefined;
      }

      const post = await this.taskPhpApiService.changeExtraTimeBillableRate({
         extraTimeID: extraTimeID,
         billableRate: billableRate,
      });

      if (post.data.success !== true) {
         return post;
      }

      extraTime.billableRate = Number(
         formatNumber(billableRate, this.manageLang.getLocaleID(), "1.2-2"),
      );

      const task = this.getTaskLocalLookup(extraTime.checklistID);
      if (post.data.note && task !== undefined) {
         this.getComments().set(post.data.note.noteID, post.data.note);
         task.noteIDs.push(post.data.note.noteID);
      }

      return post;
   }

   public async changeExtraTimeNotes(
      extraTimeID: number,
      description: string,
   ): Promise<AxiosResponse | undefined> {
      const extraTime = this.getExtraTime(extraTimeID);
      if (extraTime === undefined) {
         return undefined;
      }

      const post = await this.taskPhpApiService.changeExtraTimeNotes({
         extraTimeID: extraTimeID,
         notes: description,
      });

      if (post.data.success !== true) {
         return post;
      }
      extraTime.notes = description;

      const task = this.getTaskLocalLookup(extraTime.checklistID);
      if (post.data.note && task !== undefined) {
         this.getComments().set(post.data.note.noteID, post.data.note);
         task.noteIDs.push(post.data.note.noteID);
      }

      return post;
   }

   public async changeDowntime(
      checklistID: number,
      newTime: number,
   ): Promise<AxiosResponse> {
      const post = await this.taskPhpApiService.changeDowntime({
         checklistID: checklistID,
         newTime: newTime,
      });

      if (post.data.success) {
         const task = this.getTaskLocalLookup(checklistID);
         if (task) {
            task.checklistDowntime = newTime;
         }
         await this.taskStorageSyncService.syncTaskByID(checklistID);
         this.incTasksWatchVar();
         this.incCompletedTasksWatchVar();
      }

      return post;
   }

   /****************************************
    *@function addNoteFromExternal
    *@purpose
    *@name addNoteFromExternal
    *@param
    *@return post
    ****************************************/
   public async addNoteFromExternal(note, task, email) {
      const timestamp = Math.floor(Date.now() / 1000);
      const post = this.taskPhpApiService.addNoteFromExternal({
         note: note,
         checklistID: task.checklistID,
         timestamp: timestamp,
         customerID: task.customerID,
         email: email,
      });

      return post;
   }

   public async deleteNote(noteID: number, checklistID: number): Promise<AxiosResponse> {
      const post = await this.taskPhpApiService.deleteNote({
         noteID: noteID,
      });

      this.comments.delete(noteID);

      const task = this.getTaskLocalLookup(checklistID);
      if (post.data.success !== true || task === undefined) {
         return post;
      }

      const noteIDToDelete = task.noteIDs.indexOf(noteID);
      if (noteIDToDelete !== -1) {
         task.noteIDs.splice(noteIDToDelete, 1);
      }

      this.commentStorageSyncService.syncCommentDeletion(noteID);

      return post;
   }

   /****************************************
    * PT - GoodToGo
    *@function showOrHideNoteForExternalView
    *@purpose
    *@name showOrHideNoteForExternalView
    *@param
    *@return post
    ****************************************/
   showOrHideNoteForExternalView = async (note) => {
      const post = await this.taskPhpApiService.showOrHideNoteForExternalView({
         noteID: note.noteID,
         noteHidden: note.noteHidden,
      });

      if (post.data.success) {
         const commentID = Number(note.noteID);
         this.commentStorageSyncService.syncComment(commentID);
      }

      return post;
   };

   public async updateDueDate(
      oldTime: number,
      newTime: number,
      checklistID: number,
      timeOfDay,
      oldStartDate,
      newStartDate,
      startDateTimeOfDay,
   ): Promise<AxiosResponse> {
      const timestamp = Math.floor(Date.now() / 1000);

      const post = await this.taskPhpApiService.updateDueDate({
         oldTime: oldTime,
         newTime: newTime,
         checklistID: checklistID,
         timestamp: timestamp,
         timeOfDay: timeOfDay,
         oldStartDate: oldStartDate,
         newStartDate: newStartDate,
         startDateTimeOfDay: startDateTimeOfDay,
      });
      const task = this.getTaskLocalLookup(checklistID);
      if (post.data.success !== true || task === undefined) {
         return post;
      }

      task.checklistDueDate = Number(post.data.checklistDueDate);
      task.numOfDueDateChange = Number(task.numOfDueDateChange) + 1;
      task.checklistColor = post.data.checklistColor;

      task.checklistDueDateSetting = post.data.checklistDueDateSetting;
      task.checklistDueDateCalendarOrder = post.data.checklistDueDateCalendarOrder;

      task.checklistStartDate = post.data.checklistStartDate;
      task.checklistStartDateSetting = post.data.checklistStartDateSetting;

      if (post.data.note) {
         this.addNoteToLocalData(post.data.note, checklistID);
      }

      this.incTasksWatchVar();
      this.taskStorageSyncService.syncTaskByID(checklistID);

      return post;
   }

   public async updateCompletedDate(
      oldTime: number,
      newTime: number,
      checklistID: number,
   ) {
      const oldDate = new Date(oldTime * 1000);
      const oldTimeStr = `${oldDate.getFullYear()}/${
         oldDate.getMonth() + 1
      }/${oldDate.getDate()}`;

      const newDate = new Date(newTime * 1000);
      const newTimeStr = `${newDate.getFullYear()}/${
         newDate.getMonth() + 1
      }/${newDate.getDate()}`;

      const timestamp = Math.floor(Date.now() / 1000);

      const post = await this.taskPhpApiService.updateCompletedDate({
         oldTime: oldTime,
         newTime: newTime,
         oldTimeStr: oldTimeStr,
         newTimeStr: newTimeStr,
         checklistID: checklistID,
         timestamp: timestamp,
      });
      const task = this.getTaskLocalLookup(checklistID);
      if (post.data.success !== true || !task) {
         return post;
      }

      if (post.data.note) {
         this.addNoteToLocalData(post.data.note, checklistID);
      }

      task.checklistCompletedDate = newTime;
      task.finalColorStatus = post.data.checklistColor;

      return post;
   }

   public async updateExtraTimeDate(
      newTime: number,
      extraTimeID: number,
      checklistID: number,
      timeOfDay: boolean,
   ) {
      // Determine whether we should show the time
      const showTime = timeOfDay ? 1 : 0;

      const post = await this.taskPhpApiService.updateExtraTimeDate({
         newTime: newTime,
         extraTimeID: extraTimeID,
         checklistID: checklistID,
         showTime: showTime,
      });

      const extraTime = this.getExtraTime(extraTimeID);
      const task = this.getTaskLocalLookup(checklistID);

      if (post.data.success !== true || !extraTime || !task) {
         return post;
      }

      extraTime.loggedAt = newTime;
      extraTime.showTime = showTime;

      if (post.data.note) {
         this.addNoteToLocalData(post.data.note, checklistID);
      }

      return post;
   }
   public async updateTasksAsset(
      checklistID,
      asset,
      extraAssetsIn,
   ): Promise<AxiosResponse> {
      const post = await this.taskPhpApiService.updateTasksAsset({
         checklistID: checklistID,
         newAssetID: asset.assetID,
         extraAssets: extraAssetsIn,
      });

      if (post.data.success) {
         const taskID = Number(checklistID);
         this.taskStorageSyncService.syncTaskByID(taskID);

         this.incTasksWatchVar();
         this.incCompletedTasksWatchVar();
      }

      return post;
   }

   public async updateTasksLocation(
      checklistID: number,
      locationID: number,
   ): Promise<AxiosResponse> {
      const post = await this.taskPhpApiService.updateTasksLocation({
         checklistID: checklistID,
         locationID: locationID,
      });
      const task = await this.getTask(checklistID);
      if (post.data.success !== true || task === undefined) {
         return post;
      }
      task.locationID = locationID;
      task.assetID = 0;

      this.incTasksWatchVar();
      this.incCompletedTasksWatchVar();

      return post;
   }

   public async updateTaskType(
      checklistID: number,
      checklistTemplateOld: number,
      checklistBatchID: number,
   ): Promise<AxiosResponse> {
      const post = await this.taskPhpApiService.updateTaskType({
         checklistID: checklistID,
         checklistTemplateOld: checklistTemplateOld,
         checklistBatchID: checklistBatchID,
      });

      const task = this.getTaskLocalLookup(checklistID);
      if (!post.data.success || task === undefined) {
         return post;
      }

      task.checklistTemplateOld = post.data.checklistTemplateOld;
      task.checklistBatchID = post.data.checklistBatchID;

      if (post.data.note) {
         this.addNoteToLocalData(post.data.note, checklistID);
      }

      this.incTasksWatchVar();
      this.incCompletedTasksWatchVar();
      this.taskStorageSyncService.syncTaskByID(checklistID);

      return post;
   }

   /****************************************
    *@function addCustomTag
    *@purpose
    *@name addCustomTag
    *@param
    *@return post
    ****************************************/
   addCustomTag = async (tag) => {
      const regex = new RegExp('"', "g"); //as a safety precaution we remove all " because it causes problems.
      const tagCleaned = tag.replace(regex, "");

      const post = this.taskPhpApiService.addCustomTag({
         tag: tagCleaned,
      });

      return post;
   };

   public async renameCustomTag(
      newName: string,
      oldName: string,
   ): Promise<AxiosResponse> {
      const regex = new RegExp('"', "g"); //as a safety precaution we remove all " because it causes problems.
      const newNameCleaned = newName.replace(regex, "");

      const post = this.taskPhpApiService.renameCustomTag({
         newName: newNameCleaned,
         oldName: oldName,
      });

      post.then((answer) => {
         if (answer.data.success == true) {
            const regex2 = new RegExp(oldName, "g"); //regex to find old tag

            for (const task of this.tasks) {
               if (task?.checklistInstructions != null) {
                  if (task.checklistInstructions.includes(oldName)) {
                     task.checklistInstructions = task.checklistInstructions.replace(
                        regex2,
                        newName,
                     );
                  }
               }
            }

            for (const task of this.completedTasks) {
               if (task?.checklistInstructions != null) {
                  if (task.checklistInstructions.includes(oldName)) {
                     task.checklistInstructions = task.checklistInstructions.replace(
                        regex2,
                        newName,
                     );
                  }
               }
            }

            //increment to update watches
            this.incCompletedTasksWatchVar();
            this.incTasksWatchVar();
         }
      });

      return post;
   }

   public async deleteCustomTag(tagName: string): Promise<AxiosResponse> {
      const name = `${tagName};`;
      const post = this.taskPhpApiService.deleteCustomTag({
         name: name,
      });

      post.then((answer) => {
         if (answer.data.success == true) {
            const regex = new RegExp(name, "g"); //regex to find old tag

            for (const task of this.tasks) {
               if (task.checklistInstructions !== null) {
                  if (task.checklistInstructions.includes(name)) {
                     task.checklistInstructions = task.checklistInstructions.replace(
                        regex,
                        " ",
                     );
                  }
               }
            }

            for (const task of this.completedTasks) {
               if (task.checklistInstructions !== null) {
                  if (task.checklistInstructions.includes(name)) {
                     task.checklistInstructions = task.checklistInstructions.replace(
                        regex,
                        " ",
                     );
                  }
               }
            }

            this.incCompletedTasksWatchVar();
            this.incTasksWatchVar();
         }
      });

      return post;
   }
   //PT - Good to go
   /****************************************
    *@function getCustomTagEventSettings
    *@purpose
    *@name getCustomTagEventSettings
    *@param
    *@return post
    ****************************************/
   getCustomTagEventSettings = async (tag) => {
      const name = `${tag.name};`;
      const post = this.taskPhpApiService.getCustomTagEventSettings({
         name: name,
      });

      return post;
   };
   //PT - Good to go
   /****************************************
    *@function updateCustomTagEvent
    *@purpose
    *@name updateCustomTagEvent
    *@param
    *@return post
    ****************************************/
   updateCustomTagEvent = async (customTag, trigger, action, field1, active) => {
      const post = this.taskPhpApiService.updateCustomTagEvent({
         customTag: customTag,
         trigger: trigger,
         actionToUpdate: action,
         field1: field1,
         active: active,
      });

      return post;
   };

   /****************************************
    *@function markNotificationHistoryVisited
    *@purpose
    *@name markNotificationHistoryVisited
    *@param
    *@return post
    ****************************************/
   public async markNotificationHistoryVisited(historyID) {
      const post = this.taskPhpApiService.markNotificationHistoryVisited({
         historyID: historyID,
      });

      return post;
   }

   public async setWRFilterData(itemData: any, locationID: number) {
      const post = this.taskPhpApiService.setWorkRequestFilterData({
         itemData: itemData,
         locationID: locationID,
      });

      return post;
   }

   /****************************************
    *@function updateWorkRequestPortalSettings
    *@purpose
    *@name updateWorkRequestPortalSettings
    *@param
    *@return post
    ****************************************/
   public async updateWorkRequestPortalSettings(target, value, locationID) {
      const post = this.taskPhpApiService.updateWorkRequestPortalSettings({
         target: target,
         value: value,
         locationID: locationID,
      });

      return post;
   }

   /****************************************
    *@function updateReportProblemHeader
    *@purpose
    *@name updateReportProblemHeader
    *@param
    *@return post
    ****************************************/
   public async updateReportProblemHeader(value, locationID) {
      const post = this.taskPhpApiService.updateReportProblemHeader({
         value: value,
         locationID: locationID,
      });

      return post;
   }

   /****************************************
    *@function updateReportProblemEmailToNotify
    *@purpose
    *@name updateReportProblemEmailToNotify
    *@param
    *@return post
    ****************************************/
   public async updateReportProblemEmailToNotify(value, locationID) {
      const post = this.taskPhpApiService.updateReportProblemEmailToNotify({
         value: value,
         locationID: locationID,
      });

      return post;
   }

   /****************************************
    *@function deleteWorkRequestPortalImage
    *@purpose
    *@name deleteWorkRequestPortalImage
    *@param
    *@return post
    ****************************************/
   public async deleteWorkRequestPortalImage(locationID) {
      const post = this.taskPhpApiService.deleteWorkRequestPortalImage({
         locationID: locationID,
      });

      return post;
   }

   /****************************************
    *@function updateCustomFieldTitle
    *@purpose
    *@name updateCustomFieldTitle
    *@param
    *@return post
    ****************************************/
   public async updateCustomFieldTitle(value, locationID, index) {
      const post = this.taskPhpApiService.updateCustomFieldTitle({
         value: value,
         locationID: locationID,
         index: index,
      });

      return post;
   }

   /****************************************
    *@function updateCustomDropdownTitle
    *@purpose
    *@name updateCustomDropdownTitle
    *@param
    *@return post
    ****************************************/
   public async updateCustomDropdownTitle(value, locationID, index) {
      const post = this.taskPhpApiService.updateCustomDropdownTitle({
         value: value,
         locationID: locationID,
         index: index,
      });

      return post;
   }

   /****************************************
    *@function updateWRCustomDropdownOptions
    *@purpose
    *@name updateWRCustomDropdownOptions
    *@param
    *@return post
    ****************************************/
   public async updateWRCustomDropdownOptions(value, locationID, index) {
      const post = this.taskPhpApiService.updateWRCustomDropdownOptions({
         value: value,
         locationID: locationID,
         index: index,
      });

      return post;
   }

   /****************************************
    *@function checkMultiUsersForWRCustomDropdownOptions
    *@purpose
    *@name checkMultiUsersForWRCustomDropdownOptions
    *@param
    *@return post
    ****************************************/
   checkMultiUsersForWRCustomDropdownOptions = async (
      multiUsers,
      locationID,
      manageUser,
      manageProfile,
   ) => {
      const multiUsersStringifed = JSON.stringify(multiUsers);
      const post = this.taskPhpApiService.checkMultiUsersForWRCustomDropdownOptions({
         multiUsers: multiUsersStringifed,
         locationID: locationID,
      });

      post.then((answer) => {
         if (answer.data.success == true) {
            if (answer.data.profileID > 0) {
               //since we are assigning hidden profiles potentially we need to add them to the right flat data sets if needed... yes I know wtf do we have multiple data sets :(
               this.manageFilters.updateHiddenProfiles(
                  {
                     profileID: answer.data.profileID,
                     name: answer.data.profileDescription,
                     locationID: locationID,
                     multiUsers,
                  },
                  this,
                  manageUser,
                  manageProfile,
               );
            }
         }
      });

      return post;
   };

   /****************************************
    *@function resetWRTemplateToDefault
    *@purpose
    *@name resetWRTemplateToDefault
    *@param
    *@return post
    ****************************************/
   public async resetWRTemplateToDefault(locationID) {
      const post = this.taskPhpApiService.resetWRTemplateToDefault({
         locationID: locationID,
      });

      return post;
   }

   /****************************************
    *@function setWRDefaultAssignment
    *@purpose
    *@name setWRDefaultAssignment
    *@param
    *@return post
    ****************************************/
   public async setWRDefaultAssignment(userID, profileID, multiUsers, locationID) {
      const multiUsersStringifed = JSON.stringify(multiUsers);

      const post = this.taskPhpApiService.setWRDefaultAssignment({
         userID: userID,
         profileID: profileID,
         multiUsers: multiUsersStringifed,
         locationID: locationID,
      });

      return post;
   }

   //PT_GoodToGo
   /****************************************
    *@function getRequestDetails
    *@purpose
    *@name getRequestDetails
    *@param
    *@return post
    ****************************************/
   public async getRequestDetails(checklistID, customerCode, emailViewing, hash) {
      const post = this.taskPhpApiService.getRequestDetails({
         checklistID: checklistID,
         customerCode: customerCode,
         emailViewing: emailViewing,
         hash: hash,
      });

      return post;
   }

   /****************************************
    *@function updateReportProblemTagThisRequestStr
    *@purpose
    *@name updateReportProblemTagThisRequestStr
    *@param
    *@return post
    ****************************************/
   public async updateReportProblemTagThisRequestStr(value, locationID) {
      const post = this.taskPhpApiService.updateReportProblemTagThisRequestStr({
         value: value,
         locationID: locationID,
      });

      return post;
   }

   public async deleteCompletedTask(checklistID: number): Promise<AxiosResponse> {
      const post = await this.taskPhpApiService.deleteCompletedTask({
         checklistID: checklistID,
      });
      const task = this.getCompletedTask(checklistID, "ManageTask");

      if (post.data.success !== true || task === undefined) {
         return post;
      }
      this.completedTasks.delete(checklistID);

      for (const deferment of this.deferments) {
         if (deferment.checklistID === checklistID) {
            this.deferments.deleteValue(deferment);
         }
      }

      //clean up partRelations
      for (const partRelation of this.partRelations) {
         if (partRelation.checklistID === checklistID) {
            this.partRelations.deleteValue(partRelation);
         }
      }

      for (const vendorTask of this.vendorTasks) {
         if (vendorTask.checklistID === checklistID) {
            this.vendorTasks.deleteValue(vendorTask);
         }
      }

      //clean up invoices
      const invoices = this.manageInvoice.getInvoices();
      let index2 = invoices.length;
      while (index2--) {
         //have to go backwards in the array so that we can splice the correct items
         if (invoices[index2].checklistID == task.checklistID) {
            invoices.splice(index2, 1); //remove it from the array;
         }
      }

      //increment watch variable so watches will fire.  Workaround to deep watchs or watch collections.
      //call this carefully or you will cause digest loops.  It should only be called when key things are added or removed to the task.arr array, such as new tasks, deleted tasks, flagging tasks as deleted etc.
      this.incCompletedTasksWatchVar();

      return post;
   }

   public async reopenCompletedTask(checklistID: number): Promise<AxiosResponse> {
      const post = await this.taskPhpApiService.reopenCompletedTask({
         checklistID: checklistID,
      });

      if (post.data.success !== true) {
         return post;
      }

      this.completedTasks.delete(checklistID);
      this.tasks.set(checklistID, post.data.task);

      if (post.data.extraTime) {
         this.extraTime.set(post.data.extraTime.extraTimeID, post.data.extraTime);
         this.addExtraTimeToExtraTimeByChecklistID(post.data.extraTime);
      }

      for (const vendorTask of this.vendorTasks) {
         if (vendorTask.checklistID == post.data.task.checklistID) {
            //we need to make the shares for this checklistID available again.
            if (vendorTask.vendorCompleted) {
               vendorTask.shareDisabled = 0;
               vendorTask.vendorCompleted = 0;
            }
         }
      }

      //increment watch variable so watches will fire.  Workaround to deep watchs or watch collections.
      //call this carefully or you will cause digest loops.  It should only be called when key things are added or removed to the task.arr array, such as new tasks, deleted tasks, flagging tasks as deleted etc.
      this.incCompletedTasksWatchVar();

      return post;
   }

   updateTaskDueDateByDays = async (
      checklistID: number,
      days: number,
      incWatch: boolean,
   ) => {
      const post = this.taskPhpApiService.updateTaskDueDateByDays({
         checklistID: checklistID,
         days: days,
      });

      post.then((answer) => {
         if (answer.data.success == true) {
            //set the task's data to properly reflect the change.
            const task = this.getTaskLocalLookup(checklistID);
            if (task !== undefined) {
               //convert days to seconds
               task.checklistDueDate = Number(task.checklistDueDate) + days * 86400 * 1;

               if (task.checklistStartDate !== null && task.checklistStartDate > 0) {
                  task.checklistStartDate =
                     Number(task.checklistStartDate) + days * 86400 * 1;
               }

               task.checklistDueDateCalendarOrder =
                  answer.data.checklistDueDateCalendarOrder;

               task.numOfDueDateChange = Number(task.numOfDueDateChange) + 1;
            }
            //increment watch variable so watches will fire.  Workaround to deep watchs or watch collections.
            if (incWatch == true) {
               this.incTasksWatchVar();
            }
         }
      });

      return post;
   };

   public async resizeTaskStartOn(checklistID: number, days) {
      const post = await this.taskPhpApiService.resizeTaskStartOn({
         checklistID: checklistID,
         days: days,
      });

      if (!post.data.success) return false;
      //set the task's data to properly reflect the change.
      const task = this.getTaskLocalLookup(checklistID);
      if (!task) return false;
      task.numOfDueDateChange = Number(task.numOfDueDateChange) + 1;
      task.checklistStartDate = Number(post.data.newStartDate);
      task.checklistDueDate = Number(post.data.newDueDate);
      task.checklistDueDateCalendarOrder = Number(
         post.data.checklistDueDateCalendarOrder,
      );

      return true;
   }

   /****************************************
    *@function deleteTempFileFromAddComment
    *@purpose
    *@name deleteTempFileFromAddComment
    *@param
    *@return post
    ****************************************/
   //removes a file from the temp files when adding a comment/note
   deleteTempFileFromAddComment = async (fileName) => {
      const post = this.taskPhpApiService.deleteTempFileFromAddComment({
         fileName: fileName,
      });

      return post;
   };
   //PT - Good to go
   /****************************************
    *@function deleteFileFromChecklistEmailCNImages
    *@purpose
    *@name deleteFileFromChecklistEmailCNImages
    *@param
    *@return post
    ****************************************/
   //removes a file from the temp files when adding a comment/note
   deleteFileFromChecklistEmailCNImages = async (fileName, checklistID) => {
      const post = this.taskPhpApiService.deleteFileFromChecklistEmailCNImages({
         fileName: fileName,
         checklistID: checklistID,
      });

      return post;
   };
   //PT - GoodToGo
   /****************************************
    *@function cleanNotesTempFiles
    *@purpose
    *@name cleanNotesTempFiles
    *@param
    *@return post
    ****************************************/
   //removes a file from the temp files when adding a comment/note
   cleanNotesTempFiles = async () => {
      const post = this.taskPhpApiService.cleanNotesTempFiles();

      return post;
   };

   /****************************************
    *@function cleanNotesTempFilesForExternal
    *@purpose
    *@name cleanNotesTempFilesForExternal
    *@param
    *@return post
    ****************************************/
   //removes a file from the temp files when adding a comment/note
   public async cleanNotesTempFilesForExternal(task) {
      const post = this.taskPhpApiService.cleanNotesTempFilesForExternal({
         customerID: task.customerID,
      });

      return post;
   }

   /****************************************
    *@function setItem9FileDescription
    *@purpose
    *@name setItem9FileDescription
    *@param
    *@return post
    ****************************************/
   setItem9FileDescription = async (file) => {
      const post = this.taskPhpApiService.setItem9FileDescription({
         fileID: file.fileID,
         fileDescription: file.fileDescription,
      });
      return post;
   };

   /****************************************
    *@function getTasksMultiAssetsSchedule78NextDue
    *@purpose
    *@name getTasksMultiAssetsSchedule78NextDue
    *@param
    *@return post
    ****************************************/
   getTasksMultiAssetsSchedule78NextDue = async (reoccurID) => {
      const post = this.taskPhpApiService.getTasksMultiAssetsSchedule78NextDue({
         reoccurID: reoccurID,
      });

      return post;
   };

   public async getListOfRelatedTaskTemplatesOnBatchID(checklistID: number): Promise<
      AxiosResponse<{
         success: boolean;
         tasks: Array<{
            checklistID: number;
            assetID: number;
            locationID: number;
            checklistBatchID: number;
            checklistPriorBatchID: number;
            checklistName: string;
         }>;
      }>
   > {
      return this.taskPhpApiService.getListOfRelatedTaskTemplatesOnBatchID({
         checklistID: checklistID,
      });
   }

   /****************************************
    *@function updateListOfRelatedTaskTemplates
    *@purpose
    *@name updateListOfRelatedTaskTemplates
    *@param
    *@return post
    ****************************************/
   updateListOfRelatedTaskTemplates = async (
      sourceChecklistID,
      tasksToChange,
      syncItems,
      syncParts,
      syncSettings,
      syncName,
      syncDescription,
      syncAssignments,
      syncRecurrances,
   ) => {
      const tasksToChangeStringified = JSON.stringify(tasksToChange);

      const post = this.taskPhpApiService.updateListOfRelatedTaskTemplates({
         sourceChecklistID: sourceChecklistID,
         tasksToChange: tasksToChangeStringified,
         syncItems: syncItems,
         syncParts: syncParts,
         syncSettings: syncSettings,
         syncName: syncName,
         syncDescription: syncDescription,
         syncAssignments: syncAssignments,
         syncRecurrances: syncRecurrances,
      });

      return post;
   };

   /****************************************
    *@function disableChecklistShare
    *@purpose
    *@name disableChecklistShare
    *@param
    *@return post
    ****************************************/
   disableChecklistShare = async (shareID, shareTargetEmail) => {
      const post = this.taskPhpApiService.disableChecklistShare({
         shareID: shareID,
         shareTargetEmail: shareTargetEmail,
      });

      post.then((answer) => {
         if (answer.data.success == true) {
            this.vendorTasks.delete(shareID);
            this.incTasksWatchVar();
            this.incCompletedTasksWatchVar();
         }
      });

      return post;
   };

   /****************************************
    *@function checklistShareComplete
    *@purpose
    *@name checklistShareComplete
    *@param
    *@return post
    ****************************************/
   public async checklistShareComplete(link: string): Promise<AxiosResponse> {
      const post = await this.taskPhpApiService.taskShareComplete({
         link: link,
      });

      return post;
   }

   /****************************************
    *@function getSharedExternalUsers
    *@purpose
    *@name getSharedExternalUsers
    *@param
    *@return post
    ****************************************/
   getSharedExternalUsers = async (checklistID) => {
      const post = this.taskPhpApiService.getSharedExternalUsers({
         checklistID: checklistID,
      });

      return post;
   };
   // PT - Good to go
   /****************************************
    *@function updateItemBelongsTo
    *@purpose
    *@name updateItemBelongsTo
    *@param
    *@return post
    ****************************************/
   updateItemBelongsTo = async ({ checklistID, itemID, itemBelongsTo }) => {
      const post = this.taskPhpApiService.updateItemBelongsTo({
         checklistID: checklistID,
         itemID: itemID,
         itemBelongsTo: itemBelongsTo,
      });

      post.then((answer) => {
         if (!answer.data.success) {
            return Promise.reject(new Error(JSON.stringify(answer.data)));
         }
         return answer.data;
      });

      return post;
   };
   //PT - Good to go
   /****************************************
    *@function updateItemWODefault
    *@purpose
    *@name updateItemWODefault
    *@param
    *@return post
    ****************************************/
   updateItemWODefault = async ({
      checklistID,
      itemID,
      itemWOTemplateID,
      itemWOTemplateLocked,
   }) => {
      const post = this.taskPhpApiService
         .updateItemWODefault({
            checklistID: checklistID,
            itemID: itemID,
            itemWOTemplateID: itemWOTemplateID,
            itemWOTemplateLocked: itemWOTemplateLocked ? 1 : 0,
         })
         .then((answer) => {
            if (!answer.data.success) {
               return Promise.reject(new Error(JSON.stringify(answer.data)));
            }
            return answer.data;
         });
      return post;
   };
   //PT - Good to go
   /****************************************
    *@function updateItemWOAssignDefault
    *@purpose
    *@name updateItemWOAssignDefault
    *@param
    *@return post
    ****************************************/
   updateItemWOAssignDefault = async ({
      checklistID,
      itemID,
      itemWOAssignToProfileID,
      itemWOAssignToUserID,
      itemWOAssignToLocked,
      multiUsers,
   }) => {
      const multiUsersStringified = JSON.stringify(multiUsers);

      const post = this.taskPhpApiService
         .updateItemWOAssignDefault({
            checklistID: checklistID,
            itemID: itemID,
            itemWOAssignToProfileID: itemWOAssignToProfileID,
            itemWOAssignToUserID: itemWOAssignToUserID,
            itemWOAssignToLocked: itemWOAssignToLocked ? 1 : 0,
            multiUsers: multiUsersStringified,
         })
         .then((answer) => {
            if (!answer.data.success) {
               return Promise.reject(new Error(JSON.stringify(answer.data)));
            }

            const instructionID = Number(itemID);
            this.instructionStorageSyncService.syncInstruction(instructionID);
            return answer.data;
         });
      return post;
   };

   /****************************************
    *@function getWRCDDefaults
    *@purpose
    *@name getWRCDDefaults
    *@param
    *@return post
    ****************************************/
   public getWRCDDefaults(globalDueDate, defaultDueDateUnit) {
      const obj: any = {};
      obj.userID = -1;
      obj.profileID = -1;
      obj.tags = "";
      obj.priorityObj = { change: false };
      obj.name = "";
      obj.hint = "";
      obj.checklistID = 0;
      obj.dueDate = globalDueDate;
      obj.dueDateUnit = defaultDueDateUnit;
      return obj;
   }

   public async updateTaskTemplateType(
      template: TaskTemplateEntity | Task | TaskEntity,
      checklistTemplate: number,
   ): Promise<AxiosResponse> {
      const post = await this.taskPhpApiService.updateTaskTemplateType({
         checklistID: template.checklistID,
         checklistTemplate: checklistTemplate,
      });
      if (post.data.success !== true) {
         return post;
      }
      template.checklistTemplate = checklistTemplate;
      return post;
   }

   public getFlatAssignmentLookup(): Record<
      string,
      {
         name: string;
         color: string;
         textColorClass: string;
         id: number;
         type?: "profile" | "user";
         show: boolean;
      }
   > {
      const lookup: Record<
         string,
         {
            name: string;
            color: string;
            textColorClass: string;
            id: number;
            type?: "profile" | "user";
            show: boolean;
         }
      > = {};

      const colors = this.colorSetsService.colorSets().arr;
      let count = 0;

      lookup[0] = {
         name: this.lang().Unassigned,
         color: colors[0].colorCode,
         textColorClass: colors[0].textColorClass,
         id: 0,
         show: false,
      };
      count++;

      for (const profile of this.allProfiles) {
         if (colors[count] === undefined) {
            count = 0; //we hit the end of the color array so start reusing colors
         }

         if (profile.profileParent != 7 && profile.profileParent != 2351) {
            lookup[`p${profile.profileID}`] = {
               name: profile.profileDescription ?? "",
               color: colors[count].colorCode,
               textColorClass: colors[count].textColorClass,
               id: profile.profileID,
               type: "profile",
               show: false,
            };
            count++;
         }
      }

      for (const user of this.allUsers) {
         if (colors[count] === undefined) {
            count = 0; //we hit the end of the color array so start reusing colors
         }

         if (
            user.userInternal == 0 &&
            user.userWorkOrderUser == 0 &&
            user.userDefaultLocationID == 0
         ) {
            lookup[`u${user.userID}`] = {
               name: `${user.userFirstName} ${user.userLastName}`,
               color: colors[count].colorCode,
               textColorClass: colors[count].textColorClass,
               id: user.userID,
               type: "user",
               show: false,
            };
            count++;
         }
      }

      return lookup;
   }

   //PT - GoodToGo
   /****************************************
    *@function setShowFlatAssignmentsLookup
    *@purpose
    *@name setShowFlatAssignmentsLookup
    *@param
    *@return post
    ****************************************/
   public setShowFlatAssignmentsLookup(
      lookup: Record<
         string,
         {
            name: string;
            color: string;
            textColorClass: string;
            id: number;
            type?: "profile" | "user";
            show: boolean;
         }
      >,
      data,
   ): Record<
      string,
      {
         name: string;
         color: string;
         textColorClass: string;
         id: number;
         type?: "user" | "profile";
         show: boolean;
      }
   > {
      const legendData = {};
      if (data !== undefined) {
         data.forEach((dataItem) => {
            //u for userlookup, p for profile lookup
            if (dataItem.userID > 0 && lookup[`u${dataItem.userID}`]) {
               legendData[`u${dataItem.userID}`] = {
                  ...lookup[`u${dataItem.userID}`],
                  show: true,
               };
            } else if (dataItem.profileID > 0 && lookup[`p${dataItem.profileID}`]) {
               legendData[`p${dataItem.profileID}`] = {
                  ...lookup[`p${dataItem.profileID}`],
                  show: true,
               };
            } else if (dataItem.userID == 0 && dataItem.profileID == 0) {
               legendData[0] = { ...lookup[0], show: true };
            }
         });
      }
      return legendData;
   }

   //PT - Good to go
   /****************************************
    *@function getlistViewColumnWidthObj
    *@purpose
    *@name getlistViewColumnWidthObj
    *@param
    *@return post
    ****************************************/
   getlistViewColumnWidthObj = (count) => {
      const columnWidthObj = {};
      switch (count) {
         case 0:
            columnWidthObj[0] = 12; //0 columns should never be passed, but if it is here we go...
            break;
         case 1:
            columnWidthObj[0] = 12; // column width 0 always defining the task's name since that has to be the start

            break;
         case 2:
            columnWidthObj[0] = 6; // column width 0 always defining the task's name since that has to be the start
            columnWidthObj[1] = 6;

            break;
         case 3:
            columnWidthObj[0] = 4;
            columnWidthObj[1] = 4;
            columnWidthObj[2] = 4;

            break;
         case 4:
            columnWidthObj[0] = 4;
            columnWidthObj[1] = 3;
            columnWidthObj[2] = 3;
            columnWidthObj[3] = 2;

            break;
         case 5:
            columnWidthObj[0] = 4;
            columnWidthObj[1] = 2;
            columnWidthObj[2] = 2;
            columnWidthObj[3] = 2;
            columnWidthObj[4] = 2;

            break;
         case 6:
            columnWidthObj[0] = 3;
            columnWidthObj[1] = 2;
            columnWidthObj[2] = 2;
            columnWidthObj[3] = 2;
            columnWidthObj[4] = 2;
            columnWidthObj[5] = 1;

            break;
         case 7:
            columnWidthObj[0] = 2;
            columnWidthObj[1] = 2;
            columnWidthObj[2] = 2;
            columnWidthObj[3] = 2;
            columnWidthObj[4] = 2;
            columnWidthObj[5] = 1;
            columnWidthObj[6] = 1;

            break;
         case 8:
            columnWidthObj[0] = 3;
            columnWidthObj[1] = 2;
            columnWidthObj[2] = 2;
            columnWidthObj[3] = 1;
            columnWidthObj[4] = 1;
            columnWidthObj[5] = 1;
            columnWidthObj[6] = 1;
            columnWidthObj[7] = 1;

            break;
         case 9:
            columnWidthObj[0] = 3;
            columnWidthObj[1] = 2;
            columnWidthObj[2] = 1;
            columnWidthObj[3] = 1;
            columnWidthObj[4] = 1;
            columnWidthObj[5] = 1;
            columnWidthObj[6] = 1;
            columnWidthObj[7] = 1;
            columnWidthObj[8] = 1;

            break;
         case 10:
            columnWidthObj[0] = 3;
            columnWidthObj[1] = 1;
            columnWidthObj[2] = 1;
            columnWidthObj[3] = 1;
            columnWidthObj[4] = 1;
            columnWidthObj[5] = 1;
            columnWidthObj[6] = 1;
            columnWidthObj[7] = 1;
            columnWidthObj[8] = 1;
            columnWidthObj[9] = 1;

            break;
         case 11:
            columnWidthObj[0] = 2;
            columnWidthObj[1] = 1;
            columnWidthObj[2] = 1;
            columnWidthObj[3] = 1;
            columnWidthObj[4] = 1;
            columnWidthObj[5] = 1;
            columnWidthObj[6] = 1;
            columnWidthObj[7] = 1;
            columnWidthObj[8] = 1;
            columnWidthObj[9] = 1;
            columnWidthObj[10] = 1;

            break;
         default:
            columnWidthObj[0] = 12;
            break;
      }

      return columnWidthObj;
   };
   public buildAssetNameStringForTask(
      task: TaskTemplateEntity | TaskEntity,
      includeParents: boolean,
   ): string {
      if (!task.assets || task.assets.length === 0) {
         return "";
      }

      const separator = " • ";
      let assetNameStr = separator;

      for (let index = 0; index < task.assets.length; index++) {
         const asset = task.assets[index];

         if (index !== task.assets.length - 1) {
            assetNameStr += separator;
         }

         assetNameStr += includeParents
            ? this.manageAsset.getAssetNameIncludeParents(asset.assetID)
            : this.manageAsset.getAsset(asset.assetID)?.assetName;
         index++;
      }

      return assetNameStr;
   }

   /**
    * @deprecated - This should not be used
    */
   public buildAssetNameStringForTaskLegacy(
      checklistID: number,
      includeParents: boolean,
   ): string {
      const task = this.getTaskLocalLookup(checklistID);
      if (task === undefined) {
         return "";
      }
      if (!task.assetID) {
         return "";
      }

      let assetNameStr = " • ";
      if (includeParents) {
         assetNameStr += this.manageAsset.getAssetNameIncludeParents(task.assetID);

         if (task.extraAssetIDs) {
            let indexCount = 0;
            for (const assetID of task.extraAssetIDs) {
               const extraAsset = this.manageAsset.getAsset(assetID);
               if (extraAsset === undefined) {
                  continue;
               }
               if (indexCount != task.extraAssetIDs.length - 1) {
                  assetNameStr += " • ";
               }
               assetNameStr += this.manageAsset.getAssetNameIncludeParents(
                  extraAsset.assetID,
               );
               indexCount++;
            }
         }
      } else {
         const assetName = this.manageAsset.getAsset(task.assetID)?.assetName;
         if (assetName === undefined) {
            return "";
         }
         assetNameStr += assetName;

         if (task.extraAssetIDs) {
            let indexCount = 0;
            for (const assetID of task.extraAssetIDs) {
               const extraAsset = this.manageAsset.getAsset(assetID);
               if (extraAsset === undefined) {
                  continue;
               }
               if (indexCount != task.extraAssetIDs.length - 1) {
                  assetNameStr += " • ";
               }
               assetNameStr += this.manageAsset.getAsset(extraAsset.assetID)?.assetName;
               indexCount++;
            }
         }
      }
      return assetNameStr;
   }

   /****************************************
    *@function updateSurveyLinkTitle
    *@purpose
    *@name updateSurveyLinkTitle
    *@param
    *@return post
    ****************************************/
   public async updateSurveyLinkTitle(surveyLinkTitle, locationID) {
      const post = this.taskPhpApiService.updateSurveyLinkTitle({
         surveyLinkTitle: surveyLinkTitle,
         locationID: locationID,
      });

      return post;
   }

   /****************************************
    *@function updateSurveyLinkTitle
    *@purpose
    *@name updateSurveyLinkTitle
    *@param
    *@return post
    ****************************************/
   public async updateSurveyLink(surveyLink, locationID) {
      const post = this.taskPhpApiService.updateSurveyLink({
         surveyLink: surveyLink,
         locationID: locationID,
      });

      return post;
   }

   /****************************************
    *@function copyWRLocationSettings
    *@purpose
    *@name copyWRLocationSettings
    *@param
    *@return post
    ****************************************/
   public async copyWRLocationSettings(locationID, locationIDToCopy) {
      const post = this.taskPhpApiService.copyWRLocationSettings({
         locationID: locationID,
         locationIDToCopy: locationIDToCopy,
      });

      return post;
   }

   /****************************************
    * PT - GoodToGo
    *@function recreateWorkRequest
    *@purpose
    *@name recreateWorkRequest
    *@param
    *@return post
    ****************************************/
   public async recreateWorkRequest(
      checklistID: number,
      templateID: number,
      profileID: number,
      userID: number,
      defaultPriorityID: number,
      tags: string,
   ): Promise<AxiosResponse> {
      const post = this.taskPhpApiService.recreateWorkRequest({
         checklistID: checklistID,
         templateID: templateID,
         profileID: profileID,
         userID: userID,
         defaultPriorityID: defaultPriorityID,
         tags: tags,
      });

      return post;
   }

   public async mergeWorkRequest(
      checklistID: number,
      otherChecklistID: number,
   ): Promise<AxiosResponse> {
      const post = this.taskPhpApiService.mergeWorkRequest({
         checklistID: checklistID,
         otherChecklistID: otherChecklistID,
      });

      post.then((answer) => {
         if (answer.data.success == true) {
            this.tasks.delete(otherChecklistID);
            if (answer?.data?.note) {
               const task = this.getTaskLocalLookup(checklistID);
               task?.noteIDs.push(answer.data.note.noteID);
               this.comments.set(answer.data.note.noteID, answer.data.note);
            }
            this.incTasksWatchVar();
         }
      });

      return post;
   }

   /****************************************
    *@function setTaskEmailDefaults
    *@purpose
    *@name setTaskEmailDefaults
    *@param
    *@return post
    ****************************************/
   public async setTaskEmailDefaults(locationID, emailSubject, emailMessage) {
      const post = this.taskPhpApiService.setTaskEmailDefaults({
         locationID: locationID,
         emailSubject: emailSubject,
         emailMessage: emailMessage,
      });

      return post;
   }
   //PT - Good to go
   /****************************************
    *@function setCommentEmailDefaults
    *@purpose
    *@name setCommentEmailDefaults
    *@param
    *@return post
    ****************************************/
   setCommentEmailDefaults = async (locationID, emailSubject, emailMessage) => {
      const post = this.taskPhpApiService.setCommentEmailDefaults({
         locationID: locationID,
         emailSubject: emailSubject,
         emailMessage: emailMessage,
      });

      return post;
   };

   /****************************************
    *@function setTaskReassignmentEmailDefaults
    *@purpose
    *@name setTaskReassignmentEmailDefaults
    *@param
    *@return post
    ****************************************/
   setTaskReassignmentEmailDefaults = async (locationID, emailSubject, emailMessage) => {
      const post = this.taskPhpApiService.setTaskReassignmentEmailDefaults({
         locationID: locationID,
         emailSubject: emailSubject,
         emailMessage: emailMessage,
      });

      return post;
   };

   /****************************************
    *@function setTaskApprovalEmailDefaults
    *@purpose
    *@name setTaskApprovalEmailDefaults
    *@param
    *@return post
    ****************************************/
   setTaskApprovalEmailDefaults = async (locationID, emailSubject, emailMessage) => {
      const post = this.taskPhpApiService.setTaskApprovalEmailDefaults({
         locationID: locationID,
         emailSubject: emailSubject,
         emailMessage: emailMessage,
      });

      return post;
   };

   updateAssetInfoFromCompletion = async (assetInfoFromCompletion, checklistID) => {
      const post = this.taskPhpApiService.updateAssetInfoFromCompletion({
         assetInfoFromCompletion: assetInfoFromCompletion,
         checklistID: checklistID,
      });

      return post;
   };

   /****************************************
    *@function getCalendarLocationLookup
    *@purpose
    *@name getCalendarLocationLookup
    *@param
    *@return post
    ****************************************/
   getCalendarLocationLookup = (): {
      [key: number]: {
         name: string;
         color: string;
         id: number;
         type: "location";
         show: boolean;
      };
   } => {
      const lookup = {};
      const locations = this.manageLocation.getLocations();

      const colors = this.colorSetsService.colorSets().arr;
      let count = 0;

      for (const location of locations) {
         if (colors[count] === undefined) {
            count = 0; //we hit the end of the color array so start reusing colors
         }

         lookup[location.locationID] = {
            name: location.locationName,
            color: colors[count].colorCode,
            id: location.locationID,
            type: "location",
            show: false,
         };
         count++;
      }

      return lookup;
   };

   /****************************************
    *@function setShowCalendarLocationLookup
    *@purpose
    *@name setShowCalendarLocationLookup
    *@param
    *@return post
    ****************************************/
   setShowCalendarLocationLookup = (lookup, data) => {
      if (data !== undefined) {
         data.forEach((dataItem) => {
            if (dataItem.locationID > 0 && lookup[dataItem.locationID]) {
               lookup[dataItem.locationID].show = true;
            }
         });
      }
   };

   public setShowCalendarLocationLookupTasks(
      lookup: {
         [key: number]: {
            name: string;
            color: string;
            id: number;
            type: "location";
            show: boolean;
         };
      },
      tasks: TaskLookup | Array<Schedule>,
   ) {
      if (!tasks) return;
      tasks.forEach((idx, task) => {
         if (task.locationID > 0 && lookup[task.locationID]) {
            lookup[task.locationID].show = true;
         }
      });
   }
   //PT - Good to go
   /** Tell other users that you have a task modal open */
   public async broadcastTaskOpen(
      taskID: number,
      userID: number,
   ): Promise<AxiosResponse<void> | undefined> {
      const connectionId = this.websocketService.getConnectionId();
      if (
         typeof connectionId !== "string" ||
         typeof taskID !== "number" ||
         typeof userID !== "number"
      ) {
         if (this.websocketUrl)
            console.error(
               `Failed to broadcast: connectionID = ${connectionId}, taskID = ${taskID}, userID = ${userID}`,
            );

         return undefined;
      }
      return this.taskPhpApiService.broadcastTaskOpen({
         taskID,
         userID,
         connectionId,
      });
   }
   //PT - Good to go
   /** Tell other users that you closed a task modal */
   public async broadcastTaskClosed(
      taskID: number,
      userID: number,
   ): Promise<AxiosResponse<void> | undefined> {
      const connectionId = this.websocketService.getConnectionId();
      if (
         typeof connectionId !== "string" ||
         typeof taskID !== "number" ||
         typeof userID !== "number"
      ) {
         if (this.websocketUrl)
            console.error(
               `Failed to broadcast: connectionID = ${connectionId}, taskID = ${taskID}, userID = ${userID}`,
            );

         return undefined;
      }
      return this.taskPhpApiService.broadcastTaskClosed({
         taskID,
         userID,
         connectionId,
      });
   }

   /****************************************
    *@function getLocationCycleCountTask
    *@purpose
    *@name getLocationCycleCountTask
    *@param
    *@return post
    ****************************************/
   public async getLocationCycleCountTask(locationID) {
      const post = this.taskPhpApiService.getLocationCycleCountTask({
         locationID: locationID,
      });

      return post;
   }

   public async createInstructionSet(locationID: number): Promise<Task | undefined> {
      const post = await this.taskPhpApiService.createInstructionSet({
         locationID: locationID,
      });

      if (post.data.success) {
         this.tasks.set(post.data.newChk.chk, post.data.newChk.task);
         this.incTasksWatchVar();
      }

      return post.data.newChk.task ?? undefined;
   }

   public async deleteInstructionSet(
      checklistID: number,
      locationID: number,
   ): Promise<{ success: boolean }> {
      const post = await this.taskPhpApiService.deleteInstructionSet({
         checklistID: checklistID,
         locationID: locationID,
      });

      if (!post.data.success) return { success: false };
      this.tasks.delete(checklistID);
      this.incTasksWatchVar();
      return { success: true };
   }

   public getTaskTimeInStatuses(
      task: Task,
      taskStatusLogs: Array<any> | undefined,
   ): { timeSpentInStatusOpen: number; timeSpentInStatusInProgress: number } {
      let timeSpentInStatusOpen = 0;
      let timeSpentInStatusInProgress = 0;
      if (taskStatusLogs === undefined) {
         return { timeSpentInStatusOpen, timeSpentInStatusInProgress };
      }
      for (const log of taskStatusLogs) {
         // Status Open
         if (Number(log.statusID) === 0) {
            timeSpentInStatusOpen += Number(log.duration);
         }

         // Status In Progress
         if (Number(log.statusID) === 1) {
            timeSpentInStatusInProgress += Number(log.duration);
         }
      }

      return { timeSpentInStatusOpen, timeSpentInStatusInProgress };
   }

   public parseSavedAssetInfo(
      assetInfoFromCompletion: string | null | undefined,
   ): Array<any> {
      if (!assetInfoFromCompletion || assetInfoFromCompletion === "") {
         return [];
      }
      const strippedInfo = this.manageUtil.stripTags(assetInfoFromCompletion);

      let assetInfoFromCompletionArr = [];
      try {
         assetInfoFromCompletionArr = JSON.parse(strippedInfo);
      } catch {
         console.error("Failed to parse assetInfoFromCompletion");
      }
      return assetInfoFromCompletionArr;
   }

   public toggleAssetFieldInfoDisplayLegacy(
      asset: Asset,
      checklistID: number,
      taskViewModel?: TaskDataViewerViewModel,
   ): boolean {
      const task = taskViewModel ?? this.getTaskLocalLookup(checklistID);
      if (!task) return false;
      let displayAssetInfo = false;
      // Rely on the local store for now until asset field values are JITified.
      const assetValueIDs = this.manageAsset.getAsset(asset.assetID)?.assetValueIDs;
      if (assetValueIDs?.length && !task?.checklistStatusID) {
         const assetFieldValues = assetValueIDs.map((valueID) =>
            this.manageAsset.getFieldValue(valueID),
         );
         displayAssetInfo =
            assetFieldValues.findIndex((fieldValue) => {
               if (!fieldValue) {
                  return false;
               }
               const field = this.manageAsset.getField(fieldValue?.fieldID);
               return field?.displayOnTasks == 1;
            }) !== -1;
      } else if (task?.checklistStatusID) {
         const assetInfoFromCompletionArr = this.parseSavedAssetInfo(
            task.assetInfoFromCompletion,
         );

         displayAssetInfo =
            assetInfoFromCompletionArr.findIndex(
               (item) => item.assetID == asset?.assetID,
            ) !== -1;
      } else {
         displayAssetInfo = false;
      }
      return displayAssetInfo;
   }

   public async removeMentionedUsersFromNotifications(
      checklistID: number,
      removeAll: 0 | 1,
   ): Promise<AxiosResponse<any>> {
      const post = this.taskPhpApiService.removeMentionedUsersFromNotifications({
         checklistID,
         removeAll,
      });
      return post;
   }

   public popTask(checklistID: number): void {
      const instance = this.modalService.open(PopTask);
      this.paramsService.params = {
         modalInstance: instance,
         resolve: {
            data: {
               checklistID: checklistID,
               editable: true,
            },
         },
      };
   }

   //PT goodToGo
   public async getTaskParts(checklistID: number) {
      const post = await this.taskPhpApiService.getTaskParts({
         checklistID,
      });

      return post.data.parts;
   }

   public deferTaskPermission(id: number): boolean {
      const permittedIDs = [1305, 1776, 1808, 389, 2842, 7286];
      return permittedIDs.includes(id);
   }

   public async sendTaskStatusUpdatedNote(
      checklistID: number,
      oldStatusID: number,
      newStatusID: number,
      noteHiddenFromExternal: number,
   ) {
      const lang = this.lang();

      const statusListIndex = this.manageStatus.getStatusListIndex();
      const newStatusName = statusListIndex[newStatusID].name;
      const oldStatusName = statusListIndex[oldStatusID].name;

      const note = `${lang.ThisTasksStatusWasChanged} ${lang.from} ${oldStatusName} ${lang.to} ${newStatusName}`;

      let sendNotifications = false;
      const task = this.getTaskLocalLookup(checklistID);

      const noteHidden = noteHiddenFromExternal;

      //Make sure we only send status update emails for work requests
      if (task?.checklistTemplateOld === 2 && task?.checklistBatchID === 300112) {
         sendNotifications = true;
      }

      return this.addNote(note, checklistID, noteHidden, 1, sendNotifications);
   }

   public setInternalTaskUserNameLogDefaults(logItem): void {
      if (!this.internalTaskUserExistsByLogItem(logItem)) {
         return;
      }
      this.setTaskUserLogItemNameToLimbleSupport(logItem);
   }

   public getInternalTaskUserNameLogDefaults(logItem): string | undefined {
      if (!this.internalTaskUserExistsByLogItem(logItem)) {
         return undefined;
      }
      return `${this.lang().Limble} ${this.lang().Support} `;
   }

   private setTaskUserLogItemNameToLimbleSupport(logItem): void {
      const lang = this.lang();
      logItem.userFirstName = lang.Limble;
      logItem.userLastName = lang.Support;
      logItem.displayName = `${logItem.userFirstName} ${logItem.userLastName}`;
   }

   private internalTaskUserExistsByLogItem(logItem): boolean {
      return this.allUsers.get(logItem.userID)?.userInternal === 1;
   }

   public getTypeDisplay(
      task: Task | TaskTemplateEntity | TaskEntity,
   ): string | undefined {
      const lang = this.lang();
      //if it is an instance check the template old.
      if (task.checklistTemplate == 0) {
         if (task.checklistTemplateOld == 1) {
            return lang.PM;
         } else if (task.checklistTemplateOld == 2) {
            if (task.checklistBatchID == 300112) {
               return lang.WorkRequest;
            } else if (task.checklistBatchID == 10000) {
               return lang.ProblemReport;
            }
            return lang.UnplannedWO;
         } else if (task.checklistTemplateOld == 4) {
            if (task.checklistBatchID == 20501) {
               return lang.MaterialsRequest;
            }
            return lang.PlannedWO;
         } else if (task.checklistTemplateOld == 5) {
            return lang.CycleCount;
         }
      } else if (task.checklistTemplate == 1) {
         return lang.PM;
      } else if (task.checklistTemplate == 2) {
         return lang.UnplannedWO;
      } else if (task.checklistTemplate == 4) {
         return lang.PlannedWO;
      } else if (task.checklistTemplate == 5) {
         return lang.CycleCount;
      }
      return undefined;
   }

   public addNoteToLocalData(note: Comment, checklistID: number) {
      this.comments.set(note.noteID, note);
      const task = this.getTaskLocalLookup(checklistID);
      if (task === undefined) return;
      task.noteIDs.push(note.noteID);
   }

   public buildNoteDataMapForSingleTaskLegacy(
      task: Task | TaskTemplateEntity | TaskEntity,
   ): Map<number, CommentCalculatedInfo> {
      const noteInfoMap = new Map();
      const comments = "comments" in task ? task.comments : undefined;
      if (comments) {
         for (const comment of comments) {
            const noteInfo = this.getTaskNotesCalculatedInfoLegacy(comment);
            noteInfoMap.set(comment.noteID, noteInfo);
         }
      } else {
         const noteIDs =
            "noteIDs" in task
               ? task.noteIDs
               : task.comments?.map((comment) => comment.noteID);
         if (noteIDs === undefined) {
            return new Map();
         }
         for (const noteID of noteIDs) {
            const note = this.getComment(noteID);
            if (note === undefined) continue;
            const noteInfo = this.getTaskNotesCalculatedInfoLegacy(note);
            noteInfoMap.set(noteID, noteInfo);
         }
      }

      return noteInfoMap;
   }

   public getTaskAssignmentDisplayName(task: Task): string {
      const taskAssignmentInfo = this.getTaskAssignmentInfo(task);

      if (taskAssignmentInfo.profileDescription) {
         return taskAssignmentInfo.profileDescription;
      }
      return taskAssignmentInfo.displayName;
   }

   public startTemplateURL(task: Task | TaskEntity): string {
      const host = `${window.location.protocol}//${window.location.host}`;
      return `${host}/startTaskTemplate/${task.checklistID}/${task.assetID}?m=true`;
   }

   public flipScheduleURL(task: Task | TaskEntity): string {
      const host = `${window.location.protocol}//${window.location.host}`;
      return `${host}/flipPMSchedule/${task.checklistID}?m=true`;
   }

   public async getAssigneeDisplayName(checklistID: number) {
      const post = this.taskPhpApiService.getTaskAssignmentDisplayName({
         checklistID,
      });

      return post;
   }
}
