import { Injectable, signal, type WritableSignal } from "@angular/core";
import type { Chart, TooltipModel, FontSpec } from "chart.js";
import { toFont } from "chart.js/helpers";
import { assert } from "src/app/shared/utils/assert.utils";

@Injectable({ providedIn: "root" })
export class ChartJSHelper {
   public static readonly CSS_CLASS_PRINT = "print";
   private isPrint = false;
   private mouseEvent: MouseEvent | undefined;
   public activePoint: WritableSignal<any> = signal(undefined);

   public composePieChartLegend(
      chart: Chart<any>,
      numberOfColumnsOverride?: number,
   ): string {
      const dataLabels = chart.data.labels;
      if (!chart.height || !chart.width || !dataLabels) {
         return "";
      }
      //The styles for these generated elements live in src/assets/styles/chart-js-legend.scss
      const columnsCalculation = Math.floor((chart.width * 0.7) / 150);

      const numberOfColumns =
         columnsCalculation <= dataLabels.length ? columnsCalculation : dataLabels.length;

      const columnsWithOverride = numberOfColumnsOverride
         ? numberOfColumnsOverride
         : numberOfColumns;

      const legendStyles = `
         grid-template-columns: repeat(${columnsWithOverride}, ${columnsWithOverride}fr);
         `;

      const template = `
      <div class="${this.getPrintAwareCssClassValue("chart-js-legend-container")}" >
         <div class="${this.getPrintAwareCssClassValue("chart-js-pie-legend")}" style="${legendStyles}">
         ${dataLabels
            ?.filter((label) => {
               if (typeof label !== "string") {
                  return false;
               }
               return true;
            })
            .join("")}
         </div>
      </div>
      `;

      return template;
   }

   public getOrCreateLegendList(
      chart: Chart<any>,
      id: string,
      direction: string,
   ): HTMLElement {
      const legendContainer = document.getElementById(id);
      let listContainer = legendContainer?.querySelector("ul");
      assert(legendContainer);
      if (!listContainer) {
         listContainer = document.createElement("ul");
         listContainer.style.display = "flex";
         listContainer.style.flexDirection = direction;
         listContainer.style.alignItems = "center";
         listContainer.style.alignContent = "center";
         listContainer.style.justifyContent = "center";
         listContainer.style.flexWrap = "wrap";
         listContainer.style.alignSelf = "stretch";
         listContainer.style.padding = "0";
         listContainer.style.margin = "0";
         legendContainer.appendChild(listContainer);
      }
      return listContainer;
   }

   public chartHTMLLegend(chart: Chart<any>, ul: HTMLElement): void {
      const items = chart.options?.plugins?.legend?.labels?.generateLabels?.(chart);
      assert(items);
      items.forEach((item) => {
         const li = document.createElement("li");
         li.style.alignItems = "center";
         li.style.cursor = "pointer";
         li.style.display = "flex";
         li.style.marginLeft = "0px";
         li.style.marginBottom = "4px";
         li.onclick = () => {
            assert(typeof item.index === "number");
            chart.toggleDataVisibility(item.index);
            chart.update();
         };
         // Color box
         const boxSpan = document.createElement("span");
         let background = "";
         if (item.background) {
            background = item.background.length > 0 ? item.background : item.strokeStyle;
         } else if (item.fillStyle && item.fillStyle.length > 0) {
            background = item.fillStyle;
         } else if (item.strokeStyle && item.strokeStyle.length > 0) {
            background = item.strokeStyle;
         }
         boxSpan.style.background = background;
         boxSpan.style.borderColor = item.strokeStyle as string;
         boxSpan.style.borderWidth = `${item.lineWidth}px`;
         boxSpan.style.borderRadius = "50%";
         boxSpan.style.display = "inline-block";
         boxSpan.style.flexShrink = "0";
         boxSpan.style.height = "14px";
         boxSpan.style.width = "14px";
         boxSpan.style.marginRight = "4px";

         // Text
         const textContainer = document.createElement("p");
         textContainer.style.color = item.fontColor as string;
         textContainer.style.fontFamily = "Clarity City";
         textContainer.style.fontSize = "12px";
         textContainer.style.fontWeight = "500";
         textContainer.style.marginRight = "16px";
         textContainer.style.padding = "0";
         textContainer.style.textDecoration = item.hidden ? "line-through" : "";

         const text = document.createTextNode(item.text);
         textContainer.appendChild(text);

         li.appendChild(boxSpan);
         li.appendChild(textContainer);
         ul.appendChild(li);
      });
   }

   public composePieChartLegendItem(
      color: string,
      labelText: string,
      subTextItems?: Array<{
         value: string | number;
         preText?: string;
         postText?: string;
      }>,
   ): string {
      //The styles for these generated elements live in src/assets/styles/chart-js-legend.scss
      return `
      <div class="${this.getPrintAwareCssClassValue("chart-js-legend-item-container")}" >
         <span class="${this.getPrintAwareCssClassValue("chart-js-legend-item-text-container")}" >
            <span class="${this.getPrintAwareCssClassValue("chart-js-pie-legend-item-label")}" style="color:${color}" >
               <span class="chart-js-legend-bullet" style="background-color: ${color}">
               </span>
               ${labelText}
            </span>
            ${subTextItems
               ?.map((item, idx, items) => {
                  const isFirst = idx === 0;
                  const isLast = idx === items.length - 1;
                  return this.composeChartLegendSubItem(item, isFirst, isLast);
               })
               .join("")}
         </span>
      </div>
      `;
   }

   public composeChartLegendSubItem(
      subItem: {
         value: string | number;
         preText?: string;
         postText?: string;
      },
      isFirst = false,
      isLast = false,
   ): string {
      const preTextElement = subItem.preText
         ? this.composeLegendSubItemText(subItem.preText, true)
         : "";
      const postTextElement = subItem.postText
         ? this.composeLegendSubItemText(subItem.postText)
         : "";

      // The print version has unique specifications for sub-item display:
      // 1. The sub items are separated by a bullet
      // 2. Sub items that wrap to a new line should start with
      // The following bullet html is added appropriately to fit those specifications.
      const bulletHtml = this.isPrint
         ? '<span class="chart-js-legend-sub-item-bullet" style="background-color: #5A5B5C"></span>'
         : "";

      //The styles for these generated elements live in src/assets/styles/chart-js-legend.scss
      return `
      <span class="${this.getPrintAwareCssClassValue("chart-js-legend-sub-item-container")}">
         ${isFirst ? bulletHtml : ""}
         ${preTextElement}
         <span>
            ${subItem.value}
         </span>
         ${postTextElement}
         ${isLast ? "" : bulletHtml}
      </span>
      `;
   }

   public composeLegendSubItemText(text: string, isPre = false): string {
      //The styles for these generated elements live in src/assets/styles/chart-js-legend.scss
      return `<span class="chart-js-legend-sub-item-text">
                ${text}${isPre ? ":" : ""}
               </span>`;
   }

   public composeChartTooltipSubItem(subItem: {
      value: string | number;
      preText?: string;
      postText?: string;
   }): string {
      const preTextElement = subItem.preText
         ? this.composeTooltipSubItemText(subItem.preText, true)
         : "";
      const postTextElement = subItem.postText
         ? this.composeTooltipSubItemText(subItem.postText)
         : "";
      //The styles for these generated elements live in src/assets/styles/chart-js-legend.scss
      return `
      <span class="chart-js-legend-sub-item-container">
         ${preTextElement}
         <span>
            ${subItem.value}
         </span>
         ${postTextElement}
      </span>
      `;
   }

   public composeTooltipSubItemText(text: string, isPre = false): string {
      //The styles for these generated elements live in src/assets/styles/chart-js-legend.scss
      return `<span class="chart-js-legend-sub-tooltip-item-text">
                ${text}${isPre ? ":" : ""}
               </span>`;
   }

   public composeLineChartLegend(
      chart: Chart<any>,
      colorsArray: Array<{
         pointColor: string;
      }>,
   ): string {
      assert(chart.data.datasets);
      const dataLabels = chart.data.datasets?.map((dataSet, index) =>
         this.composeLineChartLegendItem(
            colorsArray[index].pointColor,
            dataSet.label ?? "",
         ),
      );
      const template = `
      <div class="chart-js-legend-container" >
         <div class="chart-js-line-or-bar-legend">
         ${dataLabels
            ?.filter((label) => {
               if (typeof label !== "string") {
                  return false;
               }
               return true;
            })
            .join("")}
         </div>
      </div>
      `;
      return template;
   }

   public composeLineChartLegendItem(color: string, labelText: string) {
      return `
            <span class="chart-js-line-or-bar-legend-item-label">
               <span class="chart-js-legend-bullet" style="background-color: ${color}">
               </span>
               ${labelText}
            </span>
      `;
   }

   public createCustomTooltip(
      chart: Chart<any>,
      tooltipModel: TooltipModel<any>,
      tooltipItems: Array<{ color: string; text: string; xAxisLabel?: boolean }>,
   ) {
      let tooltipEl = document.getElementById("chartjs-tooltip");
      if (!tooltipEl) {
         tooltipEl = document.createElement("div");
         tooltipEl.id = "chartjs-tooltip";
         tooltipEl.innerHTML = "<table></table>";
         tooltipEl.style.backgroundColor = "rgba(0,0,0,0.75)";
         tooltipEl.style.borderRadius = "10px";
         tooltipEl.style.borderColor = "rgba(0, 0, 0, 0.8)";
         tooltipEl.style.opacity = "0.8";
         tooltipEl.style.borderWidth = "thin";
         tooltipEl.style.borderStyle = "solid";
         tooltipEl.style.zIndex = "1001";
         tooltipEl.style.color = "#ffffff";
         tooltipEl.style.textAlign = "left";
         document.body.appendChild(tooltipEl);
      }

      // Hide if no tooltip
      if (tooltipModel.opacity === 0) {
         tooltipEl.style.opacity = "0";
         return;
      }

      const isPieChart = (chart.config as any)._config.type === "doughnut";

      // Set caret Position
      tooltipEl.classList.remove("above", "below", "no-transform");
      if (tooltipModel.yAlign) {
         tooltipEl.classList.add(tooltipModel.yAlign);
      } else {
         tooltipEl.classList.add("no-transform");
      }
      if (tooltipModel.xAlign) {
         tooltipEl.classList.add(tooltipModel.xAlign);
      }

      // Add xAxisLabel (typically the date) as the first item if it's not a pie chart
      if (!isPieChart) {
         tooltipItems.unshift({
            text: tooltipModel.title[0],
            color: "",
            xAxisLabel: true,
         });
      }

      const tooltipHtml = tooltipItems
         .map(({ color, text, xAxisLabel }) => {
            return this.buildTooltipBody(text, color, xAxisLabel);
         })
         .join("");

      const innerHtml = `<tbody>${tooltipHtml}</tbody>`;

      const tableRoot = tooltipEl.querySelector("table");
      assert(tableRoot);
      tableRoot.innerHTML = innerHtml;

      const position = chart.canvas.getBoundingClientRect();
      const bodyFont = toFont(tooltipModel.options.bodyFont as FontSpec);

      tooltipEl.style.opacity = "1";
      tooltipEl.style.position = "absolute";
      tooltipEl.style.left = `${position.left + window.pageXOffset + tooltipModel.caretX}px`;
      tooltipEl.style.top = `${position.top + window.pageYOffset + tooltipModel.caretY}px`;
      tooltipEl.style.font = bodyFont.string;
      tooltipEl.style.padding = `${tooltipModel.options.padding as number}px ${tooltipModel.options.padding as number}px`;
      tooltipEl.style.pointerEvents = "none";
   }

   private buildTooltipBody(
      bodyText: string,
      color: string,
      xAxisLabel: boolean | undefined,
   ): string {
      let style = "";

      if (!xAxisLabel) {
         style += `; border-color:${color}`;
         style += `; background-color:${color}`;
         style += "; border-width: 2px";
         style += "; border-radius: 50%";
         style += "; width: 15px";
         style += "; height: 15px;";
         style += "; display: inline-block;";
         style += "; margin: 0px 4px 0px 0px;";
         style += "; vertical-align: middle;";
      }
      const span = `<span style="${style}"></span>`;
      return xAxisLabel
         ? `<tr><td style="font-weight: bold;">${bodyText}</td></tr><tr><tr/><tr><tr/>`
         : `<tr><td>${span}${bodyText}</td></tr>`;
   }

   private getPrintAwareCssClassValue(cssClassBase: string): string {
      const ret = [cssClassBase];
      if (this.isPrint) {
         ret.push(ChartJSHelper.CSS_CLASS_PRINT);
      }
      return ret.join(" ");
   }

   public onHoverHandler(event, chart): void {
      const point = event.native
         ? chart.getElementsAtEventForMode(
              event.native,
              "point",
              { intersect: false },
              true,
           )[0]
         : null;
      if (point) {
         const element = chart.getDatasetMeta(point.datasetIndex).data[point.index];
         chart.canvas.style.cursor = element ? "pointer" : "default";
      } else {
         chart.canvas.style.cursor = "default";
      }
   }

   public handleHoverLine(chart: Chart<any>) {
      const {
         ctx,
         chartArea: { top, bottom },
      } = chart;
      ctx.save();

      chart.getDatasetMeta(0).data.forEach((dataPoint) => {
         if (dataPoint.active === true) {
            this.setVerticalHoverLine(ctx, dataPoint, top, bottom);
            this.setActivePoint(chart);
            chart.canvas.style.cursor = this.activePoint() ? "pointer" : "default";
         }
      });
   }

   private setVerticalHoverLine(
      ctx: CanvasRenderingContext2D,
      dataPoint: { x: number },
      top: number,
      bottom: number,
   ) {
      ctx.beginPath();
      ctx.strokeStyle = "#808080";
      ctx.lineWidth = 0.5;
      ctx.moveTo(dataPoint.x, top);
      ctx.lineTo(dataPoint.x, bottom);
      ctx.stroke();
   }

   private setActivePoint(chart: Chart<any>) {
      if (!this.mouseEvent) {
         this.activePoint.set(undefined);
         return;
      }

      const activePoints = chart.getElementsAtEventForMode(
         this.mouseEvent,
         "nearest",
         { intersect: true },
         true,
      );

      const isActivePoint = activePoints[0]?.element.active;
      // Set the active point which we reference from dashboardLineGraph.element.component.ts
      this.activePoint.set(isActivePoint ? activePoints[0].element : undefined);
   }

   public trackMouseEvent(event: MouseEvent) {
      this.mouseEvent = event;
   }

   public setIsPrint(): void {
      this.isPrint = true;
   }
}
