<ng-container *translate="let t">
   <button class="column-sort-header" (click)="onSortDirectionClicked()">
      <span
         class="column-sort-header__name"
         [limbleHtml]="column()?.headerNameKey ?? ''"
      ></span>
      <span class="column-sort-header__icon">
         @if (column()?.isSortable ?? true) {
            @if (sortDirection() === TableSortDirection.ASC) {
               <lim-ui-icon icon="arrowUpShortWide" />
            } @else if (sortDirection() === TableSortDirection.DESC) {
               <lim-ui-icon icon="arrowDownWideShort" />
            } @else if (sortDirection() === TableSortDirection.UNSET) {
               <lim-ui-icon icon="arrowUpArrowDown" />
            }
         }
      </span>
   </button>
</ng-container>
