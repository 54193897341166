import { HttpClient } from "@angular/common/http";
import { inject, Injectable } from "@angular/core";
import { type Observable, throwError } from "rxjs";
import { ManageUser } from "src/app/users/services/manageUser";
import { environment } from "src/environments/environment";

export interface AssetTemplateAccountSettings {
   templatesStatus: "disabled" | "optional" | "required";
}

@Injectable({
   providedIn: "root",
})
export class AssetTemplateSettingsService {
   private readonly http = inject(HttpClient);
   private readonly manageUser = inject(ManageUser);
   private readonly templatesStatus: AssetTemplateAccountSettings = {
      templatesStatus: "optional",
   };

   // Get request on lumberyard - Note it is currently hard getting this value from lumberyard as optional. See lumberyard templates.go for more info.
   public getAccountSettings(): Observable<AssetTemplateAccountSettings> {
      return this.http.get<AssetTemplateAccountSettings>(
         `${environment.servicesURL()}/assets/template/account-settings`,
         { withCredentials: true },
      );
   }

   // Patch request on lumberyard - Note it is currently hard setting this value from lumberyard as required. See lumberyard templates.go for more info.
   public updateAccountSettings(
      newStatus: AssetTemplateAccountSettings,
   ): Observable<AssetTemplateAccountSettings> {
      // Validate newStatus
      if (!["disabled", "optional", "required"].includes(newStatus.templatesStatus)) {
         return throwError(() => new Error("Invalid templatesStatus value"));
      }

      return this.http.patch<AssetTemplateAccountSettings>(
         `${environment.servicesURL()}/assets/template/account-settings`,
         newStatus,
         { withCredentials: true },
      );
   }
}
