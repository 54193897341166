import { inject, Injectable, type Signal } from "@angular/core";
import { injectQuery } from "@tanstack/angular-query-experimental";
import type { Observable } from "rxjs";
import type { DataViewerFilter } from "src/app/shared/data-viewer";
import { DataViewerStore } from "src/app/shared/data-viewer/data-viewer.store";
import type {
   RequestOptions,
   SortParams,
} from "src/app/shared/services/flannel-api-service/api.models";
import { LaunchFlagsService } from "src/app/shared/services/launch-flags/launch-flags.service";
import { createQuerySelectors } from "src/app/shared/services/query/query-selectors";
import type { RequestStatus } from "src/app/shared/services/query/query-selectors.models";
import type { WrSubmissionDataViewerModel } from "src/app/tasks/components/shared/services/w-r-submissions-view-model/models/data-viewer-model";
import type {
   WorkRequestSubmissionEntity,
   WorkRequestSubmissionEntityFilters,
} from "src/app/tasks/components/shared/services/work-request-submissions-api/work-request-submissions-api.models";
import { WorkRequestSubmissionsApiService } from "src/app/tasks/components/shared/services/work-request-submissions-api/work-request-submissions-api.service";
import { WorkRequestSubmissionViewModelBuilderService } from "src/app/tasks/services/work-request-submission-view-model-factory/builders/work-request-submission-view-model-builder.service";

export type WorkRequestsData = {
   items: Signal<WrSubmissionDataViewerModel[]>;
   status: Signal<RequestStatus>;
   totalItems: Signal<number>;
   page: Signal<number>;
   sort: Signal<string | SortParams | SortParams[]>;
};

/**
 * This service is responsible for handling all of the business logic of querying, paginating, and transforming the work requests data needed for work-requests-inbox component
 */
@Injectable({ providedIn: "root" })
export class WorkRequestsDataService {
   private readonly workRequestSubmissionsApiService = inject(
      WorkRequestSubmissionsApiService,
   );
   private readonly viewModel = inject(WorkRequestSubmissionViewModelBuilderService);
   private readonly launchFlagService = inject(LaunchFlagsService);

   protected readonly dataViewerStore = new DataViewerStore();
   private readonly requestOptions = this.dataViewerStore.requestOptions;

   private readonly submissionsQuery = injectQuery(() => {
      return this.workRequestSubmissionsApiService.getQueryListOptions(
         this.requestOptions(),
         this.launchFlagService.getFlagPromise("reject-work-requests", false),
      );
   });

   private readonly querySelectors = createQuerySelectors(
      this.submissionsQuery,
      (entities) => this.getViewModels(entities),
   );

   public setRequestOptions(requestOptions: {
      locationIDs?: number[];
      page?: number;
      pageSize?: number;
      sort?: SortParams;
      search?: string;
      filters?: DataViewerFilter[];
   }) {
      if (requestOptions.locationIDs) {
         this.dataViewerStore.setFixedFilters([
            { locationIDs: requestOptions.locationIDs },
         ]);
      }

      if (requestOptions.page) {
         this.dataViewerStore.setPage(requestOptions.page);
      }

      if (requestOptions.pageSize) {
         this.dataViewerStore.setPageSize(requestOptions.pageSize);
      }

      if (requestOptions.sort) {
         this.dataViewerStore.setSort(requestOptions.sort);
      }

      if (requestOptions.search) {
         this.dataViewerStore.setSearch(requestOptions.search);
      }

      if (requestOptions.filters) {
         this.dataViewerStore.setUserFilters(requestOptions.filters);
      }
   }

   public setSearch(search: string) {
      this.dataViewerStore.setSearch(search);
   }

   public addFilter(filter: DataViewerFilter) {
      this.dataViewerStore.addUserFilter(filter);
   }

   public removeFilter(filter: DataViewerFilter) {
      this.dataViewerStore.removeUserFilter(filter);
   }

   public getWorkRequestSubmission(
      id: number,
      requestOptions?: Partial<RequestOptions<WorkRequestSubmissionEntityFilters>>,
   ): Observable<WorkRequestSubmissionEntity> {
      // TODO: This should eventually read from a given store, and fetch from the API if not found in the store.
      return this.workRequestSubmissionsApiService.getById(id, requestOptions);
   }

   public async getWorkRequestsCount(locationID: number): Promise<{
      pending: number;
      rejected: number;
      approved: number;
   }> {
      if (!locationID) {
         return {
            pending: 0,
            rejected: 0,
            approved: 0,
         };
      }

      const pendingTotal = await this.workRequestSubmissionsApiService.getTotal({
         filters: {
            locationIDs: [locationID],
            statuses: ["pending"],
         } satisfies WorkRequestSubmissionEntityFilters,
      });
      return {
         pending: pendingTotal,
         rejected: 0, // TODO: we need to update this once the new count API endpoint is ready
         approved: 0, // TODO: we need to update this once the new count API endpoint is ready
      };
   }

   public getData(): WorkRequestsData {
      return {
         items: this.querySelectors.items,
         status: this.querySelectors.status,
         totalItems: this.querySelectors.totalItems,
         page: this.dataViewerStore.page,
         sort: this.dataViewerStore.sort,
      };
   }

   private getViewModels(workRequestSubmissions: WorkRequestSubmissionEntity[]) {
      return workRequestSubmissions.map((submission) =>
         this.viewModel.buildDataViewerViewModel(submission),
      );
   }

   public refresh() {
      this.submissionsQuery.refetch();
   }
}
