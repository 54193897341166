import { CommonModule, NgClass } from "@angular/common";
import {
   Component,
   computed,
   inject,
   input,
   Input,
   type OnDestroy,
   type OnInit,
} from "@angular/core";
import {
   BadgeComponent,
   DropdownComponent,
   DropdownDividerComponent,
   DropdownItemComponent,
   IconComponent,
   LimbleHtmlDirective,
   MinimalIconButtonComponent,
   PopoverDirective,
   TableButtonComponent,
   TooltipDirective,
   UpsellPopover,
} from "@limblecmms/lim-ui";
import { ManageLang } from "src/app/languages/services/manageLang";
import { AlertService } from "src/app/shared/services/alert.service";
import { ManageObservables } from "src/app/shared/services/manageObservables";
import { PriorityButtonLegacyComponent } from "src/app/tasks/components/priority-button-legacy/priority-button-legacy.component";
import { AssetHierarchyListItemComponent } from "src/app/tasks/components/shared/components/tasks-data-viewer/components/asset-hierarchy-list-item/asset-hierarchy-list-item.component";
import type { TaskDataViewerViewModel } from "src/app/tasks/components/shared/components/tasks-data-viewer/task-data-viewer.model";
import { TasksFacadeService } from "src/app/tasks/components/shared/services/tasks-facade/tasks-facade.service";
import { StatusButtonLegacyComponent } from "src/app/tasks/components/status-button-legacy/status-button-legacy.component";
import { ManageStatus } from "src/app/tasks/services/manageStatus";
import { CredService } from "src/app/users/services//creds/cred.service";

@Component({
   selector: "task-name-cell",
   imports: [
      CommonModule,
      BadgeComponent,
      DropdownComponent,
      DropdownDividerComponent,
      PriorityButtonLegacyComponent,
      StatusButtonLegacyComponent,
      DropdownItemComponent,
      MinimalIconButtonComponent,
      IconComponent,
      LimbleHtmlDirective,
      TooltipDirective,
      PopoverDirective,
      UpsellPopover,
      TableButtonComponent,
      AssetHierarchyListItemComponent,
      NgClass,
   ],
   templateUrl: "./task-name-cell.component.html",
   styleUrls: ["./task-name-cell.component.scss"],
})
export class TaskNameCellComponent implements OnInit, OnDestroy {
   @Input({ required: true }) public task!: TaskDataViewerViewModel;
   @Input() public dataLogSection!: string;
   public readonly showAsset = input<boolean>(true);

   /**
    * This property is intended for the printable version of the corresponding custom dashboard task list widget.
    * It prevents unnecessary interactive elements from rendering in the print-only context.
    */
   @Input() public printOnly?: boolean;

   @Input() public searchHint: string | undefined = undefined;

   public dropdownClicked = false;

   public isAllowedToDelete: boolean = false;

   private readonly manageLang = inject(ManageLang);

   protected readonly lang = computed(() => this.manageLang.lang() ?? {});

   public statusListSub;
   public statusList: any = [];
   public statusListIndex = {};

   private readonly alertService = inject(AlertService);
   private readonly credService = inject(CredService);
   private readonly manageStatus = inject(ManageStatus);
   private readonly manageObservables = inject(ManageObservables);
   protected readonly facade = inject(TasksFacadeService);

   public isSuperUser = this.credService.checkCredGlobal(30);
   protected isInModal = this.facade.getIsInModal();

   protected readonly taskAsset = computed(() => {
      if (!this.task?.assets?.[0]) return undefined;

      return this.task.assets[0];
   });

   public ngOnInit(): void {
      this.statusListSub = this.manageObservables.setSubscription("statusList", () => {
         this.statusList = [];
         setTimeout(() => {
            this.statusList = this.manageStatus.getStatusList();
            this.statusListIndex = this.manageStatus.getStatusListIndex();
         }, 1);
      });

      this.isAllowedToDelete = this.facade.isAllowedToDelete(this.task);
   }

   public ngOnDestroy(): void {
      this.manageObservables.removeManySubscriptions([this.statusListSub]);
   }
   public async viewTask(): Promise<void> {
      await this.facade.viewTask(this.task.checklistID);
   }

   public onDropdownClick($event): void {
      this.dropdownClicked = true;

      if (this.task?.isCompleted) {
         this.completedTaskWarning();
      }
      $event.stopPropagation();
   }

   public completedTaskWarning(): void {
      this.alertService.addAlert(this.lang().completedTaskWarning, "warning", 10000);
   }

   public deleteTask(): void {
      this.facade.deleteTask(this.task);
   }

   public async onDuplicate($event: any): Promise<void> {
      $event.stopPropagation();
      await this.facade.duplicate(this.task);
   }

   public onAssetClick(): void {
      //This even should open the Asset on desktop and the Task on mobile
      //The below combined with a flag passed into asset-hierarchy-list-item ensures that happens
      if (this.facade.isMobile) {
         this.facade.viewTask(this.task.checklistID);
      }
   }

   protected onChangeTaskType(): void {
      const { isCompleted, checklistID, locationID } = this.task;
      this.facade.changeTaskType(isCompleted, checklistID, locationID);
   }
}
