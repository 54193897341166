import { computed, inject, Injectable } from "@angular/core";
import { injectQuery } from "@tanstack/angular-query-experimental";
import type { WidgetDefinition } from "src/app/dashboards/custom-dashboards/customDashboard.types";
import { ManageLocation } from "src/app/locations/services/manageLocation";
import { MultiCurrencyAvailabilityService } from "src/app/purchasing/currency/services/availability/multi-currency-availability.service";
import { CurrencyDisplayService } from "src/app/purchasing/currency/services/display/currency-display.service";
import type { TaskEntity } from "src/app/tasks/components/shared/services/tasks-api";
import type { Task } from "src/app/tasks/types/task.types";
import { AccountSettingsQueriesService } from "src/app/users/services/queries/account-settings-queries.service";

@Injectable({ providedIn: "root" })
export class DashboardCurrencyService {
   private readonly manageLocation = inject(ManageLocation);
   private readonly currencyDisplayService = inject(CurrencyDisplayService);
   private readonly accountSettingsQueries = inject(AccountSettingsQueriesService);
   private readonly isMultiCurrencyEnabled = inject(MultiCurrencyAvailabilityService)
      .isEnabled;
   private readonly accountCurrencyQuery = injectQuery(() =>
      this.accountSettingsQueries.currencyDetail(),
   );

   public getTaskCurrencyCode(task: Task | TaskEntity): string {
      return this.getCurrencyCodeByLocationID(task.locationID);
   }

   public getCurrencyCodeByLocationID(locationID: number): string {
      return this.currencyDisplayService.evaluateSignal(
         computed(() => this.accountCurrencyQuery.data()?.currencyCode),
         computed(() => this.manageLocation.getLocation(locationID)?.currencyCode),
         this.isMultiCurrencyEnabled,
      )();
   }

   public isShowingLocationCurrencyByWidgetDefinition(
      widgetDef: WidgetDefinition,
   ): boolean {
      const allSameCurrency = this.getIfTheWidgetIsAllTheSameCurrency(widgetDef);

      if (allSameCurrency || widgetDef.locations.length === 1) {
         return true;
      }

      return false;
   }

   private getIfTheWidgetIsAllTheSameCurrency(widgetDef: WidgetDefinition): boolean {
      if (widgetDef) {
         const firstCurrencyCode = this.getTheFirstWidgetLocationCurrencyCode(widgetDef);
         return widgetDef.locations.every((locationID) => {
            const locationCurrencyCode = this.getCurrencyCodeByLocationID(locationID);
            return locationCurrencyCode === firstCurrencyCode;
         });
      }

      return false;
   }

   private getTheFirstWidgetLocationCurrencyCode(
      widgetDef: WidgetDefinition,
   ): string | null {
      const firstLocation = this.manageLocation.getLocation(widgetDef.locations[0]);
      const firstCurrencyCode = firstLocation?.currencyCode ?? "";
      return this.currencyDisplayService.evaluateSignal(
         computed(() => this.accountCurrencyQuery.data()?.currencyCode),
         computed(() => firstCurrencyCode),
         this.isMultiCurrencyEnabled,
      )();
   }
}
