import { Component, computed, effect, inject, input, type OnInit } from "@angular/core";
import { toSignal } from "@angular/core/rxjs-interop";
import {
   IconButtonComponent,
   ModalService,
   PanelComponent,
   TooltipDirective,
} from "@limblecmms/lim-ui";
import { injectQuery } from "@tanstack/angular-query-experimental";
import { filter, startWith } from "rxjs";
import { PopAsset } from "src/app/assets/components/popAssetModal/popAsset.modal.component";
import { TranslateDirective } from "src/app/languages/i18n/translate.directive";
import { DataViewerStore } from "src/app/shared/data-viewer/data-viewer.store";
import type { TableColumn } from "src/app/shared/empowered/base/table/table.models";
import { SearchComponent } from "src/app/shared/empowered/compound/search/search.component";
import { DataViewerComponent } from "src/app/shared/empowered/gears/data-viewer/data-viewer.component";
import type { RequestFilter } from "src/app/shared/services/flannel-api-service";
import { createQuerySelectors } from "src/app/shared/services/query/query-selectors";
import { TasksDataViewerOptionsFactoryService } from "src/app/tasks/components/shared/components/tasks-data-viewer";
import {
   TaskColumnDefinitionsFactoryService,
   type TaskViewTypes,
} from "src/app/tasks/components/shared/services/task-column-definitions-factory";
import { TaskViewModelFactoryService } from "src/app/tasks/components/shared/services/task-view-model-factory/task-view-model-factory.service";
import { TasksApiService } from "src/app/tasks/components/shared/services/tasks-api";
import { TasksFacadeService } from "src/app/tasks/components/shared/services/tasks-facade/tasks-facade.service";

@Component({
   selector: "tasks-panel",
   providers: [DataViewerStore],
   imports: [
      DataViewerComponent,
      IconButtonComponent,
      PanelComponent,
      SearchComponent,
      TooltipDirective,
      TranslateDirective,
   ],
   templateUrl: "./tasks-panel.component.html",
   styleUrl: "./tasks-panel.component.scss",
})
export class TasksPanelComponent implements OnInit {
   private readonly columnFactory = inject(TaskColumnDefinitionsFactoryService);
   private readonly tasksFacade = inject(TasksFacadeService);
   private readonly tasksDataViewer = inject(TasksDataViewerOptionsFactoryService);
   private readonly state = inject(DataViewerStore);
   private readonly tasksApi = inject(TasksApiService);
   private readonly viewModelFactory = inject(TaskViewModelFactoryService);
   private readonly modalService = inject(ModalService);

   public columnsViewType = input.required<TaskViewTypes>();

   /**
    * Any request filters that are needed in the panel.
    * @example
    * ```typescript
    *  [
    *    {
    *      statuses: [2],
    *      locationIDs: [this.locationID],
    *      assetIDs: [this.assetID()],
    *      taskTypes: [TaskType.PlannedMaintenances],
    *     },
    *  ]
    * ```
    */
   public requestFilters = input<Array<RequestFilter>>([]);

   /**
    * This will hide the export button
    */
   public canExport = input(false);

   /**
    * This is a message to display when the user does not have the correct permissions to view the content
    */
   public restrictedContentLabel = input("");

   /**
    * This is a flag to determine if the user has restricted permissions
    */
   public hasRestrictedPermissions = input(false);

   /**
    * This is an input that fires a change in the request options when its value changes
    */
   public includeChildAssets = input(false);

   public columns = computed(
      () =>
         this.columnFactory.getByViewType(
            this.columnsViewType(),
            undefined,
            true,
         ) as Array<TableColumn>,
   );
   public hasColumns = computed(() => this.columns().length > 0);

   private readonly modalCloseEvents$ = this.modalService.closeEvents.pipe(
      filter(
         () => this.modalService.getActiveModal()?.componentInstance instanceof PopAsset,
      ),
      startWith(0),
   );

   private readonly modalCloseSignal = toSignal(this.modalCloseEvents$);

   private readonly tasksQuery = injectQuery(() => {
      // eslint-disable-next-line typescript/no-unused-vars -- needed for listening
      const modalCloseVal = this.modalCloseSignal();
      return this.tasksApi.getQueryListOptions(this.state.requestOptions());
   });

   private readonly querySelectors = createQuerySelectors(this.tasksQuery, (entities) =>
      this.viewModelFactory.getViewModelList(entities),
   );

   public readonly items = this.querySelectors.items;
   public readonly totalItems = this.querySelectors.totalItems;
   public readonly status = this.querySelectors.status;
   public readonly page = this.state.page;

   public constructor() {
      effect(() => {
         const includeChildAssets = this.includeChildAssets();
         queueMicrotask(() => {
            this.setIncludeChildAssetsParam(includeChildAssets);
         });
      });
   }

   public ngOnInit() {
      const sort = this.tasksDataViewer.getSortBy(this.columnsViewType());

      this.state.setSort(sort);
      this.state.setColumns(this.columns());
      this.state.setFixedFilters([...this.requestFilters()]);
      if (this.includeChildAssets()) {
         this.setIncludeChildAssetsParam(true);
      }
   }

   public handleExportToExcel() {
      const requestOptions = this.state.requestOptionsWithoutPagination();
      this.tasksFacade.downloadExcel(requestOptions);
   }

   protected onSearchChange(search: string) {
      this.state.setSearch(search);
   }

   private setIncludeChildAssetsParam(shouldInclude: boolean) {
      if (shouldInclude) {
         this.state.addParam({ includeChildAssets: shouldInclude });
      } else {
         this.state.removeParam("includeChildAssets");
      }
   }
}
