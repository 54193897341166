import type { OnInit, Signal } from "@angular/core";
import { ChangeDetectorRef, Component, computed, inject, signal } from "@angular/core";
import { FormsModule } from "@angular/forms";
import {
   AlertComponent,
   BasicModalHeaderComponent,
   DatePickerInputComponent,
   DropdownInlineTextComponent,
   DropdownItemComponent,
   IconButtonComponent,
   IconComponent,
   InputWithPrefixComponent,
   isMobile,
   LimbleHtmlDirective,
   MinimalButtonComponent,
   MinimalIconButtonComponent,
   ModalBodyComponent,
   ModalComponent,
   ModalDirective,
   ModalService,
   PaginationComponent,
   PanelComponent,
   SearchBoxComponent,
   TooltipDirective,
} from "@limblecmms/lim-ui";
import {
   Chart,
   type ChartData,
   type ChartOptions,
   LinearScale,
   LineController,
   LineElement,
   PointElement,
   type TooltipModel,
} from "chart.js";
import clone from "rfdc";
import { AssetFieldTypeID } from "src/app/assets/schemata/fields/types/asset-field-type.enum";
import { ManageAsset } from "src/app/assets/services/manageAsset";
import type { AssetField } from "src/app/assets/types/field/asset-field.types";
import type { AssetFieldValue } from "src/app/assets/types/field/value/value.types";
import { ManageDashboard } from "src/app/dashboards/manageDashboard";
import { ChartJSHelper } from "src/app/dashboards/widgets/chartJSHelper";
import { ManageLang } from "src/app/languages/services/manageLang";
import { CurrencySymbolPipe } from "src/app/purchasing/currency/pipes/currency-symbol.pipe";
import { DEFAULT_CURRENCY } from "src/app/purchasing/currency/utils/default-currency-code";
import { Confirm } from "src/app/shared/components/global/confrimModal/confirm.modal.component";
import { NoSearchResults } from "src/app/shared/components/global/noSearchResults/noSearchResults.element.component";
import { PickDate } from "src/app/shared/components/global/pickDateModal/pickDate.modal.component";
import { SortColumn } from "src/app/shared/components/global/sortColumnModal/sortColumn.element.component";
import { ContenteditableDirective } from "src/app/shared/directives/contentEditable/contentEditable.directive";
import { BetterDatePipe } from "src/app/shared/pipes/betterDate.pipe";
import { LocaleCurrencyPipe } from "src/app/shared/pipes/locale-currency/locale-currency.pipe";
import { orderBy, OrderByPipe } from "src/app/shared/pipes/orderBy.pipe";
import { SliceArray } from "src/app/shared/pipes/sliceArray.pipe";
import { AlertService } from "src/app/shared/services/alert.service";
import { BetterDate } from "src/app/shared/services/betterDate";
import { ManageFilters } from "src/app/shared/services/manageFilters";
import { ManageUtil } from "src/app/shared/services/manageUtil";
import { ParamsService } from "src/app/shared/services/params.service";
import { assert } from "src/app/shared/utils/assert.utils";
import type { Lookup } from "src/app/shared/utils/lookup";
import { PopTask } from "src/app/tasks/components/popTaskModal/popTask.modal.component";
import { ManageTask } from "src/app/tasks/services/manageTask";
import type { TaskUser } from "src/app/tasks/types/task-user/task-user.types";
import { CredService } from "src/app/users/services/creds/cred.service";

Chart.register(LineController, LineElement, LinearScale, PointElement);

const deepClone = clone();

interface FieldValueHistoryItem {
   oldValue: any;
   timestamp: number;
   checklistID: number;
   userID: number;
   historyID: number;
   valueChangeInfo: string;
   checklistName?: string;
   checklistTemplateOld?: number | null;
   displayUserName?: string;
   enableEdit?: boolean;
}

@Component({
   selector: "view-asset-item-history",
   templateUrl: "./viewAssetItemHistory.modal.component.html",
   styleUrls: ["./viewAssetItemHistory.modal.component.scss"],
   imports: [
      ModalComponent,
      ModalDirective,
      BasicModalHeaderComponent,
      ModalBodyComponent,
      AlertComponent,
      PanelComponent,
      SearchBoxComponent,
      IconButtonComponent,
      TooltipDirective,
      MinimalButtonComponent,
      SortColumn,
      NoSearchResults,
      LimbleHtmlDirective,
      FormsModule,
      ContenteditableDirective,
      DatePickerInputComponent,
      DropdownInlineTextComponent,
      DropdownItemComponent,
      IconComponent,
      MinimalIconButtonComponent,
      PaginationComponent,
      BetterDatePipe,
      OrderByPipe,
      SliceArray,
      LocaleCurrencyPipe,
      CurrencySymbolPipe,
      InputWithPrefixComponent,
   ],
})
export class ViewAssetItemHistory implements OnInit {
   public message;
   public title;
   public histories: FieldValueHistoryItem[] = [];
   public allHistories: FieldValueHistoryItem[] = [];
   public assetFieldValue: AssetFieldValue | undefined;
   public viewAs;
   public canGraph;
   public graphHistory;
   public graphPoints;
   public allHistoriesLength;
   public sortBind;
   public searchBar;
   public noSearchResults;
   public myOptions: ChartOptions;
   public graphColors;
   public myData: ChartData<"line">;
   public graphHeight;
   public graphWidth;
   public lineOrBarGraph1;
   public hint;
   public resolve;
   public modalInstance;
   public lineChartFillColor;
   public page = 1;
   public itemsPerPage = 10;
   public superUser;
   public currentValue;
   public currentHistory;
   public currentEl;
   public assetField: AssetField | undefined;
   public fieldOptions;
   protected currencyCode: Signal<string> = signal(DEFAULT_CURRENCY);

   private readonly manageTask = inject(ManageTask);
   private readonly manageUtil = inject(ManageUtil);
   private readonly manageDashboard = inject(ManageDashboard);
   private readonly manageFilters = inject(ManageFilters);
   private readonly manageAsset = inject(ManageAsset);
   private readonly alertService = inject(AlertService);
   private readonly paramsService = inject(ParamsService);
   private readonly modalService = inject(ModalService);
   private readonly ref = inject(ChangeDetectorRef);
   private readonly betterDate = inject(BetterDate);
   private readonly credService = inject(CredService);
   private readonly manageLang = inject(ManageLang);
   private readonly chartJSHelper = inject(ChartJSHelper);

   protected readonly AssetFieldTypeID = AssetFieldTypeID;
   private readonly localeCurrencyPipe = new LocaleCurrencyPipe();

   protected readonly lang = computed(() => this.manageLang.lang() ?? {});
   public constructor() {
      this.myOptions = this.buildChartOptions();
      const defaults = {
         tension: 0.5,
         pointRadius: 3,
         fill: true,
         xAxisID: "xAxes",
         yAxisID: "yAxes",
         data: [],
      };
      this.myData = {
         labels: [],
         datasets: [{ ...defaults }],
      };
   }

   public ngOnInit() {
      const params = this.paramsService.params;
      if (params?.resolve) {
         this.resolve = params.resolve;
      }
      if (params?.modalInstance) {
         this.modalInstance = params.modalInstance;
      }

      this.assetFieldValue = this.resolve.field;

      if (this.assetFieldValue === undefined) {
         throw new Error("assetFieldValue is required but is undefined.");
      }

      assert(this.assetFieldValue?.fieldID);
      this.assetField = this.manageAsset.getField(this.assetFieldValue.fieldID);

      this.message = this.resolve.message;
      this.title = this.resolve.title;
      this.histories = this.resolve.data || [];
      this.currencyCode = this.resolve.currencyCode;
      this.viewAs = "list";
      this.canGraph = false;
      this.graphHistory = [];
      this.graphPoints = 25;
      this.superUser = this.credService.checkCredGlobal(
         this.credService.Permissions.ManageRoles,
      );
      this.buildData(true);

      // If the field is a dropdown, create an object of its options.
      const assetField = this.assetField;
      if (assetField) {
         const parsedOptionsJson = this.manageAsset.getParsedOptionsJson(assetField);
         if (assetField.fieldTypeID === 7 && parsedOptionsJson) {
            this.fieldOptions = parsedOptionsJson;
         }
      }
   }

   pageChanged = () => {
      this.ref.detectChanges();
   };

   private buildData(setToGraph: boolean) {
      const users: Lookup<"userID", TaskUser> = this.manageTask.getAllUsers();
      for (const history of this.histories) {
         if (users.get(history.userID)) {
            if (users.get(history.userID)?.userInternal === 1) {
               history.displayUserName = this.lang().System;
            } else {
               history.displayUserName = `${users.get(history.userID)?.userFirstName} ${
                  users.get(history.userID)?.userLastName
               }`;
            }
         }

         if (this.assetField?.fieldTypeID === AssetFieldTypeID.Date && history.oldValue) {
            history.oldValue = new Date(history.oldValue);
         }
      }

      this.allHistories = this.histories;
      this.allHistoriesLength = this.histories.length;
      this.graphHistory = this.allHistories.slice(0, this.graphPoints);

      this.sortBind = "-timestamp";

      // this.lineChartFillColor = "#289e49";
      if (setToGraph) {
         this.checkGraphHistory();
      }
   }

   checkGraphHistory = () => {
      let isAllNumeric = true;
      if (this.assetField?.fieldTypeID != 2 && this.graphHistory.length > 1) {
         for (const history of this.graphHistory) {
            if (!history.oldValue || isNaN(history.oldValue)) {
               isAllNumeric = false;
            }
         }
      } else {
         isAllNumeric = false;
      }
      if (isAllNumeric) {
         this.canGraph = true;
         this.createLineGraph();
         this.changeViewAs("graph");
      }
   };

   runFilter = () => {
      this.histories = this.allHistories.filter((history) =>
         this.manageFilters.searchObjValuesForString(history, this.searchBar),
      );

      this.noSearchResults = this.histories.length === 0;
   };

   popTask = (checklistID: number): void => {
      const instance = this.modalService.open(PopTask);
      this.paramsService.params = {
         modalInstance: instance,
         resolve: {
            data: {
               checklistID: checklistID,
               editable: false,
               preview: true,
            },
         },
      };
   };

   public setAssetFieldData(history: any, assetFieldValue: AssetFieldValue): void {
      assetFieldValue.valueContent = Number(history.oldValue);
   }

   downloadAssetInfoHistory = () => {
      const objs: any = [];

      for (const history of this.histories) {
         const obj: any = {};
         obj.Date = this.betterDate.formatBetterDate(
            history.timestamp * 1000,
            "dateTimeWithSeconds",
         );
         obj.Value = history.oldValue;
         obj.User = history.displayUserName;
         if (history.checklistID > 0) {
            obj.Task = `${history.checklistName} - #${history.checklistID}`;
         } else {
            obj.Task = "";
         }
         objs.push(obj);
      }

      const name = `${this.lang().HistoryOfChanges} - ${this.assetField?.fieldName}`;
      this.manageUtil.objToExcel(objs, name, `${name}.xlsx`);
   };

   changeViewAs = (view) => {
      this.viewAs = view;
      if (view === "graph") {
         setTimeout(() => {
            this.buildAssetGraphChart();
         }, 50);
      }
   };

   private buildChartOptions(): ChartOptions {
      return {
         // Sets the chart to be responsive
         responsive: true,
         plugins: {
            legend: {
               display: false,
            },
            tooltip: {
               enabled: false,
               mode: "index",
               intersect: false,
               position: "nearest",
               titleFont: {
                  size: 14,
               },
               bodyFont: {
                  size: 14,
               },
               external: (context) => {
                  if (!isMobile()) {
                     this.createCustomLineGraphTooltip(context.chart, context.tooltip);
                  }
               },
            },
         },
         aspectRatio: 0.8,
         maintainAspectRatio: false,
         scales: {
            xAxes: {
               grid: {
                  display: true,
                  color: "rgba(0,0,0,.05)",
                  lineWidth: 1,
               },
            },
            yAxes: {
               grid: {
                  display: true,
                  color: "rgba(0,0,0,.05)",
                  lineWidth: 1,
               },
            },
         },
         onHover: (event, _chartElement, chart) => {
            this.chartJSHelper.onHoverHandler(event, chart);
         },
      };
   }

   setInitialAssetFieldHistoryData = () => {
      const label = this.assetField?.fieldName;
      const colorKey = 0 % 15; //this makes sure we loop through all possible colors
      const defaults = {
         tension: 0.5,
         pointRadius: 3,
         fill: true,
         xAxisID: "xAxes",
         yAxisID: "yAxes",
         data: [],
      };
      const obj = {
         label: `${label}`,
         labelNoHTML: `${label}`,
         fillColor: this.graphColors[colorKey].fillColor,
         strokeColor: this.graphColors[colorKey].strokeColor,
         pointColor: this.graphColors[colorKey].pointColor,
         borderColor: this.graphColors[colorKey].pointColor,
         backgroundColor: this.graphColors[colorKey].fillColor,
         pointBackgroundColor: "#289e49",
         pointStrokeColor: this.graphColors[colorKey].pointStrokeColor,
         pointHighlightFill: this.graphColors[colorKey].pointHighlightFill,
         pointHighlightStroke: this.graphColors[colorKey].pointHighlightStroke,
         dataSrc: [],
         ...defaults,
      };
      this.myData.datasets.push(obj);
   };

   setGraphData = () => {
      this.myData.labels = [];
      this.myData.datasets[0].data = [];
      const sortedHistory = orderBy(this.graphHistory, "timestamp");
      for (const history of sortedHistory) {
         this.myData.labels.push(
            this.betterDate.formatBetterDate(
               history.timestamp * 1000,
               "dateTimeWithSeconds",
            ),
         );

         this.myData.datasets[0].data.push(history.oldValue);
         this.myData.datasets[0].borderColor = "#289e49";
      }
   };

   buildAssetGraphChart = () => {
      const element = document.getElementById("assetHistoryGraph");
      if (element?.offsetParent) {
         let heightOffset = element.offsetParent.clientWidth / 10000; //the smaller the containing div the more we need to amplify the graph Height
         heightOffset = heightOffset * 20;
         const graphHeight = 220 / heightOffset;

         let widthOffset = element.offsetParent.clientHeight / 10000; //the smaller the containing div the more we need to amplify the graph Height
         widthOffset = widthOffset * 20;
         const graphWidth = 320 / widthOffset;

         this.graphHeight = graphHeight;
         this.graphWidth = graphWidth;

         if (this.lineOrBarGraph1 !== undefined) {
            this.lineOrBarGraph1.destroy();
         }

         const ctx = document.getElementById("lineOrBarGraph1");
         if (ctx instanceof HTMLCanvasElement) {
            this.lineOrBarGraph1 = new Chart(ctx, {
               type: "line",
               data: this.myData,
               options: this.myOptions,
            });
         }
      }
   };

   createLineGraph = () => {
      this.graphColors = deepClone(this.manageDashboard.getGraphColors());
      this.myData = {
         labels: [],
         datasets: [],
      };
      for (const key in this.graphColors) {
         this.graphColors[key].fillColor = this.lineChartFillColor; //since it is a line graph we have to overwrite the fillColor to be slightly transparent.
      }
      this.myOptions = this.buildChartOptions();
      this.hint = this.assetField?.fieldName;
      this.setInitialAssetFieldHistoryData();
      this.setGraphData();
   };

   updateGraphData = (numberOfPoints) => {
      this.graphPoints = numberOfPoints;
      this.graphHistory = this.allHistories.slice(0, this.graphPoints);

      this.setGraphData();
      this.buildAssetGraphChart();
   };

   toggleEnableEdit = (history) => {
      if (history?.enableEdit) {
         history.enableEdit = false;
         if (this.currentEl) {
            this.currentEl.removeEventListener("keydown", this.onMouseClick);
         }
         this.currentValue = null;
         this.currentEl = null;
         this.currentHistory = null;
         this.buildData(true);
         this.changeViewAs("list");
      } else {
         if (this.currentEl) {
            this.currentEl.removeEventListener("keydown", this.onMouseClick);
         }
         for (const tempHist of this.histories) {
            tempHist.enableEdit = false;
         }
         this.currentValue = history.oldValue;
         this.currentHistory = history;
         history.enableEdit = true;
         setTimeout(() => {
            this.currentEl = document.getElementById("inputField");
            if (this.currentEl) {
               this.currentEl.focus();
               this.currentEl.addEventListener("keydown", this.onMouseClick);
            }
         }, 10);
      }
   };

   onMouseClick = (event) => {
      if (event.keyCode == 13) {
         this.editAssetItemInfoHistory(this.currentHistory);
      }
   };

   editAssetItemInfoHistory = async (history) => {
      if (history.oldValue == this.currentValue) {
         return;
      }
      const result: any = await this.manageAsset.editAssetItemInfoHistory(
         history.historyID,
         history.oldValue,
      );
      if (result?.data?.success) {
         this.alertService.addAlert(this.lang().successMsg, "success", 1000);
      } else {
         this.alertService.addAlert(this.lang().errorMsg, "danger", 6000);
      }
   };

   editAssetItemTimestampHistory = (history) => {
      const instance = this.modalService.open(PickDate);

      this.paramsService.params = {
         modalInstance: instance,
         resolve: {
            message: this.lang().changeDueDateMessage,
            title: this.lang().ChangeThisAssetItemHistoryDate,
            buttonText: this.lang().Change,
            currentDate: history.timestamp,
            data: {
               viewTimeOfDay: true,
               setNoTimeOfDayOffset: true,
               setTimeFromCurrentDate: true,
               parentOfDate: "log",
            },
         },
      };

      instance.result.then(async (modalResult) => {
         if (modalResult.date) {
            const date = new Date(modalResult.date);
            const newTime = date.getTime() / 1000;
            if (history.timestamp == newTime) {
               return;
            }
            const result: any = await this.manageAsset.editAssetItemTimestampHistory(
               history.historyID,
               newTime,
            );
            if (result?.data?.success) {
               history.timestamp = newTime;
               this.alertService.addAlert(this.lang().successMsg, "success", 1000);
            } else {
               this.alertService.addAlert(this.lang().errorMsg, "danger", 6000);
            }
         }
      });
   };

   deleteAssetItemInfoHistory = async (history) => {
      const instance = this.modalService.open(Confirm);
      this.paramsService.params = {
         modalInstance: instance,
         resolve: {
            message: this.lang().DeleteAssetFieldValueHistoryConfirmMsg,
            title: this.lang().DeleteFieldHistoryValue,
         },
      };

      const result = await instance.result;
      // If they said no, then we don't delete the history item.
      if (result != 1) {
         return;
      }
      const result2: any = await this.manageAsset.deleteAssetItemInfoHistory(
         history.historyID,
      );
      if (result2?.data?.success) {
         this.alertService.addAlert(this.lang().successMsg, "success", 1000);
         this.histories = this.histories.filter(
            (tempHistory) => tempHistory.historyID != history.historyID,
         );
         this.buildData(true);
         this.changeViewAs("list");
      } else {
         this.alertService.addAlert(this.lang().errorMsg, "danger", 6000);
      }
   };

   close = () => {
      this.modalInstance.close(0);
   };

   private createCustomLineGraphTooltip(
      chart: Chart<any>,
      tooltipModel: TooltipModel<any>,
   ) {
      const dataIndex = tooltipModel.dataPoints?.[0].dataIndex;
      const tooltipItems =
         chart.config.data?.datasets?.map((series) => {
            let dataNumberFormatted = series?.data?.[dataIndex];
            if (
               this.assetField?.fieldTypeID &&
               this.assetField?.fieldTypeID === this.AssetFieldTypeID.Currency
            ) {
               dataNumberFormatted = this.localeCurrencyPipe.transform(
                  dataNumberFormatted,
                  this.currencyCode(),
               );
            }

            return {
               text: `${series?.label}: ${dataNumberFormatted}`,
               color: String(series.borderColor),
            };
         }) ?? [];

      this.chartJSHelper.createCustomTooltip(chart, tooltipModel, tooltipItems);
   }
}
