import { HttpClient } from "@angular/common/http";
import { inject, Injectable } from "@angular/core";
import { injectIsFetching, injectQuery } from "@ngneat/query";
import { lastValueFrom, map, type Observable } from "rxjs";
import { environment } from "src/environments/environment";

@Injectable({ providedIn: "root" })
export class RefreshApiService {
   protected readonly query = injectQuery();
   protected readonly httpClient = inject(HttpClient);

   private readonly QUERY_GC_TIME = 604800000; // 1 week

   readonly #isFetching = injectIsFetching();
   public isBackgroundFetchingCompletedTasks$: Observable<boolean> = this.#isFetching({
      queryKey: ["completedTasks"],
   }).result$.pipe(map((fetchCount) => Boolean(fetchCount > 0)));

   protected completedTasksUrl: string | undefined;

   public getCompletedTasks(page: number, limit: number) {
      this.completedTasksUrl ??=
         localStorage.getItem("use-streamed-completed-tasks") === "yes"
            ? `${environment.flannelUrl}/tasks/completedTasksStreamed`
            : `${environment.flannelUrl}/tasks/completedTasks`;

      return this.query({
         queryKey: ["completedTasks", { page, limit }],
         queryFn: async () =>
            lastValueFrom(
               this.httpClient.get<Array<any>>(this.completedTasksUrl ?? "", {
                  params: {
                     limit: limit.toString(),
                     page: page.toString(),
                  },
               }),
            ),
         gcTime: this.QUERY_GC_TIME,
      });
   }
}
