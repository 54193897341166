import {
   Component,
   computed,
   inject,
   input,
   type OnInit,
   type Signal,
} from "@angular/core";
import { TableSortDirection } from "@empowered/base/table/table.models";
import {
   getExtraColumns,
   getParams,
} from "@empowered/base/table/utils/column-table-parser";
import { CardComponent, IconComponent, TooltipDirective } from "@limblecmms/lim-ui";
import { ManageLang } from "src/app/languages/services/manageLang";
import { PartTasksDataService } from "src/app/parts/components/shared/part-tasks-data-service.service.ts/part-tasks-data-sevice.service";
import type { PartEntity } from "src/app/parts/components/shared/parts-api-service/parts-api.models";
import { PartColumnDefinitionFactoryService } from "src/app/parts/components/shared/services/part-column-definitions-factory/part-column-definitions-factory.service";
import type { Column } from "src/app/shared/data-viewer/column-builder";
import { DataViewerStateService } from "src/app/shared/data-viewer/data-viewer-state.service";
import { BetterDecimalPipe } from "src/app/shared/pipes/betterDecimal.pipe";
import type { TaskDataViewerViewModel } from "src/app/tasks/components/shared/components/tasks-data-viewer/task-data-viewer.model";
import { TasksDataViewerComponent } from "src/app/tasks/components/shared/components/tasks-data-viewer/tasks-data-viewer.component";

@Component({
   selector: "part-reports-usage-table",
   templateUrl: "./part-reports-usage-table.component.html",
   styleUrls: ["./part-reports-usage-table.component.scss"],
   imports: [
      CardComponent,
      IconComponent,
      TooltipDirective,
      TasksDataViewerComponent,
      BetterDecimalPipe,
   ],
})
export class PartReportsUsageTableComponent implements OnInit {
   private readonly manageLang = inject(ManageLang);
   private readonly columnDefinitionsFactory = inject(PartColumnDefinitionFactoryService);
   private readonly dataViewerState = inject(DataViewerStateService);
   private readonly partTasksDataService = inject(PartTasksDataService);

   public readonly part = input.required<PartEntity>();

   protected readonly lang = computed(() => this.manageLang.lang() ?? {});

   protected readonly columns: Array<Column> =
      this.columnDefinitionsFactory.getByPartReportsTasks();

   protected readonly tasks: Signal<Array<TaskDataViewerViewModel>> =
      this.partTasksDataService.tasks;

   protected readonly tasksTotal: Signal<number> = this.partTasksDataService.tasksTotal;

   protected readonly isLoading = this.partTasksDataService.isLoading;
   protected readonly currencySymbol = this.partTasksDataService.currencySymbol;

   public ngOnInit() {
      this.initializeDataViewer();
   }

   public initializeDataViewer(): void {
      const requestParams = getParams(this.columns);
      this.dataViewerState.setParams(requestParams);

      const extraColumns = getExtraColumns(this.columns);
      this.dataViewerState.setExtraColumns(`${extraColumns},parts`);
      this.dataViewerState.setBaseFilters([
         { statuses: [2], partIDs: [this.part().partID] },
      ]);
      this.dataViewerState.setSort({
         field: "checklistName",
         direction: TableSortDirection.ASC,
      });
   }
}
