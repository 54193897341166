import { input, Component, inject, computed, signal } from "@angular/core";
import { toObservable, toSignal } from "@angular/core/rxjs-interop";
import { FormsModule } from "@angular/forms";
import type { SelectOption } from "@empowered/base/select/select.models";
import {
   AlertComponent,
   BasicModalHeaderComponent,
   LimbleHtmlDirective,
   LimUiModalRef,
   ModalBodyComponent,
   ModalComponent,
   PanelComponent,
   SecondaryButtonComponent,
   ModalFooterComponent,
   PrimaryButtonComponent,
} from "@limblecmms/lim-ui";
import { switchMap } from "rxjs";
import { TranslationService } from "src/app/languages/translation/translation.service";
import { ContenteditableDirective } from "src/app/shared/directives/contentEditable/contentEditable.directive";
import { SelectComponent } from "src/app/shared/empowered/base/select/select.component";
import { AlertService } from "src/app/shared/services/alert.service";
import { WorkRequestsFacadeService } from "src/app/tasks/components/shared/services/work-requests-facade/work-requests-facade.service";
import type { WorkRequestRejectReasonEntity } from "src/app/tasks/components/shared/services/wr-reject-reason-api/wr-reject-reason-api.models";
import { WrRejectReasonApiService } from "src/app/tasks/components/shared/services/wr-reject-reason-api/wr-reject-reason-api.service";
import type { DeclineWorkRequestModalViewModel } from "src/app/tasks/components/workRequests/decline-work-request-modal/decline-work-request-modal.types";

export interface RejectedReasonViewModel {
   reasonID: number;
   reason: string;
}

@Component({
   selector: "decline-work-request-modal",
   templateUrl: "./decline-work-request-modal.component.html",
   styleUrls: ["./decline-work-request-modal.component.scss"],
   standalone: true,
   imports: [
      ModalComponent,
      ModalBodyComponent,
      BasicModalHeaderComponent,
      PanelComponent,
      AlertComponent,
      SecondaryButtonComponent,
      LimbleHtmlDirective,
      ModalFooterComponent,
      ContenteditableDirective,
      PrimaryButtonComponent,
      FormsModule,
      SelectComponent,
   ],
})
export class DeclineWorkRequestModalComponent {
   public readonly declineWorkRequestModalViewModel =
      input.required<DeclineWorkRequestModalViewModel>();

   public readonly modalRef: LimUiModalRef<DeclineWorkRequestModalComponent, boolean> =
      inject(LimUiModalRef);

   protected readonly workRequestsFacadeService = inject(WorkRequestsFacadeService);
   protected readonly reasonApiService = inject(WrRejectReasonApiService);
   protected readonly translationService = inject(TranslationService);
   protected readonly alertService = inject(AlertService);

   protected readonly errorMsg = signal("");

   protected readonly selectedReasonError = signal(false);

   private readonly locationID$ = toObservable(
      computed(() => this.declineWorkRequestModalViewModel().locationID),
   );
   private readonly rejectionReasons$ = this.locationID$.pipe(
      switchMap((locationID) =>
         this.reasonApiService.get([{ locationIDs: [locationID] }]),
      ),
   );

   protected readonly rejectionReasonsResponse = toSignal(this.rejectionReasons$);

   protected readonly selectedReason = signal<SelectOption | undefined>(undefined);

   protected readonly declineForm = signal<{
      reason: SelectOption | undefined;
      customReason: string;
   }>({
      reason: undefined,
      customReason: "",
   });

   protected readonly rejectedReasons = computed(() => {
      return (
         this.rejectionReasonsResponse()?.map((reason) => ({
            label: reason.reason,
            value: reason.reasonID,
         })) ?? []
      );
   });

   protected readonly declineCustomReason = signal("");

   protected readonly selectedReasonEffect = computed(() => {
      const reason = this.selectedReason();
      if (reason) {
         this.selectedReasonError.set(false);
         this.errorMsg.set("");
         this.alertService.clearAllAlerts();
      }
   });

   //TODO: Make lang string for modal.
   protected readonly title = computed(() => {
      return `${this.translationService.i18n().t("DeclineRequest")} - ${this.declineWorkRequestModalViewModel().workRequestTitle}`;
   });

   protected readonly declineRequest = computed(() => {
      return this.translationService.i18n().t("DeclineRequest");
   });

   protected readonly reason = computed(() => {
      return this.translationService.i18n().t("SelectAReason");
   });

   protected readonly message = computed(() => {
      return this.translationService.i18n().t("DeclineWorkRequestMessageLabel");
   });

   protected readonly messageHint = computed(() => {
      return this.translationService.i18n().t("DeclineWorkRequestMessageHint");
   });

   protected readonly declineRequestMessage = computed(() => {
      return this.translationService.i18n().t("DeclineWorkRequestBanner");
   });

   protected readonly backToRequest = computed(() => {
      return this.translationService.i18n().t("BackToRequest");
   });

   protected readonly declineRequestButton = computed(() => {
      return this.translationService.i18n().t("DeclineRequest");
   });

   private getReasonViewModel(
      reason: WorkRequestRejectReasonEntity,
   ): RejectedReasonViewModel {
      return {
         reasonID: reason.reasonID,
         reason: reason.reason,
      };
   }

   protected selectReason() {
      this.selectedReasonError.set(false);
      this.errorMsg.set("");
      this.alertService.clearAllAlerts();
   }

   protected close(): void {
      this.modalRef.close(false);
   }

   private validateSubmission() {
      if (this.selectedReason()) {
         this.selectedReasonError.set(false);
         this.errorMsg.set("");
      } else {
         this.selectedReasonError.set(true);
         this.errorMsg.set(
            `${this.translationService.i18n().t("PleaseSelectAReason")}<br /><br />`,
         );
         return false;
      }

      return true;
   }

   protected async submit(): Promise<void> {
      const validSubmission = this.validateSubmission();
      if (!validSubmission) {
         this.alertService.addAlert(this.errorMsg(), "danger", 6000);
         return;
      }

      const selectedReason: RejectedReasonViewModel = {
         reasonID: this.selectedReason()?.value ?? 0,
         reason: this.selectedReason()?.label ?? "",
      };

      if (!selectedReason) {
         return;
      }

      const success = await this.workRequestsFacadeService.declineWorkRequest(
         this.declineWorkRequestModalViewModel().workRequestSubmissionID,
         selectedReason.reason,
         this.declineCustomReason(),
      );
      if (success) {
         this.modalRef.close(true);
      } else {
         this.alertService.addAlert(
            this.translationService.i18n().t("errorMsg"),
            "danger",
            6000,
         );
      }
   }
}
