import { CommonModule } from "@angular/common";
import { Component } from "@angular/core";
import { BaseCellRendererComponent } from "@empowered/base/table/components/cells-renderers/base-cell-renderer.component.ts/base-cell-renderer.component";
import type { ColumnKeys } from "src/app/shared/data-viewer/column-builder";

interface SubmittedByCellData {
   [ColumnKeys.SUBMITTED_BY]: string;
}

@Component({
   selector: "submitted-by-cell-renderer-component",
   imports: [CommonModule],
   templateUrl: "./submitted-by-cell-renderer.component.html",
})
export class SubmittedByCellRendererComponent extends BaseCellRendererComponent<SubmittedByCellData> {}
