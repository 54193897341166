import { inject, Injectable } from "@angular/core";
import { isAndroidApp } from "@limblecmms/lim-ui";
import Median from "median-js-bridge";
import { ManageFiles } from "src/app/files/services/manageFiles";

@Injectable({
   providedIn: "root",
})
export class MobileFileService {
   private readonly manageFiles = inject(ManageFiles);

   /**
    * View a file in the native app.
    * See https://median.co/docs/download-file
    */
   public viewFile(url: string, ext?: string): void {
      const isImage = ext && this.manageFiles.imageExts.includes(ext);
      if (isImage) {
         this.manageFiles.createImageViewer(url);
         return;
      }

      if (isAndroidApp()) {
         // Median.share.downloadFile does not work on Android
         window.location.href = url;
         return;
      }

      Median.share.downloadFile({
         url,
         open: true,
      });
   }
}
