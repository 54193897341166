<lim-ui-modal [showWatermark]="true">
   <lim-ui-basic-modal-header [title]="title()" (closeModal)="close()" />
   <lim-ui-modal-body>
      <lim-ui-panel>
         <label class="decline-request-header control-label medium-spacing">{{
            declineRequest()
         }}</label>
         <lim-ui-alert [alertType]="'info'" class="alert-banner">
            <span [limbleHtml]="declineRequestMessage()"></span>
         </lim-ui-alert>
         <label class="control-label medium-spacing secondary-text"
            >{{ reason() }}<span class="red-color"> *</span></label
         >
         <e-select
            class="required"
            [options]="rejectedReasons()"
            [placeholder]="reason()"
            [(ngModel)]="selectedReason"
            [required]="true"
            (ngModelChange)="selectReason()"
         />
         <label class="control-label medium-spacing secondary-text">{{
            message()
         }}</label>
         <div class="custom-reason-text-field-container">
            <div
               blurOnKeyboardClose
               class="medium-text-field custom-reason-text-field"
               type="text"
               [(ngModel)]="declineCustomReason"
               limbleContentEditable
            ></div>
         </div>
      </lim-ui-panel>
   </lim-ui-modal-body>
   <lim-ui-modal-footer>
      <lim-ui-secondary-button (click)="close()">{{
         backToRequest()
      }}</lim-ui-secondary-button>
      <lim-ui-primary-button (click)="submit()">{{
         declineRequestButton()
      }}</lim-ui-primary-button>
   </lim-ui-modal-footer>
</lim-ui-modal>
