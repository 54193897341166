import { HttpClient } from "@angular/common/http";
import { inject, Injectable } from "@angular/core";
import type { Temporal } from "@js-temporal/polyfill";
import type { AxiosResponse } from "axios/dist/axios";
import axios from "axios/dist/axios";
import moment from "moment";
import type { Observable } from "rxjs";
import { ManageAsset } from "src/app/assets/services//manageAsset";
import { TranslationService } from "src/app/languages/translation/translation.service";
import { ManageLocation } from "src/app/locations/services/manageLocation";
import type { Recurrence } from "src/app/schedules/recurrence.types";
import type { Schedule } from "src/app/schedules/schedule.types";
import { ManageUtil } from "src/app/shared/services/manageUtil";
import type { SeasonType } from "src/app/shared/types/general.types";
import { LimbleMap } from "src/app/shared/utils/limbleMap";
import type { ScheduleEntity } from "src/app/tasks/components/shared/services/schedules-api/schedules-api.models";
import type {
   TaskSeasons,
   TaskTemplateEntity,
} from "src/app/tasks/components/shared/services/task-templates-api/task-templates-api.models";
import { ManageTask } from "src/app/tasks/services/manageTask";
import type { Task } from "src/app/tasks/types/task.types";
import { environment } from "src/environments/environment";

@Injectable({ providedIn: "root" })
export class ManageSchedule {
   private readonly nextCreatedAtForRecurrences = new LimbleMap<number, number>();
   private readonly manageLocation = inject(ManageLocation);
   private readonly manageTask = inject(ManageTask);
   private readonly manageAsset = inject(ManageAsset);
   private readonly manageUtil = inject(ManageUtil);
   private readonly i18n = inject(TranslationService).i18n;
   private readonly http = inject(HttpClient);
   private readonly baseUrl: string = environment.servicesURL();

   public async disableSchedules(
      recurrence: Recurrence,
   ): Promise<AxiosResponse & { recurrence: Recurrence }> {
      const answer = await axios.post(
         "phpscripts/manageSchedule.php?action=disableSchedules",
         { reoccurID: recurrence.reoccurID },
      );
      return { ...answer, recurrence };
   }

   public async enableSchedules(
      recurrence: Recurrence,
   ): Promise<AxiosResponse & { recurrence: Recurrence }> {
      const answer = await axios.post(
         "phpscripts/manageSchedule.php?action=enableSchedules",
         { reoccurID: recurrence.reoccurID },
      );
      return { ...answer, recurrence };
   }

   public async deleteRecurrence(
      reoccurID: number,
      checklistIDForType7Reoccur?: number,
   ): Promise<AxiosResponse> {
      return axios.post("phpscripts/manageSchedule.php?action=deleteReoccurance", {
         reoccurID: reoccurID,
         checklistIDForType7Reoccur: checklistIDForType7Reoccur,
      });
   }

   /**
    * @deprecated
    * Use `updateRecurrenceSpawnDate` instead. This method relies on the
    * `findLocationDay230` method of ManageLocation, which often does not work.
    */
   public async updateSingleRecurrenceSpawnDate(
      recurrence: Recurrence,
      locationID: number,
      selectedDate: Date,
   ): Promise<AxiosResponse> {
      const timestamp = this.manageLocation.findLocationDay230(selectedDate, locationID);
      return axios.post(
         "phpscripts/manageDashboard.php?action=updateSingleReoccuranceSpawnDate",
         { reoccurID: recurrence.reoccurID, reoccurSpawn: timestamp },
      );
   }

   public async updateRecurrenceSpawnDate(
      recurrence: Recurrence,
      selectedDate: Temporal.ZonedDateTime,
   ): Promise<AxiosResponse> {
      // Gotta send it as 2:30 AM on the selected day for some reason
      const timestamp = this.zdtTo230AmEpochSeconds(selectedDate);
      return axios.post(
         "phpscripts/manageDashboard.php?action=updateSingleReoccuranceSpawnDate",
         { reoccurID: recurrence.reoccurID, reoccurSpawn: timestamp },
      );
   }

   /**
    * Converts a Temporal ZonedDateTime to an Epoch timestamp in seconds
    * representing 2:30 AM in that zone on the same day.
    */
   public zdtTo230AmEpochSeconds(zdt: Temporal.ZonedDateTime): number {
      return zdt.with({ hour: 2, minute: 30, second: 0 }).epochSeconds;
   }

   public getScheduleDisplayName(schedule: Schedule | ScheduleEntity): string {
      const users = this.manageTask.getAllUsers();
      const profiles = this.manageTask.getAllProfiles();
      const i18n = this.i18n();
      if (schedule.userID) {
         const user = users.get(schedule.userID);
         if (user) {
            return `${user.userFirstName} ${user.userLastName}`;
         }
      } else if (schedule.profileID) {
         const profile = profiles.get(schedule.profileID);
         if (profile) {
            return `${profile.profileDescription}`;
         }
      } else {
         return i18n.t("Unassigned");
      }
      return "";
   }

   public async updateSingleRecurrenceEndOnDate(
      recurrence: Recurrence,
      locationID: number,
      selectedDate?: Date,
   ): Promise<AxiosResponse> {
      const body = {
         reoccurID: recurrence.reoccurID,
         reoccurSpawnEnd: 0,
      };
      if (selectedDate !== undefined) {
         body.reoccurSpawnEnd = this.manageLocation.findLocationDay230(
            selectedDate,
            locationID,
         );
      }
      return axios.post(
         "phpscripts/manageDashboard.php?action=updateSingleReoccuranceEndOnDate",
         body,
      );
   }

   public async saveSchedule(
      recurrence: Recurrence,
      task: Task | TaskTemplateEntity,
      reoccurSpawnInMilliseconds: number,
      reoccurSpawnEndInMilliseconds: number | null,
      updateScheduleStartDate = false,
   ): Promise<AxiosResponse> {
      if (Number(recurrence.reoccurType) !== 1) {
         recurrence.calculateBasedOnLastCompletedPM = 0;
      }
      const body = {
         checklistID: recurrence.checklistID,
         fld1: recurrence.reoccurFld1,
         fld2: recurrence.reoccurFld2,
         fld3: recurrence.reoccurFld3,
         fld4: recurrence.reoccurFld4,
         fld5: recurrence.reoccurFld5,
         fld6: recurrence.reoccurFld6,
         fld7: recurrence.reoccurFld7,
         reoccurID: recurrence.reoccurID,
         endBy: recurrence.reoccurEndBy,
         spawn: this.manageLocation.findLocationDay230(
            new Date(reoccurSpawnInMilliseconds),
            task.locationID,
         ),
         reps: recurrence.reoccurRepetitions,
         type: recurrence.reoccurType,
         spawnSchedules: 1,
         assetID: task.assetID,
         calculateBasedOnLastCompletedPM: recurrence.calculateBasedOnLastCompletedPM,
         reoccurTimeOfDay: recurrence.reoccurTimeOfDay,
         reoccurSpawnEnd: null as number | null,
         updateScheduleStartDate: updateScheduleStartDate,
      };
      if (recurrence.reoccurType === 2 && Array.isArray(recurrence.reoccurFld2)) {
         body.fld2 = recurrence.reoccurFld2.join(",");
      }
      if (reoccurSpawnEndInMilliseconds !== null) {
         body.reoccurSpawnEnd = new Date(reoccurSpawnEndInMilliseconds).getTime() / 1000;
      }
      return axios.post("phpscripts/manageSchedule.php?action=saveSchedule", body);
   }

   /**
    * Takes an Epoch timestamp in milliseconds. Returns true if the timestamp
    * is at least a day old, or false otherwise.
    */
   public checkIfDateIsInThePast(dateInMilliseconds: number | null): boolean {
      if (dateInMilliseconds === null) return false;
      const yesterday = new Date();
      yesterday.setDate(yesterday.getDate() - 1);
      return dateInMilliseconds <= yesterday.getTime();
   }

   public configureReoccurSpawnForDisplay(reoccurSpawn: number | null): number {
      // If this is a new recurrence, default the spawn date to tomorrow.
      if (reoccurSpawn === 0 || reoccurSpawn === null) {
         const tomorrow = new Date();
         tomorrow.setDate(tomorrow.getDate() + 1);
         return tomorrow.getTime();
      }
      return reoccurSpawn * 1000;
   }

   public configureReoccurSpawnEndForDisplay(
      reoccurSpawnEnd: number | null,
   ): number | null {
      if (reoccurSpawnEnd === null || reoccurSpawnEnd <= 0) {
         return null;
      }
      return reoccurSpawnEnd * 1000;
   }

   public async enableDisableTypes7and8(
      reoccurID: number,
      reoccurFld4: string | null,
   ): Promise<AxiosResponse> {
      return axios.post("phpscripts/manageSchedule.php?action=enableDisableTypes7and8", {
         reoccurID: reoccurID,
         field4: reoccurFld4,
      });
   }

   public async updateType7StartOn(
      reoccurID: number,
      reoccurFld3: string | null,
   ): Promise<any> {
      return axios.post("phpscripts/manageSchedule.php?action=updateType7StartOn", {
         reoccurID: reoccurID,
         field3: reoccurFld3 ?? 0,
      });
   }

   public async updateScheduleStartDateByDays(
      scheduleID: number,
      days: number,
   ): Promise<AxiosResponse> {
      return axios.post(
         "phpscripts/manageSchedule.php?action=updateScheduleStartDateByDays",
         { scheduleID: scheduleID, days: days },
      );
   }

   public async updateAllowDuplicateInstances(
      recurrence: Recurrence,
   ): Promise<AxiosResponse> {
      return axios.post(
         "phpscripts/manageSchedule.php?action=updateAllowDuplicateInstances",
         { reoccurID: recurrence.reoccurID },
      );
   }

   public async updateCalculateBasedOnLastCompletedPM(
      recurrence: Recurrence,
   ): Promise<AxiosResponse> {
      return axios.post(
         "phpscripts/manageSchedule.php?action=updateCalculateBasedOnLastCompletedPM",
         { reoccurID: recurrence.reoccurID },
      );
   }

   public getNextCreatedAtForRecurrence(reoccurID: number): number | undefined {
      return this.nextCreatedAtForRecurrences.get(reoccurID);
   }

   public async getSeasons(seasonType: SeasonType): Promise<AxiosResponse> {
      return axios.post("phpscripts/manageSchedule.php?action=getSeasons", {
         seasonType: seasonType,
      });
   }

   public async addSeasons(seasons): Promise<AxiosResponse> {
      return axios.post("phpscripts/manageSchedule.php?action=addSeasons", {
         seasons: seasons,
      });
   }

   public createSeasons(seasons): Observable<any> {
      return this.http.post<any>(
         `${environment.servicesURL()}/schedules/seasons`,
         seasons,
         {
            withCredentials: true,
         },
      );
   }

   public async addDefaultHolidays(holidays): Promise<AxiosResponse> {
      return axios.post("phpscripts/manageSchedule.php?action=addDefaultHolidays", {
         holidays: holidays,
      });
   }

   public async deleteSeasons(seasons): Promise<AxiosResponse> {
      return axios.post("phpscripts/manageSchedule.php?action=deleteSeasons", {
         seasons: seasons,
      });
   }

   public async updateSeasons(seasons): Promise<AxiosResponse> {
      return axios.post("phpscripts/manageSchedule.php?action=updateSeasons", {
         seasons: seasons,
      });
   }

   public async updateSeasonRelations(
      reccurrenceID: number,
      seasonIDs: Array<number>,
      seasonType: SeasonType,
   ): Promise<AxiosResponse> {
      return axios.post("phpscripts/manageSchedule.php?action=updateSeasonRelations", {
         reccurrenceID: reccurrenceID,
         seasonIDs: seasonIDs,
         seasonType: seasonType,
      });
   }

   public async getRecurrenceAssociatedSeasons(
      recurrenceID: number,
   ): Promise<AxiosResponse> {
      return axios.post(
         "phpscripts/manageSchedule.php?action=getRecurrenceAssociatedSeasons",
         { recurrenceID: recurrenceID },
      );
   }

   public async getSeasonAssociatedRecurrences(
      seasonIDs: Array<number>,
   ): Promise<AxiosResponse> {
      return axios.post(
         "phpscripts/manageSchedule.php?action=getSeasonAssociatedRecurrences",
         { seasonIDs: seasonIDs },
      );
   }

   public async getTasksAssociatedSeasonNames(taskIDs: Array<number>): Promise<
      AxiosResponse<{
         seasons: TaskSeasons;
         success: boolean;
      }>
   > {
      return axios.post(
         "phpscripts/manageSchedule.php?action=getTasksAssociatedSeasonNames",
         { taskIDs: taskIDs },
      );
   }

   public async batchToggleAssociateSeasonToAllSchedules(
      season,
      recurrences,
   ): Promise<{ success: boolean }> {
      const sizeOfBatch = 500;
      const batches = this.manageUtil.splitArr(recurrences, sizeOfBatch);
      const batchPromiseArr = batches.map(async (batch) =>
         this.toggleAssociateSeasonToAllSchedules(season, batch),
      );
      const answers = await Promise.all(batchPromiseArr);
      return { success: answers.every((answer) => answer.data.success) };
   }

   private async toggleAssociateSeasonToAllSchedules(
      season,
      recurrences,
   ): Promise<AxiosResponse> {
      return axios.post(
         "phpscripts/manageSchedule.php?action=toggleAssociateSeasonToAllSchedules",
         { season: season, recurrences: recurrences },
      );
   }

   public async batchRecalculateSchedules(recurrences): Promise<{ success: boolean }> {
      const sizeOfBatch = 10;
      const batches = this.manageUtil.splitArr(recurrences, sizeOfBatch);
      let batchOfBatch: any = [];
      const answers: any = [];
      for await (const batch of batches) {
         batchOfBatch.push(batch);
         if (batchOfBatch.length === 300 || batch === batches[batches.length - 1]) {
            const answer = await this.runMultipleRequests(
               this.recalculateAllActiveSchedules.bind(this),
               batchOfBatch,
            );
            batchOfBatch = [];
            answers.push(...answer);
         }
      }
      for (const answer of answers) {
         if (!answer.data.success) {
            return { success: false };
         }
      }
      this.manageTask.incTasksWatchVar(); //increment so watches update properly
      return { success: true };
   }

   private async runMultipleRequests<T>(
      call: (params: T) => Promise<AxiosResponse>,
      parameters: Array<T>,
   ) {
      return Promise.all(parameters.map(async (params) => call(params)));
   }

   // Needs to be an arrow function to maintain the scope of 'this'
   private async recalculateAllActiveSchedules(recurrences): Promise<AxiosResponse> {
      const answer = await axios.post(
         "phpscripts/manageSchedule.php?action=recalculateAllActiveSchedules",
         { recurrences: recurrences },
      );
      if (!answer.data.success) {
         return answer;
      }
      const tasks = this.manageTask.getTasks();
      // Loop through the updated recurrences to see which tasks we need to update
      for (const updatedRecurrence of answer.data.updatedRecurrences) {
         const task = tasks.get(updatedRecurrence.checklistID);
         if (!task?.reoccurIDs) continue;
         const recurrenceIDToUpdate = task.reoccurIDs.find((reoccurID) => {
            const recurrence = this.manageTask.getRecurrence(reoccurID);
            if (recurrence === undefined) return false;
            return recurrence.reoccurID === updatedRecurrence.reoccurID;
         });
         if (recurrenceIDToUpdate === undefined) continue;
         const recurrenceToUpdate = this.manageTask.getRecurrence(recurrenceIDToUpdate);
         if (recurrenceToUpdate === undefined) continue;
         recurrenceToUpdate.reoccurSpawn = updatedRecurrence.reoccurSpawn;
      }
      return answer;
   }

   public async updateSkipEveryX(recurrence: Recurrence): Promise<AxiosResponse> {
      return axios.post("phpscripts/manageSchedule.php?action=updateSkipEveryX", {
         recurrenceID: recurrence.reoccurID,
         skipEveryX: recurrence.skipEveryX,
      });
   }

   public async updateCalculateBasedOnIncrementOnly(
      recurrence: Recurrence,
   ): Promise<AxiosResponse> {
      return axios.post(
         "phpscripts/manageSchedule.php?action=updateCalculateBasedOnIncrementOnly",
         {
            recurrenceID: recurrence.reoccurID,
            calculateBasedOnIncrementOnly: recurrence.calculateBasedOnIncrementOnly,
         },
      );
   }

   public async restoreSingleSchedule(scheduleID: number): Promise<AxiosResponse> {
      return axios.post("phpscripts/manageSchedule.php?action=restoreSingleSchedule", {
         scheduleID: scheduleID,
      });
   }

   public getCustomSeasonDateStr(season, dates): void {
      let orderDay;
      // Set the strings that are used in the UI
      season.startMonthStr = dates.get(Number(season.startMonth)).name;
      season.formattedStartDate = `${season.startMonthStr} ${season.startDay}`;
      if (season.endMonth > 0) {
         season.endMonthStr = dates.get(Number(season.endMonth)).name;
         season.formattedEndDate = `${season.endMonthStr} ${season.endDay}`;
      }
      // Format the day of the month correctly
      if (season.startDay < 10) {
         orderDay = `0${season.startDay}`;
      } else {
         orderDay = season.startDay;
      }
      // Create a dateOrder property so that we can list them chronologically
      season.dateOrder = Number(`${season.startMonth}${orderDay}`);
   }

   public getDefaultHolidayDateStr(holiday, seasonsListWithDefaults): void {
      const i18n = this.i18n();
      // If it's for the weekend option, always use this string
      if (holiday.defaultHolidayID === "WKE") {
         holiday.dateStr = i18n.t("EverySaturdayAndSunday");
         holiday.dateOrder = 1;
      }
      // Create the date strings
      if (holiday.static) {
         holiday.dateStr = moment(holiday.dates[0], "MM/DD").format("MMM D");

         // Get the order for the UI
         holiday.dateOrder = this.getDefaultHolidayOrder(holiday.dates[0]);
      } else {
         // If it's not static we need to figure out the date the holiday is on this year
         const currentYear = moment(new Date()).format("YYYY");
         for (const date of holiday.dates) {
            if (moment(new Date(date)).format("YYYY") === currentYear) {
               holiday.dateStr = moment(new Date(date)).format("MMM D");
               // Get the order for the UI
               holiday.dateOrder = this.getDefaultHolidayOrder(date);
               break;
            }
         }
      }
      // Add the values to the correct 'season' as well
      const seasonToUpdate = seasonsListWithDefaults.get(holiday.seasonID);
      if (seasonToUpdate) {
         seasonToUpdate.formattedStartDate = holiday.dateStr;
         seasonToUpdate.dateOrder = holiday.dateOrder;
      }
   }

   private getDefaultHolidayOrder(date: string): number {
      const monthDayArr = date.split("/", 2);
      const month = parseInt(monthDayArr[0], 10);
      const day = monthDayArr[1];
      const dateOrder = Number(`${month}${day}`);
      return dateOrder;
   }

   public getCreatedStr(season): void {
      const eventType = season.event;
      const i18n = this.i18n();
      if (eventType === "skip") {
         season.createdStr = i18n.t("Skipped");
      }
      if (eventType === "dayBefore") {
         if (season.defaultHolidayID === "WKE") {
            season.createdStr = i18n.t("DueFridayBeforeTheWeekend");
         } else {
            season.createdStr = i18n.t("DueTheDayBeforeTheHoliday");
         }
      }
      if (eventType === "dayAfter") {
         if (season.defaultHolidayID === "WKE") {
            season.createdStr = i18n.t("DueMondayAfterTheWeekend");
         } else {
            season.createdStr = i18n.t("DueTheDayAfterTheHoliday");
         }
      }
   }

   public async getAllTimeBasedRecurrences(): Promise<AxiosResponse> {
      return axios.post(
         "phpscripts/manageSchedule.php?action=getAllTimeBasedRecurrences",
      );
   }

   public getDateInfo(): Map<
      number,
      { name: string; number: number; days: number; daysArr: Array<number> }
   > {
      const i18n = this.i18n();
      /** An array of the form [1, 2, 3, 4, ..., 30, 31] */
      const longMonth = [...Array(32).keys()].slice(1);
      const shortMonth = longMonth.slice(0, -1);
      const february = longMonth.slice(0, -2);
      return new Map([
         [1, { name: i18n.t("Jan"), number: 1, days: 31, daysArr: [...longMonth] }],
         [2, { name: i18n.t("Feb"), number: 2, days: 29, daysArr: [...february] }],
         [3, { name: i18n.t("Mar"), number: 3, days: 31, daysArr: [...longMonth] }],
         [4, { name: i18n.t("Apr"), number: 4, days: 30, daysArr: [...shortMonth] }],
         [5, { name: i18n.t("May"), number: 5, days: 31, daysArr: [...longMonth] }],
         [6, { name: i18n.t("Jun"), number: 6, days: 30, daysArr: [...shortMonth] }],
         [7, { name: i18n.t("Jul"), number: 7, days: 31, daysArr: [...longMonth] }],
         [8, { name: i18n.t("Aug"), number: 8, days: 31, daysArr: [...longMonth] }],
         [9, { name: i18n.t("Sep"), number: 9, days: 30, daysArr: [...shortMonth] }],
         [10, { name: i18n.t("Oct"), number: 10, days: 31, daysArr: [...longMonth] }],
         [11, { name: i18n.t("Nov"), number: 11, days: 30, daysArr: [...shortMonth] }],
         [12, { name: i18n.t("Dec"), number: 12, days: 31, daysArr: [...longMonth] }],
      ]);
   }

   public recurrenceHasSchedules_JIT(
      recurrence: Recurrence,
      hasSchedules: boolean,
   ): boolean {
      if (recurrence.reoccurType === null) return false;
      switch (recurrence.reoccurType) {
         case 1:
         case 2:
         case 3:
         case 4:
         case 5:
         case 6:
            if (hasSchedules) {
               return true;
            }
            break;
         case 7:
         case 8:
            if (recurrence.reoccurFld4 == "1") {
               return true;
            }
            break;
         default:
            return false;
      }
      return false;
   }

   public getRecurrenceFieldName(recurrence: Recurrence): string | undefined {
      if (
         (recurrence.reoccurType !== 7 && recurrence.reoccurType !== 8) ||
         typeof recurrence?.reoccurFld1 !== "string"
      ) {
         return undefined;
      }
      return recurrence.reoccurFld1
         .split(",")
         .filter((valueID) => Number(valueID) > 0)
         .map((valueID) => this.manageAsset.getFieldValue(Number(valueID)))
         .filter((fieldValue) => fieldValue !== undefined)
         .map((fieldValue) => this.manageAsset.getField(fieldValue.fieldID))
         .at(-1)?.fieldName;
   }

   public async startSingleSchedule(scheduleID: number): Promise<AxiosResponse> {
      const answer = await axios.post(
         "phpscripts/checklistManager.php?action=startSingleSchedule",
         { scheduleID: scheduleID },
      );
      if (answer.data.success === true) {
         for (const task of answer.data.tasks) {
            this.manageTask.addTaskToLookup(task);
         }
         //increment watch variable so watches will fire.  Workaround to deep watchs or watch collections.
         this.manageTask.incTasksWatchVar();
      }
      return answer;
   }
}
