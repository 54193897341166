<ng-container *translate="let t">
   <lim-ui-panel [noSidePadding]="true">
      <div class="header-section-row">
         <div class="header-items-group">
            <ng-content select="[title]" />
            <e-search
               clearText="Clear"
               (searchValChange)="onSearchChange($event)"
               size="small"
            />
         </div>

         @if (!hasRestrictedPermissions() && canExport()) {
            <lim-ui-icon-button
               class="mobileDisappear"
               icon="cloudArrowDown"
               (click)="handleExportToExcel()"
               [limUiTooltip]="t('ExportListToExcel')"
               placement="bottom"
            />
         }
      </div>

      <div class="data-viewer-container">
         <e-data-viewer
            [columns]="columns()"
            [data]="items()"
            [totalItems]="totalItems()"
            [page]="page()"
            [status]="status()"
         />
      </div>
      <div class="narrow">Here goes the list of Task Card</div>
   </lim-ui-panel>
</ng-container>
