<lim-ui-modal [showWatermark]="true">
   <lim-ui-basic-modal-header [title]="lang().HistoryOfChanges" (closeModal)="close()" />
   <lim-ui-modal-body>
      @if (allHistoriesLength === 0 && !noSearchResults) {
         <div>
            <lim-ui-alert alertType="warning">
               <span>{{ lang().WhoopsThereAreNoEntriesToView }}</span>
            </lim-ui-alert>
         </div>
      }
      <lim-ui-panel [useCustomTitle]="true" [noSidePadding]="true">
         @if (canGraph) {
            <div panelCustomTitle>
               <div class="panel-header">
                  <lim-ui-search-box
                     [placeholder]="lang().Search"
                     [(searchVal)]="searchBar"
                     (searchValChange)="runFilter()"
                  />
                  @if (allHistoriesLength) {
                     <lim-ui-icon-button
                        icon="cloudDownloadRegular"
                        (click)="downloadAssetInfoHistory()"
                        placement="bottom"
                        limUiTooltip="{{ lang().ExportListToCSV }}"
                     />
                  }
               </div>
            </div>
         }
         <div buttons>
            @if (!canGraph) {
               <div class="panel-header">
                  @if (allHistoriesLength) {
                     <lim-ui-icon-button
                        icon="cloudDownloadRegular"
                        (click)="downloadAssetInfoHistory()"
                        placement="bottom"
                        limUiTooltip="{{ lang().ExportListToCSV }}"
                     />
                  }
                  <lim-ui-search-box
                     [placeholder]="lang().Search"
                     [(searchVal)]="searchBar"
                     (searchValChange)="runFilter()"
                  />
               </div>
            }
            @if (canGraph) {
               <div class="no-graph-panel-header">
                  <lim-ui-minimal-button
                     icon="list"
                     [active]="viewAs === 'list'"
                     (click)="changeViewAs('list')"
                     >{{ lang().List }}</lim-ui-minimal-button
                  >
                  <lim-ui-minimal-button
                     icon="chartLineRegular"
                     [active]="viewAs === 'graph'"
                     (click)="changeViewAs('graph')"
                     >{{ lang().LineGraph }}</lim-ui-minimal-button
                  >
               </div>
            }
         </div>
         <div>
            @if (viewAs === "list") {
               @if (assetField && allHistoriesLength > 0) {
                  @if (!noSearchResults) {
                     <div class="history-table-header">
                        <sort-column
                           column="timestamp"
                           name="{{ lang().Date }}"
                           [(bind)]="sortBind"
                           class="col-md-3"
                        />
                        <sort-column
                           column="oldValue"
                           name="{{ lang().Value }}"
                           [(bind)]="sortBind"
                           class="col-md-3"
                        />
                        <sort-column
                           column="userLastName"
                           name="{{ lang().User }}"
                           [(bind)]="sortBind"
                           class="col-md-3"
                        />
                        <sort-column
                           column="checklistName"
                           name="{{ lang().FromTask }}"
                           [(bind)]="sortBind"
                           class="col-md-3"
                        />
                     </div>
                  }

                  @if (noSearchResults) {
                     <span>
                        <no-search-results />
                     </span>
                  }

                  @for (
                     history of histories
                        | orderBy: sortBind
                        | sliceArray: (page - 1) * itemsPerPage : page * itemsPerPage;
                     track history
                  ) {
                     <div class="history-row">
                        <!-- Date -->
                        @if (!history.enableEdit) {
                           <div class="col-md-3">
                              {{ history.timestamp * 1000 | betterDate: "dateTime" }}
                           </div>
                        }

                        @if (history.enableEdit) {
                           <div
                              (click)="editAssetItemTimestampHistory(history)"
                              class="col-md-3"
                           >
                              <a>{{
                                 history.timestamp * 1000 | betterDate: "dateTime"
                              }}</a>
                           </div>
                        }

                        <!-- Display value if edit is disabled && fieldType isn't Date -->
                        @if (
                           assetField.fieldTypeID !== AssetFieldTypeID.Date &&
                           !history.enableEdit
                        ) {
                           <div class="col-md-3">
                              @if (assetField.fieldTypeID === AssetFieldTypeID.Currency) {
                                 <span
                                    [limbleHtml]="
                                       history.oldValue | localeCurrency: currencyCode()
                                    "
                                 ></span>
                              } @else {
                                 <span [limbleHtml]="history.oldValue"></span>
                              }

                              @if (history.valueChangeInfo) {
                                 <span> ({{ history.valueChangeInfo }}) </span>
                              }
                           </div>
                        }

                        <!-- Display value if edit is disabled && fieldType is Date -->
                        @if (
                           assetField.fieldTypeID === AssetFieldTypeID.Date &&
                           !history.enableEdit
                        ) {
                           <div class="col-md-3">
                              <span>{{ history.oldValue | betterDate: "date" }}</span>
                           </div>
                        }

                        <!-- Input field if edit value is enabled -->
                        @if (history.enableEdit) {
                           <div class="col-md-3">
                              @if (assetField.fieldTypeID) {
                                 <span>
                                    <!--Text Type-->
                                    @if (
                                       assetField.fieldTypeID === AssetFieldTypeID.Text
                                    ) {
                                       <div>
                                          <div
                                             id="inputField"
                                             ngDefaultControl
                                             [(ngModel)]="history.oldValue"
                                             (afterEdit)="
                                                editAssetItemInfoHistory(history)
                                             "
                                             limbleContentEditable
                                             stripHtml="true"
                                          ></div>
                                       </div>
                                    }
                                    <!--Date Type-->
                                    @if (
                                       assetField.fieldTypeID === AssetFieldTypeID.Date
                                    ) {
                                       <div>
                                          <lim-ui-date-picker-input
                                             (modelChange)="
                                                editAssetItemInfoHistory(history)
                                             "
                                             [model]="history.oldValue"
                                          />
                                       </div>
                                    }

                                    <!--Number Type-->
                                    @if (
                                       assetField.fieldTypeID === AssetFieldTypeID.Number
                                    ) {
                                       <div>
                                          <input
                                             id="inputField"
                                             type="number"
                                             [(ngModel)]="history.oldValue"
                                             (blur)="editAssetItemInfoHistory(history)"
                                             onclick="this.focus();"
                                             class="form-control"
                                          />
                                       </div>
                                    }

                                    <!--Currency Type-->
                                    @if (
                                       assetField.fieldTypeID ===
                                       AssetFieldTypeID.Currency
                                    ) {
                                       <div class="input-field-currency">
                                          <lim-ui-input-with-prefix
                                             [prefixText]="
                                                currencyCode() | currencySymbol
                                             "
                                             [type]="'number'"
                                             [value]="history.oldValue"
                                             (valueChange)="
                                                history.oldValue = $event;
                                                editAssetItemInfoHistory(history)
                                             "
                                             onclick="this.focus();"
                                             class="number-field"
                                          />
                                       </div>
                                    }

                                    <!--Dropdown Type-->

                                    @if (
                                       assetField.fieldTypeID ===
                                       AssetFieldTypeID.Dropdown
                                    ) {
                                       <div>
                                          <lim-ui-dropdown-inline-text
                                             [label]="history.oldValue"
                                          >
                                             <div menu>
                                                @for (
                                                   option of fieldOptions;
                                                   track option
                                                ) {
                                                   <lim-ui-dropdown-item
                                                      (click)="
                                                         history.oldValue = option.name;
                                                         editAssetItemInfoHistory(history)
                                                      "
                                                   >
                                                      <a [limbleHtml]="option.name"> </a>
                                                   </lim-ui-dropdown-item>
                                                }
                                             </div>
                                          </lim-ui-dropdown-inline-text>
                                          <ul></ul>
                                       </div>
                                    }
                                 </span>
                              }
                           </div>
                        }

                        <div class="col-md-3">
                           <!--{{history.userID}}-->
                           @if (history?.displayUserName) {
                              <lim-ui-icon icon="user" />
                           }
                           {{ history.displayUserName }}
                           @if (history.userID === 0) {
                              <span> - </span>
                           }
                        </div>

                        <div class="col-md-3 end-column">
                           <!--{{history.checklistID}}-->
                           @if (history?.checklistName) {
                              <span>
                                 @if (history.checklistTemplateOld === 2) {
                                    <lim-ui-icon icon="wpforms" />
                                 }
                                 @if (history.checklistTemplateOld === 1) {
                                    <lim-ui-icon icon="wrench" />
                                 }
                                 @if (history.checklistTemplateOld === 4) {
                                    <lim-ui-icon icon="file" />
                                 }
                                 <a (click)="popTask(history.checklistID)">
                                    {{ history.checklistName }}
                                 </a>
                              </span>
                           }

                           @if (history.checklistID === 0) {
                              <span> - </span>
                           }
                           @if (superUser) {
                              <span class="super-user-buttons">
                                 <lim-ui-minimal-icon-button
                                    icon="penToSquare"
                                    (click)="
                                       toggleEnableEdit(history); $event.stopPropagation()
                                    "
                                    [superMinimal]="true"
                                    placement="bottom"
                                    limUiTooltip="{{
                                       lang().EditItemValueHistoryTooltip
                                    }}"
                                 />
                                 <lim-ui-minimal-icon-button
                                    icon="trashCanRegular"
                                    iconColor="danger"
                                    [superMinimal]="true"
                                    (click)="
                                       deleteAssetItemInfoHistory(history);
                                       $event.stopPropagation()
                                    "
                                    placement="bottom"
                                    limUiTooltip="{{
                                       lang().DeleteItemValueHistoryTooltip
                                    }}"
                                 />
                              </span>
                           }
                        </div>
                     </div>
                  }
               }
            }
            @if (viewAs === "graph") {
               <div class="graph">
                  <div class="legend">
                     <div class="green-circle"></div>
                     @if (assetField && assetField.fieldName) {
                        <span>{{ assetField.fieldName }}</span>
                     }
                     <span class="points-on-graph"
                        >{{ lang().NumberOfPointsOnGraph }}:
                        <lim-ui-dropdown-inline-text [label]="graphPoints">
                           <div menu>
                              <lim-ui-dropdown-item (click)="updateGraphData(10)"
                                 >10</lim-ui-dropdown-item
                              >
                              <lim-ui-dropdown-item (click)="updateGraphData(25)"
                                 >25</lim-ui-dropdown-item
                              >
                              <lim-ui-dropdown-item (click)="updateGraphData(50)"
                                 >50</lim-ui-dropdown-item
                              >
                              <lim-ui-dropdown-item (click)="updateGraphData(100)"
                                 >100</lim-ui-dropdown-item
                              >
                           </div>
                        </lim-ui-dropdown-inline-text></span
                     >
                  </div>
                  <div id="assetHistoryGraph">
                     <canvas id="lineOrBarGraph1"></canvas>
                  </div>
               </div>
            }
         </div>
         @if (viewAs === "list") {
            <div class="footer" panelFooter>
               @if ((histories?.length ?? 0) > itemsPerPage) {
                  <lim-ui-pagination
                     [maxSize]="5"
                     [(page)]="page"
                     [pageSize]="itemsPerPage"
                     [collectionSize]="histories?.length ?? 0"
                     (pageChange)="pageChanged()"
                  />
               }
            </div>
         }
      </lim-ui-panel>
   </lim-ui-modal-body>
</lim-ui-modal>
