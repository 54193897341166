import { animate, query, stagger, style, transition, trigger } from "@angular/animations";
import { AsyncPipe, NgClass, NgStyle, NgTemplateOutlet } from "@angular/common";
import type { DoCheck, OnChanges, OnDestroy, OnInit, QueryList } from "@angular/core";
import {
   inject,
   Component,
   ElementRef,
   EventEmitter,
   Input,
   Output,
   ViewChild,
   ViewChildren,
   forwardRef,
   signal,
   viewChild,
   computed,
   DestroyRef,
} from "@angular/core";
import { takeUntilDestroyed, toSignal } from "@angular/core/rxjs-interop";
import { FormsModule } from "@angular/forms";
import { ActivatedRoute, Router } from "@angular/router";
import type { Aliases } from "@limblecmms/lim-ui";
import {
   AlertComponent,
   BadgeComponent,
   CheckboxComponent,
   DropdownComponent,
   DropdownDividerComponent,
   DropdownItemComponent,
   DropdownTextItemComponent,
   IconComponent,
   InfoPanelComponent,
   ModalService,
   LimUiModalRef,
   LimbleHtmlDirective,
   LoadingAnimationComponent,
   MinimalIconButtonComponent,
   PanelComponent,
   PopoverDirective,
   PrimaryButtonComponent,
   ProgressBarComponent,
   ScrollContainerComponent,
   ScrollWhileDraggingDirective,
   SecondaryButtonComponent,
   TertiaryButtonComponent,
   TooltipDirective,
   UpsellPopover,
   isMobile,
   isNativeMobileApp,
   LoadingBarService,
} from "@limblecmms/lim-ui";
import type { AxiosResponse } from "axios/dist/axios";
import $ from "jquery";
import type { Moment } from "moment";
import moment from "moment";
import { NgxSkeletonLoaderModule } from "ngx-skeleton-loader";
import type { Observable } from "rxjs";
import {
   BehaviorSubject,
   Subscription,
   debounceTime,
   filter,
   from,
   lastValueFrom,
   map,
   merge,
   skip,
   startWith,
   take,
} from "rxjs";
import { PickTools } from "src/app/assets/components/pickToolsModal/pickTools.modal.component";
import { PopAsset } from "src/app/assets/components/popAssetModal/popAsset.modal.component";
import { ManageAsset } from "src/app/assets/services/manageAsset";
import { ManageTool } from "src/app/assets/services/manageTool";
import type { Asset } from "src/app/assets/types/asset.types";
import { ManageFiles } from "src/app/files/services/manageFiles";
import { ManageLang } from "src/app/languages/services/manageLang";
import { TranslationService } from "src/app/languages/translation/translation.service";
import { InstructionStorageSyncService } from "src/app/lite/local-db/resources/collection/task/instruction/instruction.storage.sync.service";
import { ModeTransitionService, OfflineMode } from "src/app/lite/mode-transition.service";
import { PickLocationsModal } from "src/app/locations/components/pickLocationsModal/pickLocations.modal.component";
import { ManageLocation } from "src/app/locations/services/manageLocation";
import { ViewMap } from "src/app/maps/components/viewMapModal/viewMap.modal.component";
import { ManageMaps } from "src/app/maps/services/manageMaps";
import type { GeoFeature } from "src/app/maps/types/geoMap.types";
import { PopPart } from "src/app/parts/components/popPartsModal/popPart.modal.component";
import {
   PartsFacadeService,
   type AddPartModalData,
} from "src/app/parts/components/shared/parts-facade-service/parts-facade-service.service";
import { ManageParts } from "src/app/parts/services/manageParts";
import type { ExtraBatch } from "src/app/parts/types/extra-batch/extra-batch.types";
import type { Part, TaskEntityPart } from "src/app/parts/types/part.types";
import { UnitOfMeasureService } from "src/app/parts/unit-of-measure/unit-of-measure.service";
import { CostViewerComponent } from "src/app/purchasing/currency/components/cost-viewer-component/cost-viewer-component";
import { PoComponent } from "src/app/purchasing/pos/poWrapper/po.wrapper.component";
import { PurchaseOrderItemType } from "src/app/purchasing/pos/purchase-order-item-type";
import { ManageBilling } from "src/app/purchasing/services/manageBilling";
import { ManageInvoice } from "src/app/purchasing/services/manageInvoice";
import type { PurchaseOrderItemToAddSkeleton } from "src/app/purchasing/services/managePO";
import { ManagePO } from "src/app/purchasing/services/managePO";
import type { PurchaseOrderCurrentState } from "src/app/purchasing/types/general.types";
import type { PurchaseOrder } from "src/app/purchasing/types/purchase-order/purchase-order.types";
import { CustomizePriorities } from "src/app/settings/components/customizePrioritiesModal/customizePriorities.modal.component";
import { CustomizeStatuses } from "src/app/settings/components/customizeStatusesModal/customizeStatuses.modal.component";
import { Confirm } from "src/app/shared/components/global/confrimModal/confirm.modal.component";
import { EmailTemplate } from "src/app/shared/components/global/emailTemplateModal/emailTemplate.modal.component";
import { GatherText } from "src/app/shared/components/global/gatherTextModal/gatherText.modal.component";
import { PickDate } from "src/app/shared/components/global/pickDateModal/pickDate.modal.component";
import { PopupUpsellModal } from "src/app/shared/components/global/popupUpsellModal/popup-upsell-modal.component";
import { ContenteditableDirective } from "src/app/shared/directives/contentEditable/contentEditable.directive";
import { BetterCurrencyPipe } from "src/app/shared/pipes/betterCurrency.pipe";
import { BetterDatePipe } from "src/app/shared/pipes/betterDate.pipe";
import { BetterDecimalPipe } from "src/app/shared/pipes/betterDecimal.pipe";
import { FilterArrayPipe } from "src/app/shared/pipes/filterArray.pipe";
import { IconAlias } from "src/app/shared/pipes/iconAlias.pipe";
import { OrderByPipe, orderBy } from "src/app/shared/pipes/orderBy.pipe";
import { PartUnitOfMeasurePipe } from "src/app/shared/pipes/partUnitOfMeasure.pipe";
import { AlertService } from "src/app/shared/services/alert.service";
import { BetterDate } from "src/app/shared/services/betterDate";
import type { IsFeatureEnabledMap } from "src/app/shared/services/feature-flags/feature.types";
import { ManageFeatureFlags } from "src/app/shared/services/feature-flags/manageFeatureFlags";
import { Flags, LegacyLaunchFlagsService } from "src/app/shared/services/launch-flags";
import { LaunchFlagsService } from "src/app/shared/services/launch-flags/launch-flags.service";
import { ManageAssociations } from "src/app/shared/services/manageAssociations";
import { ManageFilters } from "src/app/shared/services/manageFilters";
import { ManageObservables } from "src/app/shared/services/manageObservables";
import { ManageUtil } from "src/app/shared/services/manageUtil";
import { ParamsService } from "src/app/shared/services/params.service";
import { RefreshService } from "src/app/shared/services/refresh.service";
import type { DataLogEventDefinition } from "src/app/shared/types/dataLog.types";
import type { CheckOutRequest } from "src/app/shared/types/general.types";
import { assert } from "src/app/shared/utils/assert.utils";
import { LimbleMap } from "src/app/shared/utils/limbleMap";
import { Lookup } from "src/app/shared/utils/lookup";
import { AddInvoiceToTaskModalComponent } from "src/app/tasks/components/add-invoice-to-task-modal/add-invoice-to-task-modal.component";
import { AddInvoiceToTemplateComponent } from "src/app/tasks/components/add-invoice-to-template-component/add-invoice-to-template.component";
import { ChangeCompletedTime } from "src/app/tasks/components/changeCompletedTimeModal/changeCompletedTime.modal.component";
import { ChangeDowntime } from "src/app/tasks/components/changeDowntimeModal/changeDowntime.modal.component";
import { ChkItem } from "src/app/tasks/components/chkItemElement/chkItem.element.component";
import { CompleteExternalTask } from "src/app/tasks/components/completeExternalTaskModal/completeExternalTask.modal.component";
import {
   CompleteTask,
   type CompleteTaskSubmitData,
} from "src/app/tasks/components/completeTaskModal/completeTask.modal.component";
import {
   CustomTagList,
   type CustomTagListInput,
} from "src/app/tasks/components/customTagListModal/customTagList.element.component";
import { ExtraTimeListComponent } from "src/app/tasks/components/extraTimeElement/extraTimeList.component";
import { LogTimeOnTask } from "src/app/tasks/components/logTimeOnTaskModal/logTimeOnTask.modal.component";
import { PickItem } from "src/app/tasks/components/pickItemModal/pickItem.modal.component";
import { PickTaskType } from "src/app/tasks/components/pickTaskTypeModal/pickTaskType.modal.component";
import { PickTasks } from "src/app/tasks/components/pickTasksModal/pickTasks.modal.component";
import { PopTask } from "src/app/tasks/components/popTaskModal/popTask.modal.component";
import { RecreateWorkRequest } from "src/app/tasks/components/recreateWorkRequestModal/recreateWorkRequest.modal.component";
import { ShareTaskTemplate } from "src/app/tasks/components/shareTaskTemplateModal/shareTaskTemplate.modal.component";
import type { TaskDataViewerViewModel } from "src/app/tasks/components/shared/components/tasks-data-viewer/task-data-viewer.model";
import { TaskFormHelperService } from "src/app/tasks/components/shared/services/task-form-helper/task-form-helper.service";
import { TaskFormDataViewerStateService } from "src/app/tasks/components/shared/services/task-form-state/task-form-state.service";
import type { TaskTemplateEntity } from "src/app/tasks/components/shared/services/task-templates-api/task-templates-api.models";
import { TaskTemplatesApiService } from "src/app/tasks/components/shared/services/task-templates-api/task-templates-api.service";
import { TaskViewModelMapperService } from "src/app/tasks/components/shared/services/task-view-model-factory/mappers/task-view-model-mapper.service";
import { TaskViewModelFactoryService } from "src/app/tasks/components/shared/services/task-view-model-factory/task-view-model-factory.service";
import type { TaskEntity } from "src/app/tasks/components/shared/services/tasks-api";
import { TasksApiService } from "src/app/tasks/components/shared/services/tasks-api/tasks-api.service";
import { TasksFacadeService } from "src/app/tasks/components/shared/services/tasks-facade/tasks-facade.service";
import { EditInstructionsComponent } from "src/app/tasks/components/task-form/edit-instructions/edit-instructions.component";
import { InstructionTreeBuilder } from "src/app/tasks/components/task-form/edit-instructions/instruction-tree-builder";
import { AssetHeaderItemComponent } from "src/app/tasks/components/task-form/header/asset-header-item/asset-header-item.component";
import { UpdateTaskStateService } from "src/app/tasks/components/task-form/services/update-task-state.service";
import { TaskCommentsComponent } from "src/app/tasks/components/task-form/task-comments/task-comments.component";
import { TaskDescriptionComponent } from "src/app/tasks/components/task-form/task-description/task-description.component";
import { TaskInvoicesComponent } from "src/app/tasks/components/task-form/task-invoices/task-invoices.component";
import { TaskPartsAvailabilityService } from "src/app/tasks/components/task-form/task-parts-availability.service";
import { TaskPartsListComponent } from "src/app/tasks/components/task-form/task-parts-list/task-parts-list.component";
import { TaskColorStatusConfig } from "src/app/tasks/components/taskColorStatusConfigElement/taskColorStatusConfig.element.component";
import { taskImage } from "src/app/tasks/components/taskImage/taskImage.element.component";
import { TaskPrintTemplateComponent } from "src/app/tasks/components/taskPrintTemplateElement/task-print-template.component";
import { TaskSimultaneousUsersComponent } from "src/app/tasks/components/taskSimultaneousUsers/taskSimultaneousUsers.component";
import { TaskTool } from "src/app/tasks/components/taskToolElement/taskTool.element.component";
import { UpdateRelatedTasksComponent } from "src/app/tasks/components/updateRelatedTasksModal/updateRelatedTasks.component";
import { UpdateRelatedTasksLegacyComponent } from "src/app/tasks/components/updateRelatedTasksModalLegacy/updateRelatedTasksLegacy.component";
import { PrintDivDirective } from "src/app/tasks/directives/printDiv/printDiv.directive";
import { TaskInstructionTypeID } from "src/app/tasks/schemata/tasks/instructions/task-instruction.enum";
import { ManagePriority } from "src/app/tasks/services/managePriority";
import { ManageStatus } from "src/app/tasks/services/manageStatus";
import { ManageTags } from "src/app/tasks/services/manageTags";
import { ManageTask } from "src/app/tasks/services/manageTask";
import { ManageTaskItem } from "src/app/tasks/services/manageTaskItem";
import { TaskCompleterService } from "src/app/tasks/services/task-completer/task-completer.service";
import { TaskEditableService } from "src/app/tasks/services/task-form/task-editable/task-editable.service";
import { TaskType, TaskTypeService } from "src/app/tasks/services/task-type.service";
import { TaskTreeSnapshot } from "src/app/tasks/services/taskTreeSnapshot.service";
import { WindowService } from "src/app/tasks/services/window.service";
import { TaskTimerComponent } from "src/app/tasks/timer/task-timer/task-timer.component";
import { TaskTimerStaticComponent } from "src/app/tasks/timer/task-timer-static/task-timer-static.component";
import { TimerDurationService } from "src/app/tasks/timer/timer-duration.service";
import type { CommentCalculatedInfo } from "src/app/tasks/types/comment/comment.types";
import { ExtraTimeDisplayModes } from "src/app/tasks/types/extra-time/extra-time.enum";
import type { TaskFormSettings } from "src/app/tasks/types/info/task-info.types";
import type { TaskPartLegacy } from "src/app/tasks/types/part/task-part.types";
import type { TaskUser } from "src/app/tasks/types/task-user/task-user.types";
import type { Task, TaskRelationData } from "src/app/tasks/types/task.types";
import { PickUserOrProfileLegacy } from "src/app/users/components/pickUserOrProfileModalLegacy/pickUserOrProfile.modal.component";
import { CredService } from "src/app/users/services/creds/cred.service";
import { ManageLogin } from "src/app/users/services/manageLogin";
import { ManageProfile } from "src/app/users/services/manageProfile";
import { ManageUser } from "src/app/users/services/manageUser";
import { PickVendors } from "src/app/vendors/components/pickVendorsModal/pickVendors.modal.component";

const SKIP_COMPLETE_TASK_STEP_CUSTOMER_IDS: Readonly<Array<number>> = [
   1130, 1175, 2591, 1439,
];

type TaskDisplayData = Partial<{
   checklistEstTimeMinutes: number;
   checklistPriorityDisplay: number;
   checklistTotalInvoiceCost: number;
   checklistTotalPartsCost: number;
   checklistPromptTimeTotal: number;
   checklistPromptTimeTotalHours: number;
   checklistPromptTimeTotalMinutes: number;
   billableTimeTotal: number;
   billableTimeTotalHours: number;
   billableTimeTotalMinutes: number;
   checklistTotalLaborCost: number;
   checklistTotalOperatingCost: number;
   checklistTemplateOldString: string;
   locationName: string;
   locationTaskImage: string;
   locationPhone: string;
   locationAddress: string;
   locationAddress2: string;
   dueDateDisplay: string;
   taskTypeIcon: Aliases;
   typeDisplay: string;
   statusName: string;
   statusAbbr: string;
   priorityLevel: number;
   priorityName: string;
   days: number;
   daysMsg: string;
   daysStatus: string;
   exactDays: number;
   userFirstName: string;
   userLastName: string;
   displayName: string;
   profileDescription: string;
   assignment: string;
   userEmail: string;
   completedFirstName: string;
   completedLastName: string;
   completedByStr: string;
   completedDaysPastDueDate: number;
   completedDaysPastDueDateTooltip: string;
   noteData: Map<number, CommentCalculatedInfo>;
}>;

export type TaskDataInput = {
   checklistID: number;
   expand?: boolean;
   guided?: boolean;
   preview?: boolean;
   options?: any;
   checklistTemplates?: Array<number>;
   checklist?: TaskTemplateEntity;
   mode?: "template" | "instance";
   limitedSettings?: boolean;
   disableAlerts?: boolean;
   limited?: boolean;
   checklistTemplate?: number;
   displayType?: "template" | "instance" | "PMSuggestion";
   editable?: boolean;
   checklistTemplateOld?: number;
   settings?: boolean;
   isSingleTask?: boolean;
};

const TASK_UPDATE_DEBOUNCE_DURATION = 1500;

@Component({
   selector: "task-form",
   templateUrl: "./task-form.component.html",
   styleUrls: ["./task-form.component.scss"],
   animations: [
      trigger("fabAnimation", [
         transition(":enter", [
            query(".fab-button", style({ opacity: 0 })),
            query(
               ".fab-button",
               stagger("33ms", [animate("0.1s ease-out", style({ opacity: 1 }))]),
            ),
         ]),
         transition(":leave", [
            query(
               ".fab-button",
               stagger("-33ms", [animate("0.1s ease-out", style({ opacity: 0 }))]),
            ),
         ]),
      ]),
   ],
   providers: [
      TaskPartsAvailabilityService,
      TaskFormDataViewerStateService,
      InstructionTreeBuilder,
   ],
   imports: [
      MinimalIconButtonComponent,
      TooltipDirective,
      ScrollContainerComponent,
      ScrollWhileDraggingDirective,
      InfoPanelComponent,
      LimbleHtmlDirective,
      PanelComponent,
      FormsModule,
      DropdownComponent,
      TertiaryButtonComponent,
      CustomTagList,
      ContenteditableDirective,
      SecondaryButtonComponent,
      TaskColorStatusConfig,
      IconComponent,
      BadgeComponent,
      PopoverDirective,
      UpsellPopover,
      NgStyle,
      DropdownItemComponent,
      DropdownDividerComponent,
      CheckboxComponent,
      TaskTool,
      PrimaryButtonComponent,
      forwardRef(() => EditInstructionsComponent),
      NgClass,
      TaskSimultaneousUsersComponent,
      NgTemplateOutlet,
      PrintDivDirective,
      taskImage,
      DropdownTextItemComponent,
      AlertComponent,
      LoadingAnimationComponent,
      CostViewerComponent,
      ExtraTimeListComponent,
      ProgressBarComponent,
      forwardRef(() => ChkItem),
      NgxSkeletonLoaderModule,
      PopoverDirective,
      AsyncPipe,
      BetterCurrencyPipe,
      BetterDatePipe,
      BetterDecimalPipe,
      FilterArrayPipe,
      IconAlias,
      OrderByPipe,
      AssetHeaderItemComponent,
      TaskTimerComponent,
      TaskTimerStaticComponent,
      TaskPrintTemplateComponent,
      PartUnitOfMeasurePipe,
      TaskInvoicesComponent,
      TaskDescriptionComponent,
      TaskPartsListComponent,
      TaskCommentsComponent,
   ],
})
export class TaskFormComponent implements OnInit, DoCheck, OnChanges, OnDestroy {
   @ViewChildren("extraTimeList") public readonly extraTimeListComponents:
      | QueryList<ExtraTimeListComponent>
      | undefined;
   @ViewChildren("chkItem") public readonly chkItemsCollection:
      | QueryList<ChkItem>
      | undefined;
   @ViewChild("taskPrintTemplate") private readonly taskPrintTemplateRef;
   @ViewChild("priorityDropdown") priorityDropdown: DropdownComponent | undefined;
   @Input({ required: true }) public data!: TaskDataInput;
   @Input() public modalInstance;
   @Input() public message: string = "";
   @Input() public title: undefined;
   @Input() public dataLogOptions: DataLogEventDefinition | undefined;
   @Input() public initialTask: TaskTemplateEntity | TaskEntity | undefined;
   @Input() public workOrderTemplateType: 2 | 4 = 2;
   @Output() public readonly workOrderTemplateTypeChange = new EventEmitter<2 | 4>();

   public allUsers: Lookup<"userID", TaskUser>;
   public partsLookup: Lookup<"partID", Part> = new Lookup("partID");
   public customerID;
   public currentUser;
   private currentlyAddingTag?: boolean;
   private tagQueue;
   public loading;
   private locations;
   public info?: TaskFormSettings;
   public showPrintButton;
   public categoriesIndex;
   protected readonly totalItems = signal(0);
   protected readonly totalCompleteItems = signal(0);
   public showMachineDownAlert;
   protected timerEnabled = signal(true);
   protected readonly asset = signal<Asset | undefined>(undefined);
   public billableHours;
   public billableMinutes;
   public checklistEstTimeHours;
   public checklistEstTimeMinutes;
   public checklistDowntimeHours;
   public checklistDowntimeMinutes;
   public showMachineDownAlertButtonText;
   public completionNotesError;
   public preventDoubleClick;
   public readonly canRecreateWorkRequest = signal(false);
   public credToEditOpenTaskInstructions;
   public deleteOpenTaskCred;
   public deleteCompletedTaskCred;
   public editCompletedTaskCred;
   public startPOCred;
   public tagTaskCred;
   public deleteCommentCred;
   public requestPurchaseCred;
   public recreateWorkRequestCred;
   public addCommentCred;
   public shareTasksCred;
   public viewLaborCostsCred;
   public viewInvoicesCred;
   public addPartsToOpenTasksCred;
   public superUser;
   public allowEditTemplateInstructions;
   public minPartQtyTask:
      | (Part & {
           pendingPOsCurrentStateMap?: LimbleMap<
              number,
              | (PurchaseOrderCurrentState & { poID: number; poNumber: number | null })
              | undefined
           >;
        })
      | undefined;

   public EditingWOTemplateInstanceBeforeLive;
   public currencySymbol;
   protected readonly loadedItems = signal(false);
   public userStartedThisTask;
   public relationData: TaskRelationData | null | undefined;
   // protected readonly customTagListObj = signal<any>(undefined);
   public noteHidden;
   private openPOWatchVarSub;
   private openPRWatchVarSub;
   private categoriesSub;
   private readonly tagsSubscription: Subscription;
   private priorityListSub;
   public priorityList: any = [];
   public priorityListIndex = {};
   private statusListSub;
   private statusList: any = [];
   private statusListIndex = {};
   protected readonly displayAssetInfo = signal<any>(undefined);
   public assetInfoFromCompletionArr: any = [];
   private assetFieldsSub;
   private taskRelationsLoadedSub;
   public skeletonThemes;
   public assetCheckInOut: boolean | undefined;
   public hasLogTimeForOthersCredentials: boolean | undefined;
   public featureAssetTools: boolean = false;
   public showPOsOnParts = new Map<number, boolean>();
   protected readonly assetNameStr = signal<string | undefined>(undefined);
   public open;
   public completed;
   public middleStatus: any = [];
   public middleStatusDisplay: any = [];
   public readonly tags = signal<Array<any>>([]);
   public scrollPosition: number = 0;
   public displayMode = ExtraTimeDisplayModes.ByUser;
   public readonly extraTimeDisplayModes = { ...ExtraTimeDisplayModes };

   protected readonly DESCRIPTION_LENGTH = 120;
   protected playIcon: Aliases = "play";
   protected pauseIcon: Aliases = "pause";
   protected showFab: boolean = false;
   protected hoursForDisplay: string = "";
   protected minutesForDisplay: string = "";
   protected hoursOrMinutes: string = "";
   protected taskOpen: boolean = false;
   protected isMobile: boolean = false;
   protected geoLocation: GeoFeature | null = null;
   protected readonly printData = computed<
      | {
           checklistID: number;
           checklist: Record<any, any>;
           items: Array<any>;
           info: TaskFormSettings;
           asset: Asset | undefined;
           displayAssetInfo: Record<any, any>;
           task?: TaskEntity | TaskTemplateEntity;
        }
      | undefined
   >(() => {
      const taskViewModel = this.taskViewModel();
      if (!taskViewModel || !this.info) {
         return undefined;
      }
      return {
         checklistID: taskViewModel.checklistID ?? 0,
         checklist: taskViewModel,
         task: taskViewModel,
         items: this.items(),
         info: this.info,
         asset: this.asset(),
         displayAssetInfo: this.displayAssetInfo(),
      };
   });
   protected showChangeTaskType: boolean = true;
   protected readonly TASK_ITEM_LIMIT_FOR_PRINT = 500;
   private grayOutWatchVar = 0;
   protected instanceVisibleItemTreeSize: number = 0;
   protected treeSize$: Observable<any> | undefined;
   private chkItemsCollectionSub: Subscription | undefined;
   protected requestedPurchases:
      | LimbleMap<
           number,
           | (PurchaseOrderCurrentState & {
                poID: number;
                poNumber: number | null;
                state: number | null;
                requestDisapproveReason: string | null;
                poNumberForDisplay: string | undefined;
             })
           | undefined
        >
      | undefined;
   private readonly taskSubscription: Subscription | null;
   protected featureDowntimeTracking: boolean = false;
   protected featureUnlimitedParts: boolean = false;
   protected featureUnlimitedPOs: boolean = false;
   protected featureTimeCost: boolean = false;
   protected featureLimitedNumber: boolean = false;
   protected featureUnlimitedWOs: boolean = false;
   protected featureShareTaskExternally: boolean = false;
   private featureMultipleLocations: boolean = false;
   private readonly manageFeatureFlagsSub: Subscription = new Subscription();
   public featureDisableTimeLogging: boolean = false;
   private onNoteIDsChanged$: Subscription | undefined;
   protected woInstructionLimit: number = 2;
   protected task!: TaskTemplateEntity | TaskEntity;
   protected tempEdit: boolean = false;
   protected relatedTask = signal<
      | {
           relatedTaskName: string;
           relatedChecklistID: number;
           relatedTaskStatusName: string;
        }
      | undefined
   >(undefined);
   protected parts: Array<TaskPartLegacy> = [];
   protected partsUnderStocked = new Set<number>();
   protected partsOverReserved = new Set<number>();
   protected readonly partsPendingPOs: LimbleMap<
      number,
      Array<
         PurchaseOrder & {
            currentState: PurchaseOrderCurrentState | undefined;
            extraBatch?: ExtraBatch;
         }
      >
   > = new LimbleMap();
   protected readonly partsSelectedPOs: LimbleMap<number, PurchaseOrder> =
      new LimbleMap();
   private readonly partsPreviousUsedNumbers: LimbleMap<number, number> = new LimbleMap();
   protected dataForCompleteTask;
   protected tools:
      | Array<{ mostRecentRequest: CheckOutRequest | null; tool }>
      | undefined;
   protected taskDisplayData: TaskDisplayData | undefined;
   public timerComponent = viewChild(TaskTimerComponent);
   protected canAddPOs: boolean = false;
   public currentlyAt: number = 0;
   public end: number = 0;
   public progressObservable$: BehaviorSubject<number> = new BehaviorSubject(0);
   public progressSub: Subscription;
   public boundGatherOptionResponseCustomTagInfo =
      this.taskHasOptionSelectedWithSameCustomTag.bind(this);
   protected jitTemplatesDesktopEnabled: boolean = false;
   protected hasEditCommentCredential: boolean | undefined;
   protected hideOnTablet: boolean = false;
   protected featureRequirePhotoInstruction: boolean = false;
   private autoCloseTask: boolean = false;

   // eslint-disable-next-line max-params -- Looks like the extra services were added to make JIT work and somehow this got past the linter
   private readonly elementRef = inject(ElementRef);
   protected readonly manageTask = inject(ManageTask);
   private readonly manageTaskItem = inject(ManageTaskItem);
   private readonly manageUser = inject(ManageUser);
   private readonly alertService = inject(AlertService);
   private readonly manageLocation = inject(ManageLocation);
   private readonly manageLogin = inject(ManageLogin);
   protected readonly unitOfMeasureService = inject(UnitOfMeasureService);
   private readonly manageParts = inject(ManageParts);
   private readonly manageInvoice = inject(ManageInvoice);
   public readonly manageAsset = inject(ManageAsset);
   protected readonly managePO = inject(ManagePO);
   private readonly manageUtil = inject(ManageUtil);
   private readonly manageBilling = inject(ManageBilling);
   private readonly manageFiles = inject(ManageFiles);
   private readonly manageObservables = inject(ManageObservables);
   private readonly manageFilters = inject(ManageFilters);
   private readonly manageProfile = inject(ManageProfile);
   private readonly paramsService = inject(ParamsService);
   private readonly modalService = inject(ModalService);
   private readonly router = inject(Router);
   private readonly managePriority = inject(ManagePriority);
   private readonly manageStatus = inject(ManageStatus);
   private readonly manageTool = inject(ManageTool);
   private readonly manageAssociations = inject(ManageAssociations);
   private readonly manageMaps = inject(ManageMaps);
   private readonly windowService = inject(WindowService);
   private readonly taskTreeSnapshotService = inject(TaskTreeSnapshot);
   private readonly manageTags = inject(ManageTags);
   private readonly credService = inject(CredService);
   private readonly taskTypeService = inject(TaskTypeService);
   private readonly manageFeatureFlags = inject(ManageFeatureFlags);
   private readonly route = inject(ActivatedRoute);
   private readonly refreshService = inject(RefreshService);
   private readonly taskPartsAvailabilityService = inject(TaskPartsAvailabilityService);
   private readonly legacyLaunchFlagService = inject(LegacyLaunchFlagsService);
   private readonly taskTemplateApiService = inject(TaskTemplatesApiService);
   private readonly instructionStorageSyncService = inject(InstructionStorageSyncService);
   private readonly loadingBarService = inject(LoadingBarService);
   private readonly partsFacadeService = inject(PartsFacadeService);
   private readonly manageLang = inject(ManageLang);
   private readonly timerDurationService = inject(TimerDurationService);
   private readonly modeTransitionService = inject(ModeTransitionService);
   private readonly tasksApiService = inject(TasksApiService);
   protected readonly taskFormState = inject(TaskFormDataViewerStateService);
   private readonly updateTaskStateService = inject(UpdateTaskStateService);
   private readonly taskFormHelperService = inject(TaskFormHelperService);
   private readonly taskViewModelFactoryService = inject(TaskViewModelFactoryService);
   private readonly taskViewModelMapperService = inject(TaskViewModelMapperService);
   private readonly betterDate = inject(BetterDate);
   private readonly launchFlagService = inject(LaunchFlagsService);
   private readonly tasksFacadeService = inject(TasksFacadeService);
   protected readonly i18n = inject(TranslationService).i18n;
   private readonly taskCompleterService = inject(TaskCompleterService);
   private readonly taskEditableService = inject(TaskEditableService);

   protected readonly mode = this.taskFormState.mode;
   protected readonly taskEditable = this.taskFormState.taskEditable;
   protected readonly itemsEditable = this.taskFormState.itemsEditable;
   protected readonly limited = this.taskFormState.limited;
   protected readonly limitedSettings = this.taskFormState.limitedSettings;
   protected readonly disableAlerts = this.taskFormState.disableAlerts;
   protected readonly optionsState = this.taskFormState.options;
   private readonly destroyRef = inject(DestroyRef);

   protected readonly collapsibleInstructionsFlag = this.launchFlagService.getFlag(
      "collapsible-instructions",
      false,
   );

   protected readonly featureMaps = this.manageUser.getCurrentUser().userInfo.featureMaps;

   protected readonly items = signal<Array<any>>([]);

   protected readonly showTimer = computed(() => {
      return (
         this.timerEnabled() &&
         this.limited() === false &&
         this.task.checklistTemplate === 0
      );
   });

   protected readonly taskExtraTimeVM = computed(() => {
      const task = this.taskViewModel();
      if (!task) return undefined;
      return {
         extraTime: task.extraTime ?? [],
         checklistPromptTime: task.checklistPromptTime,
         checklistCompletedDate: task.checklistCompletedDate,
         billableTime: task.billableTime,
         locationID: task.locationID,
         createdByUserID: task.createdByUserID,
         categoryID: task.categoryID,
         checklistID: task.checklistID,
         checklistUserCompleted: task.checklistUserCompleted,
         checklistUserWage: task.checklistUserWage,
         billableRate: task.billableRate,
         extraTimeNotes: task.extraTimeNotes,
      };
   });

   protected readonly taskViewModel = signal<TaskDataViewerViewModel | undefined>(
      undefined,
   );

   protected readonly isInstructionSetTemplate = computed(() => {
      const taskViewModel = this.taskViewModel();
      if (taskViewModel) {
         return taskViewModel.checklistTemplate === 6;
      }
      return false;
   });

   protected readonly showMergeWorkRequestButton = computed(() => {
      const task = this.taskViewModel();
      if (!task) return false;
      return (
         task.taskCredentials.recreateWorkRequestCred &&
         (task.checklistCompletedDate === 0 || task.checklistCompletedDate === null) &&
         task.checklistTemplate === 0 &&
         task.checklistTemplateOld === 2 &&
         task.checklistBatchID === 300112
      );
   });

   protected readonly showRecreateWorkRequestButton = computed(() => {
      const task = this.taskViewModel();
      if (!task) return false;
      return (
         task.taskCredentials.recreateWorkRequestCred &&
         (task.checklistCompletedDate === 0 || task.checklistCompletedDate === null) &&
         this.canRecreateWorkRequest()
      );
   });

   private subscriptionsForTaskState:
      | {
           updateGuides$: Subscription;
           completeTask$: Subscription;
           buildData$: Subscription;
           submitTags$: Subscription;
           rebuildTags$: Subscription;
           incGreyOutWatchVar$: Subscription;
           incItemWatchVar$: Subscription;
           refreshInstructions$: Subscription;
           setTask$: Subscription;
        }
      | undefined;

   /** This is optional because the provider won't be defined outside of a modal */
   private readonly modalRef = inject(LimUiModalRef, { optional: true });

   protected readonly lang = computed(() => this.manageLang.lang() ?? {});
   public isLimitedHeight$ = this.windowService.getIsLimitedHeight$();
   protected isCommentNoteFocused: boolean = false;

   protected readonly instructionTasks = signal<TaskEntity[]>([]);

   protected readonly isJITCompletedTasksChkEnabled = from(
      this.legacyLaunchFlagService.isEnabled(Flags.JIT_CT_TASK_CHK),
   );

   private readonly modalCloseEvents$ = this.modalService.closeEvents.pipe(
      filter(() => {
         if (this.modalService.hasOpenModals()) {
            return false;
         }
         return true;
      }),
      startWith(null),
   );

   protected readonly taskInstructionData = computed(() => {
      return {
         mode: this.taskFormState.mode(),
         itemsEditable: this.taskFormState.itemsEditable(),
         taskEditable: this.taskFormState.taskEditable(),
         tempEdit: this.taskFormState.tempEdit(),
         limited: this.taskFormState.limited(),
         expand: this.taskFormState.expand(),
         disableAlerts: this.taskFormState.disableAlerts(),
         options: this.taskFormState.options(),
         preventDoubleClick: this.taskFormState.preventDoubleClick(),
         shouldShowModeToggleButton: this.taskFormState.shouldShowModeToggleButton(),
         taskCompleted: this.taskViewModel()?.isCompleted ?? false,
      };
   });

   protected readonly lastEditedDate = computed(() => {
      return (
         this.betterDate.formatBetterDate(
            this.taskViewModel()?.taskInstructionsUpdatedAt ?? new Date(),
            "dateTime",
         ) ?? ""
      );
   });

   protected readonly lastEditedUser = computed(() => {
      return (
         this.manageUser.getUserFullName(
            this.taskViewModel()?.taskInstructionsUpdatedAtUserID ?? 0,
         ) ?? ""
      );
   });
   protected readonly customTagListObj = computed<CustomTagListInput>(() => {
      const source = this.customTagListSource();
      return {
         clickTag: this.clickTag.bind(this),
         selectOne: false,
         advancedSettings: true,
         sources: [source ?? false],
         deleteTag: this.deleteTag.bind(this),
         renameTag: this.renameTag.bind(this),
      };
   });

   protected readonly customTagListSource = signal<string | undefined>(undefined);

   private readonly assetFieldsUpdatedSignal = toSignal(
      merge(this.manageAsset.fieldState(), this.manageAsset.fieldValueState()).pipe(
         skip(1),
      ),
   );

   protected readonly assetFieldValues = computed(() => {
      this.assetFieldsUpdatedSignal();
      const assetID = this.taskViewModel()?.assetID;
      if (!assetID) {
         return [];
      }
      // We need to make sure Flannel returns the assetValueIDs and then we can stop using the local store.
      return this.manageAsset.getAsset(assetID)?.assetValueIDs;
   });

   public constructor() {
      this.skeletonThemes = this.manageUtil.generateSkeletonLoaderThemes();
      this.allUsers = this.manageTask.getAllUsers();
      this.partsLookup = this.manageParts.getParts();
      this.currentUser = this.manageUser.getCurrentUser();
      this.customerID = this.currentUser.userInfo.customerID;

      this.tagsSubscription = this.manageTags.tags$.subscribe((val: any) => {
         if (val) {
            const index = val.findIndex((tag) => tag.name === val.name);
            if (index > -1) val[index].tagged = val.tagged;
            this.tags.set(val);
         }
      });

      this.taskSubscription = this.manageObservables.setSubscription(
         "tasksWatchVar",
         () => {
            this.setGeolocation();

            // For the single task view (url = .../task/taskID/locationID), the notes in manageTask don't update in time,
            // so the Comments field ends up being empty.
            // Here we wait for the tasksWatchVar ping, and then update the notes again.
            if (
               this.task &&
               this.taskDisplayData?.noteData &&
               this.taskDisplayData.noteData.size === 0 &&
               this.task.checklistTemplate === 0
            ) {
               this.taskDisplayData.noteData =
                  this.manageTask.buildNoteDataMapForSingleTaskLegacy(this.task);
               this.checkUpdateUserLastVisited(false);
            }
         },
      );

      this.progressSub = this.progressObservable$.subscribe((value) => {
         this.currentlyAt = value;
      });

      this.modalCloseEvents$.subscribe(() => {
         this.tellTaskToRefresh(true);
      });

      this.registerTaskStateUpdateSubscriptions();
   }

   protected tellTaskToRefresh(updateInstructions: boolean = false) {
      this.updateTaskStateService.buildData(updateInstructions);
   }

   private registerTaskStateUpdateSubscriptions() {
      this.updateTaskStateService.setOpenLogTimeModalMethod(
         this.logTimeOnTask.bind(this),
      );
      this.subscriptionsForTaskState = {
         updateGuides$: this.updateTaskStateService.updateGuides$.subscribe(() => {
            this.setGuides();
         }),

         completeTask$: this.updateTaskStateService.completeTask$.subscribe(() => {
            this.complete();
         }),

         buildData$: this.updateTaskStateService.buildData$
            .pipe(debounceTime(TASK_UPDATE_DEBOUNCE_DURATION))
            .subscribe((updateInstructions) => {
               this.buildData(updateInstructions);
            }),

         submitTags$: this.updateTaskStateService.submitTags$.subscribe(
            (newMentionedList) => {
               this.addNewMentionsToChecklistNotifications(
                  newMentionedList,
                  this.task.checklistID,
               );
            },
         ),

         rebuildTags$: this.updateTaskStateService.rebuildTags$
            .pipe(debounceTime(TASK_UPDATE_DEBOUNCE_DURATION))
            .subscribe((tags) => {
               this.setCustomTagData(tags);
            }),

         incGreyOutWatchVar$: this.updateTaskStateService.incGreyOutWatchVar$.subscribe(
            () => {
               this.grayOutWatchVar++;
               this.updateGreyOut();
            },
         ),

         incItemWatchVar$: this.updateTaskStateService.incItemWatchVar$.subscribe(() => {
            this.updateItemWatch();
         }),
         refreshInstructions$: this.updateTaskStateService.refreshInstructions$
            .pipe(debounceTime(TASK_UPDATE_DEBOUNCE_DURATION))
            .subscribe(() => {
               // (TASK-895) Eventually, this should simply tell the instruction observable to update
               this.loadTaskInstructions();
            }),
         setTask$: this.updateTaskStateService.setTask$.subscribe(() => {
            this.setTask(false);
         }),
      };
   }

   private destroyTaskStatusUpdateSubscriptions() {
      if (!this.subscriptionsForTaskState) {
         return;
      }
      for (const subscription of Object.entries(this.subscriptionsForTaskState)) {
         subscription[1].unsubscribe();
      }
   }

   protected getExtraTimeIds(task: TaskEntity | TaskTemplateEntity): number[] {
      return "extraTime" in task ? task.extraTime?.map((et) => et.extraTimeID) : [];
   }

   protected getTaskPartRelationIDs(task: TaskEntity | TaskTemplateEntity): number[] {
      if ("parts" in task) {
         return task.parts?.map((part) => part.partID) ?? [];
      }
      return [];
   }

   protected getTaskNoteIDs(task: TaskEntity | TaskTemplateEntity): number[] {
      return "comments" in task ? task.comments?.map((comment) => comment.noteID) : [];
   }

   protected getTaskInvoices(task: TaskEntity | TaskTemplateEntity) {
      return "invoices" in task ? task.invoices : [];
   }

   public ngOnChanges(changes) {
      if (changes?.data?.previousValue !== changes?.data?.currentValue && changes?.info) {
         this.updateData();
      }

      if (
         changes?.workOrderTemplateType?.previousValue !==
            changes?.workOrderTemplateType?.currentValue &&
         !changes?.workOrderTemplateType.firstChange
      ) {
         this.setWorkOrderTemplateTypeDisplay(changes.workOrderTemplateType.currentValue);
      }

      // When setting up a work order, if you add a WO Template we need to update the task data
      if (
         changes?.data?.previousValue?.checklistID !==
            changes?.data?.currentValue?.checklistID &&
         changes.data.firstChange === false
      ) {
         this.updateTaskData();
      }
   }

   public ngOnInit() {
      this.initializeTaskState();
      this.initialize();
      this.initializeFeatFlagsSub();
   }

   private initializeFeatFlagsSub() {
      this.manageFeatureFlags.features$.subscribe(
         (isFeatureEnabledMap: IsFeatureEnabledMap) => {
            this.featureTimeCost = isFeatureEnabledMap.featureTimeCost;
            this.featureShareTaskExternally =
               isFeatureEnabledMap.featureShareTaskExternally;
            this.featureDowntimeTracking = isFeatureEnabledMap.featureDowntimeTracking;
            this.featureAssetTools = isFeatureEnabledMap.featureAssetTools;
            this.featureUnlimitedParts = isFeatureEnabledMap.featureUnlimitedParts;
            this.featureUnlimitedPOs = isFeatureEnabledMap.featureUnlimitedPOs;
            this.featureLimitedNumber = isFeatureEnabledMap.featureLimitedNumber;
            this.featureMultipleLocations = isFeatureEnabledMap.featureMultipleLocations;
            this.featureUnlimitedWOs = isFeatureEnabledMap.featureUnlimitedWOs;
            this.featureDisableTimeLogging =
               isFeatureEnabledMap.featureDisableTimeLogging;
            this.woInstructionLimit = this.manageFeatureFlags.getWOInstructionLimit();
            this.canAddPOs = this.manageFeatureFlags.canAddPOs();
            this.featureRequirePhotoInstruction =
               isFeatureEnabledMap.featureRequirePhotoInstruction;
         },
      );
   }

   private initializeTaskState() {
      const data = this.data;
      if (data.mode) {
         this.taskFormState.updateMode(data.mode);
      }

      if (data.expand !== undefined) {
         this.taskFormState.updateExpand(data.expand);
      }

      if (data.limited === undefined) {
         this.taskFormState.updateLimited(false);
      } else {
         this.taskFormState.updateLimited(data.limited);
      }

      if (data.limitedSettings === undefined) {
         this.taskFormState.updateLimitedSettings(false);
      } else {
         this.taskFormState.updateLimitedSettings(data.limitedSettings);
      }

      if (data.disableAlerts === undefined) {
         this.taskFormState.updateDisableAlerts(false);
      } else {
         this.taskFormState.updateDisableAlerts(data.disableAlerts);
      }

      if (data.editable !== undefined) {
         this.taskFormState.updateTasksEditable(data.editable);
      }

      if (data.displayType !== undefined) {
         if (data.displayType === "PMSuggestion") {
            this.taskFormState.updateShouldShowModeToggleButton(false);
         }
      }
   }

   private async initialize() {
      this.jitTemplatesDesktopEnabled = await this.legacyLaunchFlagService.isEnabled(
         Flags.JIT_TEMPLATES_DESKTOP,
      );

      if (this.data === undefined) {
         this.alertService.addAlert(this.lang().errorMsg, "warning", 10000);
         return;
      }

      this.isMobile = isMobile();
      if (this.data.preview === undefined) {
         this.data.preview = false;
      }

      // Logic to avoid hiding the selector if we change the enumeration values
      const displayModes = Object.values(this.extraTimeDisplayModes);
      const storedDisplayMode =
         this.manageUser.getCurrentUser().userInfo?.userUIPreferences
            ?.extraTimeDefaultDisplayMode;
      const initialDisplayMode =
         displayModes.find((mode) => mode === storedDisplayMode) ??
         ExtraTimeDisplayModes.ByUser;

      this.displayMode = initialDisplayMode;

      this.priorityListSub = this.manageObservables.setSubscription(
         "priorityList",
         () => {
            this.priorityList = this.managePriority.getPriorityList();
            this.priorityListIndex = this.managePriority.getPriorityListIndex();
            this.createPriorityName(this.priorityList);
         },
      );

      this.statusListSub = this.manageObservables.setSubscription(
         "statusList",
         (newValue) => {
            if (!newValue) return;
            this.statusList = [];
            setTimeout(() => {
               this.setStatusListAndDisplay();
            }, 1);
         },
      );

      this.items.set([]);
      this.taskFormState.updateOptions([]);

      this.data.options = this.data.options || {}; //have to add a default in case it is not passed
      this.currentlyAddingTag = false;
      this.tagQueue = [];

      this.loading = true;

      this.locations = this.manageLocation.getLocationsIndex();
      this.locations[0] = { locationID: 0, locationName: "Global" }; //Global location is necessary for global WR templates.
      $(document).bind("keydown", this.printOverride);

      //if gonative is in use then hide the print button -- this is because gonative webviews do not support window.print()
      this.showPrintButton = !isNativeMobileApp();

      this.categoriesSub = this.manageObservables.setSubscription("categories", () => {
         this.categoriesIndex = this.manageBilling.getCategoriesIndex();
      });

      //sets default
      this.totalItems.set(0);
      this.totalCompleteItems.set(0);
      this.showMachineDownAlert = false;

      //here are defaults
      this.info = {
         items: [],
      };

      this.timerEnabled.set(
         this.currentUser?.userInfo?.customSettings?.disableTaskTimer !== 1 &&
            this.currentUser?.workOrderUser !== 1,
      );

      this.openPOWatchVarSub = this.manageObservables.setSubscription(
         "OpenPurchaseOrderWatchVar",
         () => {
            this.updatePurchasingData();
         },
      );
      this.openPRWatchVarSub = this.manageObservables.setSubscription(
         "OpenBillWatchVar",
         () => {
            this.updatePurchasingData();
         },
      );
      this.assetFieldsSub = merge(
         this.manageAsset.fieldState(),
         this.manageAsset.fieldValueState(),
      )
         .pipe(skip(1))
         .subscribe(() => {
            this.setAssetForTask();
         });

      this.hideOnTablet = this.hasEditCommentCredential && this.deleteCommentCred;

      await this.initializeTask();
      await this.buildData();
   }

   protected async complete() {
      // Task already completed
      if (this.task.checklistUserCompleted) {
         return;
      }

      //credential check
      if (!this.manageLogin.credCheckEditChecklist(this.task)) {
         this.alertService.addAlert(this.lang().credFailGeneric, "warning", 6000);
         return;
      }

      //If an external user, we need to use a different function instead.
      if (this.currentUser?.workOrderUser === 1) {
         this.completeExternalUser();
         return;
      }

      //verify completion note settings
      if (this.manageUser.getCurrentUser().userInfo.customerRequireCompletionNotes == 1) {
         if (!this.task.checklistComments || this.task.checklistComments.length < 1) {
            this.alertService.addAlert(
               this.lang().PleaseEnterCompletionNotes,
               "warning",
               5000,
            );
            this.completionNotesError = true;
            return;
         }
      }

      if (
         this.manageUser.getCurrentUser().userInfo
            .preventTaskCompletionInsufficientPartsFlag === 1
      ) {
         const partsUnderStocked = new Set(
            this.taskPartsAvailabilityService
               .partsUnderStocked(this.parts)
               .map((part) => part.partID),
         );
         if (partsUnderStocked.size > 0) {
            this.alertService.addAlert(
               this.lang().YouDoNotHAveEnoughPartsInStockToCompleteThisTask,
               "warning",
               5000,
            );
            return;
         }
      }

      if (this.calcCompletion()) {
         //this if statement allows a customer to fully skip the complete Task step.
         if (SKIP_COMPLETE_TASK_STEP_CUSTOMER_IDS.includes(this.customerID)) {
            const instance = this.modalService.open(Confirm);

            this.paramsService.params = {
               modalInstance: instance,
               resolve: {
                  message: this.lang().AreYouSure,
                  title: this.lang().AreYouSure,
               },
            };

            const result = await instance.result;

            if (result === 1) {
               // Allow customers to specify if they want to turn this on or off.  BRYAN TO DO
               this.loadingBarService.show({
                  header: this.lang()?.WakingUpHamsters,
               });

               //specify defaults to basically skip this step.
               const tempArr: Array<[number, number, number, number]> = this.parts.map(
                  (part) => [
                     part.relationID,
                     part.suggestedNumber ?? 0,
                     part.poItemID ?? 0,
                     part.partID,
                  ],
               );
               this.processCompleteTask(0, 0, tempArr, {
                  multiWork:
                     this.task.extraTimeNotes && this.task.extraTimeNotes.length > 0,
               });
            }
         } else {
            //Customers were running into bugs where multiple users were editing/completing the same task, and the parts used on completion were different on the front end and back end.  The below call will true up parts before a user completes a task if the parts have been edited since the user opened the task modal
            this.parts = await this.manageTask.getTaskParts(this.task.checklistID);
            if (this.parts === undefined) {
               console.error(
                  "Error in 'complete' callback, parts are undefined after being fetched",
               );
               return;
            }
            this.sortParts();

            const modalRef = this.modalService.open(CompleteTask);

            modalRef.setInput("checklistID", this.task.checklistID);
            modalRef.setInput("timer", this.timerComponent()?.getTimerDuration());
            modalRef.setInput("items", this.items());

            modalRef.result.then((result: CompleteTaskSubmitData | undefined) => {
               if (result) {
                  this.loadingBarService.show({
                     header: this.lang()?.WakingUpHamsters,
                  });

                  const promptTime = result.hours * 60 * 60 + result.minutes * 60;

                  let downtime = 0;
                  if (
                     result.hoursDowntime !== undefined &&
                     result.minutesDowntime !== undefined
                  ) {
                     downtime =
                        result.hoursDowntime * 60 * 60 + result.minutesDowntime * 60;
                  }

                  const tempArr: Array<[number, number | null, number | null, number]> =
                     result.parts.map((part) => [
                        part.relationID,
                        part.suggestedNumber,
                        part.poItemID,
                        part.partID,
                     ]);
                  this.processCompleteTask(promptTime, downtime, tempArr, result);
               }
            });
         }
      } else {
         this.setGuides();
         this.alertService.addAlert(this.lang().NotCompletedMsg, "warning", 5000);
      }
   }

   protected setGuides() {
      //this makes the magic little arrow work...
      this.calcCompletion();
      const guided = (this.data.guided && this.editable()) ?? false;

      if (guided) {
         setTimeout(() => {
            $(this.elementRef.nativeElement)
               .find('div[type="point"].point')
               .clearQueue()
               .stop()
               .removeClass("point")
               .hide()
               .removeClass("bounceInLeft");
            $(this.elementRef.nativeElement)
               .find('div.include[type="point"][data-done="undone"]')
               .first()
               .addClass("point")
               .show()
               .addClass("bounceInLeft");
         }, 1);
      }
   }

   protected doWeCheck(item, currentStack = 1) {
      return this.taskFormHelperService.doWeCheck(item, this.items(), currentStack);
   }

   protected calcCompletion() {
      const { totalItems, totalCompleteItems, complete } =
         this.taskFormHelperService.calcCompletion(this.items());
      this.totalItems.set(totalItems);
      this.totalCompleteItems.set(totalCompleteItems);
      return complete;
   }

   public ngDoCheck(): void {
      this.initializeChkItemsCollectionSub();
   }

   private async initializeTask() {
      if (!this.data.checklistID) {
         throw new Error("checklistID is required for this component to work");
      }
      await this.updateTaskData();

      //update the task middle status
      this.setMiddleDisplay(this.task?.statusID);

      //the following calls depend on this.task and its corresponding notes existing,
      //which can only happen after the fetchNotes call this.updateTaskData run
      this.updateItemWatch();
      this.updateData();
      this.updateTimerEnabled();
      this.checkUpdateUserLastVisited(false);
      if (this.info) {
         this.info.task = this.task;
      }
   }

   protected setScrollPosition(event) {
      this.scrollPosition = event.target.scrollTop;
   }

   public setMiddleDisplay(statusID) {
      let newStatusID = statusID;

      if (newStatusID == null) return;
      // 0 and 2 are hardcoded as open and complete
      if (!this.statusListIndex[newStatusID]) {
         newStatusID = 0;
      }
      if (newStatusID == 0 || newStatusID == 2) {
         this.middleStatusDisplay = this.middleStatus[0];
         return;
      }

      this.middleStatusDisplay = this.statusListIndex[newStatusID];

      this.statusListIndex[newStatusID].statusShorten = `${this.statusListIndex[
         newStatusID
      ].name
         .substring(0, 17)
         .trim()}...`;
   }

   public close(): void {
      this.modalInstance.close();
   }

   public broadcastTaskOpen() {
      if (this.data === undefined) {
         throw new Error("data is not defined");
      }
      this.manageTask.broadcastTaskOpen(this.data.checklistID, this.currentUser.gUserID);
   }

   public broadcastTaskClosed() {
      if (this.data === undefined) {
         throw new Error("data is not defined");
      }
      this.manageTask.broadcastTaskClosed(
         this.data.checklistID,
         this.currentUser.gUserID,
      );
      this.manageTask.refreshLocalTask(this.data.checklistID);
   }

   public ngOnDestroy() {
      this.broadcastTaskClosed();
      this.destroyTaskStatusUpdateSubscriptions();
      this.chkItemsCollectionSub?.unsubscribe();
      if (this.onNoteIDsChanged$ !== undefined) {
         this.onNoteIDsChanged$.unsubscribe();
      }
      this.progressSub.unsubscribe();
      this.manageObservables.removeManySubscriptions([
         this.openPOWatchVarSub,
         this.openPRWatchVarSub,
         this.categoriesSub,
         this.priorityListSub,
         this.statusListSub,
         this.taskRelationsLoadedSub,
         this.tagsSubscription,
         this.taskSubscription,
         this.manageFeatureFlagsSub,
         this.assetFieldsSub,
      ]);

      //unbinding keydown to prevent building of events
      $(document).unbind("keydown", this.printOverride);

      if (this.task?.checklistCompletedDate === 0) {
         this.promptTimeFromViewing();
      }
   }

   protected setDisplayMode(displayMode: ExtraTimeDisplayModes) {
      this.displayMode = displayMode;
      this.manageUser.getCurrentUser().userInfo.userUIPreferences.extraTimeDefaultDisplayMode =
         displayMode;
      this.manageUser.updateUserUIPreferences();
   }

   private checkUpdateUserLastVisited(bypassCheck: boolean) {
      let updateTimestamp = false;
      const noteIDs =
         "noteIDs" in this.task
            ? this.task.noteIDs
            : this.task.comments?.map((comment) => comment.noteID);
      if (bypassCheck) {
         // you can bypass the checks for situations where you are leaving a comment, and want to make sure the the last visisted
         // timestamp gets updated. Otherwise when you leave the task it will look like you have unread comments.
         updateTimestamp = true;
      } else if (this.task.checklistCompletedDate === 0) {
         // we want to record a last visited timestamp for open tasks
         updateTimestamp = true;
      } else if (noteIDs && noteIDs.length > 0) {
         // We don't want to take into account auto generated notes, just comments when checking for new comments on a completed task.
         const userComments = noteIDs
            .map((noteID) => this.manageTask.getComment(noteID))
            .filter((note) => note?.noteAutomaticGen === 0);
         if (
            userComments?.length > 0 &&
            Number(userComments[userComments.length - 1]?.noteTimestamp) >
               Number(this.task.checklistCompletedDate)
         ) {
            // we want to record a last visited timestamp if a completed task has new comments on it since it was closed.
            updateTimestamp = true;
         }
      }

      if (updateTimestamp) {
         this.manageUser.updateUserLastVisited(this.task.checklistID, 1);
      }
   }

   private createPriorityName(priorityList) {
      for (const priority of priorityList) {
         priority.priorityName = `${priority.priorityLevel} - ${priority.name}`;
      }
   }

   private updateData(): void {
      if (this.info === undefined) return;
      this.setPermissions();
   }

   updateTimerEnabled = () => {
      // If view only mode
      if (
         (this.task.checklistDepreciated === 0 ||
            this.task.checklistDepreciated === null ||
            (this.task.checklistTemplate && this.task.checklistDepreciated)) &&
         !this.taskEditable() &&
         !this.task.checklistUserCompleted
      ) {
         this.timerEnabled.set(false);
      }
   };

   updateGreyOut = () => {
      if (this.grayOutWatchVar !== 0) {
         setTimeout(() => {
            //grey out has to run last
            this.setGreyOut();
         }, 1);
      }
   };

   updateItemWatch = () => {
      if (this.taskDisplayData === undefined) return;
      const { displayName } = this.manageTask.getTaskAssignmentInfo(this.task);
      this.taskDisplayData.displayName = displayName;
   };

   private async setTask(refreshLocalTask: boolean = true) {
      if (this.initialTask && this.task === undefined) {
         this.task = this.initialTask;
         this.taskViewModel.set(
            this.taskViewModelFactoryService.getTaskDataViewerViewModel(this.task),
         );

         return;
      }
      if (!this.data) {
         return;
      }
      let task;
      if (
         (this.data.checklistTemplate && this.data.checklistTemplate !== 0) ||
         (this.data.checklistTemplates && this.data.checklistTemplates[0] !== 0)
      ) {
         task = await lastValueFrom(
            this.taskTemplateApiService
               .getById(this.data.checklistID, {
                  filters: {
                     allowDeleted: true,
                  },
                  columns: "assets,invoices",
               })
               .pipe(takeUntilDestroyed(this.destroyRef)),
         );
      } else {
         task = await lastValueFrom(
            this.tasksApiService
               .getById(this.data.checklistID, {
                  columns:
                     "comments,invoices,extraTime,parts,partsDetails,locationName,assets",
               })
               .pipe(takeUntilDestroyed(this.destroyRef)),
         );
      }

      this.task = task;

      if (task.relatedChecklistID) {
         this.relatedTask.set(await this.loadRelatedTask(task.relatedChecklistID));
      }

      if (refreshLocalTask) {
         this.manageTask.refreshLocalTask(this.task.checklistID);
      }

      this.taskViewModel.set(
         this.taskViewModelFactoryService.getTaskDataViewerViewModel(this.task),
      );

      this.setGeolocation();
   }

   private async updateTaskData(): Promise<void> {
      await this.setTask(false);
      this.taskFormState.updateTempEdit(false);
      this.tempEdit = false;
      this.taskOpen =
         this.task.checklistStatusID === 0 && this.task.checklistUserCompleted === 0;

      this.taskDisplayData = this.taskViewModelMapperService.getTaskDisplayData(
         this.task,
      );

      await this.setTaskTools(Number(this.task.checklistID));
      //we're using a subscription to watch for changes to the task because calls to add notes can come from chkItem, shareExternalTask and other places.  This centralizes the logic for updating the notes.
      this.onNoteIDsChanged$?.unsubscribe();
      this.onNoteIDsChanged$ = this.manageTask.onNoteIDsChanged.subscribe((note) => {
         this.addNoteDataToTaskMap(note.noteID);
      });

      this.setAssetForTask();

      this.task.checklistInstructions = String(this.task.checklistInstructions ?? "");

      this.hoursForDisplay = String(
         this.taskDisplayData?.checklistPromptTimeTotalHours,
      ).padStart(2, "0");
      this.minutesForDisplay = String(
         this.taskDisplayData?.checklistPromptTimeTotalMinutes,
      ).padStart(2, "0");

      if (this.task.billableTime) {
         this.billableHours = Number(Math.floor(this.task.billableTime / 3600));
         this.billableMinutes = Number(Math.floor((this.task.billableTime / 60) % 60));
      }

      if (this.task.checklistEstTime) {
         this.checklistEstTimeHours = Number(
            Math.floor(this.task.checklistEstTime / 3600),
         );

         if (this.checklistEstTimeHours > 1) {
            this.hoursOrMinutes = this.lang().hrs;
         } else if (this.checklistEstTimeHours == 1) {
            this.hoursOrMinutes = this.lang().hr;
         } else {
            this.hoursOrMinutes = this.lang().mins;
         }

         this.checklistEstTimeMinutes = Number(
            Math.floor((this.task.checklistEstTime / 60) % 60),
         );
         this.checklistEstTimeHours = String(this.checklistEstTimeHours).padStart(2, "0");
         this.checklistEstTimeMinutes = String(this.checklistEstTimeMinutes).padStart(
            2,
            "0",
         );
      } else {
         this.checklistEstTimeHours = "00";
         this.checklistEstTimeMinutes = "00";
      }

      if (this.task.checklistDowntime) {
         this.checklistDowntimeHours = Number(
            Math.floor(this.task.checklistDowntime / 3600),
         );

         this.checklistDowntimeMinutes = Number(
            Math.floor((this.task.checklistDowntime / 60) % 60),
         );
      }

      if (this.locations[this.task.locationID]) {
         if (this.locations[this.task.locationID].machineDownAlert == 1) {
            //sets if we should show machinedown alert button
            this.showMachineDownAlert = true;
            this.showMachineDownAlertButtonText =
               this.locations[this.task.locationID].machineDownAlertButtonText ??
               this.i18n().t("MachineDownAlert");
         } else {
            this.showMachineDownAlert = false;
         }
      }

      if (this.data.mode) {
         this.taskFormState.updateMode(this.data.mode);
      } else {
         //if we want to override these settings simply pass in this.data.mode as template or instance
         if (this.task.checklistTemplate === 0) {
            //it is an instance
            this.taskFormState.updateMode("instance");
         } else {
            this.taskFormState.updateMode("template");
         }
      }

      if (this.task) {
         this.taskFormState.updateTasksEditable(this.editable());
         const externalUserOverwrideActive = false;
         this.taskFormState.updateTasksEditable(
            this.editable(externalUserOverwrideActive),
         );
      }

      this.getElapsedTimeDuration();
      this.modeTransitionService.modeTransition$
         .pipe(
            filter((offlineMode) => offlineMode === OfflineMode.OFFLINE),
            take(1),
         )
         .subscribe(() => {
            this.storeElapsedTimeDuration();
         });
      this.checkIfCanRecreateWorkRequest();
   }

   private setAssetForTask() {
      if (this.task === undefined) {
         return;
      }

      if (this.task.assets && this.task.assets.length > 0) {
         const potentialAsset = this.task.assets.find(
            (asset) => asset.assetID === this.task.assetID,
         );
         if (!potentialAsset && this.task.assetID) {
            this.asset.set(this.manageAsset.getAsset(this.task.assetID));
            return;
         }
         this.asset.set(potentialAsset);
      } else if (this.task.assetID) {
         this.asset.set(this.manageAsset.getAsset(this.task.assetID));
      }

      const asset = this.asset();

      if (asset) {
         this.displayAssetInfo.set(
            this.manageTask.toggleAssetFieldInfoDisplayLegacy(
               asset,
               this.task.checklistID,
               this.taskViewModel(),
            ),
         );
      }
   }

   private updatePurchasingData() {
      if (this.task === undefined) {
         return;
      }
      this.sortParts();
      this.calcIfMinPartQtyTask();
      this.tellTaskToRefresh(false);
      const purchaseOrders =
         this.managePO.findPurchaseRequestsStartedFromTask(this.task.checklistID) ||
         new Lookup("poID", []).orderBy("date");
      this.requestedPurchases = purchaseOrders.map(([poID, purchaseOrder]) => [
         poID,
         {
            ...this.managePO.getPurchaseOrderCurrentState(poID),
            poID,
            poNumber: purchaseOrder.poNumber,
            state: purchaseOrder.state,
            requestDisapproveReason: purchaseOrder.requestDisapproveReason,
            poNumberForDisplay:
               this.managePO.getPurchaseOrderNumberForDisplay(poID)?.poNumberForDisplay,
         },
      ]);
   }

   //  Add code to listen for when someone tries to print. If they try to print trigger print function
   printOverride = (event) => {
      if (event.ctrlKey && event.keyCode === 80) {
         event.preventDefault();
      }
   };

   private promptTimeFromViewing(): void {
      const time = this.timerComponent()?.getElapsedTime();
      if (time === undefined || time === 0) {
         return;
      }
      if (this.taskFormState.itemsEditable() !== true) {
         return;
      }
      //only ask them if they can actually leave time
      //promise based
      if (this.task.checklistDepreciated) {
         return; //this catches starting tasks on the fly so we don't start this for them
      }
      if (this.task.checklistTemplate) {
         return; //never do this for any templates
      }
      if (this.task.checklistUserCompleted) {
         return; //never do it for any completed tasks
      }

      const customerSetting =
         this.manageUser.getCurrentUser().userInfo.customerClosedTaskWithoutLoggingTime;

      if (time < customerSetting * 60) {
         return; // don't do it for the custom defined interval.
      }

      if (customerSetting === 0) {
         return; //if they don't have this enabled don't do it ;p
      }

      if (!this.featureTimeCost) {
         return; //if they don't have the feature don't do it
      }

      const extraMsg = this.lang().ForgotToLogTimeMsg;
      const extraMsgTooltip = this.lang().ForgotToLogTimeTooltip;
      this.logTimeOnTask(extraMsg, extraMsgTooltip);
   }

   /**
    * Grey out instructions that are preceded by blocking instructions.
    * A greyed out instruction will not be able to be completed until all the
    * blocking instructions have been completed.
    * Currently there are 3 types of blocking instructions:
    *    Request Approval (TypeId == 16)
    *    Transfer Task (TypeId == 8)
    *    Verify Location (TypeId == 19)
    */
   setGreyOut = () => {
      if (this.taskFormState.mode() === "instance") {
         for (const item of this.items()) {
            item.greyOut = false;
         }

         for (const item of this.items()) {
            if (
               (item.itemTypeID === TaskInstructionTypeID.RequestApproval ||
                  item.itemTypeID === TaskInstructionTypeID.Reassign ||
                  item.itemTypeID === TaskInstructionTypeID.VerifyLocation) &&
               item.done === false &&
               this.doWeCheck(item)
            ) {
               this.greyOutMyKids(item);
               this.greyOutMyOlderSiblings(item);
            }
         }
      }
   };

   greyOutMyKids = (itemIn) => {
      for (const item of this.items()) {
         if (item.itemParentID === itemIn.checklistItemCount && this.doWeCheck(item)) {
            item.greyOut = true;
            this.greyOutMyKids(item);
            this.greyOutMyOlderSiblings(item);
         }
      }
   };

   greyOutMyOlderSiblings = (itemIn) => {
      for (const item of this.items()) {
         if (
            item.itemParentID === itemIn.itemParentID &&
            item.itemOrder > itemIn.itemOrder &&
            this.doWeCheck(item)
         ) {
            item.greyOut = true;
            this.greyOutMyKids(item);
         }
      }
   };

   switchMode = (setLimited) => {
      if (this.info === undefined) return;
      //stupid catch all because apple is a POS that detects taps as double taps sometimes ;p
      if (this.preventDoubleClick === true) {
         return;
      }
      this.preventDoubleClick = true;
      this.taskFormState.updatePreventDoubleClick(true);
      setTimeout(() => {
         this.taskFormState.updatePreventDoubleClick(false);
         this.preventDoubleClick = false;
      }, 250);

      if (this.mode() === "template") {
         this.taskFormState.updateMode("instance");
      } else {
         this.taskFormState.updateMode("template");
         if (
            this.currentUser.userInfo.customerStartingAWODefaultState === 1 ||
            this.currentUser.userInfo.customerStartingAWOTemplateDefaultState === 1
         ) {
            this.settings();
         }
      }

      this.taskFormState.updateTasksEditable(this.editable());
      const workOrderValidationActive = false;
      this.taskFormState.updateItemsEditable(this.editable(workOrderValidationActive));

      this.taskFormState.updateLimited(setLimited);

      //put at the end of the stack so data is built after everything else comes through
      setTimeout(() => {
         this.tellTaskToRefresh(true);
         this.chkItemsCollectionSub?.unsubscribe();
         this.chkItemsCollectionSub = undefined;
      }, 0);
   };

   settings = () => {
      this.data.settings = !this.data.settings;
   };

   private checkIfCanRecreateWorkRequest() {
      const location = this.locations[this.task.locationID];
      const globalSettings = this.manageUser.getCurrentUser().userInfo;
      this.canRecreateWorkRequest.set(false);
      const settings = {};
      if (
         location &&
         this.task.checklistTemplate === 0 &&
         this.task.checklistTemplateOld === 2 &&
         this.task.checklistBatchID === 300112
      ) {
         // checking for WR templates in custom dropdowns
         for (let index = 1; index <= 3; index++) {
            if (location[`reportProblemShowCustomDropdown${index}`] === -1) {
               //-1 means we use globally
               settings[`reportProblemShowCustomDropdown${index}`] =
                  globalSettings[`reportProblemShowCustomDropdown${index}`];
            } else {
               settings[`reportProblemShowCustomDropdown${index}`] =
                  location[`reportProblemShowCustomDropdown${index}`];
            }
            if (location[`reportProblemShowCustomDropdown${index}Options`] === "{}") {
               // {} means we should use global
               settings[`reportProblemShowCustomDropdown${index}Options`] =
                  globalSettings[`reportProblemShowCustomDropdown${index}Options`];
            } else {
               settings[`reportProblemShowCustomDropdown${index}Options`] =
                  location[`reportProblemShowCustomDropdown${index}Options`];
            }

            if (settings[`reportProblemShowCustomDropdown${index}Options`] !== "{}") {
               try {
                  settings[`reportProblemShowCustomDropdown${index}OptionsArr`] =
                     JSON.parse(
                        settings[`reportProblemShowCustomDropdown${index}Options`],
                     );
               } catch {
                  console.error(
                     `Failed to parse reportProblemShowCustomDropdown${index}OptionsArr`,
                  );
                  settings[`reportProblemShowCustomDropdown${index}OptionsArr`] = [];
               }
               for (const setting of settings[
                  `reportProblemShowCustomDropdown${index}OptionsArr`
               ]) {
                  if (setting.checklistID > 0) {
                     this.canRecreateWorkRequest.set(true);
                     return;
                  }
               }
            }
         }
         // checking for WR templates in email options
         let emailToTask = this.manageLocation.getEmailToTask();

         if (this.task.locationID > 0) {
            emailToTask = this.manageFilters.filterArrayByLocationIDs(emailToTask, [
               this.task.locationID,
            ]);
         }

         for (const endpoint of emailToTask) {
            if (endpoint.checklistID > 0) {
               this.canRecreateWorkRequest.set(true);
               return;
            }
         }
      }
   }

   private async sortParts(): Promise<void> {
      const partRelationIDs =
         "partRelationIDs" in this.task
            ? this.task.partRelationIDs
            : this.task.parts?.map((part) => part.relationID);
      if (!partRelationIDs?.length || !this.parts) {
         return;
      }
      this.partsLookup = this.manageParts.getParts();
      const partRelations = this.manageTask.getPartRelations();

      // Some parts may be missing from a task if they exist at a location the user doesn't have access to
      // So we grab some basic info for those if needed here
      await this.checkForMissingParts(this.parts);

      for (const taskPart of this.parts) {
         taskPart.usedPrice = Number(partRelations.get(taskPart.relationID)?.usedPrice);

         taskPart.suggestedNumber = Number(taskPart.suggestedNumber);
         taskPart.usedNumber = Number(taskPart.usedNumber);
         this.partsPreviousUsedNumbers.set(taskPart.relationID, taskPart.usedNumber); //we use old in case we need to change usedNumber back after a failed update

         //find pending POs.  We filter to only ones we have quantity to use on them ;p
         const pendingPOs: {
            purchaseOrders: Lookup<"poID", PurchaseOrder>;
            extraBatchesByPOID: LimbleMap<number, ExtraBatch>;
         } =
            this.managePO.findPendingPurchaseOrderInfoForPart(taskPart.partID, true) ||
            [];
         const partPendingPOs: Array<
            PurchaseOrder & {
               currentState: PurchaseOrderCurrentState | undefined;
               extraBatch?: ExtraBatch;
            }
         > = [];
         for (const purchaseOrder of pendingPOs.purchaseOrders) {
            if (purchaseOrder.state === null) continue;
            const extraBatch = pendingPOs.extraBatchesByPOID.get(purchaseOrder.poID);
            const currentState = this.managePO.getPurchaseOrderCurrentState(
               purchaseOrder.poID,
            );
            if (purchaseOrder.state <= 97) {
               //if we are still ready to receive or a prior step include
               partPendingPOs.push({ ...purchaseOrder, currentState });
            } else if (
               extraBatch?.partQtyUsed !== null &&
               extraBatch?.partQty &&
               extraBatch.partQtyUsed < extraBatch.partQty
            ) {
               //we are partially received or better so only include if we have items for them to see num available
               partPendingPOs.push({ ...purchaseOrder, currentState, extraBatch });
            } else if (purchaseOrder.state === 98 && extraBatch === undefined) {
               partPendingPOs.push({ ...purchaseOrder, currentState });
            }
         }
         this.partsPendingPOs.set(taskPart.partID, partPendingPOs);
         const selectedPO = this.getPartSelectedPO(taskPart);
         if (selectedPO) {
            this.partsSelectedPOs.set(taskPart.relationID, selectedPO);
         }
      }
      this.parts = orderBy(this.parts, "partName");
      this.partsOverReserved = new Set(
         this.taskPartsAvailabilityService
            .partsOverReserved(this.parts)
            .map((part) => part.partID),
      );
      this.setShowPOsOnParts();
   }

   private getPartSelectedPO(part: TaskPartLegacy): PurchaseOrder | undefined {
      if (part.poItemID === null || part.poItemID === 0) {
         return undefined;
      }
      const partPoId = this.managePO.getPurchaseOrderItem(part.poItemID)?.poID;
      if (partPoId === undefined) {
         return undefined;
      }
      return this.managePO.getPurchaseOrder(partPoId);
   }

   private setShowPOsOnParts() {
      if (!this.parts?.length) {
         return;
      }
      for (const part of this.parts) {
         if ((this.partsPendingPOs.get(part.partID)?.length ?? 0) >= 2) {
            this.showPOsOnParts.set(part.relationID, false);
         } else {
            this.showPOsOnParts.set(part.relationID, true);
         }
      }
   }

   setPermissions = () => {
      if (this.task.checklistTemplate === 0) {
         if (this.task.checklistTemplateOld === 1) {
            this.credToEditOpenTaskInstructions = this.credService.isAuthorized(
               this.task.locationID,
               this.credService.Permissions.EditAnOpenPMsInstructions,
            ); //viewing a PM so check cred for open Task instructions
         } else if (
            this.task.checklistBatchID === 10000 ||
            this.task.checklistBatchID === 300112
         ) {
            this.credToEditOpenTaskInstructions = this.credService.isAuthorized(
               this.task.locationID,
               this.credService.Permissions.EditAnOpenWorkRequestsInstructions,
            ); //viewing WRs and PRs, etc.
         } else {
            this.credToEditOpenTaskInstructions = this.credService.isAuthorized(
               this.task.locationID,
               this.credService.Permissions.EditAnOpenWOsInstructions,
            ); //viewing WOs, etc.
         }
      }

      this.deleteOpenTaskCred = this.credService.isAuthorized(
         this.task.locationID,
         this.credService.Permissions.DeleteOpenTasks,
      );

      this.deleteCompletedTaskCred = this.credService.isAuthorized(
         this.task.locationID,
         this.credService.Permissions.DeleteCompletedTasks,
      );

      this.editCompletedTaskCred = this.credService.isAuthorized(
         this.task.locationID,
         this.credService.Permissions.EditCompletedTasks,
      );

      this.startPOCred = this.credService.isAuthorized(
         this.task.locationID,
         this.credService.Permissions.StartAPO,
      );

      this.tagTaskCred = this.credService.isAuthorized(
         this.task.locationID,
         this.credService.Permissions.TagATaskWithACustomTag,
      );

      this.deleteCommentCred = this.credService.isAuthorized(
         this.task.locationID,
         this.credService.Permissions.RemoveTaskComments,
      );

      this.requestPurchaseCred = this.credService.isAuthorized(
         this.task.locationID,
         this.credService.Permissions.RequestPurchases,
      );

      this.recreateWorkRequestCred = this.credService.isAuthorized(
         this.task.locationID,
         this.credService.Permissions.RecreateWorkRequest,
      );

      this.addCommentCred = this.credService.isAuthorized(
         this.task.locationID,
         this.credService.Permissions.AddTaskComments,
      );

      this.shareTasksCred = this.credService.isAuthorized(
         this.task.locationID,
         this.credService.Permissions.PrintOrShareTasks,
      );

      this.viewLaborCostsCred = this.credService.isAuthorized(
         this.task.locationID,
         this.credService.Permissions.ViewLaborCosts,
      );

      this.viewInvoicesCred = this.credService.isAuthorized(
         this.task.locationID,
         this.credService.Permissions.ViewTaskInvoices,
      );
      this.superUser = this.credService.checkCredGlobal(
         this.credService.Permissions.ManageRoles,
      );

      this.assetCheckInOut = this.credService.isAuthorized(
         this.task.locationID,
         this.credService.Permissions.AssetCheckInOut,
      );

      this.hasLogTimeForOthersCredentials = this.credService.isAuthorized(
         this.task.locationID,
         this.credService.Permissions.LogTimeForOtherUsers,
      );

      this.hasEditCommentCredential = this.credService.isAuthorized(
         this.task.locationID,
         this.credService.Permissions.EditComments,
      );

      this.addPartsToOpenTasksCred = this.credService.isAuthorized(
         this.task.locationID,
         this.credService.Permissions.AddPartsToOpenTasks,
      );
   };

   editable = (externalUserOverwrideActive: boolean = true) => {
      this.setPermissions();
      const task = this.taskViewModel();
      if (!task) {
         return false;
      }

      const editableMode = this.taskEditableService.getEditableMode(
         task,
         this.tempEdit,
         this.data.preview,
         this.taskFormState.mode(),
      );
      const editable = this.taskEditableService.isEditable(
         task,
         this.tempEdit,
         externalUserOverwrideActive,
         this.data.editable,
         this.data.preview,
      );
      const allowEditTemplateInstructions =
         this.taskEditableService.getAllowEditTemplateInstructions(
            task,
            this.customerID,
            this.allowEditTemplateInstructions,
            this.tempEdit,
            this.data.preview,
         );

      const showChangeTaskType = this.taskEditableService.getShowChangeTaskType(
         task,
         this.data.preview,
         this.showChangeTaskType,
         this.tempEdit,
      );

      this.taskFormState.updateMode(editableMode);
      this.taskFormState.updateTasksEditable(editable);
      this.taskFormState.updateItemsEditable(editable);
      this.allowEditTemplateInstructions = allowEditTemplateInstructions;
      this.showChangeTaskType = showChangeTaskType;

      return editable;
   };

   checkCredAtLocation = (locationID, credID) => {
      return this.credService.isAuthorized(locationID, credID);
   };

   calcIfMinPartQtyTask = () => {
      //This is used so that there is a shortcut to start a PO for that specific part
      if (
         this.task.checklistBatchID === 20001 &&
         this.task.checklistPriorBatchID !== null
      ) {
         const partID = this.task.checklistPriorBatchID;
         if (partID !== null && partID !== undefined) {
            this.minPartQtyTask = this.manageParts.getPart(partID);
         }
      }

      if (this.minPartQtyTask) {
         //lets find pending POs for this
         const purchaseOrders = this.managePO.findPendingPurchaseOrderInfoForPart(
            this.minPartQtyTask.partID,
            true,
         ).purchaseOrders;

         this.minPartQtyTask.pendingPOsCurrentStateMap = purchaseOrders.map(
            ([poID, purchaseOrder]) => [
               poID,
               {
                  ...this.managePO.getPurchaseOrderCurrentState(poID),
                  poID,
                  poNumber: purchaseOrder.poNumber,
               },
            ],
         );
      }
   };

   buildData = async (updateInstructions: boolean = true) => {
      await this.setTask(false);
      if (this.task?.checklistID === undefined) {
         return;
      }

      const purchaseOrders =
         this.managePO.findPurchaseRequestsStartedFromTask(this.task.checklistID) ||
         new Lookup("poID", []).orderBy("date");
      this.requestedPurchases = purchaseOrders.map(([poID, purchaseOrder]) => [
         poID,
         {
            ...this.managePO.getPurchaseOrderCurrentState(poID),
            poID,
            poNumber: purchaseOrder.poNumber,
            state: purchaseOrder.state,
            requestDisapproveReason: purchaseOrder.requestDisapproveReason,
            poNumberForDisplay:
               this.managePO.getPurchaseOrderNumberForDisplay(poID)?.poNumberForDisplay,
         },
      ]);

      if (Number(this.task.checklistDepreciated) > 0) {
         //basically when someone is starting a WO the WO is depreciated until it goes live.  We want to limit some of this functionality as they are basically previewing it before they make it go live.
         this.EditingWOTemplateInstanceBeforeLive = true;
      } else {
         this.EditingWOTemplateInstanceBeforeLive = false;
      }

      if (this.manageUser.getCurrentUser()?.currency !== undefined) {
         this.currencySymbol = this.manageUser.getCurrentUser().currency.symbol;
      }

      this.setAssetForTask();
      this.calcIfMinPartQtyTask();

      if (this.task.checklistTemplate && this.task.checklistDepreciated === 0) {
         //we are editing one of the templates so limit the capabilities.  We add checklistDepreciated because sometimes we have temporairy templates loaded (start WO process)

         this.taskFormState.updateLimited(true);
      }

      this.taskDisplayData = this.taskViewModelMapperService.getTaskDisplayData(
         this.task,
      );

      this.setCustomTagData();

      this.taskFormState.updateTasksEditable(true);
      this.taskFormState.updateItemsEditable(true);

      this.taskFormState.updateTasksEditable(this.editable());
      const workOrderValidationActive = false;
      this.taskFormState.updateItemsEditable(this.editable(workOrderValidationActive));

      //process saved snapshot of asset info if task is complete
      if (this.task.checklistStatusID) {
         this.parseSavedAssetInfo();
      }

      this.setGeolocation();

      if (updateInstructions) {
         await this.loadTaskInstructions();
      } else {
         this.loadedItems.set(true);
      }

      this.checkTaskRelations();
      this.taskDisplayData = this.taskViewModelMapperService.getTaskDisplayData(
         this.task,
      );
   };

   private async loadTaskInstructions() {
      const task = this.taskViewModel();
      if (!task) {
         return;
      }
      const taskItemsResult = await lastValueFrom(
         from(this.manageTaskItem.getTaskItems(task.checklistID)).pipe(
            takeUntilDestroyed(this.destroyRef),
         ),
      );
      assert(this.info !== undefined);

      let taskInstructions = taskItemsResult.data.items;
      taskInstructions = taskInstructions.map((instruction) => {
         let itemResponse: string | number = "";

         if (instruction.itemTypeID === TaskInstructionTypeID.Number) {
            if (instruction.itemResponse === "" || instruction.itemResponse === false) {
               itemResponse = "";
            } else {
               itemResponse = Number(instruction.itemResponse);
            }
         } else {
            itemResponse = instruction.itemResponse;
         }

         let instructionText = instruction.itemText.replace(/&quot;/g, "'");
         instructionText = instruction.itemText.replace(/&gt;/g, ">");
         instructionText = instruction.itemText.replace(/&lt;/g, "<");

         for (const past of instruction.pastResponses) {
            const date = new Date(past.checklistCompletedDate * 1000);
            let day: any = date.getDate();
            if (day < 10) {
               day = `0${day}`;
            }
            past.dateStr = `${date.getMonth() + 1}/${day}/${date.getFullYear()}`;
         }

         if (instruction.instructionalImages) {
            instruction.instructionalImages.forEach((image) => {
               image.getURL = image.src;
               image.fileName = image.itemFileName;
            });
         }

         return {
            ...instruction,
            itemText: instructionText,
            hovered: taskItemsResult.data.items.length < 100,
            collapsed: instruction.itemCollapsed !== 0,
            itemResponse: itemResponse,
            itemOrder: Number(instruction.itemOrder),
            viewOnly: true,
         };
      });

      taskItemsResult.data.items = taskInstructions;

      if (taskItemsResult.data.userThatStartedThisTask > 0) {
         this.userStartedThisTask = {};
         this.userStartedThisTask.userID = taskItemsResult.data.userThatStartedThisTask;

         const startedUser = this.manageTask.getTaskUser(this.userStartedThisTask.userID);
         if (startedUser) {
            this.userStartedThisTask.fullName = this.manageUser.getUserFullName(
               this.userStartedThisTask.userID,
            );
         } else {
            this.userStartedThisTask = false;
         }
      } else {
         this.userStartedThisTask = false;
      }

      const options = taskItemsResult.data.options.map((option) => {
         if (option.itemOptionCollapsed === 0) {
            option.collapsed = false;
         } else {
            option.collapsed = true;
         }
         option.itemOptionOrder = Number(option.itemOptionOrder);
         return option;
      });

      taskItemsResult.data.options = options;

      let returnedInstructions = taskItemsResult.data.items.map((returnedInstruction) => {
         if (returnedInstruction.itemTypeID === TaskInstructionTypeID.Number) {
            if (
               returnedInstruction.itemResponse !== 0 &&
               (returnedInstruction.itemResponse === "" ||
                  returnedInstruction.itemResponse === false)
            ) {
               returnedInstruction.itemResponse = "";
            } else {
               returnedInstruction.itemResponse = Number(
                  returnedInstruction.itemResponse,
               );
            }
         }

         returnedInstruction.itemText = returnedInstruction.itemText.replace(
            /&gt;/g,
            ">",
         );
         returnedInstruction.itemText = returnedInstruction.itemText.replace(
            /&lt;/g,
            "<",
         );

         for (const past of returnedInstruction.pastResponses) {
            const date = new Date(past.checklistCompletedDate * 1000);
            let day: any = date.getDate();
            if (day < 10) {
               day = `0${day}`;
            }
            past.dateStr = `${date.getMonth() + 1}/${day}/${date.getFullYear()}`;
         }

         returnedInstruction.itemOrder = Number(returnedInstruction.itemOrder);
         return returnedInstruction;
      });

      /* Prevent item.task from being undefined in places which use item.task in the file.
      If an instruction is AssignPM or StartWO, this will be overridden below. */
      returnedInstructions = returnedInstructions.map((item) => {
         if (item?.checklistID && item.checklistID === this.task.checklistID) {
            return {
               ...item,
               task: this.task,
            };
         }
         return { ...item };
      });

      const taskIDs: number[] = returnedInstructions
         .filter((item) => {
            return (
               item.itemTypeID === TaskInstructionTypeID.AssignPM ||
               item.itemTypeID === TaskInstructionTypeID.StartWO ||
               item.itemTypeID === TaskInstructionTypeID.Reassign ||
               item.itemTypeID === TaskInstructionTypeID.AssignAuditPM
            );
         })
         .map((item) => {
            return item.itemResponse as number;
         })
         .filter((taskID) => {
            return taskID > 0;
         });

      if (taskIDs.length > 0) {
         // These, alongside the instructions should eventually be fetched through a new JIT endpoint which returns the instructions with these as a column, rather than manually pairing them up.
         const instructionTasks = await this.tasksApiService.getAllItems({
            filters: { taskIDs: taskIDs },
         });

         returnedInstructions = returnedInstructions.map((item) => {
            if (
               !(
                  item.itemTypeID === TaskInstructionTypeID.AssignPM ||
                  item.itemTypeID === TaskInstructionTypeID.StartWO ||
                  item.itemTypeID === TaskInstructionTypeID.Reassign
               )
            ) {
               return { ...item };
            }

            return {
               ...item,
               task: instructionTasks?.find((innerTask) => {
                  return innerTask.checklistID === Number(item.itemResponse);
               }),
            };
         });
      }
      this.items.set(orderBy(returnedInstructions, "itemOrder"));
      this.loadedItems.set(true);

      const regex = /(?:\.([^.]+))?$/;
      for (const file of taskItemsResult.data.files) {
         const tempFile = regex.exec(file.fileName);
         if (tempFile) {
            file.ext = tempFile[1];
         }
      }

      this.info.files = taskItemsResult.data.files;

      //set info for fileUploader
      for (const file of this.info.files ?? []) {
         if (this.manageFiles.imageExts.includes(file.ext)) {
            file.getURL = `viewFile.php?f=upload-${this.customerID}/${file.checklistID}-${file.itemID}/${file.fileName}`;

            file.parentRef = "itemID";
            file.deleteData = {
               fileName: file.fileName,
               checklistID: file.checklistID,
               itemID: file.itemID,
            };
         }
      }

      this.info.items = this.items();
      this.info.files = taskItemsResult.data.files;

      this.setGuides();

      this.loading = false;

      this.taskFormState.updateOptions(taskItemsResult.data.options ?? []);
      this.parts = taskItemsResult.data.parts;
      this.sortParts();

      //set items so they can be accessed in complete Task
      this.dataForCompleteTask = {};
      this.dataForCompleteTask.items = this.info.items;

      //process saved snapshot of asset info if task is complete
      if (this.task.checklistStatusID) {
         this.parseSavedAssetInfo();
      }

      this.setGeolocation();

      //update instruction storage
      this.instructionStorageSyncService.syncInstructionsByTask(this.task.checklistID);

      this.checkTaskRelations();
      this.taskDisplayData = this.taskViewModelMapperService.getTaskDisplayData(
         this.task,
      );
   }

   private async loadRelatedTask(relatedTaskID: number) {
      const relatedTask = await lastValueFrom(
         this.tasksApiService.getById(relatedTaskID),
      );
      return relatedTask
         ? {
              relatedTaskName: relatedTask.checklistName ?? "",
              relatedChecklistID: relatedTaskID,
              relatedTaskStatusName:
                 (await this.getTaskStatusByTaskID(relatedTaskID)) ?? "",
           }
         : undefined;
   }

   private checkTaskRelations() {
      this.taskRelationsLoadedSub = this.manageObservables.setSubscription(
         "taskRelationsLoaded",
         async (loaded) => {
            this.relationData = null; //reset
            if (!loaded || this.currentUser.workOrderUser === 1) {
               return;
            }
            const relation = this.manageTask.checkRelation(this.task.checklistID);

            if (!relation.success) {
               return;
            }

            const originalChecklistStatusName = await this.getTaskStatusByTaskID(
               relation.relation?.originalChecklistID ?? 0,
            );

            const relationData: TaskRelationData = {
               ...relation.relation,
               originalTaskStatusName: originalChecklistStatusName,
            } as unknown as TaskRelationData;

            this.relationData = relationData;
         },
      );
   }

   public async getTaskStatusByTaskID(
      taskID: number | undefined,
   ): Promise<string | undefined> {
      if (!taskID) return undefined;

      //refresh the task data first to get the latest status
      await this.setTask();
      const task = this.task;

      if (task === undefined) {
         return undefined;
      }

      if (task?.checklistDepreciated) {
         return this.lang().Deleted;
      }

      const statusInfo = this.manageTask.getStatusInfo(task.statusID);
      return statusInfo?.statusName;
   }

   protected popTask(checklistID: number, options = {}): void {
      const instance = this.modalService.open(PopTask);
      this.paramsService.params = {
         modalInstance: instance,
         resolve: {
            dataLogOptions: this.dataLogOptions,
            data: {
               checklistID: checklistID,
               editable: true,
               options,
            },
         },
      };

      instance.result.then(async () => {
         //keep associated task status updated
         if (this.relationData?.originalChecklistID) {
            const originalChecklistStatusName = await this.getTaskStatusByTaskID(
               this.relationData.originalChecklistID,
            );
            this.relationData.originalTaskStatusName = originalChecklistStatusName;
         }

         if (this.relatedTask()?.relatedChecklistID) {
            const relatedTaskStatusName =
               (await this.getTaskStatusByTaskID(
                  this.relatedTask()?.relatedChecklistID,
               )) ?? "";
            this.relatedTask.update((relatedTask) => {
               if (!relatedTask) return relatedTask;
               relatedTask.relatedTaskStatusName = relatedTaskStatusName;
               return relatedTask;
            });
         }
      });
   }

   popAsset = (task) => {
      const instance = this.modalService.open(PopAsset);
      this.paramsService.params = {
         modalInstance: instance,
         resolve: {
            assetID: task.assetID,
            locationID: task.locationID,
            data: {
               restrict: false,
            },
         },
      };
   };

   public handleClickOnShareTaskExternally(event, task): void {
      if (!this.featureShareTaskExternally) {
         // Stop propagation and return early because the customer does not have this feature
         // enabled. See limUiPopoverHidden in the template.
         event.stopPropagation();
         return;
      }
      this.shareTask(task);
   }

   public shareTask(task: Task): void {
      const defaultEmailMessage = `${
         this.lang().ShareTaskExternallyEmailTemplate + this.currentUser.userInfo.fName
      } ${this.currentUser.userInfo.lName}<br />${
         this.currentUser.userInfo.customerSignature
      }<br />${this.currentUser.userInfo.userEmail}`;

      let emailMessage =
         this.locations[task.locationID].shareTaskDefaultEmailMessage ||
         defaultEmailMessage;
      let taskInfo = `${task.checklistName} - #${task.checklistID}`;

      if (this.assetNameStr()) {
         taskInfo += ` - ${this.assetNameStr()}`;
      }

      const locationName = this.manageLocation.getLocation(task.locationID)?.locationName;
      taskInfo += ` at ${locationName} `;

      let emailSubject =
         this.locations[task.locationID].shareTaskDefaultEmailSubject ||
         `${this.lang().SharedTask} "${taskInfo}"`;

      // The /{{task-info}}/g finds all instances of {{task-info}}, rather than just the first
      if (emailSubject.includes("{{task-info}}")) {
         emailSubject = emailSubject.replace(/{{task-info}}/g, taskInfo);
      }

      if (emailMessage.includes("{{task-info}}")) {
         emailMessage = emailMessage.replace(/{{task-info}}/g, taskInfo);
      }

      if (emailMessage.includes("{{task-link}}")) {
         emailMessage = emailMessage.replace(/{{task-link}}/g, "<a href>Task</a>");
      }

      const instance = this.modalService.open(ShareTaskTemplate);

      this.paramsService.params = {
         modalInstance: instance,
         resolve: {
            message: this.lang().shareTaskMessage,
            title: this.lang().shareTaskTitle,
            data: {
               recipients: `${this.lang().sample}@${this.lang().vendor}.com`,
               emailSubject: emailSubject,
               emailMessage: emailMessage,
               task: task,
               emailTo: true,
               defaultEmailMessage: defaultEmailMessage,
               locationID: task.locationID,
            },
         },
      };
   }

   shareInternalLink = (task: TaskEntity | TaskTemplateEntity) => {
      let url;
      if (
         window.location.hostname === "localhost" ||
         window.location.hostname === "localhost:4200"
      ) {
         url = `${window.location.hostname}:4200`;
      } else {
         url = `https://${window.location.hostname}`;
      }
      url += `/task/${task.checklistID}/${task.locationID}`;
      const test = $("<input>");
      test.val(url).appendTo("body").select();
      document.execCommand("copy");

      test.remove();
      this.alertService.addAlert(
         `${this.lang().shareInternalLink1}: <b>${url}</b> ${this.lang().shareInternalLink2}`,
         "success",
         20000,
      );
   };

   protected reset() {
      if (!this.manageLogin.credCheckEditChecklist(this.task)) {
         this.alertService.addAlert(this.lang().credFailGeneric, "warning", 6000);
         return;
      }

      const instance = this.modalService.open(Confirm);

      this.paramsService.params = {
         modalInstance: instance,
         resolve: {
            message: this.lang().ResetTaskMsg,
            title: this.lang().ResetTask,
         },
      };

      instance.result.then((result) => {
         if (result == 1) {
            this.loadingBarService.show({ header: this.lang()?.WakingUpHamsters });
            const post = this.manageTask.resetTask(this.data.checklistID);

            post.then((answer) => {
               this.loadingBarService.remove();
               if (answer?.data.success == true) {
                  //so we can see the associated tasks disappear
                  this.tellTaskToRefresh(true);
                  this.instructionStorageSyncService.syncInstructionsReset(
                     this.task.checklistID,
                  );
               } else if (answer?.data.reason === "alreadyCompleted") {
                  this.alertService.addAlert(
                     this.lang().AlreadyCompletedMsg,
                     "warning",
                     6000,
                  );
               } else {
                  this.alertService.addAlert(this.lang().errorMsg, "danger", 6000);
               }
            });
         }
      });
   }

   exitExternalUser = () => {
      this.router.navigate(["/thanks", "false"]);
   };

   completeExternalUser = () => {
      if (!this.manageLogin.credCheckEditChecklist(this.task)) {
         this.alertService.addAlert(this.lang().credFailGeneric, "warning", 6000);
         return;
      }

      if (this.currentUser.workOrderUser === 1) {
         //we don't need to check for calc completion as a vendor may only need to do a portion of the task
         //complete task externalUser here.
         //this wont be a real complete, it will just comment the task and say it's complete.
         //pop up modal here to confirm that the task will be complete and warn the user they will not get nofifications anymore.
         const instance = this.modalService.open(CompleteExternalTask);
         this.paramsService.params = {
            modalInstance: instance,
            resolve: {
               message: this.lang().FinishTaskExternalUserMsg,
               title: this.lang().FinishTaskExternalUser,
               task: this.task,
               parts: this.parts,
               link: this.route.snapshot.paramMap.get("link"),
            },
         };
      }
   };

   //this functions primary purpose is to sort and process any parts return or extrabatches returned.
   //this is because certain functions change part qtys and we need an easy way to update them.
   processReturnPartsExtraBatchesAndRemoveRelations = async (answer) => {
      if (this.parts === undefined) {
         console.error(
            "Parts are undefined in processReturnPartsExtraBatchesAndRemoveRelations",
         );
         return;
      }
      const partIDsForUpdatePartOverstocked = answer.data.partIDsForUpdatePartOverstocked;
      //have to update extrabatches that were changed
      if (answer.data.returnExtraBatches) {
         for (const extraBatch of answer.data.returnExtraBatches) {
            const ebLookup = this.manageParts.getExtraBatches();
            const lookupBatch = ebLookup.get(extraBatch.extraBatchID);
            if (lookupBatch) {
               lookupBatch.partQty = Number(extraBatch.partQty);
               lookupBatch.partQtyUsed = Number(extraBatch.partQtyUsed);
               lookupBatch.partPrice = Number(extraBatch.partPrice);
            } else {
               this.manageParts.addExtraBatchToLookup(extraBatch);
            }
         }
      }

      //have to update parts that were changes
      if (answer.data.returnParts) {
         for (const part of answer.data.returnParts) {
            const partsLookup = this.manageParts.getParts();
            if (partsLookup.get(part.partID)) {
               const partToUpdate = partsLookup.get(part.partID);
               assert(partToUpdate);
               partToUpdate.partQty = Number(part.partQty);
               partToUpdate.partOverstocked = part.partOverstocked;
               this.manageParts.calculatePartData(partToUpdate);
               if (partIDsForUpdatePartOverstocked?.includes(part.partID)) {
                  partToUpdate.partOverstocked = 0;
               }
            }
         }
      }

      //have to clean up relations that were removed as well.
      if (answer.data.returnRemoveRelations) {
         for (const relationID of answer.data.returnRemoveRelations) {
            const relations = this.manageTask.getPartRelations();
            for (const relation of relations) {
               if (relationID == relation.relationID) {
                  relations.delete(relationID);
               }
            }
         }
      }
      this.parts = await this.manageTask.getTaskParts(this.task.checklistID);
   };

   processCompleteTask = (
      promptTime: number,
      downtime: number,
      parts: Array<[number, number | null, number | null, number]>,
      result: Record<any, any>,
   ) => {
      assert(this.info !== undefined);
      const post = this.manageTask.finishChecklist(
         this.data.checklistID,
         promptTime,
         downtime,
         parts,
         result.notesToTheRequestor,
         result.multiWork,
         result.categoryID || result.selectedCategory, // https://limble.atlassian.net/browse/CASE-4448
         this.task.locationID,
      );

      post.then(async (answer) => {
         if (answer === undefined) {
            console.error("Could not complete task, error in manageTask.finishChecklist");
            return;
         }
         assert(this.info !== undefined);
         this.loadingBarService.remove();
         if (answer.data.success == true) {
            //creates/updates the asset information from asset fields set to display on tasks, creating a snapshot
            this.updateAssetInfoFromCompletion();

            //updates the resulting parts in the parts flat data source
            this.processReturnPartsExtraBatchesAndRemoveRelations(answer);

            //answer.data.newTasks will contain any tasks that were created as a result of finishing the task
            if (answer.data?.newTasks?.length > 0) {
               for (const task of answer.data.newTasks) {
                  //if temp part id is set that means this new task was created from the overstocked paramter and not by another way such as recurrance type 7 or 8 that might create a task if asset information is updated based on type 7 and 8
                  if (task.tempPartID > 0) {
                     const tempPart = this.manageParts.getPart(task.tempPartID);
                     if (tempPart) {
                        tempPart.partOverstocked = 1;
                     }
                  }
               }

               this.alertService.addAlert(
                  `<i class='fa-solid fa-circle-exclamation fa-fw'></i> ${this.lang().CompleteTaskExtraTaskMsg}`,
                  "success",
                  5000,
               );
            }

            // refreshes parts when a cycle count tasks gets completed
            if (this.data.checklistTemplateOld == 5) {
               this.manageParts.getData();
            }

            this.tellTaskToRefresh(true);

            this.taskFormState.updateTasksEditable(false);
            this.taskFormState.updateItemsEditable(false);

            if (answer.data.reloadScheduleData == true && !isMobile()) {
               this.manageTask.getData();
            }

            if (answer.data.reloadPOs == true) {
               this.managePO.getData();
            }

            //now we need to close the model we are in.  I wish I knew a better way to do this.
            // Also handle the completed task gif
            setTimeout(() => {
               if (this.modalRef === null) {
                  if (isMobile()) {
                     this.router.navigate(["/mobileTasks"]);
                  }
               } else {
                  this.modalRef.close();
               }
               this.taskOpen = false;

               let showCongratsMsg = false;

               if (this.manageUser.getCurrentUser().userInfo.showCongratsMsg == 1) {
                  //they also have to have it enabled
                  showCongratsMsg = true;
               }

               if (showCongratsMsg) {
                  this.manageFiles.showCompletedTaskGif(15000);
               } else {
                  this.alertService.addAlert(
                     `<i class='fa-regular fa-square-check fa-fw'></i> ${this.lang().TaskSuccessfullyCompleted}`,
                     "success",
                     5000,
                  );
               }
            }, 50);
            // Ensure that local data is synced up with the db after marking the task as complete
            const refreshedTask = await lastValueFrom(
               this.tasksApiService.getById(this.task.checklistID, {
                  columns: "comments,invoices,extraTime,parts,partsDetails,locationName",
               }),
            );
            if (refreshedTask) this.task = refreshedTask;
            this.taskViewModel.set(
               this.taskViewModelFactoryService.getTaskDataViewerViewModel(this.task),
            );
         } else {
            const answerDataReason =
               this.taskCompleterService.parseFailureReasonFromAnswer(answer);
            if (answerDataReason) {
               this.alertService.addAlert(answerDataReason, "warning", 6000);
            } else {
               this.alertService.addAlert(this.lang().errorMsg, "danger", 6000);
            }

            this.setGuides();
         }
      });
   };

   logTimeOnTask = async (extraMsg?, extraMsgTooltip?) => {
      if (!this.featureTimeCost) {
         return;
      }

      //stupid catch all because apple is a POS that detects taps as double taps sometimes ;p
      this.taskFormState.updatePreventDoubleClick(true);
      this.preventDoubleClick = true;

      if (!this.manageLogin.credCheckEditChecklist(this.task)) {
         this.alertService.addAlert(this.lang().credFailGeneric, "warning", 6000);
         return;
      }
      const instance = this.modalService.open(LogTimeOnTask);
      this.paramsService.params = {
         modalInstance: instance,
         resolve: {
            message: this.lang().LogTimeMsg,
            title: this.lang().LogTime,
            data: {
               locationID: this.task.locationID,
               buttonText: this.lang().RecordTime ?? "",
               checklistID: this.task.checklistID,
               extraMsg: extraMsg,
               extraMsgTooltip: extraMsgTooltip,
               showNotes: true,
               timer: this.timerComponent()?.getTimerDuration(),
            },
         },
      };

      const result = await instance.result;
      this.taskFormState.updatePreventDoubleClick(false);
      this.preventDoubleClick = false;
      if (!result) {
         return;
      }
      const totalSeconds = result.minutes * 60 + result.hours * 60 * 60;

      const addNote = false;
      const answer = await this.manageTask.logTimeOnTask(
         this.task.checklistID,
         totalSeconds,
         result.userID,
         result.loggedAt,
         result.notes,
         result.categoryID,
         addNote,
         result.noteHidden,
      );
      if (answer.data.success === true) {
         const newExtraTime = answer.data.extraTime;
         this.taskViewModel.update((task) => {
            if (!task) return task;
            return {
               ...task,
               extraTime: [...(task.extraTime ?? []), newExtraTime],
            };
         });
         if (!this.disableAlerts()) {
            this.alertService.addAlert(this.lang().successMsg, "success", 1000);
         }
         this.timerComponent()?.reset();
         this.tellTaskToRefresh();
      } else {
         this.alertService.addAlert(this.lang().errorMsg, "danger", 6000);
      }
      this.updateTaskStateService.timeLogged();
      this.taskFormState.updatePreventDoubleClick(false);
      this.preventDoubleClick = false;
   };

   protected async addNoteDataToTaskMap(noteID: number): Promise<void> {
      const note = this.manageTask.getComment(noteID);
      if (note === undefined) {
         return;
      }
      const noteData = this.manageTask.getTaskNotesCalculatedInfoLegacy(note);
      this.taskDisplayData?.noteData?.set(noteID, noteData);

      await this.setTask();
   }

   addNewMentionsToChecklistNotifications = async (
      newMentionedList,
      checklistID: number,
   ) => {
      if (newMentionedList === undefined || newMentionedList?.length === 0) return;
      try {
         const result =
            await this.manageTask.getWhoWillReceiveTaskNotifications(checklistID);

         for (const item of newMentionedList) {
            if (item?.tagDescription) {
               this.mentionClickTag(item);
            } else {
               if (
                  result?.data?.success !== true ||
                  (result?.data?.recips && !result.data.recips[item.userID])
               ) {
                  this.manageTask.addUserToChecklistNotifications(
                     item.userID,
                     checklistID,
                     "mentioned by",
                  );
               }
            }
         }
      } catch (error) {
         console.error("Error fetching who will receive task notifications:", error);
      }
   };

   protected async requestPurchase(): Promise<void> {
      const task = this.taskViewModel();
      if (!task) {
         return;
      }
      if (!this.featureUnlimitedPOs && !this.featureLimitedNumber) {
         return;
      }

      if (
         !this.credService.isAuthorized(
            this.task.locationID,
            this.credService.Permissions.RequestPurchases,
         )
      ) {
         this.alertService.addAlert(this.lang().cred175Fail, "danger", 10000);
         return;
      }

      this.toggleFab();

      const requestPurchaseResult = await this.tasksFacadeService.requestPurchase(task);

      if (requestPurchaseResult === "failed") {
         this.alertService.addAlert(this.lang().errorMsg, "danger", 6000);
      } else if (requestPurchaseResult === "success") {
         this.alertService.addAlert(
            this.lang().ThankYouYourRequestHasBeenSubmitted,
            "success",
            6000,
         );
         this.managePO.incOpenPurchaseOrderWatchVar();
         await this.manageInvoice.getData();
         this.updatePurchasingData();
      }
   }

   private setCustomTags(): void {
      const tags = this.manageUser.getCurrentUser().tags.split("@");

      const customTags: any = [];
      for (const key in tags) {
         if (tags[key].length > 0) {
            const obj: any = {};
            obj.name = `@${tags[key]}`;
            const test = `${obj.name};`;
            if (this.customTagListSource()?.toLowerCase().includes(test.toLowerCase())) {
               obj.tagged = true;
            } else {
               obj.tagged = false;
            }
            customTags.push(obj);
         }
      }
      this.tags.set(customTags);
      this.manageTags.tags$ = this.tags();
   }
   public updateTagged(tag) {
      const index = this.tags().findIndex((customTag) => customTag.name === tag.name);
      if (index > -1) this.tags()[index].tagged = !this.tags()[index].tagged;
      this.manageTags.tags$ = this.tags();
   }

   private setCustomTagData(newChecklistInstructions?: string): void {
      if (newChecklistInstructions) {
         this.taskViewModel.update((task) => {
            if (!task) {
               return undefined;
            }
            return { ...task, checklistInstructions: newChecklistInstructions };
         });
      }
      this.customTagListSource.set(
         newChecklistInstructions ?? this.taskViewModel()?.checklistInstructions,
      );
      this.setCustomTags();
   }

   protected mentionClickTag(tag: { name: string; tagged: boolean }): void {
      const test = `${tag.name};`;
      if (this.customTagListSource()?.toLowerCase().includes(test.toLowerCase())) {
         // already tagged
      } else {
         tag.tagged = true;
         this.clickTag(tag);
      }
   }

   protected deleteTag(tag: { name: string; tagged: boolean }): void {
      if (tag.tagged) {
         this.clickTag(tag);
      }
   }

   protected renameTag(oldName: string, tag: { name: string; tagged: boolean }): void {
      this.taskViewModel.update((tvm) => {
         if (!tvm) return tvm;
         return {
            ...tvm,
            checklistInstructions:
               tvm.checklistInstructions?.replaceAll(oldName, `${tag.name};`) ?? "",
         };
      });
   }

   protected clickTag(tag: { name: string; tagged: boolean }): void {
      const checkTagQueue = () => {
         if (this.tagQueue.length > 0) {
            const tempTag = this.tagQueue[0];
            this.tagQueue.shift();
            this.clickTag(tempTag);
         }
      };
      if (!this.tagTaskCred) {
         this.alertService.addAlert(this.lang().cred173Fail, "warning", 10000);
         return;
      }
      if (this.currentlyAddingTag === false) {
         this.currentlyAddingTag = true;
         let state, regex;

         let checklistInstructions = this.taskViewModel()?.checklistInstructions;

         if (!checklistInstructions) {
            checklistInstructions = "";
         }
         if (checklistInstructions.includes(`${tag.name};`)) {
            regex = new RegExp(
               `${tag.name};`.replace(/[-[\]{}()*+?.,\\^$|#\s]/g, "\\$&"),
               "g",
            );
            checklistInstructions = checklistInstructions.replace(regex, "");
            regex = new RegExp(
               `${tag.name.toLowerCase()};`.replace(/[-[\]{}()*+?.,\\^$|#\s]/g, "\\$&"),
               "g",
            );
            checklistInstructions = checklistInstructions.replace(regex, "");
            state = "lost";
         } else {
            checklistInstructions = `${checklistInstructions} ${tag.name};`;
            state = "gained";
         }

         this.setTagsWithNewInstructions(checklistInstructions);
         this.manageTask
            .addOrRemoveCustomTag(
               this.task.checklistID,
               checklistInstructions ?? "",
               state,
               `${tag.name};`,
            )
            .then((answer) => {
               if (answer.data.success === true) {
                  if (!this.disableAlerts()) {
                     this.alertService.addAlert(this.lang().successMsg, "success", 1000);
                  }
               } else {
                  this.tellTaskToRefresh(false);
                  this.alertService.addAlert(this.lang().errorMsg, "danger", 6000);
               }
            });

         this.currentlyAddingTag = false;
         checkTagQueue();
      } else {
         this.tagQueue.push(tag);
      }
   }

   private setTagsWithNewInstructions(newChecklistInstructions: string): void {
      if (newChecklistInstructions !== undefined) {
         this.taskViewModel.update((task) => {
            if (!task) {
               return undefined;
            }
            return {
               ...task,
               checklistInstructions: newChecklistInstructions,
            };
         });
      }

      this.customTagListSource.set(newChecklistInstructions);
      this.setCustomTags();
   }

   protected changeAssignment(): void {
      //visually disabled so no need for error message.  We don't want people to change assignment on completed tasks
      if (this.task.statusID === 2 && !this.tempEdit) {
         return;
      }

      if (!this.taskEditable() && Number(this.task.checklistUserCompleted) === 0) {
         this.alertService.addAlert(this.lang().viewModeOnly, "warning", 6000);
         return;
      }

      if (
         !this.credService.isAuthorized(
            this.task.locationID,
            this.credService.Permissions.ChangeAssignmentsOfOpenTasks,
         )
      ) {
         this.alertService.addAlert(this.lang().cred26Fail, "danger", 10000);
         return;
      }

      const extraUsersOptions = {
         arr: [
            {
               userFirstName: this.lang().Unassigned,
               userID: 0,
               profileID: 0,
            },
         ],
         key: {},
      };
      extraUsersOptions.arr.forEach((user) => {
         extraUsersOptions.key[user.userID] = user;
      });

      const instance = this.modalService.open(PickUserOrProfileLegacy);
      this.paramsService.params = {
         modalInstance: instance,
         resolve: {
            data: {
               showAuditOptions: false,
               message: this.lang().ChangeTheTasksAssignmentMsg,
               title: this.lang().ChangeTheTasksAssignment,
               locationID: this.task.locationID,
               extraUsers: extraUsersOptions.arr,
               defaultUser: this.task.userID,
               defaultProfile: this.task.profileID,
            },
         },
      };

      instance.result.then(async (result) => {
         if (!result) return;
         // If task is complete but we are editing, call "changeOwnerCompletedTask", otherwise "changeOwner"
         const call =
            this.task.statusID === 2 && this.tempEdit
               ? "changeOwnerCompletedTask"
               : "changeOwner";

         const answer = await this.manageTask[call](
            result.userID,
            result.profileID,
            result.multiUsers,
            this.task.checklistID,
            this.manageUser,
            this.manageProfile,
         );
         if (answer.status == 200) {
            await this.setTask();
            await this.manageTask.fetchNotes();
            assert(this.info !== undefined && this.taskDisplayData !== undefined);

            this.taskFormState.updateTasksEditable(true); //have to reset them to true to properly test them and get past the explict false call
            this.taskFormState.updateItemsEditable(true);

            this.taskFormState.updateTasksEditable(this.editable());
            const externalUserOverwrideActive = false;
            this.taskFormState.updateItemsEditable(
               this.editable(externalUserOverwrideActive),
            );

            this.tellTaskToRefresh();
            this.alertService.addAlert(
               `<i class='fa-regular fa-square-check fa-fw'></i>${this.lang().successMsgAssignmentAndNotification}`,
               "success",
               2000,
            );
         } else {
            this.alertService.addAlert(this.lang().errorMsg, "warning", 10000);
         }
      });
   }

   changeDueDate = () => {
      //visually disabled so no need for error message.  We don't want people to change due date on completed tasks
      if (this.task.statusID === 2 && !this.tempEdit) {
         return;
      }
      if (this.currentUser?.workOrderUser === 1) {
         this.alertService.addAlert(this.lang().credFailGeneric, "warning", 6000);
         return;
      }
      if (!this.taskEditable() && this.task.checklistUserCompleted === 0) {
         this.alertService.addAlert(this.lang().viewModeOnly, "warning", 6000);
         return;
      }

      if (
         !this.credService.isAuthorized(
            this.task.locationID,
            this.credService.Permissions.ChangeDueDatesOfOpenTasks,
         )
      ) {
         this.alertService.addAlert(this.lang().cred46Fail, "danger", 10000);
         return;
      }

      const instance = this.modalService.open(PickDate);

      this.paramsService.params = {
         modalInstance: instance,
         resolve: {
            message: this.lang().changeDueDateMessage,
            title: this.lang().changeDueDateTitle,
            buttonText: this.lang().Change,
            currentDate: this.task.checklistDueDate,
            data: {
               checklistID: this.task.checklistID,
               viewTimeOfDay: true,
               setNoTimeOfDayOffset: true,
               parentOfDate: "dueDate",
               startDate: {
                  viewTimeOfDay: true,
                  currentDate: this.task.checklistStartDate,
                  setting: this.task.checklistStartDateSetting,
                  setNoTimeOfDayOffset: true,
               },
            },
         },
      };

      instance.result.then((result) => {
         if (!result.date) {
            return;
         }
         const date = new Date(result.date);
         const newTime = date.getTime() / 1000;
         const oldTime = Number(this.task.checklistDueDate);

         if (result.timeOfDay == false) {
            this.task.checklistDueDateSetting = 0;
         } else {
            this.task.checklistDueDateSetting = 1;
         }

         let startDate, newStartDate, oldStartDate, startDateSetting;
         if (result.startDate.date == false) {
            newStartDate = 0;
            oldStartDate = this.task.checklistStartDate;
            startDateSetting = false;
         } else {
            startDate = new Date(result.startDate.date);
            newStartDate = startDate.getTime() / 1000;
            oldStartDate = this.task.checklistStartDate;
            if (result.startDate.timeOfDay == false) {
               startDateSetting = false;
            } else {
               startDateSetting = true;
            }
         }

         this.manageTask
            .updateDueDate(
               oldTime,
               newTime,
               this.task.checklistID,
               result.timeOfDay,
               oldStartDate,
               newStartDate,
               startDateSetting,
            )
            .then((answer) => {
               this.taskDisplayData = this.taskViewModelMapperService.getTaskDisplayData(
                  this.task,
               );
               if (answer.data.success == true) {
                  this.tellTaskToRefresh();
                  this.alertService.addAlert(
                     `<i class='fa-regular fa-square-check fa-fw'></i>${this.lang().successMsgDueDateChange}`,
                     "success",
                     2000,
                  );
               } else {
                  this.alertService.addAlert(this.lang().errorMsg, "danger", 10000);
               }
            });
      });
   };

   /************************
    *
    *       TASK BUILDER SECTION
    *
    ***********/

   addItem = async () => {
      if (
         await this.instructionLimitReached(
            moment.unix(this.task.checklistCreatedDate ?? 0),
         )
      ) {
         return;
      }

      if (this.taskTypeService.getType(this.task) === TaskType.InstructionSetTemplate) {
         return; //don't add any items to instructionSets at the root level
      }

      let msg;
      if (this.manageUser.getCurrentUser().userInfo.logins < 10) {
         msg = this.lang().AddItemHint;
      } else {
         msg = "";
      }

      const instance = this.modalService.open(PickItem);
      this.paramsService.params = {
         modalInstance: instance,
         resolve: {
            message: msg,
            title: this.lang().AddInstruction,
            button: this.lang().Add,
            parentBuildData: this.buildData.bind(this),
         },
      };

      instance.result.then((response) => {
         if (typeof response === "object" && response.instructionSet) {
            this.manageTaskItem
               .generateInstructionSetInstance(
                  response.instructionSetItem.itemID,
                  this.task.checklistID,
               )
               .then((answer) => {
                  if (answer.data.success) {
                     this.updateTaskStateService.refreshInstructions();
                     if (!this.disableAlerts()) {
                        this.alertService.addAlert(
                           this.lang().successMsg,
                           "success",
                           1000,
                        );
                     }
                  } else {
                     this.alertService.addAlert(this.lang().errorMsg, "danger", 6000);
                  }
               });
         } else if (response > 0) {
            const itemTypeID = response;
            this.manageTask.insItem(this.task, itemTypeID, "").then((answer) => {
               if (answer.data.success == true) {
                  this.updateTaskStateService.refreshInstructions();

                  if (!this.disableAlerts()) {
                     this.alertService.addAlert(this.lang().successMsg, "success", 1000);
                  }
               } else {
                  this.alertService.addAlert(this.lang().errorMsg, "danger", 6000);
               }
            });
         }
      });
   };

   private async instructionLimitReached(date: Moment): Promise<boolean> {
      const _instructionLimitReached =
         (await this.manageFeatureFlags.hasReachedWOInstructionLimitFor(date)) &&
         this.info !== undefined &&
         this.info.items.length > 0 && // All tasks are allowed to have up to one instruction regardless of feature flags
         this.info.items.length < 2; // If limit is reached only allow instructions to be added to tasks that already have multiple instructions

      return _instructionLimitReached;
   }

   protected async addPart(hideFab: boolean = false): Promise<void> {
      if (!this.featureUnlimitedParts && !this.featureLimitedNumber) {
         return;
      }

      //stupid catch all because apple is a POS that detects taps as double taps sometimes ;p
      if (this.preventDoubleClick == true) {
         return;
      }
      this.preventDoubleClick = true;
      this.taskFormState.updatePreventDoubleClick(true);
      setTimeout(() => {
         this.preventDoubleClick = false;
         this.taskFormState.updatePreventDoubleClick(false);
      }, 500);

      if (!this.manageLogin.credCheckEditChecklist(this.task)) {
         this.alertService.addAlert(this.lang().credFailGeneric, "warning", 6000);
         return;
      }

      //if it is a template and we aren't temporarily editing it, then we have to check to see if they can add parts to open Tasks
      if (
         this.task.checklistTemplate == 0 &&
         (this.tempEdit == false || this.tempEdit === undefined) &&
         !this.credService.isAuthorized(
            this.task.locationID,
            this.credService.Permissions.AddPartsToOpenTasks,
         )
      ) {
         this.alertService.addAlert(this.lang().cred167Fail, "warning", 6000);
         return;
      }

      const notPermittedLocations: Array<any> = [];

      const modalData: AddPartModalData = {
         message: this.lang().AddPartMsg,
         title: this.lang().AddPartTitle,
         taskID: this.task.checklistID,
         taskTemplate: this.task.checklistTemplate
            ? this.task.checklistTemplate > 0
            : false,
         buttonText: this.lang().AddPartTitle,
         dataLogOptions: this.dataLogOptions,
         locationID: this.taskViewModel()?.locationID,
      };

      const parts = await this.partsFacadeService.openAddPartModal(modalData);

      if (parts && parts.length > 0) {
         //this checks if the setresponse should override the backend check that the task has already been completed
         const addPartPromises: Array<Promise<AxiosResponse>> = [];
         for (const part of parts) {
            if (
               !this.credService.isAuthorized(
                  part.locationID,
                  this.credService.Permissions.AddPartsToOpenTasks,
               )
            ) {
               if (!notPermittedLocations.includes(part.locationName)) {
                  notPermittedLocations.push(part.locationName);
               }
               continue;
            }
            addPartPromises.push(this.processAddPartToTask(part.partID));
         }
         const addPartResponses = await Promise.all(addPartPromises);
         for (const response of addPartResponses) {
            if (!response.data.success) {
               continue;
            }
            this.parts.push(response.data.part);
         }
         this.sortParts();
         this.calcIfMinPartQtyTask();
         if (notPermittedLocations.length > 0) {
            const warning = this.setAlertForAddPartCredFail(notPermittedLocations);
            this.alertService.addAlert(warning, "danger", 6000);
         }
      }

      if (hideFab) {
         this.showFab = false;
      }
   }

   private setAlertForAddPartCredFail(locations: Array<any>): string {
      let locationString = "";
      if (locations.length > 1) {
         locationString = locations.join(", ").replace(/, ((?:.(?!, ))+)$/, " and $1");
      } else {
         locationString = locations[0];
      }
      return (
         this.lang().YouDoNotHavePermissionToAddParts +
         locationString +
         this.lang().YouNeedCred167ForThisAction
      );
   }

   private async processAddPartToTask(partID: number): Promise<AxiosResponse> {
      const overrideCompleted = this.tempEdit || false;
      return this.manageTask
         .addPartToTask(this.task.checklistID, partID, overrideCompleted)
         .then((answer) => {
            if (answer.data.success === true) {
               if (!this.disableAlerts()) {
                  this.alertService.addAlert(this.lang().successMsg, "success", 1000);
               }

               this.tellTaskToRefresh(true);
               this.managePO.incOpenPurchaseOrderWatchVar();
               this.managePO.incOpenBillWatchVar();
            } else if (answer.data.reason === "alreadyCompleted") {
               this.alertService.addAlert(
                  this.lang().AlreadyCompletedMsg,
                  "warning",
                  6000,
               );
            } else {
               this.alertService.addAlert(this.lang().errorMsg, "danger", 6000);
            }
            return answer;
         });
   }

   protected removePartFromTask(taskPart: TaskEntityPart | TaskPartLegacy): void {
      const instance = this.modalService.open(Confirm);
      this.paramsService.params = {
         modalInstance: instance,
         resolve: {
            message: this.lang().RemovePartMsg,
            title: this.lang().RemovePartTitle,
         },
      };
      instance.result.then((result) => {
         if (result === 1) {
            this.manageTask
               .removePartFromTask(
                  taskPart.relationID,
                  this.task.checklistID,
                  this.manageParts,
               )
               .then((answer) => {
                  if (answer.data.success === true) {
                     for (const [index, part] of this.parts.entries()) {
                        if (part.relationID === taskPart.relationID) {
                           this.parts.splice(index, 1);
                           break;
                        }
                     }
                     if (answer.data.partOverstocked === 0) {
                        const partToUpdate = this.manageParts.getPart(taskPart.partID);
                        assert(partToUpdate);
                        partToUpdate.partOverstocked = 0;
                     }

                     if (!this.disableAlerts()) {
                        this.alertService.addAlert(
                           this.lang().successMsg,
                           "success",
                           1000,
                        );
                     }
                     this.sortParts();
                     this.calcIfMinPartQtyTask();
                     this.tellTaskToRefresh(true);
                     this.managePO.incOpenPurchaseOrderWatchVar();
                     this.managePO.incOpenBillWatchVar();
                  } else {
                     this.alertService.addAlert(this.lang().errorMsg, "danger", 6000);
                  }
               });
         }
      });
   }

   protected updatePartSuggestedNumber(part: TaskEntityPart | TaskPartLegacy): void {
      if (typeof part.suggestedNumber === "number") {
         this.manageTask.updatePartSuggestedNumber(part).then((answer) => {
            if (answer.data.success === true) {
               for (const potentiallyUpdatedPart of this.parts) {
                  if (potentiallyUpdatedPart.relationID == part.relationID) {
                     potentiallyUpdatedPart.suggestedNumber = Number(
                        part.suggestedNumber,
                     );

                     const partRelation = this.manageTask.getPartRelation(
                        part.relationID,
                     );
                     if (partRelation !== undefined) {
                        partRelation.suggestedNumber = Number(part.suggestedNumber);
                     }
                  }

                  const partInLookup = this.manageParts.getPart(part.partID);
                  assert(partInLookup);
                  this.manageParts.calculatePartData(partInLookup);
               }

               if (!this.disableAlerts()) {
                  this.alertService.addAlert(this.lang().successMsg, "success", 1000);
               }
               this.sortParts();
               this.tellTaskToRefresh(true);
            } else {
               this.alertService.addAlert(this.lang().errorMsg, "danger", 6000);
            }
         });
      } else {
         this.alertService.addAlert(this.lang().PleaseEnterANumber, "warning", 3000);
      }
   }

   protected updatedPartSuggestedNumber(part: TaskEntityPart): void {
      for (const potentiallyUpdatedPart of this.parts) {
         if (potentiallyUpdatedPart.relationID === part.relationID) {
            potentiallyUpdatedPart.suggestedNumber = Number(part.suggestedNumber);

            const partRelation = this.manageTask.getPartRelation(part.relationID);
            if (partRelation !== undefined) {
               partRelation.suggestedNumber = Number(part.suggestedNumber);
            }
         }

         const partInLookup = this.manageParts.getPart(part.partID);
         assert(partInLookup);
         this.manageParts.calculatePartData(partInLookup);
         this.tellTaskToRefresh(true);
      }

      if (!this.disableAlerts()) {
         this.alertService.addAlert(this.lang().successMsg, "success", 1000);
      }
      this.sortParts();
   }

   protected updateTaskDescription(): void {
      this.manageTask
         .updateTaskDescription(
            this.task.checklistID,
            this.taskViewModel()?.checklistInstructions ?? "",
         )
         .then((answer) => {
            if (answer.data.success === true) {
               if (!this.disableAlerts()) {
                  this.alertService.addAlert(this.lang().successMsg, "success", 1000);
               }
            } else {
               this.setTask(false);
               this.alertService.addAlert(this.lang().errorMsg, "danger", 6000);
            }
         });
   }

   protected async updateTaskName(): Promise<void> {
      const answer = await this.manageTask.updateTaskName(
         this.task.checklistID,
         this.task.checklistName ?? "",
      );
      if (this.disableAlerts()) return;
      if (answer.data.success !== true) {
         this.tellTaskToRefresh();
         this.alertService.addAlert(this.lang().errorMsg, "danger", 6000);
         return;
      }
      this.alertService.addAlert(this.lang().successMsg, "success", 1000);
   }

   protected async updateTaskPriorityID(priority): Promise<void> {
      if (!this.taskEditable() && !this.task.checklistUserCompleted) {
         this.alertService.addAlert(this.lang().viewModeOnly, "warning", 6000);
         return;
      }
      if (
         !this.credService.isAuthorized(
            this.task.locationID,
            this.credService.Permissions.ChangeAnOpenTasksPriorityLevel,
         )
      ) {
         this.alertService.addAlert(this.lang().cred101Fail, "danger", 10000);
         return;
      }
      if (Number(priority.priorityID) === Number(this.task.priorityID)) {
         return;
      }

      const result = await this.manageTask.updateTaskPriorityID(
         this.task.checklistID,
         priority.priorityID,
         Number(this.task.priorityID),
      );

      if (result.data.success === true) {
         this.tellTaskToRefresh();
         this.alertService.addAlert(this.lang().successMsg, "success", 1000);
      } else {
         this.alertService.addAlert(this.lang().errorMsg, "danger", 10000);
      }
   }

   protected editPriorities(): void {
      const instance = this.modalService.open(CustomizePriorities);
      this.paramsService.params = {
         modalInstance: instance,
      };
   }

   protected updateTaskStatusID(newStatus): void {
      if (!this.taskEditable() || this.task.checklistUserCompleted) {
         this.alertService.addAlert(this.lang().viewModeOnly, "warning", 6000);
         return;
      }

      if (
         !this.credService.isAuthorized(
            this.task.locationID,
            this.credService.Permissions.ChangeAnOpenTasksPriorityLevel,
         )
      ) {
         this.alertService.addAlert(this.lang().cred101Fail, "danger", 10000);
         return;
      }
      // If they try to change it to completed via the dropdown, we need to display a message instead
      if (newStatus.statusID == 2) {
         this.alertService.addAlert(
            this.lang().ChangeStatusToCompletedWarning,
            "warning",
            10000,
         );
         return;
      }
      if (Number(newStatus.statusID) === Number(this.task.statusID)) {
         return;
      }
      // Check if the note should be hidden from external users
      const noteHiddenFromExternal = Number(
         this.currentUser.userInfo.noteHiddenFromExternalFlag,
      );

      const previousStatusID = this.task.statusID;

      // Using optimistic UI here to update the status before the API call. If the API call fails, we will revert the status back.
      this.setMiddleDisplay(newStatus.statusID);

      this.manageTask
         .updateTaskStatusID(
            this.task.checklistID,
            newStatus.statusID,
            this.task.statusID,
            noteHiddenFromExternal,
         )
         .then((answer: any) => {
            if (answer.data.success === true) {
               this.tellTaskToRefresh();
               this.alertService.addAlert(this.lang().successMsg, "success", 1000);
            } else {
               // Set the status back to previous value
               this.setMiddleDisplay(previousStatusID);
               this.alertService.addAlert(this.lang().errorMsg, "danger", 10000);
            }
         })
         .catch(() => {
            // Set the status back to previous value
            this.setMiddleDisplay(previousStatusID);
            this.alertService.addAlert(this.lang().errorMsg, "danger", 10000);
         });
   }

   protected editStatuses(): void {
      const instance = this.modalService.open(CustomizeStatuses);
      this.paramsService.params = {
         modalInstance: instance,
      };
   }

   protected updateTaskCompletionNotes(): void {
      this.manageTask
         .updateTaskCompletionNotes(
            this.task.checklistID,
            this.task.checklistComments ?? "",
         )
         .then((answer) => {
            if (answer.data.success === true) {
               this.tellTaskToRefresh();
               this.alertService.addAlert(this.lang().successMsg, "success", 2000);
            } else {
               this.alertService.addAlert(this.lang().errorMsg, "danger", 6000);
            }
         });
   }

   protected changeDefaultWO(): void {
      //this gets the default WO for the location
      const instance = this.modalService.open(PopTask);
      this.paramsService.params = {
         modalInstance: instance,
         resolve: {
            data: {
               checklistID: this.locations[this.task.locationID].checklistID,
               editable: true,
               template: true,
               title: this.lang().DefaultWorkOrderTemplate,
               message: this.lang().DefaultWorkOrderTemplateMsg,
               isDefaultTemplate: true,
            },
         },
      };
   }

   protected async changeAsset(): Promise<void> {
      const task = this.taskViewModel();
      if (!task) {
         throw new Error("Task is not defined when changing asset");
      }
      if (
         this.task.checklistTemplate === 1 &&
         !this.credService.isAuthorized(
            this.task.locationID,
            this.credService.Permissions.ChangeTheAssetThisPMBelongsTo,
         )
      ) {
         this.alertService.addAlert(this.lang().cred54Fail, "warning", 10000);
         return;
      }

      if (this.currentUser?.workOrderUser === 1) {
         this.alertService.addAlert(this.lang().credFailGeneric, "warning", 6000);
         return;
      }

      if (!this.taskEditable() && !this.task.checklistUserCompleted) {
         this.alertService.addAlert(this.lang().viewModeOnly, "warning", 6000);
         return;
      }

      if (!this.manageLogin.credCheckEditChecklist(this.task)) {
         this.alertService.addAlert(this.lang().credFailGeneric, "warning", 6000);
         return;
      }

      if (
         this.task.checklistStatusID &&
         (this.tempEdit === false || this.tempEdit === undefined)
      ) {
         //its completed and we are not currently editing it so don't let them change it
         this.alertService.addAlert(
            this.lang().CantChanceAssetOnCompletedTaskUnlessEditing,
            "warning",
            6000,
         );
         return;
      }

      const asset = await this.tasksFacadeService.getAssetForTask(task);
      if (!asset) {
         return;
      }
      this.asset.set(asset);

      this.assetNameStr.set(this.manageAsset.getAssetNameIncludeParents(asset.assetID));
      this.displayAssetInfo.set(
         this.manageTask.toggleAssetFieldInfoDisplayLegacy(
            asset,
            this.task.checklistID,
            this.taskViewModel(),
         ),
      );

      const changeAssetResult = await this.tasksFacadeService.changeAsset(task, asset);

      if (changeAssetResult === "success") {
         this.alertService.addAlert(this.lang().successMsg, "success", 2000);
         this.setTask(false);
      } else if (changeAssetResult === "failed") {
         this.tellTaskToRefresh(true);
         this.alertService.addAlert(this.lang().errorMsg, "danger", 6000);
      }
   }

   protected changeLocation(): void {
      if (!this.taskEditable() && !this.task?.checklistUserCompleted) {
         this.alertService.addAlert(this.lang().viewModeOnly, "warning", 6000);
         return;
      }

      if (!this.manageLogin.credCheckEditChecklist(this.task)) {
         this.alertService.addAlert(this.lang().credFailGeneric, "warning", 6000);
         return;
      }

      if (!this.featureMultipleLocations) {
         return;
      }

      if (
         this.task?.checklistStatusID &&
         (this.tempEdit === false || this.tempEdit === undefined)
      ) {
         //its completed and we are not currently editing it so don't let them change it
         this.alertService.addAlert(
            this.lang().CantChanceLocationOnCompletedTaskUnlessEditing,
            "warning",
            6000,
         );
         return;
      }

      const instance = this.modalService.open(PickLocationsModal);
      this.paramsService.params = {
         modalInstance: instance,
         resolve: {
            message: this.lang().ChangeTasksLocationMsg,
            title: this.lang().ChangeTasksLocation,
            data: {
               selectOne: true,
               buttonText: this.lang().Change,
            },
         },
      };

      instance.result.then(async (results) => {
         if (results) {
            const location = results[0];

            const answer = await this.manageTask.updateTasksLocation(
               this.task.checklistID,
               location.locationID,
            );

            if (answer.data.success === true) {
               this.alertService.addAlert(this.lang().successMsg, "success", 10000);

               if (answer.data.unassignedUserWarning) {
                  this.alertService.addAlert(
                     this.lang().DuringTheMoveOneOrMoreOfTheTasksWereSetToUnassigned,
                     "warning",
                     10000,
                  );
               }

               if (answer.data.fewerUsersWarning) {
                  this.alertService.addAlert(
                     this.lang().FewerUsersWarning,
                     "warning",
                     10000,
                  );
               }

               //the task's assetID has been set to 0 to indicate it is unassigned,
               //so the assetNameStr must also be updated here
               this.assetNameStr.set("");
               this.tellTaskToRefresh(true);
               // this is needed to update the new location Manage Work list-view assignee name
               await this.refreshService.refreshData({ force: true, notify: false });
            } else {
               this.alertService.addAlert(this.lang().errorMsg, "danger", 6000);
            }
         }
      });
   }

   //This function changes the type of the instance to any of the following:
   protected changeTaskType(): void {
      if (!this.taskEditable() && !this.task?.checklistUserCompleted) {
         this.alertService.addAlert(this.lang().viewModeOnly, "warning", 6000);
         return;
      }

      if (!this.manageLogin.credCheckEditChecklist(this.task)) {
         this.alertService.addAlert(this.lang().credFailGeneric, "warning", 6000);
         return;
      }

      if (
         this.task?.checklistStatusID &&
         (this.tempEdit === false || this.tempEdit === undefined)
      ) {
         //its completed and we are not currently editing it so don't let them change it
         this.alertService.addAlert(
            this.lang().CantChangeTaskTypeOnCompletedTaskUnlessEditing,
            "warning",
            6000,
         );
         return;
      }

      if (this.task?.checklistTemplate) {
         this.alertService.addAlert(this.lang().CantChangeTaskType, "warning", 6000);
         return;
      }

      const instance = this.modalService.open(PickTaskType);

      this.paramsService.params = {
         modalInstance: instance,
         resolve: {
            message: this.lang().ChangeTaskTypeMsg,
            title: this.lang().ChangeTaskType,
         },
      };

      instance.result.then((result) => {
         if (result) {
            this.manageTask
               .updateTaskType(
                  this.task.checklistID,
                  result.checklistTemplateOld,
                  result.checklistBatchID,
               )
               .then((answer) => {
                  if (answer.data.success == true) {
                     this.alertService.addAlert(this.lang().successMsg, "success", 2000);
                     this.tellTaskToRefresh();
                  } else {
                     this.alertService.addAlert(this.lang().errorMsg, "danger", 6000);
                  }
               });
         }
      });
   }

   protected deleteCompletedTask(): void {
      if (!this.deleteCompletedTaskCred) {
         this.alertService.addAlert(this.lang().credFailGeneric, "warning", 6000);
         return;
      }

      const instance = this.modalService.open(Confirm);

      this.paramsService.params = {
         modalInstance: instance,
         resolve: {
            message: this.lang().DeleteTaskMsg,
            title: this.lang().DeleteTask,
         },
      };

      instance.result.then((result) => {
         if (result == 1) {
            this.manageTask.deleteCompletedTask(this.task.checklistID).then((answer) => {
               if (answer.data.success == true) {
                  this.task.checklistDepreciated = 1;

                  this.alertService.addAlert(
                     this.lang().successMsgTaskDeleted,
                     "success",
                     4000,
                  );

                  //we need to close the task they just deleted
                  if (this.modalRef === null) {
                     this.manageLogin.findCorrectDefaultState();
                  } else {
                     this.modalRef.close();
                     this.manageTask.incTasksWatchVar();
                  }
               } else {
                  this.alertService.addAlert(this.lang().errorMsg, "danger", 6000);
               }
            });
         }
      });
   }

   protected deleteOpenTask(): void {
      if (!this.deleteOpenTaskCred) {
         this.alertService.addAlert(this.lang().credFailGeneric, "warning", 6000);
         return;
      }

      const instance = this.modalService.open(Confirm);

      this.paramsService.params = {
         modalInstance: instance,
         resolve: {
            message: this.lang().DeleteTaskMsg,
            title: this.lang().DeleteTask,
         },
      };

      instance.result.then((result) => {
         if (result == 1) {
            this.manageTask
               .deleteChecklistInstance(this.task?.checklistID)
               .then((answer) => {
                  if (answer.data.success == true) {
                     // this.task.checklistDepreciated = 1;

                     this.alertService.addAlert(
                        this.lang().successMsgTaskDeleted,
                        "success",
                        4000,
                     );

                     //we need to close the task they just deleted
                     if (this.modalRef === null) {
                        this.manageLogin.findCorrectDefaultState();
                     } else {
                        this.modalRef.close();
                        this.manageTask.incTasksWatchVar();
                     }
                  } else {
                     this.alertService.addAlert(this.lang().errorMsg, "danger", 6000);
                  }
               });
         }
      });
   }

   protected recreateWorkRequest(): void {
      if (!this.recreateWorkRequestCred) {
         this.alertService.addAlert(this.lang().credFailGeneric, "warning", 6000);
         return;
      }

      const instance = this.modalService.open(RecreateWorkRequest);

      this.paramsService.params = {
         modalInstance: instance,
         resolve: {
            message: this.lang().RecreateWorkRequestMsg,
            title: this.lang().RecreateWorkRequest,
            checklistID: this.task.checklistID,
         },
      };

      instance.result.then((result) => {
         const defaultPriority = this.managePriority.getDefaultPriority();
         if (result) {
            this.manageTask
               .recreateWorkRequest(
                  this.task.checklistID,
                  result.checklistID,
                  result.profileID,
                  result.userID,
                  defaultPriority.priorityID,
                  result.tags,
               )
               .then((answer) => {
                  const data = answer.data;
                  if (data?.success) {
                     this.tellTaskToRefresh(true);
                  }
               });
         }
      });
   }

   protected mergeWorkRequest(): void {
      if (!this.recreateWorkRequestCred) {
         this.alertService.addAlert(this.lang().credFailGeneric, "warning", 6000);
         return;
      }

      const location = this.manageLocation.getLocationsIndex()[this.task.locationID];
      const currentTasks = this.manageTask
         .getTasks()
         .filter(
            (task) =>
               task.locationID === Number(this.task.locationID) &&
               task.checklistTemplate === 0 &&
               task.checklistTemplateOld === 2 &&
               task.checklistBatchID === 300112 &&
               task.checklistID !== this.task.checklistID,
         );

      const instance = this.modalService.open(PickTasks);
      const currentTaskIDs: Array<number> = [];
      for (const task of currentTasks) {
         currentTaskIDs.push(task.checklistID);
      }
      this.paramsService.params = {
         modalInstance: instance,
         resolve: {
            message:
               `${this.lang().PleaseChooseWhichWorkRequestYouWouldLikeToMerge} <b>${this.task.checklistName}</b>` +
               `<br /><br /><b class="red-color">${this.lang().ThisActionCanNotBeUndone}</b>`,
            title: `${this.lang().MergeWorkRequests} <b>${location.locationName}</b>`,
            taskIDs: currentTaskIDs,
            data: { selectOne: true },
         },
      };

      instance.result.then((data) => {
         if (data && data.lookupOfTasks.size > 0) {
            const taskToMerge = data.lookupOfTasks.values().next().value;
            const instance2 = this.modalService.open(Confirm);

            this.paramsService.params = {
               modalInstance: instance2,
               resolve: {
                  message:
                     `${this.lang().AreYouSureYouWantToMergeWorkRequest} <b>${taskToMerge.checklistName}</b> ${this.lang().IntoTheCurrentWorkRequest} <b>${this.task.checklistName}</b>` +
                     `<br /><br /><b class="red-color">${this.lang().ThisActionCanNotBeUndone}</b>`,
                  title: this.lang().MergeWorkRequests,
               },
            };

            instance2.result.then((result2) => {
               if (result2 === 1) {
                  this.manageTask
                     .mergeWorkRequest(this.task.checklistID, taskToMerge.checklistID)
                     .then((answer) => {
                        if (answer?.data?.success) {
                           this.tellTaskToRefresh(true);
                           this.alertService.addAlert(
                              this.lang().successMsg,
                              "success",
                              2000,
                           );
                        } else {
                           this.alertService.addAlert(
                              this.lang().errorMsg,
                              "danger",
                              6000,
                           );
                        }
                     });
               }
            });
         }
      });
   }

   reopenCompletedTask = () => {
      if (!this.editCompletedTaskCred) {
         this.alertService.addAlert(this.lang().credFailGeneric, "warning", 6000);
         return;
      }

      const instance = this.modalService.open(Confirm);

      this.paramsService.params = {
         modalInstance: instance,
         resolve: {
            message: this.lang().ReopenCompletedTaskMsg,
            title: this.lang().ReopenCompletedTask,
         },
      };

      instance.result.then((result) => {
         if (result === 1) {
            if (this.tempEdit === true) {
               this.taskFormState.updateTempEdit(false);
               this.tempEdit = false;
            }
            this.loadingBarService.show({ header: this.lang()?.WakingUpHamsters });
            this.manageTask
               .reopenCompletedTask(this.task.checklistID)
               .then(async (answer) => {
                  if (answer.data.success === true) {
                     // We used to get the task from the PHP response, but now we just fetch it from the server because we're moving to JIT loading.
                     const task = await lastValueFrom(
                        this.tasksApiService.getById(this.task.checklistID, {
                           columns:
                              "comments,invoices,extraTime,parts,partsDetails,locationName",
                        }),
                     );

                     if (task === undefined) {
                        throw new Error(
                           "Error retrieving task after running reopenCompletedTask",
                        );
                     }

                     this.task = task;
                     this.taskViewModel.set(
                        this.taskViewModelFactoryService.getTaskDataViewerViewModel(
                           this.task,
                        ),
                     );

                     //update any extrabatches or parts that may have changed from reopening this completed Task
                     await this.processReturnPartsExtraBatchesAndRemoveRelations(answer);

                     this.tellTaskToRefresh(true);
                     this.loadingBarService.remove();
                     //set the task to have an updated last edited value

                     this.taskFormState.updateTasksEditable(true); //have to reset them to true to properly test them and get past the explict false call
                     this.taskFormState.updateItemsEditable(true);

                     // The editable input is false for completed tasks, so override it with true when re-opening a task.
                     this.data.editable = true;

                     this.taskFormState.updateTasksEditable(this.editable());
                     const externalUserOverwrideActive = false;
                     this.taskFormState.updateItemsEditable(
                        this.editable(externalUserOverwrideActive),
                     );

                     this.data.preview = false;
                     const asset = this.asset();
                     if (asset) {
                        this.displayAssetInfo.set(
                           this.manageTask.toggleAssetFieldInfoDisplayLegacy(
                              asset,
                              this.task.checklistID,
                           ),
                        );
                     }

                     this.taskOpen = true;
                  } else {
                     this.alertService.addAlert(this.lang().errorMsg, "danger", 6000);
                     this.loadingBarService.remove();
                  }
               });
         }
      });
   };

   //this function is so that users can temp edit a completed task.
   protected tempEditTask(): void {
      assert(this.info !== undefined);
      if (this.tempEdit === false || this.tempEdit === undefined) {
         this.taskFormState.updateTempEdit(true);
         this.tempEdit = true;
         this.taskFormState.updateItemsEditable(true);

         const note = this.lang().EditingCompletedTaskAutomaticallyGenerated;

         const currentUser = this.manageUser.getCurrentUser();

         this.noteHidden = Number(currentUser.userInfo.noteHiddenFromExternalFlag);

         const post = this.manageTask.addNote(
            note,
            this.task.checklistID,
            this.noteHidden,
            1,
            false,
         );

         post.then((answer) => {
            if (answer.data.success == true) {
               this.task.checklistLastEdited = Math.floor(Date.now() / 1000);
            } else {
               this.alertService.addAlert(this.lang().errorMsg, "warning", 6000);
            }
         });
      } else {
         this.taskFormState.updateTempEdit(false);
         this.tempEdit = false;
         this.taskFormState.updateItemsEditable(false);
      }
   }

   protected async addInvoice(): Promise<void> {
      //stupid catch all because apple is a POS that detects taps as double taps sometimes ;p
      if (this.preventDoubleClick == true) {
         return;
      }
      this.preventDoubleClick = true;
      this.taskFormState.updatePreventDoubleClick(true);
      setTimeout(() => {
         this.preventDoubleClick = false;
         this.taskFormState.updatePreventDoubleClick(false);
      }, 500);

      if (!this.manageLogin.credCheckEditChecklist(this.task)) {
         this.alertService.addAlert(this.lang().credFailGeneric, "warning", 6000);
         return;
      }

      if (
         this.task?.checklistTemplate == 0 &&
         this.currentUser.workOrderUser == 0 &&
         (this.tempEdit == false || this.tempEdit === undefined) &&
         !this.credService.isAuthorized(
            this.task.locationID,
            this.credService.Permissions.AddInvoicesToOpenTasks,
         )
      ) {
         this.alertService.addAlert(this.lang().cred168Fail, "warning", 6000);
         return;
      }
      let instance;
      if (
         this.task.checklistTemplate !== null &&
         (this.task.checklistTemplate ?? 0) > 0
      ) {
         instance = this.modalService.open(AddInvoiceToTemplateComponent);
         this.paramsService.params = {
            modalInstance: instance,
            resolve: {
               data: {
                  message: this.lang().AddAnInvoiceToThisTaskMsg,
                  title: this.lang().AddAnInvoiceToThisTask,
                  checklistID: this.task.checklistID,
               },
            },
         };
      } else {
         instance = this.modalService.open(AddInvoiceToTaskModalComponent);
         instance.setInput("checklistID", this.taskViewModel()?.checklistID);
         instance.setInput("locationID", this.taskViewModel()?.locationID);
         this.paramsService.params = {
            modalInstance: instance,
            resolve: {
               data: {
                  message: this.lang().AddAnInvoiceToThisTaskMsg,
                  title: this.lang().AddAnInvoiceToThisTask,
                  checklistID: this.taskViewModel()?.checklistID,
                  fromTemplate:
                     this.taskViewModel()?.checklistTemplate !== null &&
                     (this.taskViewModel()?.checklistTemplate ?? 0) > 0,
               },
            },
         };
      }

      await instance.result;
      this.manageTask.incCompletedTasksWatchVar();
      await this.manageInvoice.getData();
      this.updatePurchasingData();
   }

   protected async updateEstimatedTime(): Promise<void> {
      if (this.task?.checklistCompletedDate) {
         //if it is a completed task they must have the credential to complete it.
         if (!this.editCompletedTaskCred) {
            this.alertService.addAlert(this.lang().credFailGeneric, "warning", 6000);
            return;
         }
      } else if (
         !this.task?.checklistCompletedDate &&
         !this.taskFormState.itemsEditable()
      ) {
         //if it isn't completed and it is not editable then show them as view mode only

         this.alertService.addAlert(this.lang().viewModeOnly, "warning", 6000);
         return;
      }

      const instance = this.modalService.open(ChangeCompletedTime);
      this.paramsService.params = {
         modalInstance: instance,
         resolve: {
            message: "",
            title: this.lang().ChangeEstimatedTime,
            data: {
               startMin: this.checklistEstTimeMinutes,
               startHour: this.checklistEstTimeHours,
               allowZero: true,
            },
         },
      };

      const modalResult = await instance.result;
      if (!modalResult) {
         return;
      }
      const newMinutes = modalResult.hours * 60 + Number(modalResult.minutes);
      const updateTimeResult = await this.manageTask.updateChecklistEstTime(
         this.task.checklistID,
         newMinutes,
      );
      if (updateTimeResult.data.success === true) {
         await this.setTask();
         this.checklistEstTimeHours = Number(
            Math.floor((this.task.checklistEstTime ?? 0) / 3600),
         );

         this.checklistEstTimeMinutes = Number(
            Math.floor(((this.task.checklistEstTime ?? 0) / 60) % 60),
         );

         this.alertService.addAlert(this.lang().successMsg, "success", 2000);
      } else {
         this.alertService.addAlert(this.lang().errorMsg, "danger", 6000);
      }
   }

   protected tempChangeDowntime(): void {
      if (this.task?.statusID === 2 && !this.tempEdit) {
         return;
      }

      if (this.task?.checklistCompletedDate) {
         if (!this.editCompletedTaskCred) {
            this.alertService.addAlert(this.lang().credFailGeneric, "warning", 6000);
            return;
         }
      } else {
         if (!this.taskEditable()) {
            this.alertService.addAlert(this.lang().viewModeOnly, "warning", 6000);
            return;
         }
      }

      const instance = this.modalService.open(ChangeDowntime);
      this.paramsService.params = {
         modalInstance: instance,
         resolve: {
            message: "",
            title: this.lang().ChangeDowntimeTime,
            hours: this.checklistDowntimeHours,
            minutes: this.checklistDowntimeMinutes,
         },
      };

      instance.result.then((result) => {
         if (result) {
            const newTime = result.hours * 60 * 60 + result.minutes * 60;

            this.manageTask
               .changeDowntime(this.task.checklistID, newTime)
               .then(async (answer) => {
                  if (answer.data.success == true) {
                     await this.setTask();
                     this.tellTaskToRefresh();
                     if (newTime == 0) {
                        this.checklistDowntimeHours = "";
                        this.checklistDowntimeMinutes = "";
                     }

                     if (this.task.checklistDowntime) {
                        this.checklistDowntimeHours = Number(
                           Math.floor(this.task.checklistDowntime / 3600),
                        );

                        this.checklistDowntimeMinutes = Number(
                           Math.floor((this.task.checklistDowntime / 60) % 60),
                        );
                     }

                     this.alertService.addAlert(this.lang().successMsg, "success", 2000);
                  } else {
                     this.alertService.addAlert(this.lang().errorMsg, "danger", 6000);
                  }
               });
         }
      });
   }

   protected tempChangeAssignedTo(): void {
      if (!this.editCompletedTaskCred) {
         this.alertService.addAlert(this.lang().credFailGeneric, "warning", 6000);
         return;
      }

      const extraUsersOptions = {
         arr: [
            {
               userFirstName: this.lang().Unassigned,
               userID: 0,
               profileID: 0,
            },
         ],
         key: {},
      };

      extraUsersOptions.arr.forEach((user) => {
         extraUsersOptions.key[user.userID] = user;
      });

      const instance = this.modalService.open(PickUserOrProfileLegacy);
      this.paramsService.params = {
         modalInstance: instance,
         resolve: {
            data: {
               showAuditOptions: false,
               message: this.lang().ChangeTheTasksAssignmentMsg,
               title: this.lang().ChangeTheTasksAssignment,
               locationID: this.task.locationID,
               extraUsers: extraUsersOptions.arr,
               defaultUser: this.task?.userID,
               defaultProfile: this.task?.profileID,
            },
         },
      };

      instance.result.then((result) => {
         if (result != 0) {
            this.manageTask
               .changeOwnerCompletedTask(
                  result.userID,
                  result.profileID,
                  result.multiUsers,
                  this.task.checklistID,
                  this.manageUser,
                  this.manageProfile,
               )
               .then((answer) => {
                  if (answer.data.success === true) {
                     this.taskDisplayData =
                        this.taskViewModelMapperService.getTaskDisplayData(this.task);
                     this.alertService.addAlert(this.lang().successMsg, "success", 2000);
                  } else {
                     this.alertService.addAlert(this.lang().errorMsg, "warning", 10000);
                  }
               });
         }
      });
   }

   protected updateRelatedTasks(): void {
      if (
         !this.credService.isAuthorized(
            this.task.locationID,
            this.credService.Permissions.BulkUpdatePMs,
         )
      ) {
         this.alertService.addAlert(this.lang().cred57Fail, "danger", 10000);
         return;
      }
      if (this.jitTemplatesDesktopEnabled) {
         if (!this.task.checklistBatchID) {
            console.error(
               "Invalid checklistBatchID when opening UpdateRelatedTasksComponent",
            );
            return;
         }
         const modalRef = this.modalService.open(UpdateRelatedTasksComponent);
         modalRef.componentInstance.checklistID = this.task.checklistID;
         modalRef.componentInstance.locationID = this.task.locationID;
         modalRef.componentInstance.checklistBatchID = this.task.checklistBatchID;
      } else {
         const instance = this.modalService.open(UpdateRelatedTasksLegacyComponent);
         this.paramsService.params = {
            modalInstance: instance,
            resolve: {
               data: {
                  checklistID: this.task.checklistID,
                  title: this.lang().UpdateRelatedPMs,
                  message: this.lang().UpdateRelatedPMsMsg,
               },
            },
         };
      }
   }

   viewInvoice = (invoice) => {
      const imagePath = `viewFile.php?f=upload-${this.customerID}/invoices/${invoice.checklistID}/${invoice.invoiceFileName}`;

      this.manageFiles.createImageViewer(imagePath);
   };

   protected machineDownAlert(): void {
      //stupid catch all because apple is a POS that detects taps as double taps sometimes ;p
      if (this.preventDoubleClick === true) {
         return;
      }
      this.preventDoubleClick = true;
      this.taskFormState.updatePreventDoubleClick(true);
      setTimeout(() => {
         this.preventDoubleClick = false;
         this.taskFormState.updatePreventDoubleClick(false);
      }, 500);

      let body =
         this.locations[this.task.locationID].machineDownAlertBody ??
         this.i18n().t("MachineDownBodyText");
      if (this.task.assetID) {
         const asset = this.manageAsset.getAsset(this.task.assetID);
         if (asset?.assetName) {
            body = body.replace("{{assetName}}", asset.assetName);
         }
      } else {
         body = body.replace("{{assetName}}", this.lang().NotAssignedToAnAsset);
      }

      body = body.replace(/<div>/gi, "");
      body = body.replace(/<\/div>/gi, "");

      const toEmail = this.locations[this.task.locationID].machineDownAlertTo;
      const lastChar = toEmail.substr(toEmail.length - 1);
      if (lastChar !== ";") {
         this.locations[this.task.locationID].machineDownAlertTo = `${
            this.locations[this.task.locationID].machineDownAlertTo
         };`;
      }

      const instance = this.modalService.open(EmailTemplate);

      this.paramsService.params = {
         modalInstance: instance,
         resolve: {
            message: this.lang().emailReminderMessage,
            title: this.lang().emailReminderTitle,
            data: {
               emailTo:
                  String(this.locations[this.task.locationID].machineDownAlertTo) +
                  this.locations[this.task.locationID].machineDownAlertCC,
               emailSubject:
                  this.locations[this.task.locationID].machineDownAlertSubject ??
                  this.i18n().t("MachineDownAlert"),
               emailMessage: body,
               pickEmployees: false,
               checklistID: this.task.checklistID,
               onSubmit: "send",
            },
         },
         backdrop: "static",
         keyboard: false,
      };
   }

   protected changeCompletedTaskDueDate(): void {
      if (this.currentUser?.workOrderUser === 1) {
         this.alertService.addAlert(this.lang().credFailGeneric, "warning", 6000);
         return;
      }
      const instance = this.modalService.open(PickDate);

      this.paramsService.params = {
         modalInstance: instance,
         resolve: {
            message: this.lang().changeCompletedTaskDueDateMsg,
            title: this.lang().changeCompletedTaskDueDateTitle,
            buttonText: this.lang().Change,
            currentDate: this.task.checklistDueDate,
            data: {
               viewTimeOfDay: true,
               setNoTimeOfDayOffset: true,
               checklistID: this.task.checklistID,
               parentOfDate: "dueDate",
               startDate: {
                  viewTimeOfDay: true,
                  currentDate: this.task.checklistStartDate,
                  setting: this.task.checklistStartDateSetting,
                  setNoTimeOfDayOffset: true,
               },
            },
         },
      };

      instance.result.then((result) => {
         if (result.date) {
            const date = new Date(result.date);
            const newTime = date.getTime() / 1000;

            if (result.timeOfDay === false) {
               this.task.checklistDueDateSetting = 0;
            } else {
               this.task.checklistDueDateSetting = 1;
            }

            let startDate, newStartDate, oldStartDate, startDateSetting;
            if (result.startDate.date === false) {
               newStartDate = 0;
               oldStartDate = this.task.checklistStartDate;
               startDateSetting = false;
            } else {
               startDate = new Date(result.startDate.date);
               newStartDate = startDate.getTime() / 1000;
               oldStartDate = this.task.checklistStartDate;
               if (result.startDate.timeOfDay === false) {
                  startDateSetting = false;
               } else {
                  startDateSetting = true;
               }
            }

            this.manageTask
               .updateDueDate(
                  this.task.checklistDueDate ?? 0,
                  newTime,
                  this.task.checklistID,
                  result.timeOfDay,
                  oldStartDate,
                  newStartDate,
                  startDateSetting,
               )
               .then((answer) => {
                  this.taskDisplayData =
                     this.taskViewModelMapperService.getTaskDisplayData(this.task);
                  if (answer.data.success === true) {
                     this.alertService.addAlert(
                        `<i class='fa-regular fa-square-check fa-fw'></i> ${this.lang().successMsgDueDateChange}`,
                        "success",
                        5000,
                     );
                  } else {
                     this.alertService.addAlert(this.lang().errorMsg, "danger", 10000);
                  }
               });
         }
      });
   }

   protected changeCompletedTaskCompletedDate(): void {
      const instance = this.modalService.open(PickDate);

      this.paramsService.params = {
         modalInstance: instance,
         resolve: {
            message: this.lang().changeCompletedTaskCompletedDateMsg,
            title: this.lang().changeCompletedTaskCompletedDateTitle,
            buttonText: this.lang().Change,
            currentDate: this.task.checklistCompletedDate,
            parentOfDate: "completedDate",
            data: {
               viewTimeOfDay: true,
               setNoTimeOfDayOffset: true,
            },
         },
      };

      instance.result.then((result) => {
         if (!this.task.checklistCompletedDate) {
            console.error(
               "Task is missing a completed date, cannot update completed date",
            );
            return;
         }
         if (result.date) {
            const date = new Date(result.date);
            const newTime = date.getTime() / 1000;
            const oldTimeCheck =
               new Date(this.task.checklistCompletedDate ?? 0).getTime() / 1000;

            if (result.timeOfDay != false || (oldTimeCheck != newTime && newTime != 0)) {
               this.manageTask
                  .updateCompletedDate(
                     this.task.checklistCompletedDate,
                     newTime,
                     this.task.checklistID,
                  )
                  .then((answer) => {
                     if (answer.data.success == true) {
                        this.task.checklistCompletedDate = Number(newTime);

                        this.alertService.addAlert(
                           `<i class='fa-regular fa-square-check fa-fw'></i> ${this.lang().successMsgCompletedDateChange}`,
                           "success",
                           5000,
                        );
                     } else {
                        this.alertService.addAlert(this.lang().errorMsg, "danger", 10000);
                     }
                  });
            }
         }
      });
   }

   protected changeWOTemplatePlannedVsUnplanned() {
      let checklistTemplate;
      if (this.task.checklistTemplate === 2) {
         checklistTemplate = 4;
      } else {
         checklistTemplate = 2;
      }

      this.manageTask
         .updateTaskTemplateType(this.task, checklistTemplate)
         .then((answer) => {
            if (answer.data.success == true) {
               this.task.checklistLastEdited = Math.floor(Date.now() / 1000);
               this.workOrderTemplateTypeChange.next(checklistTemplate);
               this.taskDisplayData = this.taskViewModelMapperService.getTaskDisplayData(
                  this.task,
               );

               this.alertService.addAlert(this.lang().successMsg, "success", 2000);
            } else {
               this.alertService.addAlert(this.lang().errorMsg, "danger", 6000);
            }
         });
   }

   private setWorkOrderTemplateTypeDisplay(checklistTemplate: 2 | 4) {
      this.task.checklistTemplate = checklistTemplate;
   }

   emailReminder = (task) => {
      const instance = this.modalService.open(EmailTemplate);

      this.paramsService.params = {
         modalInstance: instance,
         resolve: {
            message: this.lang().emailReminderMessage,
            title: this.lang().emailReminderTitle,
            data: {
               emailTo: false,
               emailSubject: this.lang().emailReminderSubject,
               emailMessage: `"${task.checklistName} - #${task.checklistID} - ${this.assetNameStr()} at ${task.locationName}" ${this.lang().emailReminderBody} - ${this.currentUser.userInfo.customerSignature}`,
               pickEmployees: true,
               checklistID: task.checklistID,
               onSubmit: "send",
            },
         },
         backdrop: "static",
         keyboard: false,
      };
   };

   protected popPoComponent(poID: number): void {
      const instance = this.modalService.open(PoComponent);
      this.paramsService.params = {
         modalInstance: instance,
         resolve: {
            data: { poID },
         },
      };
      instance.result.then(() => {
         if (this.autoCloseTask) {
            this.close();
         }
      });
   }

   popPart = (part: TaskEntityPart | TaskPartLegacy) => {
      const instance = this.modalService.open(PopPart);
      this.paramsService.params = {
         modalInstance: instance,
         resolve: {
            partID: part.partID,
            locationID: part.locationID,
            data: {
               restrict: false,
            },
         },
      };
      instance.result.then(() => {
         this.tellTaskToRefresh(true);
         this.sortParts();
      });
   };

   private upsellModalStartPO() {
      // Upsell modal here
      const popupUpsellInstance = this.modalService.open(PopupUpsellModal);
      this.paramsService.params = {
         modalInstance: popupUpsellInstance,
         resolve: {
            data: {
               icon: "filePowerpoint",
               title: this.lang().POs,
               message: this.lang().FeatureUnlimitedPOsMessage,
               iconColor: "success",
               iconSize: "extra-large",
               actionText: this.lang().LearnMore,
               cancelText: this.lang().Cancel,
               actionLink: "https://limblecmms.com/pricing/",
            },
         },
      };
   }

   protected async prepStartPOForPart(): Promise<void> {
      if (!this.canAddPOs) {
         this.upsellModalStartPO();
         return;
      }

      const modalData: AddPartModalData = {
         message: this.lang().StartPOForThisPartMsg,
         title: this.lang().StartPOForThisPart,
         taskID: this.task.checklistID,
         taskTemplate: this.task.checklistTemplate
            ? this.task.checklistTemplate > 0
            : false,
         buttonText: this.lang().AddPartTitle,
         locationID: this.taskViewModel()?.locationID,
      };

      const parts = await this.partsFacadeService.openAddPartModal(modalData);

      if (parts && parts.length > 0) {
         const partIDs = parts.map((part) => part.partID);
         this.startPOForPart(partIDs);
      }
   }

   prepStartPOForService = () => {
      if (!this.canAddPOs) {
         this.upsellModalStartPO();
         return;
      }
      const instance = this.modalService.open(PickVendors);

      this.paramsService.params = {
         modalInstance: instance,
         resolve: {
            message: this.lang().PickVendorsForServiceMsg,
            title: this.lang().PickVendorsForServiceMsg,
            data: {
               singleLocation: false,
               selectOne: true,
               restrictToCred: true,
               iDontKnowOption: false,
               locationID: this.task.locationID,
            },
         },
      };

      instance.result.then((vendorIDArray) => {
         if (!vendorIDArray || vendorIDArray === "unsure") {
            return;
         }
         //now that we know which vendor we need to add a PO
         this.startPOforService(vendorIDArray[0]);
      });
   };

   protected startPOforService(vendorID: number): void {
      if (!this.canAddPOs) {
         this.upsellModalStartPO();
         return;
      }

      if (
         !this.credService.isAuthorized(
            this.task.locationID,
            this.credService.Permissions.StartAPO,
         )
      ) {
         this.alertService.addAlert(this.lang().cred144Fail, "danger", 10000);
         return;
      }

      const itemSkeleton: PurchaseOrderItemToAddSkeleton = {
         itemType: PurchaseOrderItemType.Service,
         partID: 0,
         assetID: 0,
         checklistID: this.task.checklistID,
         description: this.lang().Service,
         qty: 0,
         glID: 0,
         rate: 0,
      };

      this.managePO
         .addPurchaseOrder(
            this.task.locationID,
            [itemSkeleton],
            vendorID,
            this.task.checklistID,
            0,
            "",
            "standard",
         )
         .then((answer) => {
            if (answer?.data.success == true) {
               this.popPoComponent(answer.data.po.poID);
               this.managePO.incOpenPurchaseOrderWatchVar();
               //we also need to set relationships for any part
            } else {
               this.alertService.addAlert(this.lang().errorMsg, "danger", 6000);
            }
         });
   }

   prepStartPOForOther = () => {
      if (!this.canAddPOs) {
         this.upsellModalStartPO();
         return;
      }
      const instance = this.modalService.open(GatherText);

      this.paramsService.params = {
         modalInstance: instance,
         resolve: {
            message: this.lang().WhatWouldYouLikeToPurchase,
            message2: false,
            title: this.lang().StartPOForAOther,
            warning: false,
            data: {
               currentText: "",
               singleLine: true,
               buttonText: false,
            },
         },
      };

      instance.result.then((text) => {
         if (text) {
            this.startPOForOther(text);
         }
      });
   };
   private startPOForOther(text: string): void {
      if (!this.canAddPOs) {
         return;
      }

      if (
         !this.credService.isAuthorized(
            this.task.locationID,
            this.credService.Permissions.StartAPO,
         )
      ) {
         this.alertService.addAlert(this.lang().cred144Fail, "danger", 10000);
         return;
      }

      const itemSkeleton: PurchaseOrderItemToAddSkeleton = {
         itemType: PurchaseOrderItemType.Other,
         partID: 0,
         assetID: 0,
         checklistID: this.task.checklistID,
         description: text,
         qty: 0,
         glID: 0,
         rate: 0,
      };

      const vendorID = 0;

      this.managePO
         .addPurchaseOrder(
            this.task.locationID,
            [itemSkeleton],
            vendorID,
            this.task.checklistID,
            0,
            "",
            "standard",
         )
         .then((answer) => {
            if (answer?.data.success == true) {
               this.popPoComponent(answer.data.po.poID);
               this.managePO.incOpenPurchaseOrderWatchVar();
               //we also need to set relationships for any part
            } else {
               this.alertService.addAlert(this.lang().errorMsg, "danger", 6000);
            }
         });
   }

   changeTaskExtraTimeNotes = () => {
      const instance = this.modalService.open(GatherText);

      this.paramsService.params = {
         modalInstance: instance,
         resolve: {
            message: false,
            message2: false,
            title: this.lang().UpdateLaborDescription,
            warning: false,
            data: {
               currentText: this.task.extraTimeNotes,
               singleLine: false,
               buttonText: false,
            },
         },
      };

      instance.result.then((text) => {
         if (text) {
            this.manageTask
               .changeTaskExtraTimeNotes(this.task.checklistID, text)
               .then((answer: any) => {
                  if (answer.data.success == true) {
                     this.alertService.addAlert(this.lang().successMsg, "success", 2000);
                  } else {
                     this.alertService.addAlert(this.lang().errorMsg, "danger", 6000);
                  }
               });
         }
      });
   };

   protected startPOForPart(partIDs: Array<number>): void {
      if (!this.canAddPOs) {
         return;
      }

      if (
         !this.credService.isAuthorized(
            this.task.locationID,
            this.credService.Permissions.StartAPO,
         )
      ) {
         this.alertService.addAlert(this.lang().cred144Fail, "danger", 10000);
         return;
      }

      const parts = partIDs.map((partID) => this.manageParts.getPart(partID));

      const poItemsToAdd: any = [];
      let vendorID;

      for (const part of parts) {
         if (part === undefined) continue;

         const itemSkeleton: PurchaseOrderItemToAddSkeleton = {
            itemType: PurchaseOrderItemType.Part,
            partID: part.partID,
            assetID: 0,
            checklistID: 0,
            description: "",
            qty: this.managePO.calcPurchaseOrderItemQty(part),
            glID: 0,
            rate: 0,
         };
         itemSkeleton.description = this.manageParts.generateDescriptionForPart(
            itemSkeleton,
            part,
         );
         poItemsToAdd.push(itemSkeleton);

         if (part.defaultVendorID !== null && part.defaultVendorID > 0) {
            vendorID = part.defaultVendorID;
         } else {
            const associatedVendors = this.manageAssociations.getAssociatedVendorsForPart(
               part.partID,
               this.managePO.getPurchaseOrderItemsByPartID(part.partID),
               this.managePO,
            ); //yes this may be different vendors depending on who you are buying it from... but they can always pick which vendor to buy it from ;p
            if (associatedVendors && associatedVendors.size > 0) {
               // We just assign it to the first vendor in the list
               vendorID = Array.from(associatedVendors)[0].vendorID;
            } else {
               vendorID = 0;
            }
         }
      }

      let source;

      if (this.minPartQtyTask) {
         source = "minPartQty";
      } else {
         source = "standard";
      }

      this.managePO
         .addPurchaseOrder(
            this.task.locationID,
            poItemsToAdd,
            vendorID,
            this.task.checklistID,
            0,
            "",
            source,
         )
         .then((answer) => {
            if (answer?.data.success == true) {
               this.popPoComponent(answer.data.po.poID);
               this.managePO.incOpenPurchaseOrderWatchVar();
               if (this.minPartQtyTask) {
                  this.autoCloseTaskIfSet(partIDs[0]);
               }
            } else {
               this.alertService.addAlert(this.lang().errorMsg, "danger", 6000);
            }
         });
   }

   private parseSavedAssetInfo(): void {
      if (this.task.assetInfoFromCompletion === null) {
         this.assetInfoFromCompletionArr = [];
         return;
      }
      this.task.assetInfoFromCompletion = this.manageUtil.stripTags(
         this.task.assetInfoFromCompletion ?? "",
      );
      try {
         this.assetInfoFromCompletionArr = JSON.parse(this.task.assetInfoFromCompletion);
      } catch {
         console.error("Failed to parse assetInfoFromCompletion");
         this.assetInfoFromCompletionArr = [];
      }
   }

   private updateAssetInfoFromCompletion() {
      if (this.info === undefined) return;
      //Find any linked dropdown items so their valueContent can be updated

      const linkedDropdownItems = this.taskCompleterService.getLinkedDropdownItems(
         this.info.items,
      );

      this.taskCompleterService.updateLinkedDropdownItemsAssetInfoFromCompletion(
         this.info.items,
      );

      this.taskCompleterService.updateValueContentForLinkedDropdownItems(this.info.items);

      if (this.task.assetID) {
         if (!this.asset()) {
            this.asset.set(this.manageAsset.getAsset(this.task.assetID));
         }
         // JIT task's asset doesn't seem to have assetValueIDs, so we'll need to fallback to manageAsset.getAsset()
         const assetValueIDs =
            this.asset()?.assetValueIDs ??
            this.manageAsset.getAsset(this.task.assetID)?.assetValueIDs;
         const assetFieldValues = assetValueIDs?.map((valueID) =>
            this.manageAsset.getFieldValue(valueID),
         );

         //if the task has an associated asset, it may need to update the displayed asset info
         const newAssetInformation = assetFieldValues
            ?.filter((fieldValue) => {
               if (!fieldValue) {
                  return false;
               }
               const field = this.manageAsset.getField(fieldValue.fieldID);
               if (!field) {
                  return false;
               }
               return field.displayOnTasks === 1;
            })
            .map((fieldValue) => {
               if (!fieldValue) {
                  return undefined;
               }
               const field = this.manageAsset.getField(fieldValue.fieldID);
               if (!field) {
                  return false;
               }
               const fieldType = this.manageAsset.getFieldType(field.fieldTypeID);
               if (!fieldType) {
                  return false;
               }
               //Change the valueContent for dropdowns linked to asset text fields
               if (linkedDropdownItems.length !== 0) {
                  const matchedValueID = linkedDropdownItems.find((mappedObject) => {
                     return Number(mappedObject?.valueID) === Number(fieldValue.valueID);
                  });
                  if (matchedValueID) {
                     fieldValue.valueContent = matchedValueID.displayText ?? null;
                  }
               }
               //The below is used to prevent the JSON from breaking when we scrub it on the back end.  Our scrub function escapes all quotation marks but skips those that are already escaped, so if values that go in JSON later dont have their quotes double escaped, the JSON will be impossible to parse.
               let escapedValueContent = fieldValue.valueContent;
               if (typeof fieldValue.valueContent === "string") {
                  escapedValueContent = fieldValue.valueContent
                     ?.replace(/"/gi, `\\"`)
                     .replace(/\n/g, "");
               }

               return {
                  assetID: fieldValue.assetID,
                  fieldName: field.fieldName,
                  valueContent: escapedValueContent,
                  fieldTypeIcon: fieldType.fieldTypeIcon,
                  fieldTypeHint: fieldType.fieldTypeHint,
                  fieldTypeID: field.fieldTypeID,
               };
            });

         const stringifiedNewAssetInformation = JSON.stringify(newAssetInformation);

         //if the task has been reopened and is being completed again, but the data is the same, don't change the data
         if (stringifiedNewAssetInformation !== this.task.assetInfoFromCompletion) {
            this.manageTask
               .updateAssetInfoFromCompletion(
                  stringifiedNewAssetInformation,
                  this.task.checklistID,
               )
               .then((answer) => {
                  if (answer.data.success) {
                     //set both local versions of stringified asset info to new asset info
                     this.task.assetInfoFromCompletion = stringifiedNewAssetInformation;
                     this.parseSavedAssetInfo();
                  }
               });
         }
      }
   }

   protected async syncRelatedInstructionSets() {
      this.loadingBarService.show({ header: this.lang()?.WakingUpHamsters });
      const resObj = await this.manageTaskItem.prepSyncRelatedInstructionSets(
         Number(this.task.checklistID),
      );
      this.loadingBarService.remove();
      if (!resObj) {
         this.alertService.addAlert(this.lang().errorMsg, "danger", 6000);
         return;
      }

      const instance = this.modalService.open(Confirm);

      this.paramsService.params = {
         modalInstance: instance,
         resolve: {
            message: resObj.message,
            title: this.lang().ConfirmSyncRelatedInstructionSetsTitle,
         },
      };
      instance.result.then(async (result) => {
         if (result === 1) {
            this.currentlyAt = 0;
            this.end = resObj.toUpdateArray.length;
            const answer = await this.tasksFacadeService.syncRelatedInstructionSets(
               resObj.instructionSetBatchID,
               resObj.toUpdateArray,
               this.progressObservable$,
            );
            this.currentlyAt = 0;
            this.end = 0;
            if (answer) {
               this.alertService.addAlert(this.lang().successMsg, "success", 1000);
            } else {
               this.alertService.addAlert(this.lang().errorMsg, "danger", 6000);
            }
         }
      });
   }

   public async addToolToTask() {
      if (this.preventDoubleClick === true) {
         return;
      }
      this.preventDoubleClick = true;
      this.taskFormState.updatePreventDoubleClick(true);
      setTimeout(() => {
         this.preventDoubleClick = false;
         this.taskFormState.updatePreventDoubleClick(false);
      }, 500);

      if (!this.featureAssetTools) {
         return;
      }

      const instance = this.modalService.open(PickTools);

      this.paramsService.params = {
         modalInstance: instance,
         resolve: {
            dataLogOptions: this.dataLogOptions,
            title: this.lang().PickTools,
            message: this.lang().ChooseWhichToolsYouWouldLikeToAddToTheTask,
         },
      };
      const selectedToolAssetIDs = await instance.result;
      if (!selectedToolAssetIDs) {
         return;
      }
      const associatedToolData = await this.manageTool.associateToolsWithTask(
         this.task.checklistID,
         selectedToolAssetIDs,
      );
      if (!associatedToolData) {
         return;
      }
      const orderedTools = orderBy(associatedToolData, "assetName");
      this.tools = orderedTools;
   }

   public async setTaskTools(checklistID: number) {
      this.tools = await lastValueFrom(
         from(this.manageTool.getTaskTools(checklistID)).pipe(
            takeUntilDestroyed(this.destroyRef),
         ),
      );
   }

   //arrow syntax used to preserve 'this' when passed to child component
   public removeToolFromTask = async (toolAssetID: number) => {
      const instance = this.modalService.open(Confirm);

      this.paramsService.params = {
         modalInstance: instance,
         resolve: {
            message: this.lang().AreYouSureYouWantToRemoveThisToolFromTheTask,
            title: this.lang().RemoveTool,
         },
      };

      const confirmed = await instance.result;
      if (!confirmed) {
         return;
      }

      const deleted = await this.manageTool.removeToolFromTask(
         Number(toolAssetID),
         this.task.checklistID,
      );
      if (deleted && this.tools) {
         const toolIndex = this.tools.findIndex(
            (tool) => Number(tool.tool.assetID) === Number(toolAssetID),
         );
         this.tools.splice(toolIndex, 1);
      }
   };

   public async openViewMap() {
      if (this.task.statusID === 2) {
         return;
      }

      const instance = this.modalService.open(ViewMap);
      const canEdit = this.editable() && this.currentUser.workOrderUser !== 1;

      const title = this.geoLocation
         ? this.lang().ViewMapLocation
         : this.lang().SetMapLocation;

      this.paramsService.params = {
         modalInstance: instance,
         resolve: {
            task: this.task,
            locationID: this.task.locationID,
            title: title,
            canEdit: canEdit,
         },
      };

      await instance.result;
      await this.setTask(false);
   }

   public navigateToLocation(geoLocation) {
      this.manageMaps.navigateToGeolocation(geoLocation.geometry.coordinates);
   }

   private async checkForMissingParts(parts: Array<TaskPartLegacy>): Promise<any> {
      const missingPartIDs: Array<number> = [];

      for (const part of parts) {
         if (!this.partsLookup.get(part.partID)) {
            missingPartIDs.push(part.partID);
         }
      }

      if (missingPartIDs.length === 0) {
         return;
      }

      await Promise.all([
         this.manageParts.getMissingPartInfoForTask(missingPartIDs),
         this.manageAssociations.fetchPartVendorRelations(missingPartIDs),
      ]);
      this.partsLookup = this.manageParts.getParts();
   }

   protected toggleFab(): void {
      this.showFab = !this.showFab;
   }

   protected showTaskTooLongToPrintMessage() {
      this.alertService.addAlert(this.lang().ThisTaskIsTooLongToPrint, "warning", 6000);
   }

   private initializeChkItemsCollectionSub() {
      if (!this.chkItemsCollection || this.chkItemsCollectionSub) {
         return;
      }
      this.chkItemsCollectionSub = this.chkItemsCollection?.changes.subscribe(() => {
         if (!this.chkItemsCollection) {
            return;
         }
         this.treeSize$ = this.taskTreeSnapshotService
            .generateTreeChangeObservable(this.chkItemsCollection)
            .pipe(
               map(() => {
                  if (!this.chkItemsCollection) {
                     return 0;
                  }
                  return this.taskTreeSnapshotService.getTaskTreeSnapshotSize(
                     this.chkItemsCollection,
                  );
               }),
               startWith(
                  this.taskTreeSnapshotService.getTaskTreeSnapshotSize(
                     this.chkItemsCollection,
                  ),
               ),
            );
      });
   }

   private setGeolocation() {
      this.geoLocation = null;

      const asset = this.asset();
      if (asset && asset.assetID !== 0 && asset.geoLocation) {
         this.geoLocation = asset.geoLocation;
      } else if (this.task?.geoLocation) {
         this.geoLocation = JSON.parse(this.task.geoLocation);
      } else if (this.task && !this.task.geoLocation && this.task.assetID) {
         const assetFromTask = this.manageAsset.getAsset(this.task.assetID);
         if (assetFromTask?.geoLocation) {
            this.geoLocation = assetFromTask.geoLocation;
         }
      }
   }

   private setStatusListAndDisplay() {
      this.statusList = this.manageStatus.getStatusList();

      if (!this.statusList) return;

      for (const status of this.statusList) {
         if (status.statusID === 0) {
            this.open = status;
         } else if (status.statusID === 2) {
            this.completed = status;
         } else {
            this.middleStatus.push(status);
         }
      }
      this.statusListIndex = this.manageStatus.getStatusListIndex();
      this.setMiddleDisplay(this.task?.statusID);
   }

   private taskHasOptionSelectedWithSameCustomTag(
      previousOption: Record<string, any>,
      parentItem: Record<string, any>,
   ): boolean {
      const itemsToCheck =
         this.taskTreeSnapshotService.getRadioOrDropdownItemsWithResponses(
            this.chkItemsCollection,
            parentItem.itemID,
         );
      const selectedOptions = itemsToCheck
         .map((element) => {
            return element
               .item()
               .nodes.find(
                  (option) =>
                     option.itemOptionCount === Number(element.item().itemResponse),
               );
         })
         .filter((option) => option !== undefined);
      return selectedOptions.some(
         (option) =>
            (option.itemOptionID !== previousOption.itemOptionID &&
               (option.customTagToAdd !== undefined ||
                  option.customTagToRemove !== undefined) &&
               option.customTagToAdd === previousOption.customTagToAdd &&
               (option.customTagToAddEvent === 1 || option.customTagToAddEvent === 2)) ||
            (option.customTagToRemove === previousOption.customTagToRemove &&
               (option.customTagToRemoveEvent === 1 ||
                  option.customTagToRemoveEvent === 2)),
      );
   }

   protected priorityDropdownClickHandler() {
      if (!this.priorityDropdown?.isOpen()) {
         this.priorityDropdown?.initiateOpen();
      }
   }

   private getElapsedTimeDuration(): void {
      const timer = this.timerComponent();
      const taskID = this.task.checklistID;
      if (taskID == undefined || timer == undefined) return;
      this.timerDurationService.getDuration(timer, taskID);
   }

   private storeElapsedTimeDuration(): void {
      const timer = this.timerComponent();
      const taskID = this.task.checklistID;
      if (taskID == undefined || timer == undefined) return;
      this.timerDurationService.storeDuration(timer, taskID);
   }

   private async autoCloseTaskIfSet(partID: number): Promise<void> {
      const part = this.manageParts.getPart(partID);
      const partAutoCloseSetting =
         part?.minThresholdTaskClose ??
         this.locations[this.task.locationID].minThresholdTaskCloseDefault;
      this.autoCloseTask = partAutoCloseSetting === "automatic";

      if (this.autoCloseTask) {
         // Mark the checkbox as checked
         // If they've added more instructions we're not going to auto close the task
         if (this.items().length === 1) {
            const setResponse = await this.manageTaskItem.setResponse(
               this.items()[0].itemID,
               1,
               false,
               false,
            );

            if (setResponse.data.success !== true) {
               this.alertService.addAlert(this.lang().errorMsg, "danger", 6000);
               this.autoCloseTask = false;
               return;
            }
         } else {
            this.autoCloseTask = false;
            return;
         }

         // Then close the task
         const finishChecklist = await this.manageTask.finishChecklist(
            this.data.checklistID,
            60,
            0,
            [],
            "",
            false,
            0,
            this.task.locationID,
         );

         if (finishChecklist?.data.success === true) {
            const message = `${this.lang().POHasBeenCreated} <b>${this.task.checklistName}</b> ${this.lang().HasBeenClosed}`;
            this.alertService.addAlert(message, "success", 5000);
            this.tellTaskToRefresh(true);
         } else {
            this.autoCloseTask = false;
            this.alertService.addAlert(this.lang().errorMsg, "danger", 6000);
         }
      }
   }
}
