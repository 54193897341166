import { Component } from "@angular/core";
import { DropdownButtonComponent, DropdownTextItemComponent } from "@limblecmms/lim-ui";
import { TranslateDirective } from "src/app/languages/i18n/translate.directive";
import { BaseFilterComponent } from "src/app/shared/data-viewer/data-viewer-filters/components/base-data-viewer-filter/base-filter.component";
import { WorkRequestSubmissionStatus } from "src/app/tasks/services/work-request-submission-view-model-factory/mappers/work-request-submission-view-model-mapper.service";

export type WorkRequestStatusFilterType = {
   value: WorkRequestSubmissionStatus;
   name: string;
};

@Component({
   selector: "work-request-status-filter",
   imports: [DropdownButtonComponent, DropdownTextItemComponent, TranslateDirective],
   templateUrl: "./work-request-status-filter.component.html",
})
export class WorkRequestStatusFilterComponent extends BaseFilterComponent {
   protected readonly statuses: Array<WorkRequestStatusFilterType> = [
      {
         value: WorkRequestSubmissionStatus.PENDING,
         name: "Pending",
      },
      {
         value: WorkRequestSubmissionStatus.REJECTED,
         name: "Rejected",
      },
   ];

   public onSetFilter(status: WorkRequestStatusFilterType) {
      const value = { [this.filter().key]: status.value };
      this.set.emit({ ...this.filter(), activeLabel: status.name, value });
   }
}
