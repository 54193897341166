<div class="container" [ngClass]="{ fluid: fluid() }">
   <p-iconfield class="input-container">
      <p-inputicon styleClass="pi">
         <lim-ui-icon icon="magnifyingGlass" [iconSize]="iconSize()" />
      </p-inputicon>
      <input
         #searchInput
         eInputText
         type="text"
         ngDefaultControl
         [size]="size()"
         [fluid]="fluid()"
         placeholder="{{ placeholder() }}"
         [ngModel]="searchVal()"
         (ngModelChange)="writeValue($event)"
         (blur)="onTouchedCallback()"
      />
   </p-iconfield>
   @if (searchVal()?.length && clearText()?.length) {
      <span class="clear-search" (click)="clearSearch($event)">
         {{ clearText() }}
      </span>
   }
</div>
