@if (task(); as taskItem) {
   <span
      id="{{ taskItem.checklistID }}TaskHolder"
      class="task-item-wrapper lim-ui-responsive-table-row"
      [class.completed-task-row]="taskItem.isCompleted"
   >
      <!-- Start of Building Out Columns -->
      @for (column of columns(); track column; let isLast = $last) {
         <div class="col-md-{{ column.columnWidth }} lim-ui-responsive-table-cell">
            @switch (column.key) {
               @case ("checklistName") {
                  <task-name-cell
                     class="lim-ui-mobile-table-header"
                     [task]="taskItem"
                     [searchHint]="searchHint()"
                  />
               }

               @case ("taskMinimalName") {
                  <task-minimal-name-cell
                     class="lim-ui-mobile-table-header"
                     [task]="taskItem"
                     [searchHint]="searchHint()"
                  />
               }

               @case ("tasksSchedulesCombinedName") {
                  @if (taskItem.tscNameCell) {
                     <tasks-schedules-combined-name-cell
                        [data]="taskItem.tscNameCell"
                        [searchHint]="searchHint()"
                     />
                  }
                  @if (taskItem.checklistName) {
                     <task-minimal-name-cell
                        class="lim-ui-mobile-table-header"
                        [task]="taskItem"
                        [searchHint]="searchHint()"
                     />
                  }
               }

               @case ("locationName") {
                  <location-cell [task]="taskItem" />
               }

               @case ("simultaneousUsers") {
                  <task-simultaneous-users [id]="taskItem.checklistID" />
               }

               @case ("checklistDueDate") {
                  <task-due-date-cell [task]="taskItem" [column]="column" />
               }

               @case ("assignedTo") {
                  <task-assigned-to-cell [task]="taskItem" [column]="column" />
               }

               @case ("checklistPromptTimeTotal") {
                  <task-time-spent-cell [task]="taskItem" [column]="column" />
               }

               @case ("estimatedTime") {
                  <task-estimated-time-cell
                     [estimatedTime]="
                        taskItem.estimatedTime ?? taskItem.checklistEstTime ?? 0
                     "
                  />
               }

               @case ("checklistTotalPartsCost") {
                  <task-parts-cost-cell [task]="taskItem" [column]="column" />
               }

               @case ("checklistTotalInvoiceCost") {
                  <task-invoices-cost-cell [task]="taskItem" [column]="column" />
               }

               @case ("checklistTotalOperatingCost") {
                  <task-total-cost-cell [task]="taskItem" [column]="column" />
               }

               @case ("checklistPromptTimeTotalWithLaborCost") {
                  <task-labor-cost-and-time-cell [task]="taskItem" [column]="column" />
               }

               @case ("laborCost") {
                  <task-labor-cost-cell [task]="taskItem" [column]="column" />
               }

               @case ("checklistDowntime") {
                  <task-downtime-cell [task]="taskItem" [column]="column" />
               }

               @case ("finalColorStatus") {
                  <task-status-color-cell [task]="taskItem" [column]="column" />
               }

               @case ("checklistTemplateOldString") {
                  <task-type-cell [task]="taskItem" />
               }

               @case ("checklistCompletedDate") {
                  <task-completed-on-cell [task]="taskItem" [column]="column" />
               }

               @case ("completedByStr") {
                  <task-completed-by-cell [task]="taskItem" [column]="column" />
               }

               @case ("completionNotes") {
                  <completion-notes-cell [task]="taskItem" />
               }

               @case ("taskPartName") {
                  <report-task-name-cell [task]="taskItem" />
               }
               @case ("taskPartQuantity") {
                  @if (part(); as part) {
                     <report-task-quantity-cell [task]="taskItem" [part]="part" />
                  }
               }
               @case ("checklistLastEdited") {
                  <task-latest-activity-cell [task]="taskItem" />
               }
               @case ("downtimeEfficiencyRate") {
                  <downtime-efficiency-rate-cell [task]="taskItem" />
               }
               @case ("laborCostEfficiencyRate") {
                  <labor-cost-efficiency-rate-cell [task]="taskItem" />
               }
               @case ("partCostEfficiencyRate") {
                  <part-cost-efficiency-rate-cell [task]="taskItem" />
               }
               @case ("averageDowntimeCost") {
                  <average-downtime-cost-cell [task]="taskItem" />
               }
               @case ("downtimeEfficiencySavings") {
                  <downtime-efficiency-savings-cell [task]="taskItem" />
               }
               @case ("laborCostEfficiencySavings") {
                  <labor-cost-efficiency-savings-cell [task]="taskItem" />
               }
               @case ("partCostEfficiencySavings") {
                  <part-cost-efficiency-savings-cell [task]="taskItem" />
               }
               @case ("taskNameWithoutAsset") {
                  <task-name-without-asset-cell
                     [task]="taskItem"
                     [searchHint]="searchHint()"
                  />
               }
               @case ("assetName") {
                  <task-asset-cell [task]="taskItem" />
               }
               @case ("checklistID") {
                  <task-id-cell [task]="taskItem" />
               }
            }
         </div>
      }

      @if (searchHint(); as searchHint) {
         <span class="search-hint mobile-search-hint">
            <span [limbleHtml]="searchHint"> </span>
         </span>
      }
      @if (
         !taskItem.isCompleted &&
         facade.isAllowedToDelete(taskItem) &&
         globalSearch() === false
      ) {
         <lim-ui-row-hover-buttons
            class="table-end-of-row-buttons lim-ui-show-on-desktop"
            (clickDelete)="deleteTask()"
            (clickCopy)="duplicate($event)"
            [showDeleteButton]="true"
            [showCopyButton]="true"
            [deleteButtonTooltip]="lang().permDeleteTaskTooltip"
            [copyButtonTooltip]="lang().duplicateOpenTask"
            [limUiPopoverType]="'upsell'"
            [limUiPopoverPlacement]="'left'"
            [limUiPopoverHidden]="
               (facade.canDuplicateTasks$ | async) || !addPartPopoverCheck
            "
            [limUiPopover]="duplicateTaskDesktopPopover"
         />
      }
      <ng-template #duplicateTaskDesktopPopover>
         <upsell-popover
            [message]="
               woInstructionLimit > 2
                  ? lang().FeatureThreeWOsPopoverMessage
                  : lang().FeatureTwoWOsPopoverMessage
            "
            [pricingLinkText]="lang().LearnMore"
         />
      </ng-template>
   </span>

   @if (areActionButtonsVisible()) {
      <lim-ui-row-hover-buttons
         class="table-end-of-row-buttons lim-ui-show-on-desktop"
         (clickDelete)="deleteTask()"
         (clickCopy)="duplicate($event)"
         [showDeleteButton]="true"
         [showCopyButton]="true"
         [deleteButtonTooltip]="lang().permDeleteTaskTooltip"
         [copyButtonTooltip]="lang().duplicateOpenTask"
         [limUiPopoverType]="'upsell'"
         [limUiPopoverPlacement]="'left'"
         [limUiPopoverHidden]="
            (facade.canDuplicateTasks$ | async) || !addPartPopoverCheck
         "
         [limUiPopover]="duplicateTaskDesktopPopoverTwo"
      />
   }
   <ng-template #duplicateTaskDesktopPopoverTwo>
      <upsell-popover
         [message]="
            woInstructionLimit > 2
               ? lang().FeatureThreeWOsPopoverMessage
               : lang().FeatureTwoWOsPopoverMessage
         "
         [pricingLinkText]="lang().LearnMore"
      />
   </ng-template>
}
