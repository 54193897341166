import { CommonModule } from "@angular/common";
import { Component } from "@angular/core";
import { BaseCellRendererComponent } from "@empowered/base/table/components/cells-renderers/base-cell-renderer.component.ts/base-cell-renderer.component";

interface IdCellData {
   id: string;
}

@Component({
   selector: "id-cell-renderer",
   imports: [CommonModule],
   templateUrl: "./id-cell-renderer.component.html",
})
export class IdCellRendererComponent extends BaseCellRendererComponent<IdCellData> {}
