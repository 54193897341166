import { Directive, inject, input } from "@angular/core";
import type { InputTextSize } from "@empowered/base/input-text/input-text.types";
import { InputText } from "primeng/inputtext";

@Directive({
   selector: "[eInputText]",
   standalone: true,
   hostDirectives: [
      {
         directive: InputText,
         inputs: ["variant", "fluid", "pSize"],
      },
   ],
})
export class InputTextDirective {
   private readonly host: InputText;

   /**
    * Defines the size of the component and communicates it to the host directive
    */
   public size = input<InputTextSize, InputTextSize>("normal", {
      transform: (val: InputTextSize) => {
         if (val === "normal") {
            // @ts-expect-error: Unreachable code error -- optional input; can accept undefined
            this.host.pSize = undefined;
         } else {
            this.host.pSize = val;
         }
         return val;
      },
   });

   /**
    * Spans 100% width of the container when enabled.
    */
   fluid = input<boolean>(false);

   public constructor() {
      this.host = inject(InputText);
   }
}
