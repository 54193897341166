import { computed, inject, Injectable, signal, type Signal } from "@angular/core";
import { toObservable } from "@angular/core/rxjs-interop";
import {
   getExtraColumns,
   getParams,
} from "@empowered/base/table/utils/column-table-parser";
import {
   BehaviorSubject,
   combineLatest,
   debounceTime,
   firstValueFrom,
   lastValueFrom,
   map,
   type Observable,
} from "rxjs";
import { filter } from "rxjs/operators";
import type { Column } from "src/app/shared/data-viewer/column-builder";
import type { DataViewerFilter } from "src/app/shared/data-viewer/data-viewer-filters";
import { distinctUntilKeysChanged } from "src/app/shared/data-viewer/operators/distinct-until-keys-changed-operator/distinct-until-keys-changed-operator";
import type {
   RequestFilter,
   RequestOptions,
   RequestParam,
   SortParams,
} from "src/app/shared/services/flannel-api-service";
import { ManageUser } from "src/app/users/services/manageUser";

@Injectable()
export class DataViewerStateService {
   private readonly manageUser = inject(ManageUser);

   protected columns: Array<Column> = [];

   private readonly activeFiltersSubject = new BehaviorSubject(
      new Map<string, DataViewerFilter>(),
   );

   /**
    * This is used to keep track the active filters and will be used to display the active filters in the UI.
    * e.g. [{ key: "assignedTo", value: "John Doe" }, { key: "completedBy", value: "Jane Doe" }]
    * NOTE: `activeFilters$` will be renamed to `dataViewer` once we fully switch over to using requestOptionsState
    */
   public activeFilters$ = this.activeFiltersSubject
      .asObservable()
      .pipe(map((filters) => Array.from(filters.values())));

   /**
    * The base filters are immutable. For example, they include the locationID on the page.
    * Users will not change these filters.
    */
   private readonly baseFiltersSubject = new BehaviorSubject<Array<RequestFilter>>([]);
   private readonly baseFilters$ = this.baseFiltersSubject.asObservable();

   /**
    * This is used to get the filters that will be sent to the backend.
    * e.g. { assignedTo: "John Doe", completedBy: "Jane Doe" }
    */
   public readonly filters$ = combineLatest([
      this.activeFilters$,
      this.baseFilters$,
   ]).pipe(
      map(([activeFilters, baseFilters]) => {
         let filters = {};
         activeFilters.forEach((dataViewerFilter: DataViewerFilter) => {
            filters = {
               ...filters,
               ...dataViewerFilter.value,
            };
         });
         baseFilters.forEach((baseFilter: RequestFilter) => {
            filters = {
               ...filters,
               ...baseFilter,
            };
         });

         return filters;
      }),
   );

   /**
    * TODO: Eventually this should replace all of the public observables/signals getting used by consumers of this service.
    * we should be using this state to make reducer updates instead of using individual behaviorSubjects for keeping track of each request option state.
    *
    * once we are ready to clean up deprecated implementations - let's rename this as "state"
    * then each part of the state - search, filters, pagination, etc - can be exposed as selectors via "computed()"
    */
   public readonly requestOptionsState = signal<Partial<RequestOptions>>({
      pagination: { page: 0, limit: 0 },
      filters: {},
      search: "",
      sort: "",
      columns: "",
      params: {},
   });

   public readonly sort = computed(() => {
      const sortValue = this.requestOptionsState().sort;

      return typeof sortValue === "string" ? sortValue : "";
   });

   /**
    * When consuming this, please be sure to avoid adding stacked debounce calls from the consumer component.
    */
   public readonly requestOptions$: Observable<Partial<RequestOptions>> = toObservable(
      this.requestOptionsState,
   ).pipe(
      filter((requestOptions) => (requestOptions.pagination?.limit ?? 0) > 0),
      debounceTime(400),
      distinctUntilKeysChanged([
         "pagination",
         "filters",
         "search",
         "sort",
         "columns",
         "params",
      ]),
   );

   public readonly requestOptionsWithoutPagination$: Observable<Partial<RequestOptions>> =
      this.requestOptions$.pipe(
         map((requestOptions) => {
            // eslint-disable-next-line typescript/no-unused-vars -- not this is a way to remove pagination from the request options
            const { pagination, ...rest } = requestOptions;
            return rest;
         }),
      );

   public requestOptions: Signal<Partial<RequestOptions>> = this.requestOptionsState;

   public requestOptionsWithoutPagination = computed(() => {
      // eslint-disable-next-line typescript/no-unused-vars -- not this is a way to remove pagination from the request options
      const { pagination, ...rest } = this.requestOptions();
      return rest;
   });

   public page = computed(() => {
      return this.requestOptionsState().pagination?.page ?? 0;
   });

   public pageSize = computed(() => {
      return this.requestOptionsState().pagination?.limit ?? 0;
   });

   public constructor() {
      this.initializePagination();
   }

   public async addFilter(dataViewerFilter: DataViewerFilter) {
      const currentFilters = this.activeFiltersSubject.value;
      currentFilters.set(dataViewerFilter.key, dataViewerFilter);
      this.activeFiltersSubject.next(currentFilters);

      const baseFilters = await firstValueFrom(this.baseFilters$);
      const state = this.requestOptionsState();
      this.requestOptionsState.set({
         ...this.requestOptionsState(),
         filters: this.combineFilters([...currentFilters.values()], baseFilters),
         pagination: {
            ...state.pagination,
            page: 1,
         },
      });
   }

   public async removeFilter(dataViewerFilter: DataViewerFilter) {
      const currentFilters = this.activeFiltersSubject.value;

      // Before we delete, first check if the filter exists
      if (!currentFilters.has(dataViewerFilter.key)) {
         return;
      }
      currentFilters.delete(dataViewerFilter.key);
      this.activeFiltersSubject.next(currentFilters);

      const baseFilters = await firstValueFrom(this.baseFilters$);
      const state = this.requestOptionsState();
      this.requestOptionsState.set({
         ...this.requestOptionsState(),
         filters: this.combineFilters([...currentFilters.values()], baseFilters),
         pagination: {
            ...state.pagination,
            page: 1,
         },
      });
   }

   public setPageSize(size: number): void {
      if (size === this.requestOptionsState().pagination?.limit) {
         return;
      }
      const state = this.requestOptionsState();

      this.requestOptionsState.set({
         ...state,
         pagination: {
            ...state.pagination,
            limit: size,
            page: 1,
         },
      });
   }

   public setPage(page: number) {
      if (page === this.requestOptionsState().pagination?.page) {
         return;
      }
      const state = this.requestOptionsState();

      this.requestOptionsState.set({
         ...state,
         pagination: {
            ...state.pagination,
            page,
         },
      });
   }

   public setSearch(searchedText: string) {
      this.requestOptionsState.set({
         ...this.requestOptionsState(),
         search: searchedText,
         pagination: {
            ...this.requestOptionsState().pagination,
            page: 1, // we need to reset the page to 1 since results to display will be different for updated searchText
         },
      });
   }

   private async initializePagination() {
      await lastValueFrom(
         this.manageUser.currentUserInitialized$.pipe(
            filter((initialized) => Boolean(initialized)),
         ),
      );

      const pageSize =
         this.manageUser.getCurrentUser().userInfo.userUIPreferences.itemsPerPage || 10;
      this.setPageSize(pageSize);
   }

   /**
    *  Set the view type, and based on it will set the columns, params and extra columns
    */
   public initialize(options: {
      sort: string;
      columns: Array<Column>;
      requestFilters?: Array<RequestFilter>;
      requestParams?: RequestParam;
   }) {
      const { sort, columns, requestFilters } = options;

      // Sets the sort type
      if (sort) {
         this.setSort(sort);
      }

      // Get the params and extra columns needed based on the columns definitions
      if (columns && columns.length > 0) {
         const requestParams = getParams(columns, options.requestParams);
         this.setParams(requestParams);

         const extraColumns = getExtraColumns(columns);
         this.setExtraColumns(extraColumns);
      }

      // Sets the base filters required for the view
      if (requestFilters && requestFilters.length > 0) {
         this.setBaseFilters(requestFilters);
      }
   }
   /**
    * Set the sort option
    * @param sortParams - The sort options, you can either pass a string ("-checkListName")
    *  or an object with field and direction
    */
   public setSort(sortParams: SortParams | string) {
      let sort: string;
      if (typeof sortParams === "string") {
         sort = sortParams;
      } else {
         const { direction, field } = sortParams;
         sort = `${direction === "asc" ? "" : "-"}${field}`;
      }

      this.requestOptionsState.set({
         ...this.requestOptionsState(),
         sort,
         pagination: {
            ...this.requestOptionsState().pagination,
            page: 1, // we need to reset the page to 1 since results to display will be different for updated searchText
         },
      });
   }

   /**
    * @deprecated - Use the `initialize` method instead
    */
   public setExtraColumns(columns: string) {
      this.requestOptionsState.set({
         ...this.requestOptionsState(),
         columns,
      });
   }

   /**
    * @deprecated - Use the `initialize` method instead
    */
   public setParams(params: RequestParam) {
      this.requestOptionsState.set({
         ...this.requestOptionsState(),
         params,
      });
   }

   /**
    * @deprecated - Use the `initialize` method instead
    */
   public async setBaseFilters(baseFilters: Array<RequestFilter>) {
      const dataViewerFilters = await firstValueFrom(this.activeFilters$);

      this.baseFiltersSubject.next(baseFilters);

      this.requestOptionsState.set({
         ...this.requestOptionsState(),
         filters: this.combineFilters(dataViewerFilters, baseFilters),
      });
   }

   private combineFilters(
      dataViewerFilters: DataViewerFilter[],
      requestFilters: RequestFilter[],
   ): RequestFilter {
      let filters = {};
      dataViewerFilters.forEach((dataViewerFilter: DataViewerFilter) => {
         filters = {
            ...filters,
            ...dataViewerFilter.value,
         };
      });
      requestFilters.forEach((requestFilter: RequestFilter) => {
         filters = {
            ...filters,
            ...requestFilter,
         };
      });

      return filters;
   }
}
