import { NgClass } from "@angular/common";
import {
   type AfterViewInit,
   type ElementRef,
   input,
   Component,
   computed,
   ViewChild,
   forwardRef,
   model,
} from "@angular/core";
import type { ControlValueAccessor } from "@angular/forms";
import { FormsModule, NG_VALUE_ACCESSOR } from "@angular/forms";
import { IconComponent } from "@limblecmms/lim-ui";
import { IconField } from "primeng/iconfield";
import { InputIcon } from "primeng/inputicon";
import { noop } from "rxjs";
import { InputTextDirective } from "src/app/shared/empowered/base/input-text/input-text.directive";
import type { InputTextSize } from "src/app/shared/empowered/base/input-text/input-text.types";

@Component({
   selector: "e-search",
   templateUrl: "./search.component.html",
   styleUrls: ["./search.component.scss"],
   standalone: true,
   providers: [
      {
         provide: NG_VALUE_ACCESSOR,
         useExisting: forwardRef(() => SearchComponent),
         multi: true,
      },
   ],
   imports: [
      FormsModule,
      IconComponent,
      IconField,
      InputIcon,
      InputTextDirective,
      NgClass,
   ],
})
export class SearchComponent implements AfterViewInit, ControlValueAccessor {
   /**
    * Text value for the 'Clear' button
    */
   public clearText = input<string>("");

   /**
    * Spans 100% width of the container when enabled.
    */
   public fluid = input<boolean>(false);

   /**
    * Whether or not to focus this input on component initialization
    */
   public focusOnLoad = input<boolean>(false);

   /**
    * Placeholder text for the search bar
    */
   public placeholder = input<string>("Search...");

   /**
    * The user input search value.
    */
   public searchVal = model<string>("");

   /**
    * Defines the size of the component.
    */
   public size = input<InputTextSize>("normal");

   /**
    * searchInput ViewChild to focus the element if focusOnLoad is set
    */
   @ViewChild("searchInput") searchInput!: ElementRef<HTMLDivElement>;

   /**
    * Defines size of search icon. Computed based on "size" input property.
    */
   protected iconSize = computed(() => {
      switch (this.size()) {
         case "small":
            return "small";
         case "large":
            return "medium";
         default:
            return "default";
      }
   });

   private onChangeCallback: (value: string) => void = noop;
   protected onTouchedCallback: () => void = noop;

   public ngAfterViewInit(): void {
      if (this.focusOnLoad()) {
         setTimeout(() => {
            this.searchInput.nativeElement.focus();
         });
      }
   }

   public clearSearch(event: MouseEvent): void {
      this.writeValue("");
      this.searchInput.nativeElement.focus();
      // Prevent the dropdowns from closing on clear search
      event.stopPropagation();
   }

   public writeValue(searchVal: string): void {
      this.searchVal.set(searchVal);
      this.onChangeCallback(searchVal);
   }

   public registerOnChange(callback: (value: string) => void): void {
      this.onChangeCallback = callback;
   }

   public registerOnTouched(callback: () => void): void {
      this.onTouchedCallback = callback;
   }
}
