<ng-container *translate="let t">
   <lim-ui-modal [showWatermark]="true">
      <lim-ui-basic-modal-header
         [title]="t('ErrorsForLinkingAssets')"
         (closeModal)="close()"
      />
      <lim-ui-modal-body>
         <lim-ui-panel>
            @if (formattedCollisions.length === 0) {
               <lim-ui-alert class="mb-medium-large" alertType="success">
                  <span>{{ t("ConflictsHaveBeenResolved") }}</span>
               </lim-ui-alert>
            } @else {
               <lim-ui-alert class="mb-medium-large" alertType="danger">
                  <div>
                     <p class="extra-bold">{{ t("UnableToMergeFields") }}</p>
                     <p>
                        {{ t("AssetsCannotBeLinkedConflicts") }}
                     </p>
                     <p>
                        {{ t("ToContinueRemoveFields") }}
                     </p>
                  </div>
               </lim-ui-alert>
            }
            <div>
               @for (
                  conflict of this.formattedCollisions;
                  track conflict.templateFieldName + conflict.assetName
               ) {
                  <div class="mb-medium-large conflict">
                     <div class="local-fields-container">
                        <div class="name-container">
                           <lim-ui-icon icon="cube" />
                           <lim-ui-text-button
                              data-log="assetTemplates-mergeErrorModal-clickAssetName"
                              (click)="
                                 openAssetModal(conflict.assetID, conflict.locationID)
                              "
                              >{{ conflict.assetName }}
                           </lim-ui-text-button>
                        </div>
                        @for (localField of conflict.localFields; track localField.ID) {
                           <div class="field">
                              <span>{{ localField.name }}</span>
                              <input
                                 type="text"
                                 class="field-value"
                                 value="w545435"
                                 disabled
                              />
                           </div>
                        }
                     </div>
                     <div class="merge-icon-container">
                        <lim-ui-icon icon="merge" />
                     </div>
                     <div class="template-field-container">
                        <div class="name-container">
                           <lim-ui-icon icon="books" />
                           <span>{{ templateName() }}</span>
                        </div>
                        <div class="field">
                           <span>{{ conflict.templateFieldName }}</span>
                           <input type="text" class="field-value" disabled />
                        </div>
                     </div>
                  </div>
               }
            </div>
         </lim-ui-panel>
      </lim-ui-modal-body>
      <lim-ui-modal-footer>
         <lim-ui-primary-button
            (click)="close()"
            data-log="assetTemplates-mergeErrorModal-clickClose"
            >{{ t("Close") }}</lim-ui-primary-button
         >
      </lim-ui-modal-footer>
   </lim-ui-modal>
</ng-container>
