@if (showCellContent()) {
   <e-menu #menu [items]="menuItems" [popup]="true" />
   <e-button
      [iconName]="'ellipsisVertical'"
      [iconColor]="'standard'"
      [iconSize]="'small'"
      [type]="'secondary'"
      [outlined]="true"
      [size]="'extra-small'"
      [ariaLabel]="'Work Request actions menu'"
      (click)="menu.togglePopup($event)"
   />
}
