import { CommonModule } from "@angular/common";
import { Component, inject, type OnInit } from "@angular/core";
import { BaseCellRendererComponent } from "@empowered/base/table/components/cells-renderers/base-cell-renderer.component.ts/base-cell-renderer.component";
import { MinimalIconButtonComponent, TooltipDirective } from "@limblecmms/lim-ui";
import { TranslateDirective } from "src/app/languages/i18n/translate.directive";
import type { TagDetailsCellRenderer } from "src/app/shared/empowered/base/table/components/cells-renderers/cell-renderers.types";
import type { DueDateCellData } from "src/app/shared/empowered/base/table/components/cells-renderers/due-date-cell/due-date-cell-renderer.models";
import { TagComponent } from "src/app/shared/empowered/base/tag/tag.component";
import type { TagSeverityTrio } from "src/app/shared/empowered/base/tag/tag.component.types";
import { BetterDecimalPipe } from "src/app/shared/pipes/betterDecimal.pipe";
import type {
   ChangeDueDateParams,
   DeferTaskParams,
   EmailReminderParams,
} from "src/app/tasks/components/shared/services/tasks-facade";
import { TasksFacadeService } from "src/app/tasks/components/shared/services/tasks-facade/tasks-facade.service";

@Component({
   selector: "due-date-cell-renderer",
   imports: [
      CommonModule,
      MinimalIconButtonComponent,
      TooltipDirective,
      BetterDecimalPipe,
      TranslateDirective,
      TagComponent,
   ],
   templateUrl: "./due-date-cell-renderer.component.html",
   styleUrls: ["./due-date-cell-renderer.component.scss"],
})
export class DueDateCellRendererComponent
   extends BaseCellRendererComponent<DueDateCellData>
   implements OnInit
{
   private readonly facade = inject(TasksFacadeService);

   protected get estimatedTime(): number {
      return (this.cell?.checklistEstTime ?? 0) * 1000;
   }

   /**
    * This property is intended for the printable version of the corresponding custom dashboard task list widget.
    * It prevents unnecessary interactive elements from rendering in the print-only context.
    */
   protected get printOnly(): boolean {
      return this.cell?.printOnly ?? false;
   }

   protected canDeferTask = false;
   protected canChangeDueDate = false;

   protected get tagDetails(): TagDetailsCellRenderer | undefined {
      if (this.cell?.daysInfo?.exactDays === null) return undefined;

      const tooltip = [this.cell?.daysInfo?.days, this.cell?.daysInfo?.daysMsg]
         .filter(Boolean)
         .join(" ");

      let severity: TagSeverityTrio = undefined;
      // eslint-disable-next-line default-case, typescript/switch-exhaustiveness-check -- this is intentional
      switch (this.cell?.checklistColor) {
         case 0:
         case 3:
            severity = "danger";
            break;
         case 1:
            severity = "success";
            break;
         case 2:
            severity = "warn";
            break;
      }

      return {
         tooltip,
         severity,
         value: (this.cell?.daysInfo?.exactDays ?? 0).toString(),
      };
   }

   public ngOnInit(): void {
      this.canDeferTask = this.facade.getPermissionToDeferTask();
      this.canChangeDueDate = this.facade.getPermissionToChangeDueDate(
         this.cell.locationID,
      );
   }

   public emailReminder($event): void {
      this.facade.emailReminder(this.cell as EmailReminderParams);
      $event.stopPropagation();
   }

   public changeDueDate($event): void {
      $event.stopPropagation();

      this.facade.changeDueDate(this.cell as ChangeDueDateParams);
   }

   public deferTask($event): void {
      this.facade.deferTask(this.cell as DeferTaskParams);
      $event.stopPropagation();
   }
}
