import type { Possible } from "src/app/shared/empowered/types/util";
import type { RequestParam } from "src/app/shared/services/flannel-api-service";

export interface SortEvent {
   columnKey: string;
   sort: Possible<TableSortDirection>;
}

export enum TableSortDirection {
   ASC = "asc",
   DESC = "desc",
   UNSET = "unset",
}

export interface TableColumn {
   field: string;
   headerNameKey: string;
   cellRenderer?: any;
   maxWidth?: number;
   minWidth?: number;
   pinned?: "left" | "right";
   sortBy?: string | null;

   /** Whether the column should be frozen */
   frozen?: boolean;

   /**
    * List of field keys that should be exported
    */
   exportableFields?: Array<string>;

   /**
    * List of extra columns to be added to the API request in order to render the column
    */
   extraColumns?: Array<string>;
   /**
    * List of parameters to be added to the API request in order to render the column
    */
   params?: RequestParam;

   /**
    * Whether the column is sortable
    */
   isSortable?: boolean;
}
